import {Directive, Input, ViewContainerRef, TemplateRef} from '@angular/core';
import {HasAnyRoleDirective, ROLE_ADMIN, OwnershipAccessState} from './has-any-role';

@Directive({
  selector: '[hasRoleAdmin]'
})
export class HasRoleAdminDirective extends HasAnyRoleDirective {

    constructor(protected templateRef: TemplateRef<any>,
        protected viewContainer: ViewContainerRef) {
        super(templateRef, viewContainer);
    }

    @Input()
    set hasRoleAdmin(accessState: OwnershipAccessState) {
        this.currentUserRoles =  accessState.currentUserRoles;
        this.checkRoles([ROLE_ADMIN]);
    }

}
