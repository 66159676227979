<form class="agent-update-form aed-entity-form" (ngSubmit)="onSubmit(identiteForm)" #identiteForm="ngForm" novalidate>
  <div class="row">

    <div class="form-group col-md-6 col-lg-6">
      <label for="field_nom">Nom</label>
      <input type="text" class="form-control" id="field_nom" name="field_nom"
      [(ngModel)]="agent.field_nom"
      #fieldNom="ngModel"
      [disabled]="true">
    </div>
    <div class="form-group col-md-6 col-lg-6">
      <label for="field_prenom">Prénom</label>
      <input type="text" class="form-control" id="field_prenom" name="field_prenom"
      [(ngModel)]="agent.field_prenom"
      #fieldNom="ngModel"
      [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6">
      <label for="field_civilite">Civilité</label>
      <input type="text" class="form-control" id="field_emploi_fpt"
              name="field_civilite"
              [value]="agent.field_civilite"
              [disabled]="true">
        <!-- <select id="field_civilite" name="field_civilite" class="form-control"
                [(ngModel)]="agent.field_civilite" required
                #field_civilite="ngModel">
          <option value="F">Mme</option>
          <option value="M">Mr</option>
        </select> -->
    </div>
    <div class="form-group col-md-6 col-lg-6">
      <label for="field_date_de_naissance">Date de naissance</label>
      <input type="text" class="form-control" id="field_date_de_naissance"
      name="field_date_de_naissance"
      [(ngModel)]="agent.field_date_de_naissance ? agent.field_date_de_naissance.formatted : agent.field_date_de_naissance"
      #fieldDateDeNaissance="ngModel"
      [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6">
      <label for="mail">Courriel</label>
      <input type="text" class="form-control" id="mail" name="mail"
      [(ngModel)]="agent.mail"
      #fieldNom="ngModel"
      [disabled]="true">
    </div>
    <div class="form-group col-md-6 col-lg-6">
      <label for="field_email_de_secours">Courriel de secours</label>
      <input type="text" class="form-control" id="field_email_de_secours"
      name="field_email_de_secours"
      [(ngModel)]="agent.field_email_de_secours"
      #fieldNom="ngModel"
      [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6">
      <label for="field-telephone">Téléphone</label>
      <input type="text" class="form-control" id="field-telephone"
      name="field_telephone"
      [(ngModel)]="agent.field_telephone"
      #fieldTelephone="ngModel"
      [disabled]="true">
    </div>
    <div class="form-group col-md-6 col-lg-6">
      <label for="field-mobile">Mobile</label>
      <input type="text" class="form-control" id="field-mobile"
      name="field_mobile"
      [(ngModel)]="agent.field_mobile"
      #fieldTelephone="ngModel"
      [disabled]="true">
    </div>

    <!-- <div class="form-group col-md-12 col-lg-12">
      <button type="submit"
        class="arrow-btn btn btn-primary text-uppercase"
        [disabled]="identiteForm.invalid">
        Mettre à jour mes données personnelles
      </button>
    </div> -->

  </div>
</form>
