<!-- begin : BREADCRUMB -->
<div class="breadcrumb">
    <div class="container">
        <ul class="list-unstyled">
            <li class="bb-home">
                <a [routerLink]="'/'">
                    <span class="aed-icon aed-icon-home" aria-hidden="true"></span>
                    Accueil
                </a>
            </li>
            <li>
                {{ title }}
            </li>
        </ul>
    </div>
</div>
<!-- end : BREADCRUMB -->
