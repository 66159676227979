<form class="delete-dialog-form" name="deleteForm" (ngSubmit)="confirmDelete()">
    <div class="modal-header">
        <h2 class="modal-title bordered-title">Confirmation de {{ action }}</h2>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">
            <span class="aed-icon aed-icon-close" aria-hidden="true"></span>
        </button>
    </div>
    <div class="modal-body">
        <p>Êtes-vous sûr de vouloir {{ actionVerb }} {{ element }} ?</p>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-danger btn-dialog-sm">
            {{ actionVerb | titlecase }}
        </button>
        <button type="button" class="btn btn-third btn-dialog-sm" data-dismiss="modal" (click)="cancel()">
           Annuler
        </button>
    </div>
</form>
