import {CollectiviteResolve} from './collectivite.resolve';
import {FicheCtComponent} from './fiche-ct/fiche-ct.component';
import {FicheCtEditComponent} from './fiche-ct-edit/fiche-ct-edit.component';
import {AuthRequiredGuard} from '../authentification/auth-required.guard';
import {ROLE_ADMIN, ROLE_AGENT, ROLE_CNFPT, ROLE_REFERENT_CT} from '../authentification/has-any-role';
import { ListeCtComponent } from './liste-ct/liste-ct.component';
import { FicheEditEmploiComponent } from '../emploi/fiche-edit-emploi/fiche-edit-emploi.component';
import { EmploiResolve } from '../emploi/emploi.resolve';

export const collectiviteRoutes = [
    {
        path: 'collectivite/:id',
        resolve: {
            collectivite: CollectiviteResolve
        },
        component: FicheCtComponent,
        data: {
            title: 'Fiche collectivité',
        }
    },
    {
        path: 'collectivite/:id/edit',
        resolve: {
            collectivite: CollectiviteResolve
        },
        canActivate: [AuthRequiredGuard],
        component: FicheCtEditComponent,
        data: {
            title: 'Modification d\'une collectivité',
            roles: [ROLE_CNFPT, ROLE_ADMIN],
            rolesWithOwnership: [ROLE_REFERENT_CT],
            ownershipMethod: 'collectivite'
        }
    },
    {
        path: 'mes-collectivites',
        canActivate: [AuthRequiredGuard],
        component: ListeCtComponent,
        data: {
            title: 'Mes collectivités',
            roles: [ROLE_CNFPT, ROLE_ADMIN, ROLE_REFERENT_CT],
            rolesWithOwnership: [ROLE_REFERENT_CT],
            ownershipMethod: 'collectivite'
        }
    },
    {
        path: 'collectivite/:idCollectivite/emploi/new',
        resolve: {
            collectivite: CollectiviteResolve
        },
        canActivate: [AuthRequiredGuard],
        component: FicheEditEmploiComponent,
        data: {
            title: 'Ajout d\'un emploi',
            roles: [ROLE_CNFPT, ROLE_ADMIN, ROLE_REFERENT_CT]
        }
    },
    {
        path: 'collectivite/:idCollectivite/emploi/:id',
        resolve: {
          collectivite: CollectiviteResolve,
          emploi: EmploiResolve
        },
        canActivate: [AuthRequiredGuard],
        component: FicheEditEmploiComponent,
        data: {
            title: 'Modification d\'un emploi',
            roles: [ROLE_CNFPT, ROLE_ADMIN, ROLE_REFERENT_CT]
        }
    }
];
