
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';


import {HttpClient} from '@angular/common/http';
import {getDrupalUri} from '../shared/external';

@Injectable()
export class PageService {

    public pagePath = '/customapi/page/';
    public blockPath = '/customapi/block/';

    constructor(private http: HttpClient) {
    }

    /**
     *
     * @param slug
     * @returns {Observable<any>}
     */
    public getPageBySlug(slug: any): Observable<any> {
        return this.http.get<any>(
            getDrupalUri() + this.pagePath + 'detail/' + slug).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     *
     * @param name
     * @returns {Observable<any>}
     */
    public getMenuLinks(name: any): Observable<any> {
        return this.http.get<any>(
            getDrupalUri() + this.pagePath + 'menu/' + name).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     * Get a block content
     * @param name
     * @param field
     * @returns {Observable<any>}
     */
    public getBlockContentByNameAndField(name: any, field: any): Observable<any> {
        return this.http.get<any>(
            getDrupalUri() + this.blockPath + name + '/' + field).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

}
