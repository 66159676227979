import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-social-networks',
    templateUrl: './social-networks.component.html',
    styleUrls: ['./social-networks.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SocialNetworksComponent implements OnInit {

    @Input() owner: any;

    constructor() {
    }

    ngOnInit() {
    }

}
