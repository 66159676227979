<form class="ct-update-form" (ngSubmit)="onSubmit(archivageForm)" #archivageForm="ngForm" novalidate>

  <div class="row">
    <div class="form-group col-md-12 col-lg-12">
      <label class="form-check-label label-alone">
        Archiver la collectivité ?
      </label>
      <div class="form-check form-check-inline">
        <label class="form-check-label" for="archivage_y">
          <input class="form-check-input" type="radio"
            name="archivage" id="archivage_y" value="1"
            [checked]="this.showArchivage"
            (click)="setCollectiviteArchivage(true)">
          Oui
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label" for="archivage_n">
          <input class="form-check-input" type="radio"
            name="archivage" id="archivage_n" value="0"
            [checked]="!this.showArchivage"
            (click)="setCollectiviteArchivage(false)">
          Non
        </label>
      </div>
    </div>
  </div>

  <div *ngIf="this.showArchivage">
    <div class="row">
      <div class="form-group col-md-6 col-lg-6">
        <label for="date_archivage">Date souhaitée de l'archivage</label>
        <div class="input-group">
          <input class="form-control" name="date_archivage" [(ngModel)]="this.date" ngbDatepicker #d="ngbDatepicker" [placement]="'bottom'">
          <div class="input-group-append">
            <button class="btn btn-primary aed-icon aed-icon-calendar" (click)="d.toggle()" type="button">
              <span class="sr-only sr-only-focusable">Ouvrir le calendrier</span>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group col-md-6 col-lg-6">
        <label for="motif_archivage">Motif de l'archivage</label>
          <input type="text" id="motif_archivage" name="motif_archivage" class="form-control"
            [(ngModel)]="this.collectivite.fields_archivage.motif_archivage"
            #motif_archivage="ngModel" required>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-6 col-lg-6">
        <app-aed-typeahead
          (onChoose)="setCollectiviteTransfert($event)"
          [retriever]="'searchPlaces'"
          [text]="'Sélectionner une collectivité de transfert'"
          [description]="'Code postal (3 caractères minimum) et/ou libellé de la ville de la collectivité (3 caractères minimum)'"
          [placeholder]="'ex: 34800'"
          [defaultValue]="this.collectivite.fields_archivage.collectivite_transfert"
          [showLabel]="true">
        </app-aed-typeahead>
      </div>

      <div *ngIf="this.collectivite.fields_archivage.collectivite_transfert" class="form-group col-md-6 col-lg-6">
        <label class="form-check-label label-alone" for="transfert_referents">
          Souhaitez-vous ventiler les référents CT vers la nouvelle Collectivité ?
        </label>
        <div class="form-check form-check-inline">
          <label class="form-check-label" for="transfert_referents_y">
            <input class="form-check-input" type="radio"
            name="transfert_referents" id="transfert_referents_y" value="1"
            [checked]="this.collectivite.fields_archivage.transfert_referents"
            (click)="this.collectivite.fields_archivage.transfert_referents = 1">
            Oui
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label" for="transfert_referents_n">
            <input class="form-check-input" type="radio"
            name="transfert_referents" id="transfert_referents_n" value="0"
            [checked]="!this.collectivite.fields_archivage.transfert_referents"
            (click)="this.collectivite.fields_archivage.transfert_referents = 0">
            Non
          </label>
        </div>
      </div>
    </div>

    <div class="form-group col-md-12 col-lg-12">
      <button type="submit" class="arrow-btn btn btn-primary text-uppercase" [disabled]="archivageForm.invalid">
        Archiver la collectivité
      </button>
    </div>
  </div>

</form>
