import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-historique-affectation',
    templateUrl: './historique-affectation.component.html',
    styleUrls: ['./historique-affectation.component.scss']
})
export class HistoriqueAffectationComponent implements OnInit {
    @Input() history: any;
    @Input() currentAgent: any;

    public historyAffectations: any;

    constructor() {
    }

    ngOnInit() {
        this.historyAffectations = this.history;
        // this.historyAffectations = this.history.filter(
        //     affectation => affectation.agent.id !== this.currentAgent);

    }

}
