import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-dve-teaser',
    templateUrl: './dve-teaser.component.html',
    styleUrls: ['./dve-teaser.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DveTeaserComponent implements OnInit {

    @Input() dve: any;


    constructor() {
    }

    ngOnInit() {
    }

}
