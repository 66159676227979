<div class="modal-header">
    <h2 class="bordered-title bolded" *ngIf="!emploi?.id">
        Saisir un emploi
    </h2>
    <h2 class="bordered-title bolded" *ngIf="emploi?.id">
        Modifier un emploi
    </h2>

    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span class="aed-icon aed-icon-close" aria-hidden="true"></span>
        <span class="sr-only" aria-hidden="true">Fermer</span>
    </button>
</div>
<div class="modal-body">
    <div class="inner">
        <form class="add-emploi-form" #emploiForm="ngForm" (ngSubmit)="onSubmit(emploiForm)" novalidate>
            <div class="row" *ngIf="isEditedByReferent">
                <div class="col-md-12">
                    <div class="alert alert-info">
                        <h3 class="alert-heading">Information</h3>
                        <p *ngIf="emploi.collectivite.strate_population_min < 40000">Le formulaire vous permet d’enregistrer uniquement les emplois fonctionnels de votre collectivité ou les emplois de conservateurs de bibliothèques ou du patrimoine.</p>
                        <p *ngIf="emploi.collectivite.strate_population_min >= 40000">Le formulaire vous permet d’enregistrer tous les emplois de direction fonctionnels ou non de votre collectivité qui sont ou qui ont été occupés par des agents de catégorie A+ (administrateur, ingénieur en chef,
                            conservateur des bibliothèques, conservateur du patrimoine).</p>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!isEditedByReferent">
                <div class="form-group col-md-12">
                    <div class="btn-group btn-group-toggle" ngbRadioGroup name="type" [(ngModel)]="emploi.type" required #type="ngModel">
                        <label ngbButtonLabel class="btn-light">
                            <input ngbButton type="radio" value="1"> Collectivité locale
                        </label>
                        <label ngbButtonLabel class="btn-light">
                            <input ngbButton type="radio" value="2"> Autre employeur
                        </label>
                        <label ngbButtonLabel class="btn-light">
                            <input ngbButton type="radio" value="3"> Autre affectation
                        </label>
                    </div>
                    <div *ngIf="type?.errors && type?.invalid && (type?.dirty || type?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="type?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>
            </div>

            <!-- begin: PAGE CONTENT / EMPLOYER -->
            <div class="row" *ngIf="emploi.type != undefined && emploi.type != '3'">
                <div class="col-md-12 col-lg-12" *ngIf="!isEditedByReferent">
                    <h2 class="text-uppercase bolded bordered-title bt-secondary">
                        Choix de l'employeur
                    </h2>
                </div>

                <div class="col-md-12 col-lg-12" *ngIf="emploi.type == '1' && collectivite == null">
                    <app-aed-typeahead (onChoose)="onPlaceSelection($event)"
                                       [retriever]="isConservateur ? 'searchPlaces' : 'searchCollectiviteRefAplus'"
                                       [text]="'Dans quelle collectivité travaillez-vous ?'"
                                       [description]="'Code postal (3 caractères minimum) et/ou libellé de la ville de la collectivité (3 caractères minimum)'"
                                       [placeholder]="'ex: 34800'"
                                       [showLabel]="true">
                    </app-aed-typeahead>
                    <p class="field-info margin-bottom">
                        Votre collectivité n’apparait pas dans cette liste ? Assurez-vous que vous avez saisi le bon
                        code postal. En cas d’absence avérée, vous pouvez <a href="javascript:void(0);"
                                                                             class="clickable"
                                                                             (click)="notifyCnfpt('contact_cnfpt_missing_ct')">contacter
                        le CNFPT</a> pour que
                        la collectivité soit rajoutée.
                    </p>
                </div>

                <div class="form-group col-md-7" *ngIf="emploi.type == '1' && showCollectiviteEmploiList">
                    <label for="emploi">Quel emploi ?<span class="required-field"> (obligatoire)</span></label>
                    <angular2-multiselect [data]="colEmploisList"
                        [(ngModel)]="selectedEmploi"
                        [ngModelOptions]="{standalone: true}"
                        [settings]="settingsSingle"
                        (ngModelChange)="applyEmploiRules($event)"
                        class="single-select"
                        >
                        <c-badge>
                          <ng-template let-item="item">
                            <label>{{item.label ? item.label : item.name}}</label>
                          </ng-template>
                        </c-badge>
                    </angular2-multiselect>
                </div>
                <div class="form-group col-md-5" *ngIf="showFonction">
                    <label for="emploiFonction">
                        Titre de l'emploi
                        <span class="required-field">(obligatoire)</span>
                    </label>
                    <input type="text" class="form-control" id="emploiFonction" name="emploiFonction"
                           [(ngModel)]="emploi.emploiFonction" required #emploiFonction="ngModel">

                    <div *ngIf="emploiFonction?.errors && emploiFonction?.invalid && (emploiFonction?.dirty || emploiFonction?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="emploiFonction?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>

                <div class="form-group col-md-12 col-lg-12" *ngIf="emploi.type == '1'">
                    <label class="form-check-label label-alone">
                        Emploi fonctionnel
                        <span class="required-field">(obligatoire)</span>
                    </label>

                    <div class="form-check form-check-inline" *ngIf="!staticFonctionnel">
                        <label class="form-check-label" for="emploiFonctionnelYes">
                            <input class="form-check-input" type="radio" name="emploiFonctionnel"
                                   id="emploiFonctionnelYes" [value]="true"
                                   [ngModel]="emploi.fonctionnel" required #emploiFonctionnel="ngModel">
                            Oui
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <label class="form-check-label" for="emploiFonctionnelNo" *ngIf="!staticFonctionnel">
                            <input class="form-check-input" type="radio" name="emploiFonctionnel"
                                   id="emploiFonctionnelNo" [value]="false"
                                   [ngModel]="emploi.fonctionnel" required #emploiFonctionnel="ngModel">
                            Non
                        </label>
                    </div>

                    <div *ngIf="(emploiFonctionnel?.errors && emploiFonctionnel?.invalid && (emploiFonctionnel?.dirty || emploiFonctionnel?.touched)) && !staticFonctionnel" class="form-error alert alert-danger">
                        <small *ngIf="emploiFonctionnel?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>

                    <div class="form-check form-check-inline">
                        <label class="form-check-label" for="emploiFonctionnelStatic" *ngIf="staticFonctionnel">
                            <input type="hidden" name="emploiFonctionnel"
                                   id="emploiFonctionnelStatic"
                                   [(ngModel)]="emploi.fonctionnel"
                                   #emploiFonctionnel="ngModel">
                            {{emploi.fonctionnel ? 'Oui' : 'Non'}}
                        </label>
                    </div>
                </div>

                <div class="col-md-4 col-lg-4" *ngIf="emploi.type == '2'">
                    <label for="employeur">Quel employeur ?<span class="required-field">(obligatoire)</span></label>
                    <input type="text" class="form-control" id="employeur" name="employeur" [(ngModel)]="emploi.employeur" required #employeur="ngModel">
                    <div *ngIf="employeur?.errors && employeur?.invalid && (employeur?.dirty || employeur?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="employeur?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>

                <div class="col-md-4 col-lg-4" *ngIf="emploi.type === '2'">
                    <label for="employeurType">
                        Type d’employeur
                        <span class="required-field">(obligatoire)</span>
                    </label>
                    <select id="employeurType" name="employeurType" class="form-control" [(ngModel)]="emploi.employeurType" required #employeurType="ngModel">
                        <option *ngFor="let typeEmp of typeEmployeurList" [value]="typeEmp.id">{{ typeEmp.name }}
                        </option>
                    </select>
                    <div *ngIf="employeurType?.errors && employeurType?.invalid && (employeurType?.dirty || employeurType?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="employeurType?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>

                <div class="col-md-4 col-lg-4" *ngIf="emploi.type === '2'">
                    <app-aed-typeahead (onChoose)="onEmployeurPlaceSelection($event)"
                                       [retriever]="'searchCities'"
                                       [text]="'Code postal (obligatoire)'"
                                       [showLabel]="true">
                    </app-aed-typeahead>
                </div>

                <!-- FILIERE -->
                <div class="form-group cds-single col-md-6 col-lg-6" *ngIf="emploi.type == '1'">
                    <label for="filiere">
                        Filière de l'emploi
                        <span class="required-field">(obligatoire)</span>
                    </label>
                    <angular2-multiselect [data]="filiereList"
                        [(ngModel)]="selectedFiliere"
                        [ngModelOptions]="{standalone: true}"
                        [settings]="settingsSingle"
                        id="filiere" #filiere="ngModel"
                        class="single-select"
                        >
                        <c-badge>
                          <ng-template let-item="item">
                            <label>{{item.label ? item.label : item.name}}</label>
                          </ng-template>
                        </c-badge>
                    </angular2-multiselect>

                    <div *ngIf="filiere?.errors && filiere?.invalid && (filiere?.dirty || filiere?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="filiere?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>

                <!-- DOMAINE -->
                <div class="form-group cds-single col-md-6 col-lg-6" *ngIf="emploi.type == '1'">
                    <label for="domaine_famille">
                        Domaine de l'emploi
                        <span class="required-field">(obligatoire)</span>
                    </label>
                    <angular2-multiselect [data]="domaineList"
                        [(ngModel)]="selectedDomaine"
                        [ngModelOptions]="{standalone: true}"
                        [settings]="settingsSingle"
                        id="domaine_famille" required #domaine_famille="ngModel"
                        class="single-select"
                        >
                        <c-badge>
                          <ng-template let-item="item">
                            <label>{{item.label ? item.label : item.name}}</label>
                          </ng-template>
                        </c-badge>
                    </angular2-multiselect>

                    <div *ngIf="domaine_famille?.errors && domaine_famille?.invalid && (domaine_famille?.dirty || domaine_famille?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="domaine_famille?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>

                <div class="form-group col-md-12" *ngIf="isEditedByReferent && !emploi?.affectation?.current?.id">
                    <label>Emploi occupé actuellement ?</label>
                    <div class="btn-group btn-group-toggle" ngbRadioGroup name="typeAffectation" [(ngModel)]="emploi.typeAffectation" #type="ngModel" required>
                        <label ngbButtonLabel [class.active]="emploi.typeAffectation == '1'" [class.btn-light]="true">
                            <input ngbButton type="radio" value="1" autocomplete="off" aria-describedby="posteVacantHelpBlock"> Poste vacant
                        </label>
                        <label ngbButtonLabel [class.active]="emploi.typeAffectation == '2'" [class.btn-light]="true">
                            <input ngbButton type="radio" value="2" autocomplete="off"> Sans information sur l'affectation
                        </label>
                        <label ngbButtonLabel [class.active]="emploi.typeAffectation == '3'" [class.btn-light]="true">
                            <input ngbButton type="radio" value="3" autocomplete="off"> Déclarer une affectation
                        </label>
                    </div>
                    <small id="posteVacantHelpBlock" class="form-text text-muted">
                        Attention : cette information ne vaut pas déclaration de vacance de poste.
                    </small>
                </div>
            </div>
            <div class="row">

                <div class="col-md-12" *ngIf="emploi.typeAffectation == '3'">
                    <label for="declarationNom">Civilité <span class="required-field">(obligatoire)</span></label>
                </div>
                <div class="form-group col-md-4" *ngIf="emploi.typeAffectation == '3'">
                    <div class="btn-group btn-group-toggle" ngbRadioGroup name="declarationCivilite" [(ngModel)]="emploi.declarationCivilite" #declarationCivilite="ngModel" required>
                        <label ngbButtonLabel class="btn-light">
                            <input ngbButton type="radio" value="Mme" autocomplete="off"> Mme
                        </label>
                        <label ngbButtonLabel class="btn-light">
                            <input ngbButton type="radio" value="M." autocomplete="off"> M.
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 col-lg-6" *ngIf="emploi.typeAffectation == '3'">
                    <label for="declarationPrenom">Prénom <span class="required-field">(obligatoire)</span></label>
                    <input type="text" class="form-control" id="declarationPrenom" name="declarationPrenom" [(ngModel)]="emploi.declarationPrenom" required #declarationPrenom="ngModel">
                    <div *ngIf="declarationPrenom?.errors && declarationPrenom?.invalid && (declarationPrenom?.dirty || declarationPrenom?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="declarationPrenom?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>
                <div class="form-group col-md-6 col-lg-6" *ngIf="emploi.typeAffectation == '3'">
                    <label for="declarationNom">Nom <span class="required-field">(obligatoire)</span></label>
                    <input type="text" class="form-control" id="declarationNom" name="declarationNom" [(ngModel)]="emploi.declarationNom" required #declarationNom="ngModel">
                    <div *ngIf="declarationNom?.errors && declarationNom?.invalid && (declarationNom?.dirty || declarationNom?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="declarationNom?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>

                <div class="form-group col-md-5 month-selector" *ngIf="emploi.typeAffectation == '3' || (emploi.typeAffectation == undefined && (emploi.type == '1' || emploi.type == '2'))">
                    <label for="emploiSinceMois">
                        Date de prise de poste
                        <span class="required-field">(obligatoire)</span>
                    </label>
                    <div>
                        <select id="emploiSinceMois" class="form-control" name="emploiSinceMois"
                                [(ngModel)]="emploi.emploiSinceMois" #emploiSinceMois="ngModel" (change)="validateTimePeriod()" required>
                            <option value="01">Janvier</option>
                            <option value="02">Février</option>
                            <option value="03">Mars</option>
                            <option value="04">Avril</option>
                            <option value="05">Mai</option>
                            <option value="06">Juin</option>
                            <option value="07">Juillet</option>
                            <option value="08">Août</option>
                            <option value="09">Septembre</option>
                            <option value="10">Octobre</option>
                            <option value="11">Novembre</option>
                            <option value="12">Décembre</option>
                        </select>
                        <select id="emploiSinceAnnee" class="form-control" name="emploiSinceAnnee"
                                [(ngModel)]="emploi.emploiSinceAnnee" #emploiSinceAnnee="ngModel" (change)="validateTimePeriod()" required>
                            <option [value]="dr40Date" *ngFor="let dr40Date of datesRange40">{{ dr40Date }}
                            </option>
                        </select>
                        <div *ngIf="(emploiSinceMois?.errors && emploiSinceMois?.invalid && (emploiSinceMois?.dirty || emploiSinceMois?.touched)) || (emploiSinceAnnee?.errors && emploiSinceAnnee?.invalid && (emploiSinceAnnee?.dirty || emploiSinceAnnee?.touched))"
                             class="form-error alert alert-danger">
                            <small *ngIf="emploiSinceMois?.errors?.required">
                                Le champ mois est requis.
                            </small>
                            <small *ngIf="emploiSinceAnnee?.errors?.required">
                                Le champ année est requis.
                            </small>
                        </div>
                    </div>
                </div>

                <div class="form-group col-md-2" id="ongoing-container" *ngIf="emploi.typeAffectation == '3' || (emploi.typeAffectation == undefined && (emploi.type == '1' || emploi.type == '2'))">
                    <label class="form-check-label" for="ongoing">
                        <input class="form-check-input" type="checkbox" name="ongoing" id="ongoing"
                               [(ngModel)]="emploi.ongoing" #ongoing="ngModel" [disabled]="isEditedByReferent">
                        Poste actuel
                    </label>
                </div>

                <div class="form-group col-md-5 month-selector" *ngIf="!isEditedByReferent && (emploi.type == '1' || emploi.type == '2') && !emploi.ongoing">
                    <label for="emploiEndMois">
                        Date de fin du poste
                        <span class="required-field">(obligatoire)</span>
                    </label>
                    <div>
                        <select id="emploiEndMois" class="form-control" name="emploiEndMois"
                                [(ngModel)]="emploi.emploiEndMois" #emploiEndMois="ngModel" (change)="validateTimePeriod()" required>
                            <option value="01">Janvier</option>
                            <option value="02">Février</option>
                            <option value="03">Mars</option>
                            <option value="04">Avril</option>
                            <option value="05">Mai</option>
                            <option value="06">Juin</option>
                            <option value="07">Juillet</option>
                            <option value="08">Août</option>
                            <option value="09">Septembre</option>
                            <option value="10">Octobre</option>
                            <option value="11">Novembre</option>
                            <option value="12">Décembre</option>
                        </select>
                        <select id="emploiEndAnnee" class="form-control" name="emploiEndAnnee"
                                [(ngModel)]="emploi.emploiEndAnnee" #emploiEndAnnee="ngModel" (change)="validateTimePeriod()" required>
                            <option [value]="dr40Date" *ngFor="let dr40Date of datesRange40">{{ dr40Date }}
                            </option>
                        </select>
                        <div *ngIf="(emploiEndMois?.errors && emploiEndMois?.invalid && (emploiEndMois?.dirty || emploiEndMois?.touched)) || (emploiEndAnnee?.errors && emploiEndAnnee?.invalid && (emploiEndAnnee?.dirty || emploiEndAnnee?.touched)) || invalidTimePeriod"
                             class="form-error alert alert-danger">
                            <small *ngIf="emploiEndMois?.errors?.required">
                                Le champ mois est requis.
                            </small>
                            <small *ngIf="emploiEndAnnee?.errors?.required">
                                Le champ année est requis.
                            </small>
                            <small *ngIf="invalidTimePeriod">
                                La date de fin doit être postérieure ou égale à la date de début
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end: PAGE CONTENT / EMPLOYER -->
            <div class="row" *ngIf="emploi.type == '3'">
                <div class="col-md-12 col-lg-12">
                    <div class="alert alert-warning">En sélectionnant autre affectation, votre dernière affectation aura pour date de fin {{currentMonth}}/{{currentYear}} et vous apparaitrez dans l’annuaire comme occupant une autre affectation, sans précision.</div>
                </div>
            </div>

            <div class="row" *ngIf="showProvidedAlert">
                <div class="col-md-12 col-lg-12">
                    <div class="alert alert-warning">Une personne occupe actuellement ce poste, confirmez-vous votre affectation sur ce poste ?</div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <button type="submit"
                            class="arrow-btn btn btn-primary text-uppercase"
                            [disabled]="emploiForm.invalid || (emploi.type === '2' && !collectiviteIsSelected)" *ngIf="!providedAlert">
                        Valider
                    </button>
                    <button type="button"
                            class="arrow-btn btn btn-primary text-uppercase"
                            [disabled]="emploiForm.invalid || (emploi.type === '2' && !collectiviteIsSelected)" *ngIf="providedAlert && !showProvidedAlert" (click)="showProvidedAlert = true">
                        Valider
                    </button>

                    <button type="submit" class="arrow-btn btn btn-success text-uppercase mar-r-10" [disabled]="emploiForm.invalid || (emploi.type === '2' && !collectiviteIsSelected)" *ngIf="providedAlert && showProvidedAlert">
                        Valider
                    </button>
                    <button type="button" (click)="showProvidedAlert = false" class="arrow-btn btn btn-primary text-uppercase mar-r-10" *ngIf="providedAlert && showProvidedAlert">
                        Modifier
                    </button>
                    <ng-container *hasRoleCnfpt="accessState">
                        <button type="button" id="delete-button"
                                class="arrow-btn btn btn-danger text-uppercase float-right" (click)="openDeleteDialog()"
                                *ngIf="emploi?.id">
                            Supprimer
                        </button>
                    </ng-container>
                    <ng-container *hasRoleReferentCT="accessState">
                        <ng-container *ngIf="emploi?.id && emploi?.is_valid">
                            <button type="button" class="arrow-btn btn btn-danger text-uppercase float-right" (click)="changeEmploiValidity()">
                                <span>Désactiver</span>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="emploi?.id && !emploi?.is_valid">
                            <button type="button" class="arrow-btn btn btn-success text-uppercase float-right" (click)="changeEmploiValidity()">
                                <span>Activer</span>
                            </button>
                        </ng-container>
                    </ng-container>
                </div>

            </div>
        </form>
    </div>
</div>
