<app-breadcrumb></app-breadcrumb>

<div class="container-fluid">

  <div class="row col-md-12 col-lg-12 justify-content-center">

    <div class="col-md-7 col-lg-7">

      <h1 class="page-title bordered-title bolded">Les actualités</h1>

      <div class="aed-news" *ngIf="newsList != null && newsList.length > 0">

        <div class="ahn-news row" *ngFor="let news of newsList;">

          <div class="col-md-4 col-lg-4">
            <a [routerLink]="['/news', news.id]">
              <img class="img-fluid" src="assets/images/nothumb.png" [alt]="news.titre" *ngIf="!news.vignette">
              <img class="img-fluid" [src]="news.vignette" [alt]="news.titre" *ngIf="news.vignette">
            </a>
          </div>

          <div class="col-md-8 col-lg-8 inner">
            <a [routerLink]="['/news', news.id]">
              <h2>{{news.titre}}</h2>
            </a>
            <div class="news-listing-date">{{news.date}}</div>
            <div>{{news.summary}}</div>
          </div>

        </div>

      </div>

      <div class="row justify-content-center" *ngIf="showMoreButton">
        <a class="btn arrow-btn btn-third text-uppercase" title="Voir toutes les actualités" (click)="loadMore(false)">
          Voir plus d'actualités
        </a>
      </div>

    </div>

  </div>

</div>