<table class="table table-striped">
  <thead>
    <tr class="table-header">
      <th scope="col">#</th>
      <th scope="col">Nom</th>
      <th scope="col">Réferent depuis</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let referent of collectivite.referents; index as i">
      <td>{{ i + 1 }}</td>
      <td><a class="clickable" (click)="goToProfilUtilisateur(referent.id)">{{ referent.label }}</a></td>
      <td>{{ referent.dateDebutPosteReferent }}</td>
    </tr>
  </tbody>
</table>
