import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {DveService} from './dve.service';

@Injectable()
export class DveResolve implements Resolve<any> {
    constructor(private dveService: DveService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.dveService.get(route.paramMap.get('num'));
    }
}
