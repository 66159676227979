import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'profil-utilisateur-demandes',
  templateUrl: './demandes.component.html',
  styleUrls: ['./demandes.component.scss']
})
export class DemandesComponent implements OnInit {

  @Input() agent: any;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  goToProfilCollectivite(id: string) {
    this.router.navigate(['dashboard/profil-collectivite', id]);
  }

  getStatusAsString(status: any) {
    switch (status) {
      case "1":
        return "A traiter";
      case "2":
        return "En attente"
      case "3":
        return "Rejetée"
      case "4":
        return "Validée"
      default:
        return "Inconnu"
    }
  }
}
