import {Component, Input, OnInit} from '@angular/core';

/**
 * EXAMPLE :
 * <app-alert  *ngIf="success === true"
 [isAlertClosable]="false"
 [alertType]="'success'"
 [alertMessage]="'Le profil a été mis à jour avec succès.'"
 >
 </app-alert>
 */
@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

    // Alert type: { success | danger | warning }
    @Input() alertType: string;
    @Input() alertMessage: string;
    @Input() listError: string[];

// Set to true to display a close button, if the alert can be closed
    @Input() isAlertClosable = false;

    public isAlertVisible = false;

    constructor() {
    }

    ngOnInit() {
    }

    /**
     * Close the alert
     */
    public closeAlert(): void {
        this.isAlertVisible = true;
    }
}
