import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CollectiviteService} from '../../collectivite/collectivite.service';
import {LoaderService} from '../../shared/loader/loader.service';

@Component({
  selector: 'app-profil-collectivite',
  templateUrl: './profil-collectivite.component.html',
  styleUrls: ['./profil-collectivite.component.scss']
})
export class ProfilCollectiviteComponent implements OnInit {

  collectivite: any;

  success: boolean = undefined;
  notifySuccess: boolean = undefined;
  archivageSuccess: boolean = undefined;

  fieldsError = false;

  constructor(
    private route: ActivatedRoute,
    private collectiviteService: CollectiviteService,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.collectivite = this.route.snapshot.data['collectivite'];
  }

  public refresh() {
    const id = this.collectivite.id;

    this.collectiviteService.getWithRelationships(id, ['emplois', 'referents']).subscribe(
      (value) => {
        this.collectivite = value;
        this.loader.hide();
      },
      () => {
        this.loader.hide();
      }
    );
  }
}
