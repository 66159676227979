<div class="modal-header">
  <h2 class="bolded">
      {{ affectation?.id ? 'Modifier une affectation' : 'Affecter un poste' }}
  </h2>

  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span class="aed-icon aed-icon-close" aria-hidden="true"></span>
    <span class="sr-only" aria-hidden="true">Fermer</span>
  </button>
</div>
<div class="modal-body">
  <div class="inner">
    <form class="affectation-form" #affectationForm="ngForm" (ngSubmit)="onSubmit(affectationForm)" novalidate>

      <ng-container *ngIf="affectation?.id; then hasAgent else noAgent;"></ng-container>
      <ng-container *ngIf="!affectation?.id && existingAgent === '1'; then searchAgent;"></ng-container>
      <ng-container *ngIf="!affectation?.id && existingAgent === '0'; then declareAgent;"></ng-container>

      <ng-template #noAgent>
        <div class="row">
          <div class="form-group col-md-6 col-lg-6">
            <label class="form-check-label label-alone">
              Choisir un agent inscrit ?
            </label>

            <div class="form-check form-check-inline">
              <label class="form-check-label" for="existingAgent_y">
                <input class="form-check-input" type="radio"
                      name="existingAgent"
                      id="existingAgent_y"
                      [(ngModel)]="existingAgent"
                      value="1">
                Oui
              </label>
            </div>

            <div class="form-check form-check-inline">
              <label class="form-check-label" for="existingAgent_n">
                <input class="form-check-input" type="radio"
                      name="existingAgent"
                      id="existingAgent_n"
                      [(ngModel)]="existingAgent"
                      value="0">
                Non
              </label>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #hasAgent>
        <div class="row">
          <div class="form-group col-md-6 col-lg-6">
              <label for="affectationAgent">Agent en poste</label>
              <input type="text" class="form-control" id="affectationAgent" name="affectationAgent" [(ngModel)]="affectation.user_id.label" #affectationAgent="ngModel" [disabled]="true">
          </div>
        </div>
      </ng-template>

      <ng-template #searchAgent>
        <div class="row">
          <div class="form-group col-md-6 col-lg-6 bolded">
              <app-aed-typeahead
                  (onChoose)="onAgentSelection($event)"
                  [text]="'Rechercher un agent (nom, courriel...)'"
                  [retriever]="'searchAgents'"
                  [placeholder]="'ex: Paul Lefebvre'"
                  [defaultValue]="agent"
                  [required]="true">
              </app-aed-typeahead>
          </div>
        </div>
      </ng-template>

      <ng-template #declareAgent>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="affectationCivilite">Civilité <span class="required-field">(obligatoire)</span></label>
              <div class="btn-group btn-group-toggle" ngbRadioGroup name="affectationCivilite" [(ngModel)]="agent.civilite" #affectationCivilite="ngModel" required>
                  <label ngbButtonLabel class="btn-light">
                      <input ngbButton type="radio" value="mme" autocomplete="off"> Mme
                  </label>
                  <label ngbButtonLabel class="btn-light">
                      <input ngbButton type="radio" value="m" autocomplete="off"> M.
                  </label>
              </div>
          </div>
          <div class="form-group col-md-6 col-lg-6">
            <label for="affectationCourriel">Courriel <span class="required-field">(obligatoire)</span></label>
            <input type="text" class="form-control" id="affectationCourriel" name="affectationCourriel" [(ngModel)]="agent.courriel" #affectationCourriel="ngModel" required  (change)="checkEmail()" email>
            <small *ngIf="emailExists" class="form-text text-muted">
                Attention : ce courriel est lié à un agent existant.
            </small>
            <div *ngIf="affectationCourriel?.errors && affectationCourriel?.invalid && (affectationCourriel?.dirty || affectationCourriel?.touched)" class="form-error alert alert-danger">
              <small *ngIf="affectationCourriel?.errors?.email">
                L'email entré est invalide.
              </small>
              <small *ngIf="affectationCourriel?.errors?.required">
                Ce champ est requis.
              </small>
            </div>
        </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 col-lg-6">
              <label for="affectationPrenom">Prénom <span class="required-field">(obligatoire)</span></label>
              <input type="text" class="form-control" id="affectationPrenom" name="affectationPrenom" [(ngModel)]="agent.prenom" #affectationPrenom="ngModel" required>
              <div *ngIf="affectationPrenom?.errors && affectationPrenom?.invalid && (affectationPrenom?.dirty || affectationPrenom?.touched)" class="form-error alert alert-danger">
                  <small *ngIf="affectationPrenom?.errors?.required">
                      Ce champ est requis.
                  </small>
              </div>
          </div>
          <div class="form-group col-md-6 col-lg-6">
              <label for="affectationNom">Nom <span class="required-field">(obligatoire)</span></label>
              <input type="text" class="form-control" id="affectationNom" name="affectationNom" [(ngModel)]="agent.nom" #affectationNom="ngModel" required>
              <div *ngIf="affectationNom?.errors && affectationNom?.invalid && (affectationNom?.dirty || affectationNom?.touched)" class="form-error alert alert-danger">
                  <small *ngIf="affectationNom?.errors?.required">
                      Ce champ est requis.
                  </small>
              </div>
          </div>
        </div>
      </ng-template>

      <div class="row">
        <div class="form-group col-md-6 col-lg-6">
            <label for="affectationFonction">Fonction <span class="required-field">(obligatoire)</span></label>
            <input type="text" class="form-control" id="affectationFonction" name="affectationFonction" [(ngModel)]="fonction" #affectationFonction="ngModel" required>
            <div *ngIf="affectationFonction?.errors && affectationFonction?.invalid && (affectationFonction?.dirty || affectationFonction?.touched)" class="form-error alert alert-danger">
                <small *ngIf="affectationFonction?.errors?.required">
                    Ce champ est requis.
                </small>
            </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-5 month-selector">
          <label for="debut_month">
            Date de prise de poste
            <span class="required-field">(obligatoire)</span>
          </label>
          <div>
            <select id="debut_month" class="form-control" name="debut_mois"
                    [(ngModel)]="dateDebut.month" #debut_month="ngModel" (change)="validateTimePeriod()" required>
              <option value="01">Janvier</option>
              <option value="02">Février</option>
              <option value="03">Mars</option>
              <option value="04">Avril</option>
              <option value="05">Mai</option>
              <option value="06">Juin</option>
              <option value="07">Juillet</option>
              <option value="08">Août</option>
              <option value="09">Septembre</option>
              <option value="10">Octobre</option>
              <option value="11">Novembre</option>
              <option value="12">Décembre</option>
            </select>
            <select id="debut_year" class="form-control" name="debut_annee"
                    [(ngModel)]="dateDebut.year" #debut_year="ngModel" (change)="validateTimePeriod()" required>
              <option [value]="dr40Date" *ngFor="let dr40Date of datesRange40">{{ dr40Date }}
              </option>
            </select>
            <div *ngIf="(debut_month?.errors && debut_month?.invalid && (debut_month?.dirty || debut_month?.touched)) || (debut_year?.errors && debut_year?.invalid && (debut_year?.dirty || debut_year?.touched))"
                 class="form-error alert alert-danger">
              <small *ngIf="debut_month?.errors?.required">
                Le champ mois est requis.
              </small>
              <small *ngIf="debut_year?.errors?.required">
                Le champ année est requis.
              </small>
            </div>
          </div>
        </div>

        <div class="form-group col-md-2" id="ongoing-container">
          <label class="form-check-label" for="ongoing">
            <input class="form-check-input" type="checkbox" name="ongoing" id="ongoing"
                   [(ngModel)]="ongoing" (change)="validateTimePeriod()">
            Poste actuel
          </label>
        </div>

        <div class="form-group col-md-5 month-selector" *ngIf="!ongoing">
          <label for="fin_month">
            Date de fin du poste
            <span class="required-field">(obligatoire)</span>
          </label>
          <div>
            <select id="fin_month" class="form-control" name="fin_mois"
                    [(ngModel)]="dateFin.month" #fin_month="ngModel" (change)="validateTimePeriod()" required>
              <option value="01">Janvier</option>
              <option value="02">Février</option>
              <option value="03">Mars</option>
              <option value="04">Avril</option>
              <option value="05">Mai</option>
              <option value="06">Juin</option>
              <option value="07">Juillet</option>
              <option value="08">Août</option>
              <option value="09">Septembre</option>
              <option value="10">Octobre</option>
              <option value="11">Novembre</option>
              <option value="12">Décembre</option>
            </select>
            <select id="fin_year" class="form-control" name="fin_annee"
                    [(ngModel)]="dateFin.year" #fin_year="ngModel" (change)="validateTimePeriod()" required>
              <option [value]="dr40Date" *ngFor="let dr40Date of datesRange40">{{ dr40Date }}
              </option>
            </select>
            <div *ngIf="(fin_month?.errors && fin_month?.invalid && (fin_month?.dirty || fin_month?.touched)) || (fin_year?.errors && fin_year?.invalid && (fin_year?.dirty || fin_year?.touched)) || invalidTimePeriod"
                 class="form-error alert alert-danger">
              <small *ngIf="fin_month?.errors?.required">
                Le champ mois est requis.
              </small>
              <small *ngIf="fin_year?.errors?.required">
                Le champ année est requis.
              </small>
              <small *ngIf="invalidTimePeriod">
                La date de fin doit être postérieure ou égale à la date de début
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 col-lg-12">
          <button type="submit"
                  class="arrow-btn btn btn-primary text-uppercase"
                  [disabled]="affectationForm.invalid || invalidTimePeriod || emailExists">
            Valider
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
