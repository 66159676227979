import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-periode',
    templateUrl: './periode.component.html',
    styleUrls: ['./periode.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PeriodeComponent implements OnInit {
    @Input() start: any;
    @Input() end: any;

    public startDate: Date = undefined;
    public endDate: Date = undefined;

    constructor() {
    }

    ngOnInit() {
        if (this.start !== undefined && this.start !== null) {
            this.startDate = this.parseDate(this.start);
        }
        if (this.end !== undefined && this.end !== null) {
            this.endDate = this.parseDate(this.end);
        }
    }

    public isSameYear(): boolean {
        return this.endDate === undefined || this.endDate.getFullYear() === this.startDate.getFullYear();
    }

    private parseDate(date: any): Date {
        return date.raw !== undefined
            ? new Date(date.raw)
            : new Date(date);
    }
}
