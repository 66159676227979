<app-breadcrumb [title]="news.titre"></app-breadcrumb>
<div class="container" *ngIf="news == null">
  <div class="row">
    <div class="col-md-12 col-lg-12 empty-block">
      <div class="inner">
        <p>
          Aucune actualité n'a été trouvée.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="news != null">

  <div class="row col-md-4 col-lg-4 back-button">
    <a class="clickable" back-button>
      <span class="aed-icon aed-icon-back" aria-hidden="true"></span>
      <span class="pm-link-text plt-back">Retour</span>
    </a>
  </div>

  <div class="row col-md-12 col-lg-12 justify-content-center">

    <div class="col-md-5 col-lg-5">
      <div class="row aed-news">
        <div class="ahn-news">

          <h1 class="page-title bordered-title bolded">{{news.titre}}</h1>

          <div class="news-detail-date">
            <span class="news-date">{{news.date}}</span>
            <img class="img-fluid" src="assets/images/nothumb.png" [alt]="news.titre" *ngIf="!news.vignette">
            <img class="img-fluid" [src]="news.vignette" [alt]="news.titre" *ngIf="news.vignette">
          </div>

          <div class="news-detail-desc">{{news.summary}}</div>

        </div>
      </div>
        <div class="row justify-content-center">
            <a class="btn arrow-btn btn-third text-uppercase" title="En savoir plus" [href]="news.url" target="_blank">
                En savoir plus
            </a>
        </div>

    </div>
  </div>
</div>
