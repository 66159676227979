import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewsService} from './news.service';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { NewsListingComponent } from './news-listing/news-listing.component';
import { NewsTeaserComponent } from './news-teaser/news-teaser.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from "@angular/router";
import {newsRoutes} from "./news-routing.module";
import {NewsResolve} from "./news.resolve";
import {SharedModule} from "../shared/shared.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(newsRoutes, { relativeLinkResolution: 'legacy' }),
        SharedModule,
        NgbModule
    ],
    providers: [
        NewsService,
        NewsResolve
    ],
    declarations: [
        NewsDetailComponent,
        NewsListingComponent,
        NewsTeaserComponent
    ],
    exports: [
        NewsDetailComponent,
        NewsListingComponent,
        NewsTeaserComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NewsModule {
}
