import {NewsResolve} from './news.resolve';
import {NewsListingComponent} from './news-listing/news-listing.component';
import {CollectiviteResolve} from '../collectivite/collectivite.resolve';
import {FicheCtComponent} from '../collectivite/fiche-ct/fiche-ct.component';
import {NewsDetailComponent} from './news-detail/news-detail.component';

export const newsRoutes = [
    {
        path: 'news/list',
        component: NewsListingComponent,
        data: {
            title: 'Liste des actualités'
        }
    },
    {
        path: 'news/:id',
        resolve: {
            news: NewsResolve
        },
        component: NewsDetailComponent,
    },
];
