import { Directive, HostListener } from '@angular/core'
import { NavigationService } from '../utils/navigation/navigation.service'

@Directive({
  selector: '[back-button]',
})
export class BackButtonDirective {
  constructor(private navigation: NavigationService) {}

  @HostListener('click')
  onClick(): void {
    this.navigation.back()
  }
}
