<div class="aed-news" *ngIf="newsList != null && newsList.length > 0">
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <h2 class="bordered-title bt-secondary bolded" title="Dernières actualités - Voir toutes les actualités">Dernières actualités</h2>
    </div>
    <div class="ahn-news col-sm-6 col-md-3 col-lg-3" *ngFor="let news of newsList;">
      <a [routerLink]="['/news', news.id]">
        <h3>{{news.titre}}</h3>
        <img class="img-fluid" src="assets/images/nothumb.png" [alt]="news.titre" *ngIf="!news.vignette">
        <img class="img-fluid" [src]="news.vignette" [alt]="news.titre" *ngIf="news.vignette">
      </a>
    </div>
  </div>
  <div class="row justify-content-center">
    <a class="btn arrow-btn btn-third text-uppercase" title="Voir toutes les actualités" [routerLink]="['news/list']">
      Voir toutes les actualités
    </a>
  </div>
</div>