import {Component, OnInit} from '@angular/core';
import {AccueilService} from '../accueil.service';
import {LoaderSmallService} from '../../shared/loader-small/loader-small.service';

@Component({
    selector: 'app-bandeau',
    templateUrl: './bandeau.component.html',
    styleUrls: ['./bandeau.component.scss']
})
export class BandeauComponent implements OnInit {

    public banners: any;

    constructor(
        private accueilService: AccueilService,
        private loaderSmallService: LoaderSmallService
    ) {
    }

    ngOnInit() {
        this.loaderSmallService.show();
        this.accueilService.getAllBanners().subscribe((result) => {
            this.loaderSmallService.hide();
            return this.banners = result;
        });
    }

}
