import {Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS, Validator, AbstractControl} from '@angular/forms';

import {isValidNumber, parse} from "libphonenumber-js";

const PHONE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => PhoneValidatorDirective),
    multi: true
};

@Directive({
    selector: '[phone][formControlName],[phone][formControl],[phone][ngModel]',
    providers: [PHONE_VALIDATOR]
})
export class PhoneValidatorDirective implements Validator {

    validate(c: AbstractControl): { [key: string]: any } {
        let v: string = c.value;

        if ((v === null || v === undefined) || v.trim() == '') {
            return null;
        }

        const parsedValue = parse(v, {defaultCountry: 'FR'});
        return isValidNumber(parsedValue) ? null : {phone: true};
    }
}
