import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {OwnershipAccessState} from '../../authentification/has-any-role';
import {EmploiService} from '../emploi.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AuthentificationService} from '../../authentification/authentification.service';
import {NgForm} from '@angular/forms';
import {DeleteDialogComponent} from '../../shared/delete-dialog/delete-dialog.component';

@Component({
    selector: 'app-experience-form',
    templateUrl: './experience-form.component.html',
    styleUrls: ['./experience-form.component.scss']
})
export class ExperienceFormComponent implements OnInit {

  @Input() experience: any;
  @Input() fromReferent: boolean = false;

    currentYear = new Date().getFullYear();
    datesRange6 = [];
    datesRange40 = [];

    isHFPT = false;
    isExperienceFpt = false;

    isOwner = false;
    invalidTimePeriod = false;

    success: boolean = undefined;
    fieldError: boolean;
    isSubmitting: boolean;

    dateDebut: any;
    dateFin: any;

    accessState: OwnershipAccessState;

    constructor(private emploiService: EmploiService,
                private loader: LoaderService,
                public activeModal: NgbActiveModal,
                private modalService: NgbModal,
                private authService: AuthentificationService) {
    }

    ngOnInit() {

        // On déclare des nouveaux objets date pour éviter les effets de bord & la modif en live
        this.dateDebut = Object.assign({}, this.experience.debut);
        if ((this.experience.fin === null || this.experience.fin === undefined)) {
            this.dateFin = Object.assign({}, this.experience.debut);
            this.experience.ongoing = true;
        } else {
            this.dateFin = Object.assign({}, this.experience.fin);
            this.experience.ongoing = false;
        }

        this.success = undefined;
        this.fieldError = false;
        this.isSubmitting = false;
        this.loader.hide();

        for (let i = 0; i < 6; i++) {
            this.datesRange6.push(this.currentYear - i);
        }

        for (let i = 0; i < 39; i++) {
            this.datesRange40.push(this.currentYear - i);
        }

        // For role specific display
        this.authService.identity.subscribe((identity) => {
            if (identity && identity.roles) {

                if (!(this.experience === null || this.experience === undefined) && !(this.experience.user_id === null || this.experience.user_id === undefined)) {
                    this.isOwner = identity.id === this.experience.user_id.id;
                }

                this.accessState = {
                    currentUserRoles: identity.roles,
                    hasOwnership: this.isOwner
                };
            } else {
                this.accessState = {
                    currentUserRoles: [],
                    hasOwnership: false
                };
            }
        }, () => {
            this.accessState = {
                currentUserRoles: [],
                hasOwnership: false
            };
        });

        this.experience.hidden = (this.experience.hidden === true || this.experience.hidden === 1 || this.experience.hidden === '1') ? '1' : '0';
    }

    public onSubmit(submittedForm: NgForm) {
        this.loader.show();
        this.isSubmitting = true;
        const data = submittedForm.value;

        // On assigne les dates modifiées à l'entité
        data['debut'] = this.dateDebut;
        data['fin'] = this.dateFin;

        if (this.experience) {
          data['id'] = this.experience.id;
        }

        if (this.fromReferent) {
          data['from_referent'] = true;
        }

        this.emploiService.handleExperienceSubmission(data).subscribe(() => {
            this.loader.hide();
            this.activeModal.close({success: true});
        }, () => {
            this.loader.hide();
            this.activeModal.close({success: false});
        });
    }

    public changeExperienceValidity() {
        this.emploiService.changeExperienceValidity(this.experience.id, !this.experience.is_valid).subscribe(
            (value) => {
                this.experience.is_valid = value;
            }, () => {
            });
    }

    public openDeleteDialog() {
        let modalRef: NgbModalRef;
        modalRef = this.modalService.open(DeleteDialogComponent, {size: 'sm'});
        modalRef.result.then((result) => {
            if (result === true) {
                this.loader.show();
                this.emploiService.deleteExperience(this.experience.id).subscribe(() => {
                    this.loader.hide();
                    this.activeModal.close({success: true});
                }, () => {
                    this.loader.hide();
                    this.activeModal.close({success: false});
                });
            }
        });
    }

    public validateTimePeriod() {
        if (this.experience.ongoing) {
            return;
        }
        const debut = this.dateDebut;
        const fin = this.dateFin;

        if ((debut === null || debut === undefined)
            || (fin === null || fin === undefined)
            || (debut.month === null || debut.month === undefined)
            || (debut.year === null || debut.year === undefined)
            || (fin.month === null || fin.month === undefined)
            || (fin.year === null || fin.year === undefined)
        ) {
            this.invalidTimePeriod = false;
            return;
        }

        // Date de début (ajout d'un 0 avant le mois si besoin)
        const beginMonthClean = (debut.month < 10 && debut.month.length !== 2)
            ? `0${debut.month}`
            : `${debut.month}`;
        const beginDate = new Date(`${debut.year}-${beginMonthClean}`);

        // Date de fin (ajout d'un 0 avant le mois si besoin)
        const endMonthClean = (fin.month < 10 && fin.month.length !== 2)
            ? `0${fin.month}`
            : `${fin.month}`;
        const endDate = new Date(`${fin.year}-${endMonthClean}`);

        this.invalidTimePeriod = endDate < beginDate;
    }
}
