import {Component, OnInit} from '@angular/core';
import {LoaderService} from '../../shared/loader/loader.service';
import {AccountService, UserCustomField} from '../account.service';
import {AnnuaireService} from '../../annuaire/annuaire.service';
import {IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthentificationService} from '../../authentification/authentification.service';
import {ContactCnfptComponent} from '../contact-cnfpt/contact-cnfpt.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';





import {NgForm} from "@angular/forms";
import { DemandeDeDroitService } from '../../admin/demande-de-droit.service';
import { CsrfService } from '../../authentification/csrf.service';

@Component({
    selector: 'app-form-referent',
    templateUrl: './form-referent.component.html',
    styleUrls: ['./form-referent.component.scss']
})
export class FormReferentComponent implements OnInit {

    public fields: UserCustomField[];
    public registration: any = {};
    public success: boolean;
    public displayError: boolean;
    public isSubmitting = false;
    public fieldError = false;
    public notifySuccess: boolean = undefined;
    public lastKeyIsDigit: boolean = true;

    public metiers: any[];

    public isConfirmStage = false;

    selectedCategorie: any = [];

    /**
     * Multiselect field settings
     */
     public settingsSingle = {
      singleSelection: true,
      text: '-',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      searchPlaceholderText: 'Rechercher',
      enableSearchFilter: true,
      labelKey: 'name',
      primaryKey: 'id',
    };
    multiSelectSettings: IMultiSelectSettings = {
        enableSearch: true,
        checkedStyle: 'checkboxes',
        buttonClasses: 'btn btn-block',
        dynamicTitleMaxItems: 1,
        displayAllSelectedText: true,
        containerClasses: 'w-100',
        showCheckAll: false,
        showUncheckAll: false,
        autoUnselect: true,
    };

    /**
     * Multiselect field settings
     */
    singleSelectSettings: IMultiSelectSettings = {
        enableSearch: true,
        checkedStyle: 'checkboxes',
        buttonClasses: 'btn btn-block',
        dynamicTitleMaxItems: 1,
        displayAllSelectedText: true,
        containerClasses: 'cds-single',
        showCheckAll: false,
        showUncheckAll: false,
        selectionLimit: 1,
        autoUnselect: true,
        closeOnSelect: true,
    };

    /**
     * Multiselect field traductions
     */
    multiSelectTexts: IMultiSelectTexts = {
        checkAll: 'Tout sélectionner',
        uncheckAll: 'Tout désélectionner',
        checked: 'Sélectionné',
        checkedPlural: 'Sélectionnés',
        searchPlaceholder: 'Rechercher',
        searchEmptyResult: 'Pas de résultat...',
        searchNoRenderText: 'Rechercher...',
        defaultTitle: '-',
        allSelected: 'Tous les éléments',
    };

    public csrfToken = '';
    currentUserId: any;

    constructor(
      private accountService: AccountService,
      private loader: LoaderService,
      private authService: AuthentificationService,
      private modalService: NgbModal,
      private demandeDeDroitService: DemandeDeDroitService,
      private csrfService: CsrfService,
      private router: Router
      ) { }

    ngOnInit() {
        this.success = undefined;
        this.fieldError = false;
        this.isSubmitting = false;
        this.registration = {};

        // For role specific display
        this.authService.identity.subscribe((identity) => {
          if (identity != null) {
              this.currentUserId = identity.id;
              this.registration.field_email = identity.email;
          }
        });

        this.accountService.getReferentFormDatas().subscribe(
          (result) => {
              this.metiers = result.metiers;
              this.loader.hide();
          }
        );

        this.updateCsrfToken();
    }

    /**
     * Refresh the CSRF Token
     */
    private updateCsrfToken() {
        this.csrfService.getToken()
            .subscribe(
                token => {
                    this.csrfToken = token;
                });
    }

    public onSubmit() {
        this.loader.show();
        this.isSubmitting = true;
        this.registration.field_statut = 1;
        this.registration.field_agent = this.currentUserId;
        this.registration.field_collectivite = this.registration.field_collectivite.id;
        this.registration.field_categorie = this.selectedCategorie.map(x => x.id);

        this.demandeDeDroitService.create(this.registration, this.csrfToken).subscribe(
            (value) => {
                this.loader.hide();
                this.isSubmitting = false;
                this.success = true;
                this.router.navigate(['/registered-demande']);
                this.authService.authenticate();
            },
            () => {
                this.loader.hide();
                this.isSubmitting = false;
                this.success = false;
            }
        );
    }

    public onPlaceSelection(selected: any) {
        if (!(selected === null || selected === undefined) && selected.id != null) {
            this.registration.field_collectivite = selected;
        } else {
            this.registration.field_collectivite = null;
        }
    }

    public toggleConfirm(active: boolean) {
        this.isConfirmStage = active;
    }

    public notifyCnfpt(type: string): void {
        const modalRef = this.modalService.open(ContactCnfptComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.type = type;
        modalRef.result.then((result) => {
            this.notifySuccess = result.success;
            window.scrollTo(0, 0);
        }, (result) => {
            this.notifySuccess = undefined;
        });
    }

    /**
     * Tests if character is a number, if not: doesn't allow it
     *
     * @returns {boolean}
     */
    public checkIfNumber(event: any) {
        let keyPressed = event.charCode;  // k = event.keyCode;  (Both can be used)
        this.lastKeyIsDigit = (keyPressed >= 48 && keyPressed <= 57);
        return this.lastKeyIsDigit;
    }
}
