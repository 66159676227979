
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';


import {HttpClient, HttpHeaders} from '@angular/common/http';
import {getDrupalUri} from '../shared/external';

@Injectable()
export class AgentService {

    public agentPath = '/customapi/agent';

    constructor(private http: HttpClient) {
    }

    /**
     * return the agent
     * @param idAgent
     * @returns {Observable<any>}
     */
    public get(id: any): Observable<any> {
        return this.http.get<any>(
            getDrupalUri() + this.agentPath + '/' + id).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     * return the connected agent
     * @returns {Observable<any>}
     */
    public getCurrent(): Observable<any> {
        return this.http.get<any>(
            getDrupalUri() + this.agentPath + '/current').pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     * return the collectivite id if the current agent is referent
     * @returns {Observable<any>}
     */
    public getCollectiviteIdForReferent(): Observable<any> {
      return this.http.get<any>(
          getDrupalUri() + this.agentPath + '/current/collectivite').pipe(
          map(
              (value) => {
                  return value;
              }
          ));
    }

    public getForAutocomplete(id: any): Observable<any> {
        return this.http.get<any>(
            getDrupalUri() + this.agentPath + '/' + id).pipe(
            map(
                (value) => {
                    return {id: value.id, label: value.label, type: 'agent'};
                }
            ));
    }

    /**
     * Return all datas for the profile form
     * @param id
     * @returns {Observable<any>}
     */
    public getFormDatas(id: any): Observable<any> {
        return this.http.get<any>(
            getDrupalUri() + this.agentPath + '/' + id + '/formDatas').pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     *
     * @param agent
     * @returns {Observable<any>}
     */
    public updateAgent(data: any, photoFile: any, cvFile: any): Observable<any> {
        const fd = new FormData();

        fd.append('data', JSON.stringify(data));

        if (photoFile) {
            fd.append('photo_file', photoFile, photoFile.name);
        }

        if (cvFile) {
            fd.append('cv_file', cvFile, cvFile.name);
        }

        return this.http.post<any>(
            getDrupalUri() + this.agentPath + '/update', fd).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     *
     * @param fieldName
     * @returns {Observable<any>}
     */
    public getFieldValidators(fieldName: any): Observable<any> {
        return this.http.get<any>(getDrupalUri() + this.agentPath + '/validators/' + fieldName + '?validators[]=max_filesize&validators[]=file_extensions');
    }

    /**
     * Make the agent a referent for the collectivite
     * @param agentId
     * @param collectiviteId
     * @returns {Observable<any>}
     */
    public becomeReferent(datas: any): Observable<any> {
        return this.http.post<any>(
            getDrupalUri() + this.agentPath + '/referent', datas).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     * Add agent role to a referent
     * @returns {Observable<any>}
     */
    public addRoleAgent(): Observable<any> {
        return this.http.post<any>(
          getDrupalUri() + "/customapi/registration/agent/role", []).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     *
     * @param id
     * @returns {Observable<any>}
     */
    public getAll(page: number, itemsPerPage: number, fulltextQuery: string): Observable<any> {

      let params = '?';
      params = this.addParamToQuery(params, 'fulltextQuery', fulltextQuery, "", true);
      params = this.addParamToQuery(params, 'page', page, 1, true);
      params = this.addParamToQuery(params, 'itemsPerPage', itemsPerPage, 15, true);
      params = this.addParamToQuery(params, '_format', null, 'json', false);

      let url = getDrupalUri() + this.agentPath + '/all' + params;

      return this.http.get<any>(url).pipe(
          map(
              (value) => {
                  return value;
              }
          ));
    }

    private addParamToQuery(query: string, param: string, value: any, backup: any, hasNext: boolean) {
      const finalValue = (value === null || value === undefined) ? backup : value;
      query += param + "=" + finalValue;
      if (hasNext) {
        query += '&';
      }
      return query;
    }
}
