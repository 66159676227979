
import {OffreResolve} from './offre.resolve';
import {OffreDetailComponent} from './offre-detail/offre-detail.component';

export const offreRoutes = [
    {
        path: 'offre-emploi/:num',
        resolve: {
            offre: OffreResolve
        },
        component: OffreDetailComponent,
        data: {
            title: 'Offre d\'emploi',
        }
    },
];
