import {Component, Input, OnInit} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {CollectiviteService} from '../../collectivite/collectivite.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {AccountService} from '../account.service';
import {CsrfService} from "../../authentification/csrf.service";

@Component({
    selector: 'app-contact-cnfpt',
    templateUrl: './contact-cnfpt.component.html',
    styleUrls: ['./contact-cnfpt.component.scss']
})
export class ContactCnfptComponent implements OnInit {

    @Input() type: any;
    @Input() agent: any;
    @Input() collectivite: any;

    public success: boolean;
    public isSubmitting = false;
    public reporting = {
        message: ''
    };

    public csrfToken = '';

    constructor(public activeModal: NgbActiveModal,
                private loader: LoaderService,
                private accountService: AccountService,
                private csrfService: CsrfService) {
    }

    ngOnInit() {
        this.updateCsrfToken();
    }

    /**
     * Refresh the CSRF Token
     */
    private updateCsrfToken() {
        this.csrfService.getToken()
            .subscribe(
                token => {
                    this.csrfToken = token;
                });
    }

    public onSubmit(submittedForm: NgForm) {
        if (!(this.reporting.message === null || this.reporting.message === undefined)) {
            this.isSubmitting = true;
            this.loader.show();
            this.accountService.notifyCnfpt(this.type, this.reporting.message, this.csrfToken).subscribe((result) => {
                this.loader.hide();
                this.activeModal.close({success: true});
            }, () => {
                this.loader.hide();
                this.activeModal.close({success: false});
            });
        }
    }

}
