import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-dve-detail',
    templateUrl: './dve-detail.component.html',
    styleUrls: ['./dve-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DveDetailComponent implements OnInit {

    public dve: any;
    public view: any;
    public unknown = 'Non précisée';
    public unknownGrade = 'Non renseigné';

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        // Get Agent from resolver
        this.dve = this.route.snapshot.data['dve'];

        const grades = [];

        if (this.dve.field_dve_d2 && this.dve.field_dve_d2.id != null) {
            grades.push(this.dve.field_dve_d2);
        }
        if (this.dve.field_dve_d3 && this.dve.field_dve_d3.id != null) {
            grades.push(this.dve.field_dve_d3);
        }
        if (this.dve.field_dve_d4 && this.dve.field_dve_d4.id != null) {
            grades.push(this.dve.field_dve_d4);
        }
        if (this.dve.field_dve_d5 && this.dve.field_dve_d5.id != null) {
            grades.push(this.dve.field_dve_d5);
        }
        if (this.dve.field_dve_d5 && this.dve.field_dve_d5.id != null) {
            grades.push(this.dve.field_dve_d5);
        }

        this.view = {
            title: this.dve.title,
            number: this.dve.field_dve_d1,
            description: this.dve.field_dve_d44 ? this.dve.field_dve_d44 : this.unknown,
            dateRecrutement: this.dve.field_dve_d41 ? this.dve.field_dve_d41.formatted : this.unknown,
            dateDeclaration: this.dve.field_dve_d42 ? this.dve.field_dve_d42.formatted : this.unknown,
            grades: grades,
            noGrade: grades.length > 0 ? null : this.unknownGrade,
        };
    }
}
