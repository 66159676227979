<div class="modal-header">
  <h2 class="bolded">
      Changement de statut de la demande de droit
  </h2>

  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span class="aed-icon aed-icon-close" aria-hidden="true"></span>
      <span class="sr-only" aria-hidden="true">Fermer</span>
  </button>
</div>
<div class="modal-body">
  <div class="inner">
      <form class="add-emploi-form" #dddForm="ngForm" (ngSubmit)="onSubmit(dddForm)" novalidate>

        <div class="row">
          <div class="col-md-12 col-lg-12">
              <h3 class="bordered-title bolded">{{ dddLabel }}</h3>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12 col-lg-12">
            <label for="motif">Motif {{ newStatusText() }}</label>
            <input type="text" class="form-control" id="motif" name="motif" [(ngModel)]="formModel.motif" #motif="ngModel" required>
            <div *ngIf="motif?.errors && motif?.invalid && (motif?.dirty || motif?.touched)" class="form-error alert alert-danger">
              <small *ngIf="motif?.errors?.required">
                  Ce champ est requis.
              </small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-lg-12">
            <button type="submit" class="arrow-btn btn btn-primary text-uppercase" [disabled]="dddForm.invalid">
              Valider
            </button>
          </div>
        </div>

      </form>
  </div>
</div>
