<form class="ct-update-form" (ngSubmit)="onSubmit(collectiviteForm)" #collectiviteForm="ngForm" novalidate>
  <div class="row">

    <div class="form-group col-md-6 col-lg-6">
      <label for="l1_normalisee">
        Libellé
        <span class="required-field">(obligatoire)</span>
      </label>
      <input type="text" class="form-control" id="nomen_long" name="nomen_long"
      [(ngModel)]="collectivite.nomen_long" #nomen_long="ngModel" required>
      <div *ngIf="nomen_long?.errors && nomen_long?.invalid && (nomen_long?.dirty || nomen_long?.touched)" class="form-error alert alert-danger">
        <small *ngIf="nomen_long?.errors?.required">
          Le libellé est obligatoire
        </small>
      </div>
    </div>

    <div class="form-group col-md-12 col-lg-12">
      <button type="submit" class="arrow-btn btn btn-primary text-uppercase" [disabled]="collectiviteForm.invalid">
        Mettre à jour la collectivité
      </button>
    </div>

  </div>
</form>
