import { Routes } from '@angular/router';
import { LoginCallbackRedirectGuard } from './login-callback-redirect.guard';
import { SilentLoginCallbackComponent } from './silent-login-callback/silent-login-callback.component';
import { LoginRequiredComponent } from './login-required/login-required.component';
import { NEED_TO_LOG_IN } from './auth-required.guard';

export const moduleRoutes = [
    {
        path: 'login-callback',
        canActivate: [LoginCallbackRedirectGuard],
        component: SilentLoginCallbackComponent
    },
    {
        path: NEED_TO_LOG_IN,
        component: LoginRequiredComponent
    }
];
