<div class="affectation-history clearfix" *ngIf="history.length > 0">
    <div class="inner">
        <h2 class="bordered-title bolded">Historique des affectations</h2>
        <div class="row">
            <div class="col-md-4 col-lg-4 alc-agent-elt" *ngFor="let experience of history">
                <app-agent-light [showPeriode]="true" [agent]="experience.user_id" [experience]="experience"></app-agent-light>
            </div>
        </div>
    </div>
</div>
