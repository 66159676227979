import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { CsrfService } from '../../../../authentification/csrf.service';
import { CollectiviteService } from '../../../../collectivite/collectivite.service';
import { LoaderService } from '../../../../shared/loader/loader.service';

@Component({
  selector: 'profil-collectivite-fichiers',
  templateUrl: './fichiers.component.html',
  styleUrls: ['./fichiers.component.scss']
})
export class FichiersComponent implements OnInit {

  @Input() collectivite: any;
  @Output() collectiviteChange = new EventEmitter<any>();

  @Input() success: boolean;
  @Output() successChange = new EventEmitter<boolean>();

  @Output() refreshEvent = new EventEmitter<any>();

  files: any[] = [];
  errorsBlason: string[] = [];
  errorsOrganigramme: string[] = [];

  ogranigrammeForm: FormGroup = new FormGroup({});

  public csrfToken = '';

  constructor(
    private loader: LoaderService,
    private collectiviteService: CollectiviteService,
    private csrfService: CsrfService,
    private fb: FormBuilder
  ) { }

  get controls() {
    return this.ogranigrammeForm.controls;
  }

  ngOnInit() {
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.ogranigrammeForm = this.fb.group({
      organigramme_url: [this.collectivite.organigramme_url, [Validators.required, Validators.pattern(reg)]]
    })
    this.files['blason'] = null;
    this.files['organigramme'] = null;
    this.updateCsrfToken();
  }

  private updateCsrfToken() {
      this.csrfService.getToken()
          .subscribe(
              token => {
                  this.csrfToken = token;
              });
  }

  public fileSelected($event: any, file: string) {
    if ($event.target && $event.target.files && $event.target.files.length > 0) {
      this.files[file] = $event.target.files[0];
    } else {
      this.files[file] = undefined;
    }
  }

  public uploadFile(file: string) {
    this.loader.show();

    this.collectiviteService.uploadFile(this.collectivite.id, this.files[file], file)
      .subscribe(
        () => {
          this.successChange.emit(true);
          this.loader.hide();
          this.files[file] = null;
          if ('blason' === file) {
            this.errorsBlason = [];
          } else {
            this.errorsOrganigramme = [];
          }
          this.refresh();
        },
        (errors) => {
          this.successChange.emit(false);
          this.loader.hide();
          if ('blason' === file) {
            this.errorsBlason = errors.error;
          } else {
            this.errorsOrganigramme = errors.error;
          }
        }
      );
  }

  public removeFile(file: string) {
    this.loader.show();

    this.collectiviteService.removeFile(this.collectivite.id, file)
      .subscribe(
        () => {
          this.successChange.emit(true);
          this.loader.hide();
          this.refresh();
        },
        () => {
          this.successChange.emit(false);
          this.loader.hide();
        }
      );
  }

  public onSubmit(submittedForm): void {
    this.loader.show();
    const data = submittedForm.value;
    data['id'] = this.collectivite.id;

    this.collectiviteService.update(data, this.csrfToken)
        .subscribe(
            () => {
                this.loader.hide();
                this.updateCsrfToken();
                this.successChange.emit(true);
                this.refresh();
            },
            () => {
                this.loader.hide();
                this.updateCsrfToken();
                this.successChange.emit(false);
            }
        );
  }

  refresh() {
    this.refreshEvent.emit(true);
  }
}
