<form class="ct-update-form" (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm" novalidate>
  <div class="row">
    <div class="form-group col-md-4 col-lg-4">
      <label for="telephone1">Téléphone</label>
      <input type="text" class="form-control" id="telephone1" name="telephone1"
      [(ngModel)]="collectivite.telephone1" #telephone1="ngModel" phone>
      <div *ngIf="telephone1?.errors && telephone1?.invalid && (telephone1?.dirty || telephone1?.touched)" class="form-error alert alert-danger">
        <small *ngIf="telephone1?.errors?.phone">
          Le numéro n'est pas valide.
        </small>
      </div>
    </div>

    <div class="form-group col-md-4 col-lg-4">
      <label for="email1">Email</label>
      <input type="email" class="form-control" id="email1" name="email1"
      [(ngModel)]="collectivite.email1" #email1="ngModel" customEmail>
      <div *ngIf="email1?.errors && email1?.invalid && (email1?.dirty || email1?.touched)" class="form-error alert alert-danger">
        <small *ngIf="email1?.errors?.customEmail">
          Le courriel n'est pas valide.
        </small>
      </div>
    </div>

    <div class="form-group col-md-4 col-lg-4">
      <label for="web">Site web</label>
      <input type="text" class="form-control" id="web" name="web"
      [(ngModel)]="collectivite.web" #web="ngModel" url>
      <div *ngIf="web?.errors && web?.invalid && (web?.dirty || web?.touched)" class="form-error alert alert-danger">
        <small *ngIf="web?.errors?.url">
          L'adresse n'est pas valide
        </small>
      </div>
    </div>
    <!-- end: CTI / CONATCTS -->

    <!-- begin: CTI / RESEAUX -->
    <div class="col-md-12 col-lg-12">
      <h3 class="bordered-title bolded">Reseaux sociaux</h3>
    </div>
    <div class="form-group col-md-4 col-lg-4">
      <label for="linkedin">LinkedIn</label>
      <input type="text" class="form-control" id="linkedin"
      name="linkedin"
      [(ngModel)]="collectivite.linkedin" #linkedin="ngModel"
      linkedin>
      <div *ngIf="linkedin?.invalid" class="form-error alert alert-danger">
        <small *ngIf="linkedin?.errors?.linkedin">
          L'adresse saisie n'est pas un profil Linkedin valide.
        </small>
      </div>
    </div>

    <div class="form-group col-md-4 col-lg-4">
      <label for="viadeo">Viadeo</label>
      <input type="text" class="form-control" id="viadeo"
      name="viadeo"
      [(ngModel)]="collectivite.viadeo" #viadeo="ngModel" viadeo>
      <div *ngIf="viadeo?.invalid" class="form-error alert alert-danger">
        <small *ngIf="viadeo?.errors?.viadeo">
          L'adresse saisie n'est pas un profil Viadeo valide.
        </small>
      </div>
    </div>

    <div class="form-group col-md-4 col-lg-4">
      <label for="facebook">Facebook</label>
      <input type="text" class="form-control" id="facebook"
      name="facebook"
      [(ngModel)]="collectivite.facebook" #facebook="ngModel"
      facebook>
      <div *ngIf="facebook?.invalid" class="form-error alert alert-danger">
        <small *ngIf="facebook?.errors?.facebook">
          L'adresse saisie n'est pas un profil Facebook valide.
        </small>
      </div>
    </div>

    <div class="form-group col-md-4 col-lg-4">
      <label for="twitter">Twitter</label>
      <input type="text" class="form-control" id="twitter"
      name="twitter"
      [(ngModel)]="collectivite.twitter" #twitter="ngModel" twitter>
      <div *ngIf="twitter?.invalid" class="form-error alert alert-danger">
        <small *ngIf="twitter?.errors?.twitter">
          L'adresse saisie n'est pas un profil Twitter valide.
        </small>
      </div>
    </div>
    <div class="form-group col-md-12 col-lg-12">
      <button type="submit" class="arrow-btn btn btn-primary text-uppercase" [disabled]="contactForm.invalid">
        Mettre à jour les contacts
      </button>
    </div>
  </div>
</form>
