import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthentificationService} from '../../authentification/authentification.service';
import {PageService} from '../../page/page.service';
import {LoaderService} from '../loader/loader.service';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, UrlSegment} from '@angular/router';
import {Observable} from 'rxjs';
import {RegistrationCaptureService} from '../../account/registration-capture.service';
import {TextSizeService} from '../utils/text-size.service';
import {getDrupalUri} from '../external';
import {OwnershipAccessState} from '../../authentification/has-any-role';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    title = 'Annuaire des Cadres de Direction de la Fonction Publique Territoriale';
    version = environment.buildTag === '' ? environment.buildCommit : environment.buildTag;

    userDisplayName: string;
    userPhoto: string;
    userId: number;
    userTitle: string;

    public isCollapsed = false;
    public contrastActive = false;
    public sidebarMenuLinks: any;
    public footerMenuLinks: any;
    public socialMenuLinks: any;
    public footerMessage: any;
    public accessibility: any;

    public accessState: OwnershipAccessState;

    constructor(private authService: AuthentificationService,
                private pageService: PageService,
                private loader: LoaderService,
                private titleService: Title,
                private route: ActivatedRoute,
                private registrationCaptureService: RegistrationCaptureService,
                private textSizeService: TextSizeService,
                private router: Router) {

        const routeTitle = route.snapshot.data['title'];
        if (!(routeTitle === null || routeTitle === undefined)) {
            this.setTitle(routeTitle + ' - ' + this.title);
        } else {
            this.setTitle(this.title);
        }
    }

    ngOnInit(): void {
        this.textSizeService.cssClass.subscribe((cssclass) => {
            this.accessibility = cssclass;
        });

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });

        this.authService.identity.subscribe(
            (identity) => {
                if (identity == null) {
                    this.userDisplayName = null;
                    this.userPhoto = null;
                    this.userId = null;
                    this.userTitle = null;
                } else {
                    this.userDisplayName = identity.displayname;
                    this.userPhoto = identity.photo;
                    this.userId = identity.id;
                    this.userTitle = identity.title;
                }
                if (identity && identity.roles) {
                    this.accessState = {
                        currentUserRoles: identity.roles,
                        hasOwnership: false
                    };
                } else {
                    this.accessState = {
                        currentUserRoles: [],
                        hasOwnership: false
                    };
                }
            },
            () => {
                this.userDisplayName = null;
                this.userPhoto = null;
                this.userId = null;
                this.userTitle = null;
                this.accessState = {
                    currentUserRoles: [],
                    hasOwnership: false
                };
            }
        );

        // Get the sidebar menu by given name
        this.pageService.getMenuLinks('main')
            .subscribe((value) => {
                    this.sidebarMenuLinks = value;
                    this.loader.hide();
                },
                () => {
                    this.loader.hide();
                });

        // Get the footer menu
        this.pageService.getMenuLinks('footer')
            .subscribe((value) => {
                    this.footerMenuLinks = value.sort(function(obj1, obj2) {
                        // Ascending: first age less than the previous
                        return obj1.weight - obj2.weight;
                    });
                    this.loader.hide();
                },
                () => {
                    this.loader.hide();
                });

        // Get the social network menu
        this.pageService.getMenuLinks('social-network')
            .subscribe((value) => {
                    this.socialMenuLinks = value;
                    this.loader.hide();
                },
                () => {
                    this.loader.hide();
                });

        // Get the social network menu
        this.pageService.getBlockContentByNameAndField('footermessageblock', 'aed_footer_message_block_contenu')
            .subscribe((value) => {
                    this.footerMessage = value.content;
                    this.loader.hide();
                },
                () => {
                    this.loader.hide();
                });
    }

    /**
     *
     */
    public toggleSidebar(): void {
        this.isCollapsed = !this.isCollapsed;

        if (this.isCollapsed) {
            document.body.classList.add('content-slided');
        } else {
            document.body.classList.remove('content-slided');
        }
    }

    /**
     * Scroll to the page top when the scrolltotop button is clicked
     */
    public scrollToTop() {
        window.scrollTo(0, 0);
    }

    /**
     *
     */
    public changeContrast(event): void {
        this.contrastActive = !this.contrastActive;

        if (this.contrastActive) {
            document.body.classList.add('contrast-active');
        } else {
            document.body.classList.remove('contrast-active');
        }
    }

    /**
     *
     * @param {string} newTitle
     */
    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    /**
     *
     */
    public increaseTextSize(): void {
        this.textSizeService.increase();
    }

    /**
     *
     */
    public decreaseTextSize(): void {
        this.textSizeService.decrease();
    }
}
