<ng-container *ngIf="!displayName">
    <div class="aed-account-actions">
        <a class="aaa-connexion" (click)="login()" [class.disabled]="!loginAvailable">
            S'identifier
            <span class="aed-icon aed-icon-locked" aria-hidden="true"></span>
        </a>
        <div class="header-separator header-separator-sm"></div>
        <a class="aaa-registration" (click)="createAccount()" [class.disabled]="!loginAvailable">
            <span class="aed-icon aed-icon-user" aria-hidden="true"></span>
            S'inscrire
        </a>
    </div>
</ng-container>
<ng-container *ngIf="displayName">
    <div class="aed-account-actions">
        <a class="aaa-logout" (click)="logout()" [class.disabled]="!loginAvailable">
            <span class="aed-icon aed-icon-power" aria-hidden="true"></span>
            Se déconnecter
        </a>
    </div>
</ng-container>
