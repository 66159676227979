import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home/home.component';
import {accueilRoutes} from './accueil-routing.module';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AccueilService} from './accueil.service';
import {AnnuaireModule} from '../annuaire/annuaire.module';
import {AlertComponent} from '../shared/alert/alert.component';
import {SharedModule} from '../shared/shared.module';
import {NewsModule} from '../news/news.module';
import {AedTypeaheadComponent} from '../shared/forms/aed-typeahead/aed-typeahead.component';
import {BandeauComponent} from './bandeau/bandeau.component';
import {OffreModule} from '../offre/offre.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(
            accueilRoutes
        ),
        NgbModule,
        SharedModule,
        AnnuaireModule,
        NewsModule,
        OffreModule
    ],
    providers: [
        AccueilService
    ],
    declarations: [
        HomeComponent,
        BandeauComponent
    ],
})
export class AccueilModule {
}
