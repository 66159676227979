import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CsrfService } from '../../../../authentification/csrf.service';
import { CollectiviteService } from '../../../../collectivite/collectivite.service';
import { LoaderService } from '../../../../shared/loader/loader.service';

@Component({
  selector: 'profil-collectivite-collectivite',
  templateUrl: './collectivite.component.html',
  styleUrls: ['./collectivite.component.scss']
})
export class CollectiviteComponent implements OnInit {

  @Input() collectivite: any;
  @Output() collectiviteChange = new EventEmitter<any>();

  @Input() success: boolean;
  @Output() successChange = new EventEmitter<boolean>();

  @Input() fieldsError: any;
  @Output() fieldsErrorChange = new EventEmitter<any>();

  @Output() refreshEvent = new EventEmitter<any>();

  public csrfToken = '';

  constructor(
    private loader: LoaderService,
    private collectiviteService: CollectiviteService,
    private csrfService: CsrfService
  ) { }

  ngOnInit() {
    this.updateCsrfToken();
  }

  private updateCsrfToken() {
      this.csrfService.getToken()
          .subscribe(
              token => {
                  this.csrfToken = token;
              });
  }

  public onSubmit(submittedForm: NgForm): void {
    this.loader.show();
    const data = submittedForm.value;
    data['id'] = this.collectivite.id;

    this.collectiviteService.update(data, this.csrfToken)
        .subscribe(
            () => {
                this.loader.hide();
                this.updateCsrfToken();
                this.successChange.emit(true);
                this.refresh();
            },
            (value) => {
                this.loader.hide();
                this.updateCsrfToken();
                this.fieldsError = value.errors;
                this.successChange.emit(false);
            }
        );
  }

  refresh() {
    this.refreshEvent.emit(true);
  }
}
