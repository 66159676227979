<form class="delete-dialog-form" name="deleteForm" (ngSubmit)="confirm()">
    <div class="modal-header">
        <h2 class="modal-title bordered-title">{{title}}</h2>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">
            <span class="aed-icon aed-icon-close" aria-hidden="true"></span>
        </button>
    </div>
    <div class="modal-body">
        <p *ngFor="let message of messages" [innerHTML]="message">Confirmation</p>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-dialog-sm">
            {{confirmText}}
        </button>
        <button type="button" class="btn btn-third btn-dialog-sm" data-dismiss="modal" (click)="cancel()">
            {{cancelText}}
        </button>
    </div>
</form>
