import {Component, Input, OnInit} from '@angular/core';
import {AgentService} from '../agent.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthentificationService} from '../../authentification/authentification.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {NgForm} from '@angular/forms';
import {CsrfService} from '../../authentification/csrf.service';
import {FormationService} from '../formation.service';
import {ReferentFormComponent} from '../referent-form/referent-form.component';

@Component({
    selector: 'app-formation-form',
    templateUrl: './formation-form.component.html',
    styleUrls: ['./formation-form.component.scss']
})
export class FormationFormComponent implements OnInit {

    @Input() agent: any;
    @Input() formation: any;

    public success: boolean;
    public isSubmitting = false;
    public fieldError = false;
    public csrfToken = '';
    public isEdition = true;

    constructor(public activeModal: NgbActiveModal,
                private agentService: AgentService,
                private loaderService: LoaderService,
                private authService: AuthentificationService,
                private csrfService: CsrfService,
                private formationService: FormationService,
                private modalService: NgbModal) {
    }

    ngOnInit() {
        if ((this.formation === null || this.formation === undefined)) {
            this.isEdition = false;
            this.formation = {};
        }
        this.updateCsrfToken();
    }

    /**
     *  @param {NgForm} submittedForm
     */
    public onSubmit(submittedForm: NgForm): void {
        this.loaderService.show();

        const data = submittedForm.value;
        if (this.formation != null && this.formation.id) {
            data['id'] = this.formation.id;
        }
        data['user_id'] = this.agent.id;
        data['debut'] = this.formation.debut;
        data['fin'] = this.formation.fin;
        this.formationService.update(data, this.csrfToken).subscribe((value) => {
            this.loaderService.hide();
            this.activeModal.close({success: true});
        }, () => {
            this.loaderService.hide();
            this.activeModal.close({success: false});
        });
    }

    /**
     * Refresh the CSRF Token
     */
    private updateCsrfToken() {
        this.csrfService.getToken()
            .subscribe(
                token => {
                    this.csrfToken = token;
                });
    }

    public onDebutSelection(selected: any) {
        this.formation.debut = selected;
    }

    public onFinSelection(selected: any) {
        this.formation.fin = selected;
    }
}
