import {DveResolve} from './dve.resolve';
import {DveDetailComponent} from './dve-detail/dve-detail.component';

export const dveRoutes = [
    {
        path: 'dve/:num',
        resolve: {
            dve: DveResolve
        },
        component: DveDetailComponent,
        data: {
            title: "Déclaration de vacances d'emploi",
        }
    },
];
