
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';


import {HttpClient, HttpHeaders} from '@angular/common/http';
import {getDrupalUri} from '../shared/external';

@Injectable()
export class EmploiService {

  readonly customApiPath: string = '/customapi';
  readonly allForCollectiviteApiPath: string = '/customapi/emplois/collectivite';

    constructor(private http: HttpClient) {
    }

    public get(id: any): Observable<any> {
        return this.http.get<any>(getDrupalUri() + this.customApiPath + '/emploi/' + id).pipe(map(
            (emp) => {
                return emp;
            }
        ));
    }

    public getForAutocomplete(id: any): Observable<any> {
        return this.http.get<any>(getDrupalUri() + this.customApiPath + '/emploi/' + id).pipe(map(
            (emp) => {
                return {id: emp.id, label: emp.label, type: 'emploi'};
            }
        ));
    }

    /**
     * Return results for autocomplete search on places
     *
     * @param {string} term
     * @returns {any}
     */
    searchPlaces(term: string) {
        if (term === '') {
            return of([]);
        }

        return this.http.get<any>(getDrupalUri() + this.customApiPath + '/search/collectivites/' + term).pipe(map(
            (cols) => {
                return cols;
            }
        ));
    }

    /**
     * Return results for autocomplete search on agents and emplois
     *
     * @param {string} term
     * @returns {any}
     */
    searchAgentEmploi(term: string) {
        if (term === '') {
            return of([]);
        }

        return this.http.get<any>(getDrupalUri() + this.customApiPath + '/search/emplois_agents/' + term).pipe(map(
            (res) => {
                return res;
            }
        ));
    }

    /**
     * search
     * @param {string[]} departements
     * @param {any} collectivites
     * @returns {any}
     */
    search(agentEmploi: any,
           collectivite: any,
           departements: string,
           cjs: string,
           tcds: string,
           cotations: string,
           filieres: string,
           domaines: string,
           page: number) {
        if (
            (departements == null || departements.length === 0)
            && agentEmploi == null
            && collectivite == null
            && (cjs == null || cjs.length === 0)
            && (tcds == null || tcds.length === 0)
            && (cotations == null || cotations.length === 0)
            && (filieres == null || filieres.length === 0)
            && (domaines == null || domaines.length === 0)) {
            return of([]);
        }

        const query = [];

        query.push(this.getQueryStringForParam(departements, 'dep[]'));
        query.push(this.getQueryStringForParam(tcds, 'tcd[]'));
        query.push(this.getQueryStringForParam(cotations, 'cot[]'));
        query.push(this.getQueryStringForParam(filieres, 'fil[]'));
        query.push(this.getQueryStringForParam(domaines, 'dom[]'));
        query.push(this.getQueryStringForParam(cjs, 'cj[]'));
        if (collectivite != null) {
            query.push(this.getQueryStringForParam(collectivite.name, 'col'));
        }
        if (agentEmploi != null) {
            if (agentEmploi.type == 'emploi') {
                query.push(this.getQueryStringForParam(agentEmploi.id, 'emp'));
            } else if (agentEmploi.type == 'agent') {
                query.push(this.getQueryStringForParam(agentEmploi.id, 'agt'));
            }
        }

        const queryStr = (query.filter(x => x != null) as string[]).join('&');

        return this.http.get<any>(getDrupalUri() + this.customApiPath + '/search/annuaire?page=' + page + '&' + queryStr).pipe(map(
            (emplois) => {
                return emplois;
            }
        ));
    }

    /**
     * Send emploi form data to backend for creation
     * @param datas
     * @returns {Observable<any>}
     */
    public createEmploi(data: any) {
      return this.http.post<any>(
          getDrupalUri() + this.customApiPath + '/emploi/new', data).pipe(
          map(
              (value) => {
                  return value;
              }
          ));
    }

    /**
     * Send emploi form data to backend for creation
     * @param datas
     * @returns {Observable<any>}
     */
    public editEmploi(data: any) {
      let id = data.id;
      return this.http.post<any>(
          getDrupalUri() + this.customApiPath + '/emploi/' + id +'/edit' , data).pipe(
          map(
              (value) => {
                  return value;
              }
          ));
    }

    /**
     * Send emploi form data to backend
     * @param datas
     * @returns {Observable<any>}
     */
    public handleEmploiSubmission(data: any) {
        return this.http.post<any>(
            getDrupalUri() + this.customApiPath + '/emploi', data).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     * Closes an emploi's affectations and declares vacant if needed
     * @param datas
     * @returns {Observable<any>}
     */
    public declareVacant(data: any) {
      return this.http.post<any>(
          getDrupalUri() + this.customApiPath + '/emploi/vacant' , data).pipe(
          map(
              (value) => {
                  return value;
              }
          ));
    }

    /**
     * Send experience form data to backend
     * @param data
     * @returns {Observable<any>}
     */
    public handleExperienceSubmission(data: any) {
        return this.http.post<any>(
            getDrupalUri() + this.customApiPath + '/experience', data).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     * Disable emploi
     * @param id
     * @returns {Observable<any>}
     */
    public disableEmploi(id: any): Observable<any> {
        return this.http.post<any>(getDrupalUri() + this.customApiPath + '/emploi/disable', {'id': id}).pipe(map(
            (result) => {
                return result;
            }
        ));
    }

    /**
     * Enable emploi
     * @param id
     * @returns {Observable<any>}
     */
    public enableEmploi(id: any): Observable<any> {
        return this.http.post<any>(getDrupalUri() + this.customApiPath + '/emploi/enable', {'id': id}).pipe(map(
            (result) => {
                return result;
            }
        ));
    }

    /**
     * Send emploi form data to backend
     * @param id
     * @returns {Observable<any>}
     */
    public deleteEmploi(id: any): Observable<any> {
        return this.http.delete<any>(getDrupalUri() + this.customApiPath + '/emploi/' + id).pipe(map(
            (result) => {
                return result;
            }
        ));
    }

    /**
     * Delete an experience
     * @param id
     * @returns {Observable<any>}
     */
    public deleteExperience(id: any): Observable<any> {
        return this.http.delete<any>(getDrupalUri() + this.customApiPath + '/experience/' + id).pipe(map(
            (result) => {
                return result;
            }
        ));
    }

    /**
     * Active ou désactive un Emploi
     * @param id
     * @param {boolean} active
     * @returns {Observable<any>}
     */
    public changeEmploiValidity(id: any, active: boolean): Observable<any> {
        return this.http.post<any>(getDrupalUri() + this.customApiPath + '/emploi/' + id + '/validity', active).pipe(map(
            (result) => {
                return result;
            }
        ));
    }

    /**
     * Active ou désactive une Expérience
     * @param id
     * @param {boolean} active
     * @returns {Observable<any>}
     */
    public changeExperienceValidity(id: any, active: boolean): Observable<any> {
        return this.http.post<any>(getDrupalUri() + this.customApiPath + '/experience/' + id + '/validity', active).pipe(map(
            (result) => {
                return result;
            }
        ));
    }

    /**
     * Build the query string width form params
     * @param param
     * @param {string} key
     * @returns {string}
     */
    private getQueryStringForParam(param: any, key: string) {
        if (param == null) {
            return null;
        }

        if (typeof param === 'string') {
            return key + '=' + encodeURIComponent(param);
        }

        if (param.length > 0) {
            const q = [];
            for (const item of param) {
                if (item.indexOf(',') !== -1) {
                    for (const subItem of item.split(',')) {
                        q.push(key + '=' + encodeURIComponent(subItem));
                    }
                } else {
                    q.push(key + '=' + encodeURIComponent(item));
                }
            }
            return q.join('&');
        }
        return null;
    }

    /**
     * Return form data to fill select tags
     *
     * @returns {Observable<any>}
     */
    public getFormDatas(): Observable<any> {
        return this.http.get<any>(getDrupalUri() + this.customApiPath + '/emplois/referentiel').pipe(map(
            (val) => {
                return val;
            }
        ));
    }


    /**
     * Return emploi/cotation list for a collectivite
     *
     * @returns {Observable<any>}
     */
    public getAvailable(collectivite: string): Observable<any> {
        return this.http.get<any>(getDrupalUri() + this.customApiPath + '/emplois/available/' + collectivite).pipe(map(
            (val) => {
                return val;
            }
        ));
    }

    /**
     * Notify an error on a job affectation
     * @param {number} agentId
     * @param {number} emploiId
     * @param {string} message
     * @returns {Observable<any>}
     */
    public notifyError(agentId: number, emploiId: number, message: string) {
        return this.http.post<any>(
            getDrupalUri() + this.customApiPath + '/emploi/' + emploiId + '/signaler', {
                'agent_id': agentId,
                'emploi_id': emploiId,
                'message': message
            }).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     *
     * @param id
     * @returns {Observable<any>}
     */
    public getAllForCollectivite(id: any, page: number, itemsPerPage: number, fulltextQuery: string): Observable<any> {

      let params = '?';
      params = this.addParamToQuery(params, 'id', id, null, true);
      params = this.addParamToQuery(params, 'page', page, 1, true);
      params = this.addParamToQuery(params, 'itemsPerPage', itemsPerPage, 15, true);
      if (fulltextQuery !== "") {
        params = this.addParamToQuery(params, 'fulltextQuery', fulltextQuery, "", true);
      }
      params = this.addParamToQuery(params, '_format', null, 'json', false);

      let url = getDrupalUri() + this.allForCollectiviteApiPath  + params;
      return this.http.get<any>(url).pipe(
          map(
              (value) => {
                  return value;
              }
          ));
    }

    private addParamToQuery(query: string, param: string, value: any, backup: any, hasNext: boolean) {
      const finalValue = (value === null || value === undefined) ? backup : value;
      query += param + "=" + finalValue;
      if (hasNext) {
        query += '&';
      }
      return query;
    }
}
