
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getDrupalUri} from '../external';
import {HttpClient} from "@angular/common/http";
import { Observable, of } from 'rxjs';



@Injectable()
export class MapService {


    readonly apiPath: string = '/customapi/map';

    constructor(private http: HttpClient) {
    }

    /**
     * Return the department list with map infos
     *
     * @returns {Observable<any>}
     */
    public getMapInfos(): Observable<any>  {
        return this.http.get<any>(getDrupalUri() + this.apiPath + '/departements').pipe(map(
            (val) => {
                return val;
            }
        ));
    }

    /**
     *  Return the regions list with map infos
     */
    public getMapRegions(): Observable<any>  {
        return this.http.get<any>(getDrupalUri() + this.apiPath + '/regions').pipe(map(
            (val) => {
                return val;
            }
        ));
    }

    /**
     * Return the jobs total count for a department list
     *
     * @param {Array<any>} deps
     * @returns {Observable<any>}
     */
    public getJobCountByDepartments(deps: Array<any>): Observable<any> {
        const ret = [];
        for (const dep of deps) {
            ret.push(encodeURIComponent(dep));
        }

        const queryString = ret.join(',');

        return this.http.get<any>(getDrupalUri() + this.apiPath + '/jobs/' + queryString).pipe(map(
            (val) => {
                return val;
            }
        ));
    }
}
