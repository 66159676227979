import { Component, OnInit, AfterContentInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { STORAGE_PREVIOUS_URL } from '../authentification.service';

@Component({
  selector: 'app-silent-login-callback',
  templateUrl: './silent-login-callback.component.html'
})
// Do the redirect if not in iframe, guard are too early.
export class SilentLoginCallbackComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
