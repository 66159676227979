import {Attribute, Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS, Validator, AbstractControl, Validators} from '@angular/forms';

const VIADEO_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => YearPeriodeValidatorDirective),
    multi: true
};

@Directive({
    selector: '[yearPeriode][formControlName],[yearPeriode][formControl],[yearPeriode][ngModel]',
    providers: [VIADEO_VALIDATOR]
})
export class YearPeriodeValidatorDirective implements Validator {
    constructor(@Attribute('beginField') private beginField: string) {
    }

    validate(control: AbstractControl): { [key: string]: any } {
        const beginYear = +control.root.get(this.beginField).value;
        if ((beginYear === null || beginYear === undefined)) {
            return null;
        }
        const endYear = +control.value;

        return endYear >= beginYear ? null : {yearPeriode: true};
    }
}
