import { Routes } from '@angular/router';
import { FicheComponent } from './fiche/fiche.component';
import { AgentResolve } from './agent.resolve';
import {FicheEditComponent} from './fiche-edit/fiche-edit.component';
import {AuthRequiredGuard} from '../authentification/auth-required.guard';
import {ROLE_ADMIN, ROLE_AGENT, ROLE_CNFPT, ROLE_LOGGED, ROLE_REFERENT_CT} from '../authentification/has-any-role';
import {AgentSessionResolve} from './agent-session.resolve';

export const agentRoutes = [
    {
        path: 'agent/:id',
        resolve: {
            agent: AgentResolve
        },
        component: FicheComponent,
        data: {
            title: 'Fiche d\'un agent'
        }
    },
    {
        path: 'agent/edit/:id',
        resolve: {
            agent: AgentResolve
        },
        canActivate: [AuthRequiredGuard],
        component: FicheEditComponent,
        data: {
            title: 'Modification de votre profil',
            roles: [ROLE_CNFPT, ROLE_ADMIN],
            rolesWithOwnership: [ROLE_AGENT, ROLE_REFERENT_CT],
            ownershipMethod: 'agent'
        }
    },
    {
        path: 'profil',
        resolve: {
            agent: AgentSessionResolve
        },
        canActivate: [AuthRequiredGuard],
        component: FicheEditComponent,
        data: {
            title: 'Modification de votre profil',
            roles: [ROLE_CNFPT, ROLE_ADMIN, ROLE_AGENT, ROLE_REFERENT_CT, ROLE_LOGGED]
        }
    }
];
