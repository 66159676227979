import { Component, OnInit } from '@angular/core';
import {LoaderSmallService} from "./loader-small.service";

@Component({
  selector: 'app-loader-small',
  templateUrl: './loader-small.component.html',
  styleUrls: ['./loader-small.component.scss']
})
export class LoaderSmallComponent implements OnInit {
    public show = false;

    constructor(
        private loaderSmallService: LoaderSmallService
    ) {}

    ngOnInit() {
        this.loaderSmallService.activated.subscribe(
            (showOrHide) => {
                this.show = showOrHide;
            },
            () => {
                this.show = false;
            }
        );
    }
}
