import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-formation',
    templateUrl: './formation.component.html',
    styleUrls: ['./formation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FormationComponent implements OnInit {
    @Input() formation: any;
    @Input() showVisibility = false;

    constructor() {
    }

    ngOnInit() {
    }

}
