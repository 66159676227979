import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-emploi-affectation-light',
    templateUrl: './affectation-light.component.html',
    styleUrls: ['./affectation-light.component.scss']
})
export class AffectationLightComponent implements OnInit {

    @Input() affectation: any;
    @Input() isVacant = false;
    @Input() isOwner = false;
    @Input() currentUserId = undefined;

    constructor() {
    }

    ngOnInit() {
        if (!(this.affectation === null || this.affectation === undefined) && !(this.affectation.user_id === null || this.affectation.user_id === undefined) && !this.isOwner) {
            this.isOwner = this.currentUserId === this.affectation.user_id.id;
        }
    }
}
