import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: [
    'loader.component.scss'
  ]
})
export class LoaderComponent implements OnInit {
  public show = false;

  constructor(
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.loaderService.activated.subscribe(
      (showOrHide) => {
        this.show = showOrHide;
      },
      () => {
        this.show = false;
      }
    );
  }
}
