import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { HasAnyRoleDirective, ROLE_ADMIN, ROLE_AGENT, ROLE_CNFPT, ROLE_REFERENT_CT } from './has-any-role';
import { OwnershipAccessState } from './has-any-role';

@Directive({
    selector: '[hasRoleReferentCT]'
})
export class HasRoleReferentCtDirective extends HasAnyRoleDirective {

    constructor(protected templateRef: TemplateRef<any>,
        protected viewContainer: ViewContainerRef) {
        super(templateRef, viewContainer);
    }

    @Input()
    set hasRoleReferentCT(accessState: OwnershipAccessState) {
        this.currentUserRoles = accessState.currentUserRoles;
        this.checkMasterRoles();
        if (!this.hasView) {
            this.checkRoles([ROLE_REFERENT_CT]);
        }
    }

}
