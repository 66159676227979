import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';
import {EmploiService} from '../emploi.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {DeleteDialogComponent} from '../../shared/delete-dialog/delete-dialog.component';
import {OwnershipAccessState} from '../../authentification/has-any-role';
import {AuthentificationService} from '../../authentification/authentification.service';
import {ContactCnfptComponent} from '../../account/contact-cnfpt/contact-cnfpt.component';

@Component({
    selector: 'app-emploi-form',
    templateUrl: './emploi-form.component.html',
    styleUrls: ['./emploi-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EmploiFormComponent implements OnInit {

    @Input() agent: any;
    @Input() emploi: any;
    @Input() collectivite: any;
    @Input() isEditedByReferent = false;

    currentYear = new Date().getFullYear();
    currentMonth = ('00' + (new Date().getMonth() + 1)).slice(-2);
    datesRange6 = [];
    datesRange40 = [];

    showFonction = false;
    providedAlert = false;
    showProvidedAlert = false;
    staticFonctionnel = false;
    collectiviteIsSelected = false;
    showCollectiviteEmploiList = false;
    isConservateur = false;
    success: boolean;
    fieldError: boolean;
    isSubmitting: boolean;
    invalidTimePeriod = false;

    public notifySuccess: boolean = undefined;
    public lastKeyIsDigit = true;

    cotationsList: any = [];
    typeEmployeurList: any = [];
    domaineList: any = [];
    filiereList: any = [];
    colEmploisList: any = [];

    selectedEmploi: any = [];
    selectedFiliere: any = [];
    selectedDomaine: any = [];

    /**
     * Multiselect field settings
     */
    public settingsSingle = {
      singleSelection: true,
      text: '-',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      searchPlaceholderText: 'Rechercher',
      enableSearchFilter: true,
      labelKey: 'name',
      primaryKey: 'id',
    };

    accessState: OwnershipAccessState;

    constructor(private emploiService: EmploiService,
                private loader: LoaderService,
                public activeModal: NgbActiveModal,
                private modalService: NgbModal,
              private authService: AuthentificationService) {

      if (this.isEditedByReferent) {
          if (!this.emploi) {
              this.emploi = {
                  type: '1',
                  collectivite: this.collectivite,
                  typeAffectation: '2',
                  ongoing: 1,
                  cotation_select_id: [],
              };
          } else {
              this.emploi.type = '1';
              this.emploi.typeAffectation = '2';
              this.emploi.cotation_select_id = [];

          }
      } else {
          this.emploi = {
              ongoing: 1,
              emploiFonctionnel: undefined,
              typeAffectation: undefined,
              cotation_select_id: [],
          };
      }
      if (!this.emploi.filiere) {
        this.emploi.filiere = {id: null};
      }

      if (!this.emploi.domaine_famille) {
        this.emploi.domaine_famille = {id: null};
      }

    }


    ngOnInit() {
        // For role specific display
        this.authService.identity.subscribe((identity) => {
            if (identity && identity.roles) {
                this.accessState = {
                    currentUserRoles: identity.roles,
                    hasOwnership: false
                };
            } else {
                this.accessState = {
                    currentUserRoles: [],
                    hasOwnership: false
                };
            }
        }, () => {
            this.accessState = {
                currentUserRoles: [],
                hasOwnership: false
            };
        });


        if (this.emploi && this.emploi.collectivite && this.emploi.collectivite.id) {
            this.emploiService.getAvailable(this.emploi.collectivite.id).subscribe(
                (result) => {
                    for (const cotation of result) {
                        if (cotation.id !== 0 && cotation.id.indexOf('cot-') >= 0) {
                            this.colEmploisList.push(cotation);
                        }
                    }
                    this.showCollectiviteEmploiList = true;
                }
            );
        }

        this.success = undefined;
        this.fieldError = false;
        this.isSubmitting = false;
        this.loader.hide();

        this.emploiService.getFormDatas().subscribe(
            (result) => {
                this.cotationsList = result.cotations;
                this.typeEmployeurList = result.types_employeur;
                this.domaineList = result.domaines;
                this.filiereList = result.filieres;
            }
        );

        for (let i = 0; i < 6; i++) {
            this.datesRange6.push(this.currentYear - i);
        }

        for (let i = 0; i < 39; i++) {
            this.datesRange40.push(this.currentYear - i);
        }
    }

    public onSubmit(submittedForm: NgForm) {
        this.loader.show();
        this.isSubmitting = true;
        const data = submittedForm.value;
        if (this.agent) {
            data['agent'] = this.agent.id;
        }
        if (this.emploi.collectivite) {
            data['collectivite'] = this.emploi.collectivite;
        }
        if (this.emploi.employeurVille) {
            data['employeurVille'] = this.emploi.employeurVille;
        }
        if (this.isEditedByReferent) {
            data['type'] = '1';
        }
        if (this.emploi) {
            data['emploi_id'] = this.emploi.id;
        }
        if (this.selectedEmploi) {
          data['emploi'] = [this.selectedEmploi[0].id];
        }
        if (this.selectedFiliere) {
          data['filiere'] = this.selectedFiliere;
        }
        if (this.selectedDomaine) {
          data['domaine_famille'] = this.selectedDomaine;
        }
        data['emploiFonctionnel'] = this.emploi.fonctionnel ? 1 : 0;
        this.emploiService.handleEmploiSubmission(data).subscribe(() => {
            this.loader.hide();
            this.activeModal.close({success: true});
        }, () => {
            this.loader.hide();
            this.activeModal.close({success: false});
        });
    }

    public onPlaceSelection(selected: any) {
        if (!(selected === null || selected === undefined) && selected.id != null) {
            this.emploi.collectivite = selected;
            this.showCollectiviteEmploiList = true;
            this.emploiService.getAvailable(this.emploi.collectivite.id).subscribe(
                (result) => {
                    this.colEmploisList = result;
                },
                (error) => {
                  console.log(error);
                }
            );
        } else {
            this.emploi.collectivite = null;
            this.showCollectiviteEmploiList = false;
        }
    }

    public onEmployeurPlaceSelection(selected: any) {
        this.emploi.employeurVille = selected;
        this.collectiviteIsSelected = (!(selected === null || selected === undefined) && selected.id != null);
        console.log(selected, this.emploi.type, this.collectiviteIsSelected);
    }

    public applyEmploiRules(emploi: any) {
        let emploiId = emploi[0].id;
        if (Array.isArray(emploiId)) {
            emploiId = emploiId[0].id;
        }
        if (!(this.colEmploisList === null || this.colEmploisList === undefined)) {
            const selectedEmp = this.colEmploisList.filter(x => x.id === ((typeof emploiId !== 'undefined') ? emploiId.toString(): null))[0];
            if (selectedEmp != null && selectedEmp.code === 'DC') {
                this.staticFonctionnel = true;
                this.emploi.fonctionnel = false;
            } else if (selectedEmp != null
                && (selectedEmp.code === '02' // DGA
                || selectedEmp.code === '03' // DGS
                || selectedEmp.code === '04') // DGST
            ) {
                this.staticFonctionnel = true;
                this.emploi.fonctionnel = true;
            } else {
                this.staticFonctionnel = false;
                this.emploi.fonctionnel = undefined;
            }
            // positionnement de la fonction
            if (!(selectedEmp === null || selectedEmp === undefined)) {
              this.emploi.emploiFonction = selectedEmp.fonction;
              if (!(selectedEmp.filiere === null || selectedEmp.filiere === undefined)) {
                this.selectedFiliere = this.filiereList.filter(x => x.id === selectedEmp.filiere.id);
              }
              if (!(selectedEmp.domaine_famille === null || selectedEmp.domaine_famille === undefined)) {
                this.selectedDomaine = this.domaineList.filter(x => x.id === selectedEmp.domaine_famille.id);
              }
            }
            // positionnement de la flilère

            this.showFonction = this.colEmploisList.filter(x => x.id === emploiId && x.needFonction).length > 0;

            this.providedAlert = selectedEmp != null && selectedEmp.isProvided;
        }
    }

    public changeEmploiValidity() {
        this.emploiService.changeEmploiValidity(this.emploi.id, !this.emploi.is_valid).subscribe(
            (value) => {
                this.emploi.is_valid = value;
            }, () => {
            });
    }

    public openDeleteDialog() {
        let modalRef: NgbModalRef;
        modalRef = this.modalService.open(DeleteDialogComponent, {size: 'sm'});
        modalRef.result.then((result) => {
            if (result === true) {
                this.loader.show();
                this.emploiService.deleteEmploi(this.emploi.id).subscribe(() => {
                    this.loader.hide();
                    this.activeModal.close({success: true});
                }, () => {
                    this.loader.hide();
                    this.activeModal.close({success: false});
                });
            }
        });
    }

    public validateTimePeriod() {
        if ((this.emploi.emploiSinceMois === null || this.emploi.emploiSinceMois === undefined)
            || (this.emploi.emploiSinceAnnee === null || this.emploi.emploiSinceAnnee === undefined)
            || (this.emploi.emploiEndMois === null || this.emploi.emploiEndMois === undefined)
            || (this.emploi.emploiEndAnnee === null || this.emploi.emploiEndAnnee === undefined)
        ) {
            this.invalidTimePeriod = false;
            return;
        }

        // Date de début (ajout d'un 0 avant le mois si besoin)
        const beginMonthClean = (this.emploi.emploiSinceMois < 10 && this.emploi.emploiSinceMois.length !== 2)
            ? `0${this.emploi.emploiSinceMois}`
            : `${this.emploi.emploiSinceMois}`;
        const beginDate = new Date(`${this.emploi.emploiSinceAnnee}-${beginMonthClean}`);

        // Date de fin (ajout d'un 0 avant le mois si besoin)
        const endMonthClean = (this.emploi.emploiEndMois < 10 && this.emploi.emploiEndMois.length !== 2)
            ? `0${this.emploi.emploiEndMois}`
            : `${this.emploi.emploiEndMois}`;
        const endDate = new Date(`${this.emploi.emploiEndAnnee}-${endMonthClean}`);

        this.invalidTimePeriod = endDate < beginDate;

        // Debug
        // console.log(this.emploi.emploiSinceMois, beginMonthClean, beginDate);
        // console.log(this.emploi.emploiEndMois, endMonthClean, endDate);
        // console.log(this.invalidTimePeriod);
    }

    public notifyCnfpt(type: string): void {
        const modalRef = this.modalService.open(ContactCnfptComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.type = type;
        modalRef.result.then((result) => {
            this.notifySuccess = result.success;
            window.scrollTo(0, 0);
        }, () => {
            this.notifySuccess = undefined;
        });
    }

    /**
     * Tests if character is a number, if not: doesn't allow it
     *
     * @returns {boolean}
     */
    public checkIfNumber(event: any) {
        const keyPressed = event.charCode;  // k = event.keyCode;  (Both can be used)
        this.lastKeyIsDigit = (keyPressed >= 48 && keyPressed <= 57);
        return this.lastKeyIsDigit;
    }
}
