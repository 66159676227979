<div class="aj-job">
    <a class="clickable clearfix" [routerLink]="['/dve', dve.field_dve_d1]">
        <div class="aj-job-metas">
            <ul class="list-unstyled">
                <li>
                    <span class="aed-icon aed-icon-hash" aria-hidden="true"></span>
                    <span class="sr-only">Numéro de la déclaration</span>
                    <span>{{dve.field_dve_d1}}</span>
                </li>
            </ul>
        </div>

        <ul class="list-unstyled aed-label-value">
            <li *ngIf="dve.field_dve_d41">
                <span class="aed-icon aed-icon-clock" aria-hidden="true"></span>
                <span class="">
                    Date de recrutement prévu :  <span class="bolded">{{dve.field_dve_d41.formatted}}</span>
                </span>
            </li>
            <li *ngIf="dve.field_dve_d42">
                <span class="aed-icon aed-icon-clock" aria-hidden="true"></span>
                <span class="">
                    Date de la déclaration :  <span class="bolded">{{dve.field_dve_d42.formatted}}</span>
                </span>
            </li>
        </ul>
    </a>
</div>