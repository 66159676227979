import {Component, OnInit,  ChangeDetectorRef, AfterContentChecked} from '@angular/core';
import {LoaderService} from '../../shared/loader/loader.service';
import {AccountService, UserCustomField} from '../account.service';
import {AnnuaireService} from '../../annuaire/annuaire.service';
import {IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';
import {Router} from '@angular/router';
import {EmploiService} from '../../emploi/emploi.service';
import {AuthentificationService} from '../../authentification/authentification.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ContactCnfptComponent} from '../contact-cnfpt/contact-cnfpt.component';


@Component({
    selector: 'app-form-encadrant',
    templateUrl: './form-encadrant.component.html',
    styleUrls: ['./form-encadrant.component.scss']
})
export class FormEncadrantComponent implements OnInit {
    public fields: UserCustomField[];
    public registration: any = {};
    public identity: any = {};
    public success: boolean;
    public isSubmitting = false;
    public fieldError = false;
    public notifySuccess: boolean = undefined;
    public lastKeyIsDigit = true;

    public collectiviteIsSelected = false;
    public cotationsList: any[];
    public cEmploisList: any[];
    public noJobsList: any[];
    public modeAccesList: any[];
    public corpsOrigineList: any[];
    public typeEmployeurList: any[];
    public colEmploisList: any[];

    public currentYear = new Date().getFullYear();
    public datesRange6 = [];
    public datesRange40 = [];

    public showFonction: boolean;
    public showProvidedAlert = false;
    public importedEmploiId = undefined;
    public showAutreCorps = false;
    public showDetailPromotion = false;
    public showListeAptitude = false;
    public isConfirmStage = false;
    public isConservateur = false;
    public showCollectiviteEmploiList = false;

    selectedCadreEmploi: any = [];
    selectedEmploi: any = [];

    /**
     * Multiselect field settings
     */
     public settingsSingle = {
      singleSelection: true,
      text: '-',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      searchPlaceholderText: 'Rechercher',
      enableSearchFilter: true,
      labelKey: 'name',
      primaryKey: 'id',
    };

    constructor(private accountService: AccountService, private annuaireService: AnnuaireService,
                private loader: LoaderService, private router: Router, private emploiService: EmploiService,
                private authService: AuthentificationService,
                private modalService: NgbModal,
                private changeDetector: ChangeDetectorRef)
    {
      this.registration = {};
    }
    // règle le soucis de mise à jour de showFonction
    ngAfterContentChecked() {
      this.changeDetector.detectChanges();
    }
    ngOnInit() {
        this.success = undefined;
        this.fieldError = false;
        this.isSubmitting = false;



        this.authService.identity.subscribe(
            (identity) => this.identity = identity,
            () => {}
        );

        this.accountService.getAgentFormDatas(this.identity.email).subscribe(
            (result) => {
                this.cotationsList = result.cotations;
                this.cEmploisList = result.cadre_emplois;
                this.noJobsList = result.no_jobs;
                this.modeAccesList = result.mode_acces;
                this.corpsOrigineList = result.corps_origine;
                this.typeEmployeurList = result.types_employeur;
                this.populateRegistration(result);
                this.loader.hide();
            }
        );

        for (let i = 0; i < 6; i++) {
            this.datesRange6.push(this.currentYear - i);
        }

        for (let i = 0; i < 39; i++) {
            this.datesRange40.push(this.currentYear - i);
        }
    }

    private populateRegistration(data) {
        if (data.emploi !== undefined && data.emploi !== null) {
            this.registration.fieldEmploiFpt = (data.emploiFtp === true || data.emploiFtp === '1') ? '1' : '0';
            this.registration.emploiSinceMois = data.emploiSinceMois;
            this.registration.emploiSinceAnnee = data.emploiSinceAnnee;
            this.registration.emploiFonction = data.emploiFonction;
            this.registration.emploiFonctionnel = (data.emploiFonctionnel === true || data.emploiFonctionnel === '1') ? '1' : '0';
            this.registration.emploi = data.emploi.emp_id;
            this.importedEmploiId = data.emploi.emp_id;
            this.applyEmploiRules(this.importedEmploiId);
            this.registration.fieldEtesVousEnEmploi = '1';
            this.registration.experienceExists = '1';
            this.registration.experienceSansEmploi = null;
            this.registration.fieldDateNomination = data.field_date_nomination;
            this.registration.fieldEtesVousFt = (data.field_etes_vous_ft === true || data.field_etes_vous_ft === '1') ? '1' : '0';
        }
        if (data.collectivite !== undefined && data.collectivite !== null) {
            this.registration.collectivite = data.collectivite;
            this.showCollectiviteEmploiList = true;
            this.emploiService.getAvailable(this.registration.collectivite.id).subscribe(
                (result) => {
                    this.colEmploisList = result;
                }
            );
        }
        if (data.field_grade !== undefined && data.field_grade !== null) {
            this.registration.fieldCadreEmploi = data.field_grade;
            this.selectedCadreEmploi = data.field_grade;
        }
    }

    public onSubmit() {
        this.loader.show();
        this.isSubmitting = true;
        this.registration.profile = 'agent';
        this.registration.fieldCadreEmploi = this.selectedCadreEmploi.map(x => x.id);
        this.registration.emploi = this.selectedEmploi.map(x => x.id);
        this.accountService.postForm(this.registration).subscribe(
            (value) => {
                this.loader.hide();
                this.isSubmitting = false;
                this.success = true;
                this.router.navigate(['/registered']);
                this.authService.authenticate();
                window.scrollTo(0, 0);
            },
            () => {
                this.loader.hide();
                this.isSubmitting = false;
                this.success = false;
            }
        );
    }

    public onPlaceSelection(selected: any) {
        if (!(selected === null || selected === undefined) && selected.id != null) {
            this.registration.collectivite = selected;
            this.showCollectiviteEmploiList = true;
            this.emploiService.getAvailable(this.registration.collectivite.id).subscribe(
                (result) => {
                    this.colEmploisList = result;
                }
            );
        } else {
            this.registration.collectivite = null;
            this.showCollectiviteEmploiList = false;
        }
    }

    public onEmployeurPlaceSelection(selected: any) {
        this.registration.employeurVille = selected;
        this.collectiviteIsSelected = (!(selected === null || selected === undefined) && selected.id != null);
    }

    public applyEmploiRules(emploi: any) {
      let emploiId = emploi[0].id;
        if (Array.isArray(emploiId)) {
            emploiId = emploiId[0].id;
        }
        if (!(this.colEmploisList === null || this.colEmploisList === undefined) && !(emploiId === null || emploiId === undefined)) {
          console.log(this.colEmploisList, emploiId);
            // Affichage input "Titre de l'emploi"
            this.showFonction = this.colEmploisList.filter(x => x.id === emploiId && x.needFonction).length > 0;
            // Sélection de
            const selectedEmp = this.colEmploisList.filter(x => x.id === ((typeof emploiId !== 'undefined') ? emploiId.toString(): null))[0];
            // positionnement de la fonction
            if (!(selectedEmp === null || selectedEmp === undefined)) {
              this.registration.emploiFonction = selectedEmp.fonction;
            }

            // On défini si l'emploi est fonctionnel ou non en fonction du code
            this.registration.emploiFonctionnel = this.colEmploisList.filter(x => x.id === emploiId && ['02', '03', '04'].includes(x.code)).length > 0 ? '1' : '0';

            // On n'affiche pas l'alert de remplacement d'un poste existant si c'est cet emploi est importé avec l'utilisateur
            this.showProvidedAlert = this.importedEmploiId !== emploiId
                && this.colEmploisList.filter(x => x.id === emploiId && !x.isLabel && x.isProvided).length > 0;
        }
    }

    public applyWorkInFptRules(workInFpt: any) {
        this.registration.collectivite = null;
        this.showCollectiviteEmploiList = false;
        this.registration.collectiviteCode = null;
        this.colEmploisList = null;
    }

    public applyModeAccesRules(modeAccesId: number) {
        this.showDetailPromotion = this.modeAccesList.filter(x => x.id === modeAccesId && x.id_aed === 'concours').length > 0;
    }

    public applyCorpsOrigineRules(corpsOrigineId: number) {
        this.showAutreCorps = this.corpsOrigineList.filter(x => x.id === corpsOrigineId && x.needDetail).length > 0;
    }

    public applyCadreEmploiRules(cadreEmploi: any) {
        let cadreEmploiId = cadreEmploi[0].id;
        if (Array.isArray(cadreEmploiId)) {
            cadreEmploiId = cadreEmploiId[0].id;
        }
        this.registration.fieldCadreEmploi = [cadreEmploiId];
        console.log(this.cEmploisList, cadreEmploiId, this.registration.fieldCadreEmploi);

        this.showListeAptitude = this.cEmploisList.filter(x => x.id === cadreEmploiId && !x.a_plus).length > 0;
        this.isConservateur = this.cEmploisList.filter(x => x.id === cadreEmploiId && (x.code === '0301' || x.code === '0302')).length > 0;
    }

    public getCadreEmploiAPlus(): any[] {
        return this.cEmploisList.filter(x => x.a_plus);
    }

    public toggleConfirm(active: boolean) {
        this.isConfirmStage = active;
    }

    public getConfirmationMessage() {
        if (this.registration.fieldEtesVousEnEmploi === '0') {
            for (const epType of this.noJobsList) {
                if (this.registration.experienceSansEmploi === epType.id) {
                    return epType.name;
                }
            }
            return 'non saisi';
        } else if (this.registration.fieldEmploiFpt === '0') {
            return 'en emploi hors FPT';
        } else {
            for (const cot of this.colEmploisList) {
                if (this.registration.emploi[0] === cot.id) {
                    let status = cot.cotationLib;
                    if (this.registration.emploiFonction !== undefined) {
                        status += ' ' + this.registration.emploiFonction;
                    }
                    return status;
                }
            }
            return 'non saisi';
        }
    }

    public getCurrentCadreEmploi() {
        for (const cadre of this.cEmploisList) {
            if (this.registration.fieldCadreEmploi[0] === cadre.id) {
                return cadre.name;
            }
        }
        return 'non saisi';
    }

    public notifyCnfpt(type: string): void {
        const modalRef = this.modalService.open(ContactCnfptComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.type = type;
        modalRef.result.then((result) => {
            this.notifySuccess = result.success;
            window.scrollTo(0, 0);
        }, (result) => {
            this.notifySuccess = undefined;
        });
    }

    /**
     * Tests if character is a number, if not: doesn't allow it
     *
     * @returns {boolean}
     */
    public checkIfNumber(event: any) {
        const keyPressed = event.charCode;  // k = event.keyCode;  (Both can be used)
        this.lastKeyIsDigit = (keyPressed >= 48 && keyPressed <= 57);
        return this.lastKeyIsDigit;
    }
}
