import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {
    HasAnyRoleDirective, ROLE_ADMIN, ROLE_AGENT, ROLE_CNFPT, ROLE_LOGGED,
    ROLE_REFERENT_CT,
    OwnershipAccessState
} from './has-any-role';

@Directive({
  selector: '[isLogged]'
})
export class IsLoggedDirective extends HasAnyRoleDirective {

    constructor(protected templateRef: TemplateRef<any>,
        protected viewContainer: ViewContainerRef) {
        super(templateRef, viewContainer);
    }

    @Input()
    set isLogged(accessState: OwnershipAccessState) {
        this.currentUserRoles = accessState.currentUserRoles;
        this.checkRoles([ROLE_LOGGED, ROLE_REFERENT_CT, ROLE_AGENT, ROLE_CNFPT, ROLE_ADMIN]);
    }

}
