import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectiviteService } from '../../collectivite/collectivite.service';
import { AgentService } from '../../agent/agent.service';
import { DemandeDeDroitService } from '../demande-de-droit.service';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DemandeDeDroitFormComponent } from '../demande-de-droit-form/demande-de-droit-form.component';
import {getDrupalUri} from "../../shared/external";
import { AdminService } from '../admin.service';

@Component({
  selector: 'admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  activeIdString: string;
  selectedDemandesTab: string;

  // Utilistateurs
  searchDataUtilisateurs = [];
  pageUtilistateurs = 1;
  itemsPerPageUtilistateurs = 10;
  collectionSizeUtilistateurs = 10;
  fulltextQueryUtilistateurs = '';
  usersCount = 0;

  // Collectivités
  searchDataCollectivites = [];
  pageCollectivites = 1;
  itemsPerPageCollectivites = 10;
  collectionSizeCollectivites = 10;
  fulltextQueryCollectivites = '';
  collectivitesCount = 0;

  // Demandes de droit
  demandesTodo = [];
  pageTodo = 1;
  pageSizeTodo = 10;
  demandesIdle = [];
  pageIdle = 1;
  pageSizeIdle = 10;
  demandesDeclined = [];
  pageDeclined = 1;
  pageSizeDeclined = 10;
  demandesAccepted = [];
  pageAccepted = 1;
  pageSizeAccepted = 10;
  fulltextQueryDemandes = '';
  hasSearched = false;

  // Extractions
  periodStart: NgbDateStruct;
  periodEnd: NgbDateStruct;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private agentService: AgentService,
    private collectiviteService: CollectiviteService,
    private demandeDeDroitService: DemandeDeDroitService,
    private modalService: NgbModal,
    private adminService: AdminService,
    private calendar: NgbCalendar
    ) { }

  ngOnInit() {
    this.route.fragment.subscribe((fragment) => {
        this.activeIdString = fragment;
    });
    this.getDemandesDeDroit();
    this.searchUtilisateurs(true);
    this.searchCollectivites(true);
    this.periodStart = this.calendar.getToday();
    this.periodEnd = this.calendar.getToday();
  }

  fetchIfNeeded(event: any) {
    if (event.nextId === 'contents') {
      event.preventDefault();
      this.goToAdminContent();
    } else {
      switch (event.activeId) {
        case "dashboard":
          this.getDemandesDeDroit();
          break;
        case "rights":
          this.fulltextQueryDemandes = '';
          this.getDemandesDeDroit();
          break;
        case "users":
          this.fulltextQueryUtilistateurs = '';
          break;
        case "collectivities":
          this.fulltextQueryCollectivites = '';
          break;
        default:
          break;
      }
    }
  }

  goToAdminContent() {
    window.open(getDrupalUri() + "/admin/content");
  }

  getDemandesDeDroit(mustSearch: boolean = false) {
    this.hasSearched = false;
    this.demandeDeDroitService
      .getAll()
      .subscribe(
        (value) => {
          this.demandesTodo = [];
          this.demandesIdle = [];
          this.demandesDeclined = [];
          this.demandesAccepted = [];
          this.filterDemandesByStatus(value);
          if (mustSearch) {
            this.filterDemandesBySearch();
          }
        }
      );
  }

  filterDemandesByStatus(demandes: Array<any>) {
    demandes.forEach(demande => {
      switch (demande.field_statut) {
        case "1":
          this.demandesTodo.push(demande);
          break;
        case "2":
          this.demandesIdle.push(demande);
          break;
        case "3":
          this.demandesDeclined.push(demande);
          break;
        case "4":
          this.demandesAccepted.push(demande);
          break;
        default:
          break;
      }
    });
  }

  initSearch() {
    if (this.hasSearched) {
      this.getDemandesDeDroit(true);
    } else {
      this.filterDemandesBySearch();
    }
  }

  filterDemandesBySearch() {
    if (this.fulltextQueryDemandes !== '') {
      const lowercaseQuery = this.fulltextQueryDemandes.toLowerCase()
      this.demandesTodo = this.demandesTodo.filter((demande) =>
        demande.field_agent.label.toLowerCase().includes(lowercaseQuery)
        || demande.field_collectivite.label.toLowerCase().includes(lowercaseQuery)
        || demande.field_email.toLowerCase().includes(lowercaseQuery)
      );
      this.demandesIdle = this.demandesIdle.filter((demande) =>
        demande.field_agent.label.toLowerCase().includes(lowercaseQuery)
        || demande.field_collectivite.label.toLowerCase().includes(lowercaseQuery)
        || demande.field_email.toLowerCase().includes(lowercaseQuery)
      );
      this.demandesDeclined = this.demandesDeclined.filter((demande) =>
        demande.field_agent.label.toLowerCase().includes(lowercaseQuery)
        || demande.field_collectivite.label.toLowerCase().includes(lowercaseQuery)
        || demande.field_email.toLowerCase().includes(lowercaseQuery)
      );
      this.demandesAccepted = this.demandesAccepted.filter((demande) =>
        demande.field_agent.label.toLowerCase().includes(lowercaseQuery)
        || demande.field_collectivite.label.toLowerCase().includes(lowercaseQuery)
        || demande.field_email.toLowerCase().includes(lowercaseQuery)
      );
    }
    this.hasSearched = true;
  }

  searchUtilisateurs(setUsersCount: boolean = false) {
    this.agentService
    .getAll(this.pageUtilistateurs, this.itemsPerPageUtilistateurs, this.fulltextQueryUtilistateurs)
    .subscribe(
      (value) => {
        this.searchDataUtilisateurs = value.data;
        this.collectionSizeUtilistateurs = value.count;
        if (setUsersCount) {
          this.usersCount = value.count;
        }
      },
      () => {
        this.searchDataUtilisateurs = [];
        this.pageUtilistateurs = 1;
        this.collectionSizeUtilistateurs = 10;
      }
    );
  }

  searchCollectivites(setCollectivitesCount: boolean = false) {
    this.collectiviteService
    .getAllForUser(this.pageCollectivites, this.itemsPerPageCollectivites, this.fulltextQueryCollectivites)
    .subscribe(
        (value) => {
          this.searchDataCollectivites = value.data;
          this.collectionSizeCollectivites = value.count;
          if (setCollectivitesCount) {
            this.collectivitesCount = value.count;
          }
        },
        () => {
          this.searchDataCollectivites = [];
          this.pageCollectivites = 1;
          this.collectionSizeCollectivites = 10;
        }
      );
  }

  goToProfilUtilisateur(id: string) {
    this.router.navigate(['dashboard/profil-utilisateur', id]);
  }

  goToProfilCollectivite(id: string) {
    this.router.navigate(['dashboard/profil-collectivite', id]);
  }

  changeDemandeStatus(id: any, status: any) {
    this.demandeDeDroitService.update(id, status)
    .subscribe(
      () => {
        this.getDemandesDeDroit();
      }
    );
  }

  openDemandeForm(demande: any, newStatus: any): void {
    const modalRef = this.modalService.open(DemandeDeDroitFormComponent, {windowClass: 'custom-modal-lg'});
    modalRef.componentInstance.dddId = demande.id;
    modalRef.componentInstance.dddLabel = demande.field_agent.label + " vers " + demande.field_collectivite.label;
    modalRef.componentInstance.oldStatus = demande.status;
    modalRef.componentInstance.newStatus = newStatus;
    modalRef.result.then((result) => {
      if (result.success) {
        this.getDemandesDeDroit();
      }
    });
  }

  getRolesAsString(roles: []) {
    let rolesAsString = "";
    roles.forEach(function (role) {
      if (rolesAsString.length > 0) {
        rolesAsString += ", ";
      }
      switch (role) {
        case "anonymous":
          rolesAsString += "Utilisateur anonyme";
          break;
        case "authenticated":
          rolesAsString += "Utilisateur authentifié";
          break;
        case "referent_ct":
          rolesAsString += "Référent CT";
          break;
        case "cnfpt":
          rolesAsString += "CNFPT";
          break;
        case "authenticated":
          rolesAsString += "Administrateur fonctionnel";
          break;
        case "agent":
          rolesAsString += "Agent A+";
          break;
      }
    });
    return rolesAsString;
  }

  get periodIsInvalid() {
    const before = new Date(this.periodStart.year, this.periodStart.month - 1, this.periodStart.day);
    const after = new Date(this.periodEnd.year, this.periodEnd.month - 1, this.periodEnd.day);
    return before > after;
  }

  exportAsCsv(type: any) {
    const periodStart = this.periodStart.year + '-' + this.periodStart.month + '-' + this.periodStart.day;
    const periodEnd = this.periodEnd.year + '-' + this.periodEnd.month + '-' + this.periodEnd.day;
    switch (type) {
      case 1:
        return this.adminService.exportAgents(periodStart, periodEnd);
      case 2:
        return this.adminService.exportCollectivites(periodStart, periodEnd);
      case 3:
        return this.adminService.exportEmplois(periodStart, periodEnd);
      case 4:
        return this.adminService.exportDemandes(periodStart, periodEnd);
    }
  }
}
