import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthentificationService} from './authentification.service';
import {LoginComponent} from './login/login.component';
import {HttpClientModule} from '@angular/common/http';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {BearerHttpInterceptor} from './bearer-http.interceptor';
import {SilentLoginCallbackComponent} from './silent-login-callback/silent-login-callback.component';
import {RouterModule} from '@angular/router';
import {moduleRoutes} from './authentification-routing.module';
import {LoginCallbackRedirectGuard} from './login-callback-redirect.guard';
import {ConfigureAuthComponent} from './configure-auth/configure-auth.component';
import {AuthRequiredGuard} from './auth-required.guard';
import {LoginRequiredComponent} from './login-required/login-required.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HasRoleAgentDirective} from './has-role-agent.directive';
import {HasRoleReferentButNotAgentDirective} from './has-role-referent-but-not-agent.directive';
import {HasRoleReferentCtDirective} from './has-role-referent-ct.directive';
import {HasRoleAgentWithOwnershipDirective} from './has-role-agent-with-ownership.directive';
import {HasRoleReferentCtWithOwnershipDirective} from './has-role-referent-ct-with-ownership.directive';
import {HasRoleAdminDirective} from './has-role-admin.directive';
import {HasRoleCnfptDirective} from './has-role-cnfpt.directive';
import {IsLoggedDirective} from './is-logged.directive';
import {CsrfService} from './csrf.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule.forChild(
            moduleRoutes
        ),
        NgbModule
    ],
    providers: [
        AuthentificationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BearerHttpInterceptor,
            multi: true,
        },
        LoginCallbackRedirectGuard,
        AuthRequiredGuard,
        CsrfService],
    declarations: [
        LoginComponent,
        SilentLoginCallbackComponent,
        ConfigureAuthComponent,
        LoginRequiredComponent,
        HasRoleAgentDirective,
        HasRoleReferentButNotAgentDirective,
        HasRoleAgentWithOwnershipDirective,
        HasRoleReferentCtDirective,
        HasRoleReferentCtWithOwnershipDirective,
        HasRoleAdminDirective,
        HasRoleCnfptDirective,
        IsLoggedDirective
    ],
    exports: [
        LoginComponent,
        ConfigureAuthComponent,
        HasRoleAgentDirective,
        HasRoleReferentButNotAgentDirective,
        HasRoleAgentWithOwnershipDirective,
        HasRoleReferentCtDirective,
        HasRoleReferentCtWithOwnershipDirective,
        HasRoleAdminDirective,
        HasRoleCnfptDirective,
        IsLoggedDirective
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthentificationModule {
}
