import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-historique-experience',
    templateUrl: './historique-experience.component.html',
    styleUrls: ['./historique-experience.component.scss']
})
export class HistoriqueExperienceComponent implements OnInit {
    @Input() history: any;

    constructor() {
    }

    ngOnInit() {
    }
}
