import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'address'
})
export class AddressPipe implements PipeTransform {

    transform(allAddresses: any[]): string {
        let address:string;

        return address;
    }

}
