import {Component, OnInit} from '@angular/core';
import {AccueilService} from '../accueil.service';
import {ActivatedRoute, Router} from '@angular/router';
import {getDrupalUri} from '../../shared/external';
import {LoaderSmallService} from '../../shared/loader-small/loader-small.service';
import {SearchDataService} from '../../shared/search-data/search-data.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    public offerCounter = undefined;
    public jobCounter = undefined;
    public profileCounter = undefined;

    public offres: any[];

    public alertRegisteredIsVisible = false;

    public showRegistrationSuccess = false;
    public showRegistrationDemandeSuccess = false;

    public collectiviteId = false;

    constructor(private homeService: AccueilService,
                private loaderSmallService: LoaderSmallService,
                private router: Router,
                private route: ActivatedRoute,
                private searchDataService: SearchDataService) {
    }

    ngOnInit() {
        this.loaderSmallService.show();
        this.homeService.getCounters().subscribe(
            (result) => {
                this.loaderSmallService.hide();
                this.offerCounter = result.offres;
                this.jobCounter = result.emplois;
                this.profileCounter = result.agents;
            }
        );
        this.showRegistrationSuccess = this.route.snapshot.data['isNewlyRegistered'];
        this.showRegistrationDemandeSuccess = this.route.snapshot.data['isNewlyRegisteredFromDemande'];
        this.collectiviteId = this.route.snapshot.data['collectiviteId'];
    }

    /**
     * Go to search component
     */
    public triggerSearch(agent: string, emploi: string, place: string) {
        const params = {
            queryParams: {
                place: undefined,
                agent: undefined,
                emploi: undefined,
            },
            fragment: 'scroll'
        };

        let hasData = false;
        if (!(agent === null || agent === undefined) && '' !== agent) {
            params.queryParams.agent = agent;
            hasData = true;
        }
        if (!(emploi === null || emploi === undefined) && '' !== emploi) {
            params.queryParams.emploi = emploi;
            hasData = true;
        }
        if (!(place === null || place === undefined) && '' !== place) {
            params.queryParams.place = place;
            hasData = true;
        }

        if (hasData) {
            this.searchDataService.clearData();
            this.router.navigate(['/annuaire'], params);
        }
    }

    /**
     * Close the alert
     */
    public closeRegisteredAlert(): void {
        this.alertRegisteredIsVisible = true;
    }

    keyDownFunction(event, agent: string, emploi: string, place: string) {
        if (event.keyCode == 13) {
            this.triggerSearch(agent, emploi, place);
        }
    }
}
