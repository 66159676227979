<app-breadcrumb></app-breadcrumb>
<div class="container">
    <ng-container *ngIf="currentForm == 'profil'">

        <!-- begin: PAGE HEADER -->
        <header class="page-title">
            <h1 class="text-uppercase bolded">
                Choix d'un profil
            </h1>
        </header>
        <!-- end: PAGE HEADER -->

        <!-- begin: PAGE CONTENT -->
        <div id="content" class="registration-profiles-choice">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <h2 class="bordered-title bt-secondary text-uppercase bolded">
                        Vous êtes ?
                    </h2>
                </div>

                <div class="col-md-5 col-lg-5 rpc-profile animated bounceInUp delay2">
                    <a (click)="switchForm('encadrant')">
                        <div class="inner">
                            <span class="aed-icon aed-icon-user" aria-hidden="true"></span>
                            <div class="rp-widget-infos">
                                <span class="rp-widget-label bolded">Cadre de Direction</span>
                                <span class="rp-widget-label lighter">d'une collectivité</span>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="col-md-2 col-lg-2 rpc-profile-or animated bounceInUp delay2">
                    <div class="inner">
                        <div class="rp-widget-infos">
                            <span class="rp-widget-label bolded">OU</span>
                        </div>
                    </div>
                </div>

                <div class="col-md-5 col-lg-5 rpc-profile animated bounceInUp delay2">
                    <a (click)="switchForm('referent')">
                        <div class="inner">
                            <span class="aed-icon aed-icon-user" aria-hidden="true"></span>
                            <div class="rp-widget-infos">
                                <span class="rp-widget-label bolded">Référent</span>
                                <span class="rp-widget-label lighter">d'une collectivité</span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-5 col-lg-5 rpc-profile-help animated bounceInUp delay2">
                    <div class="inner">
                        <div class="rp-widget-infos">
                            <span class="rp-widget-label lighter">Vous occupez un emploi de direction :</span>
                            <br/>
                            <span class="rp-widget-label lighter">Tenez-vous au courant,</span>
                            <br/>
                            <span class="rp-widget-label lighter">Découvrez sur quoi travaillent vos relations et contactez-les où que vous soyez,</span>
                            <br/>
                            <span class="rp-widget-label lighter">Identifiez les postes de direction de votre collectivité pour promouvoir vos emplois et favoriser la mobilité.</span>
                        </div>
                    </div>
                </div>

                <div class="offset-md-2 offset-lg-2 col-md-5 col-lg-5 rpc-profile-help animated bounceInUp delay2">
                    <div class="inner">
                        <div class="rp-widget-infos">
                            <span class="rp-widget-label lighter">Identifiez les postes de direction de votre collectivité pour promouvoir vos emplois et favoriser la mobilité.</span>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12 rpc-profile-help animated bounceInUp delay2">
                    <div class="inner">
                        <div class="rp-widget-infos">
                            <span class="rp-widget-label">Si vous êtes Cadre de direction et voulez déclarer les emplois de direction de votre collectivité,</span>
                            <span class="rp-widget-label">commencez par créer votre <strong>profil Cadre</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end: PAGE CONTENT -->
    </ng-container>

    <div class="page-metas clearfix" *ngIf="currentForm != 'profil'">
        <nav>
            <a class="float-right" (click)="switchForm('profil')" id="aed-registration-change-profile">
                <span class="aed-icon aed-icon-back" aria-hidden="true"></span>
                <span class="pm-link-text plt-back">Choisir un autre profil</span>
            </a>
        </nav>
    </div>

    <ng-container *ngIf="currentForm == 'encadrant'">
        <app-form-encadrant></app-form-encadrant>
    </ng-container>

    <ng-container *ngIf="currentForm == 'referent'">
        <app-form-referent></app-form-referent>
    </ng-container>

</div>