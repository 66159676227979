import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {OffreService} from './offre.service';

@Injectable()
export class OffreResolve implements Resolve<any> {
    constructor(private offreService: OffreService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.offreService.get(route.paramMap.get('num'));
    }
}
