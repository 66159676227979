import {map, merge, switchMap, tap, distinctUntilChanged, debounceTime, catchError} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {getDrupalUri} from '../../external';
import {HttpClient} from '@angular/common/http';
import {Observable, of } from 'rxjs';

@Component({
    selector: 'app-aed-typeahead',
    templateUrl: './aed-typeahead.component.html',
    styleUrls: ['./aed-typeahead.component.scss']
})
export class AedTypeaheadComponent implements OnInit {

  public selectedValue: any;

  public searching = false;
  public searchFailed = false;
  public hideSearchingWhenUnsubscribed = new Observable(() => () => this.searching = false);

  @Input() retriever: string;
  @Input() disabled: boolean;
  @Input() required = false;
  @Input() text: string;
  @Input() description: string;
  @Input() showLabel = true;
  @Input() defaultValue: string;
  @Input() placeholder: string;
  @Input() focused: boolean;
  @Output() onChoose = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef;

  readonly customApiPath: string = '/customapi';

  constructor(
    private http: HttpClient
    ) { }

  ngOnInit() {
    if (!(this.defaultValue === null || this.defaultValue === undefined)) {
      this.selectedValue = this.defaultValue;
    }
    if (true === this.focused) {
      this.focused = !this.focused;
      // this will make the execution after the above boolean has changed
      setTimeout(() => {
          this.searchInput.nativeElement.focus();
      }, 0);
    }
  }

  /**
   * Query the search service for autocomplete
   * @param {Observable<string>} text$
   */
  public search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this[this.retriever](term)
          .pipe(
            tap(() => this.searchFailed = false),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            })
          )
        ),
      tap(() => this.searching = false),
      merge(this.hideSearchingWhenUnsubscribed));

  public formatter = (x: { label: string }) => x.label;

  public exportValue(event: any) {
    if (typeof this.selectedValue === 'string') {
      this.selectedValue = {label: this.selectedValue, id: null, type: null};
    }
    this.onChoose.emit(this.selectedValue);
  }

  /**
   * Return results for autocomplete search on places
   *
   * @param {string} term
   * @returns {any}
   */
  private searchPlaces(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http.get<any>(getDrupalUri() + this.customApiPath + '/search/collectivites/' + term)
      .pipe(
        map(
          (cols) => {
            return cols;
          }
        )
      );
  }

  /**
   * Return results for autocomplete search on cities
   *
   * @param {string} term
   * @returns {any}
   */
  private searchCities(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http.get<any>(getDrupalUri() + this.customApiPath + '/search/cities/' + term)
      .pipe(
        map(
          (cols) => {
            return cols;
          }
        )
      );
  }

  /**
   * Return results for autocomplete search on collectivite for A+ registration
   *
   * @param {string} term
   * @returns {any}
   */
  private searchCollectiviteRefAplus(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http.get<any>(getDrupalUri() + this.customApiPath + '/search/registration/agent/collectivites/' + term)
      .pipe(
        map(
          (cols) => {
            return cols;
          }
        )
      );
  }

  /**
   * Return results for autocomplete search on collectivite for Referent registration
   *
   * @param {string} term
   * @returns {any}
   */
  private searchCollectiviteRefReferent(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http.get<any>(getDrupalUri() + this.customApiPath + '/search/registration/referent/collectivites/' + term)
      .pipe(
        map(
          (cols) => {
            return cols;
          }
        )
      );
  }

  /**
   * Return results for autocomplete search on agents and emplois
   *
   * @param {string} term
   * @returns {any}
   */
  private searchAgentEmploi(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http.get<any>(getDrupalUri() + this.customApiPath + '/search/emplois_agents/' + term)
      .pipe(
        map(
          (res) => {
            return res;
          }
        )
      );
  }

  /**
   * Return results for autocomplete search on agents
   *
   * @param {string} term
   * @returns {any}
   */
  private searchAgents(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http.get<any>(getDrupalUri() + this.customApiPath + '/agent/autocomplete/' + term)
      .pipe(
        map(
          (value) => {
            return value;
          }
        )
      );
  }
}
