import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmploiFormComponent } from '../../../../emploi/emploi-form/emploi-form.component';
import { EmploiService } from '../../../../emploi/emploi.service';
import { ExperienceFormComponent } from '../../../../emploi/experience-form/experience-form.component';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../../../shared/delete-dialog/delete-dialog.component';
import { LoaderService } from '../../../../shared/loader/loader.service';

@Component({
  selector: 'profil-collectivite-emplois',
  templateUrl: './emplois.component.html',
  styleUrls: ['./emplois.component.scss']
})
export class EmploisComponent implements OnInit {

  @Input() collectivite: any;
  @Output() collectiviteChange = new EventEmitter<any>();

  @Input() success: boolean;
  @Output() successChange = new EventEmitter<boolean>();

  @Output() refreshEvent = new EventEmitter<any>();

  page = 1;
  itemsPerPage = 10;
  collectionSize = 10;

  fulltextQuery = '';

  constructor(
    private loader: LoaderService,
    private modalService: NgbModal,
    private emploiService: EmploiService
    ) { }

  ngOnInit() {
    this.refreshJobs();
  }

  /**
   * Get the jobs of a collectivite
   */
  public refreshJobs(fromFulltext: boolean = false) {
      const id = this.collectivite.id;
      if (fromFulltext) {
        this.page = 1;
      }
      // Get the Collectivite by given id
      this.emploiService.getAllForCollectivite(id, this.page, this.itemsPerPage, this.fulltextQuery).subscribe(
        (value) => {
          this.collectivite.emplois = value.data;
          this.collectionSize = value.count;
        },
        () => {
          this.collectivite.emplois = [];
          this.page = 1;
          this.collectionSize = 10;
        });
  }

  /**
   * Display the emploi edition form
   */
  public openAddEmploiForm(): void {
      const modalRef = this.modalService.open(EmploiFormComponent, {windowClass: 'custom-modal-lg'});
      modalRef.componentInstance.emploi = null;
      modalRef.componentInstance.agent = null;
      modalRef.componentInstance.isEditedByReferent = true;
      modalRef.componentInstance.collectivite = this.collectivite;
      modalRef.result.then((result) => {
          if (result.success) {
            this.successChange.emit(true);
            this.loader.show();
            this.refresh();
          } else {
            this.successChange.emit(false);
          }
      }, (result) => { });
  }

  /**
   * Display the emploi edition form
   */
  public openEditEmploiForm(emploi: any): void {
      const modalRef = this.modalService.open(EmploiFormComponent, {windowClass: 'custom-modal-lg'});
      modalRef.componentInstance.emploi = emploi;
      modalRef.componentInstance.agent = null;
      modalRef.componentInstance.isEditedByReferent = true;
      modalRef.componentInstance.collectivite = this.collectivite;
      modalRef.result.then((result) => {
          if (result.success) {
            this.successChange.emit(true);
            this.loader.show();
            this.refresh();
          } else {
            this.successChange.emit(false);
          }
      }, (result) => { });
  }


  /**
   * Display the emploi edition form
   */
  public openEditExperienceForm(experience: any): void {
      const modalRef = this.modalService.open(ExperienceFormComponent, {windowClass: 'custom-modal-lg'});
      modalRef.componentInstance.experience = experience;
      modalRef.result.then((result) => {
          if (result.success) {
            this.successChange.emit(true);
            this.loader.show();
            this.refresh();
          } else {
            this.successChange.emit(false);
          }
      }, (result) => { });
  }

  public openDisableDialog(id: number) {
      const modalRef = this.modalService.open(DeleteDialogComponent, {size: 'sm'});
      modalRef.componentInstance.action = "désactivation";
      modalRef.componentInstance.actionVerb = "désactiver";
      modalRef.componentInstance.element = "cet emploi";
      modalRef.result.then((result) => {
        this.loader.show();
        this.emploiService.changeEmploiValidity(id, false)
            .subscribe(
                () => {
                  this.successChange.emit(true);
                  this.loader.hide();
                  this.refreshJobs();
                },
                (value) => {
                  this.successChange.emit(false);
                  this.loader.hide();
                  modalRef.componentInstance.fieldsError = value.errors;
                }
            );
      });
  }

  public openEnableDialog(id: number) {
      const modalRef = this.modalService.open(ConfirmDialogComponent, {size: 'sm'});
      modalRef.componentInstance.title = "Confirmation de réactivation";
      modalRef.componentInstance.confirmText = "Réactiver";
      modalRef.componentInstance.messages = ["Êtes-vous sûr de vouloir réactiver cet emploi ?"];
      modalRef.result.then((result) => {
        this.loader.show();
        this.emploiService.changeEmploiValidity(id, true)
          .subscribe(
            () => {
              this.successChange.emit(true);
              this.loader.hide();
              this.refreshJobs();
            },
            (value) => {
              this.successChange.emit(false);
              this.loader.hide();
              modalRef.componentInstance.fieldsError = value.errors;
            }
          );
      });
  }

  refresh() {
    this.refreshEvent.emit(true);
  }
}
