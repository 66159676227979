<div class="modal-header">
    <h2 class="bordered-title bolded">
        Référent de collectivité
    </h2>

    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span class="aed-icon aed-icon-close" aria-hidden="true"></span>
        <span class="sr-only" aria-hidden="true">Fermer</span>
    </button>
</div>
<div class="modal-body">
    <div class="inner">
        <form class="add-emploi-form" #becomeReferentForm="ngForm" (ngSubmit)="onSubmit(becomeReferentForm)" novalidate>
            <div class="row">

                <div class="col-md-12 col-lg-12">
                    <h3 class="bordered-title bolded" *ngIf="collectivite?.label">{{collectivite.label}}</h3>
                    <h3 class="bordered-title bolded" *ngIf="!collectivite?.label && !formModel.collectivite">Choix de la collectivité</h3>
                    <h3 class="bordered-title bolded" *ngIf="formModel.collectivite?.label">{{formModel.collectivite.label}}</h3>
                </div>

            </div>
            <div class="row" *ngIf="!poste">

                <div class="col-md-6 col-lg-6" *ngIf="!collectivite">
                    <app-aed-typeahead (onChoose)="onPlaceSelection($event)"
                                       (keypress)="checkIfNumber($event)"
                                       [retriever]="'searchCollectiviteRefReferent'"
                                       [text]="'Dans quelle collectivité travaillez-vous ? Code postal'"
                                       [placeholder]="'ex: 34800'"
                                       [showLabel]="true" [disabled]="isConfirmStage" required>
                    </app-aed-typeahead>
                    <div class="form-error alert alert-danger" *ngIf="!lastKeyIsDigit">
                        <small>Ce champ ne peut contenir que des chiffres.</small>
                    </div>
                    <p class="field-info">
                        Votre collectivité n’apparait pas dans cette liste ? Assurez-vous que vous avez saisi le bon code postal. En cas d’absence avérée, vous pouvez <a href="/form/contact" target="_blank">contacter le CNFPT</a> pour que la
                        collectivité soit rajoutée.
                    </p>
                </div>
            </div>
            <div class="row" *ngIf="!poste">
                <div class="col-md-12 col-lg-12">
                    <h3 class="bordered-title bolded">Vos informations</h3>
                </div>
            </div>
            <div class="row" *ngIf="!poste">
                <div class="form-group col-md-6 col-lg-6">
                    <label for="metier_referentiel">Métier (référentiel)</label>
                    <angular2-multiselect [data]="metiers"
                        [(ngModel)]="selectedMetier"
                        [ngModelOptions]="{standalone: true}"
                        [settings]="settingsSingle"
                        #field_metier_referentiel="ngModel"
                        class="single-select">
                        <c-badge>
                          <ng-template let-item="item">
                            <label>{{item.label ? item.label : item.name}}</label>
                          </ng-template>
                        </c-badge>
                    </angular2-multiselect>
                    <div *ngIf="metier_referentiel?.errors && metier_referentiel?.invalid && (metier_referentiel?.dirty || metier_referentiel?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="metier_referentiel?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>

                <div class="form-group col-md-6 col-lg-6">
                    <label for="metier_libre">Métier (saisie libre)</label>
                    <input type="tel" class="form-control" id="metier_libre" name="metier_libre"
                           [(ngModel)]="formModel.metier_libre" #metier_libre="ngModel" required>
                    <div *ngIf="metier_libre?.errors && metier_libre?.invalid && (metier_libre?.dirty || metier_libre?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="metier_libre?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>

                <div class="form-group col-md-5 month-selector">
                    <label for="emploiSinceMois">
                        Date de prise de poste
                    </label>
                    <div>
                        <select id="emploiSinceMois" class="form-control" name="emploiSinceMois"
                                [(ngModel)]="formModel.emploiSinceMois" #emploiSinceMois="ngModel" required>
                            <option value="01">Janvier</option>
                            <option value="02">Février</option>
                            <option value="03">Mars</option>
                            <option value="04">Avril</option>
                            <option value="05">Mai</option>
                            <option value="06">Juin</option>
                            <option value="07">Juillet</option>
                            <option value="08">Août</option>
                            <option value="09">Septembre</option>
                            <option value="10">Octobre</option>
                            <option value="11">Novembre</option>
                            <option value="12">Décembre</option>
                        </select>
                        <select id="emploiSinceAnnee" class="form-control" name="emploiSinceAnnee"
                                [(ngModel)]="formModel.emploiSinceAnnee" #emploiSinceAnnee="ngModel" required>
                            <option [value]="dr40Date" *ngFor="let dr40Date of datesRange40">{{ dr40Date }}
                            </option>
                        </select>
                        <div *ngIf="(emploiSinceMois?.errors && emploiSinceMois?.invalid && (emploiSinceMois?.dirty || emploiSinceMois?.touched)) || (emploiSinceAnnee?.errors && emploiSinceAnnee?.invalid && (emploiSinceAnnee?.dirty || emploiSinceAnnee?.touched))"
                             class="form-error alert alert-danger">
                            <small *ngIf="emploiSinceMois?.errors?.required">
                                Le champ mois est requis.
                            </small>
                            <small *ngIf="emploiSinceAnnee?.errors?.required">
                                Le champ année est requis.
                            </small>
                        </div>
                    </div>
                </div>

                <div class="form-group col-md-2" id="ongoing-container">
                    <label class="form-check-label" for="ongoing">
                        <input class="form-check-input" type="checkbox" name="ongoing" id="ongoing"
                               [(ngModel)]="formModel.ongoing" #ongoing="ngModel">
                        Poste actuel
                    </label>
                </div>

                <div class="form-group col-md-5 month-selector" *ngIf="!formModel.ongoing">
                    <label for="emploiEndMois">
                        Date de fin du poste
                    </label>
                    <div>
                        <select id="emploiEndMois" class="form-control" name="emploiEndMois"
                                [(ngModel)]="formModel.emploiEndMois" #emploiSinceMois="ngModel" required>
                            <option value="01">Janvier</option>
                            <option value="02">Février</option>
                            <option value="03">Mars</option>
                            <option value="04">Avril</option>
                            <option value="05">Mai</option>
                            <option value="06">Juin</option>
                            <option value="07">Juillet</option>
                            <option value="08">Août</option>
                            <option value="09">Septembre</option>
                            <option value="10">Octobre</option>
                            <option value="11">Novembre</option>
                            <option value="12">Décembre</option>
                        </select>
                        <select id="emploiEndAnnee" class="form-control" name="emploiEndAnnee"
                                [(ngModel)]="formModel.emploiEndAnnee" #emploiEndAnnee="ngModel" required>
                            <option [value]="dr40Date" *ngFor="let dr40Date of datesRange40">{{ dr40Date }}
                            </option>
                        </select>
                        <div *ngIf="(emploiEndMois?.errors && emploiEndMois?.invalid && (emploiEndMois?.dirty || emploiEndMois?.touched)) || (emploiEndAnnee?.errors && emploiEndAnnee?.invalid && (emploiEndAnnee?.dirty || emploiEndAnnee?.touched))"
                             class="form-error alert alert-danger">
                            <small *ngIf="emploiEndMois?.errors?.required">
                                Le champ mois est requis.
                            </small>
                            <small *ngIf="emploiEndAnnee?.errors?.required">
                                Le champ année est requis.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12 col-lg-12">
                    <button type="submit"
                            class="arrow-btn btn btn-primary text-uppercase"
                            [disabled]="formModel.invalid" *ngIf="!poste">
                        Me déclarer Référent
                    </button>
                    <button type="submit"
                            class="arrow-btn btn btn-primary text-uppercase"
                            [disabled]="formModel.invalid" *ngIf="poste">
                        Ne plus être référent
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
