import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgentService } from '../../agent/agent.service';
import { AuthentificationService } from '../../authentification/authentification.service';
import { OwnershipAccessState } from '../../authentification/has-any-role';
import { LoaderService } from '../../shared/loader/loader.service';

@Component({
  selector: 'app-profil-utilisateur',
  templateUrl: './profil-utilisateur.component.html',
  styleUrls: ['./profil-utilisateur.component.scss']
})
export class ProfilUtilisateurComponent implements OnInit {

  // Agent
  agent: any;
  currentUserId: any;

  accessState: OwnershipAccessState;

  success: boolean;
  cvSuccess: boolean;
  photoSuccess: boolean;
  notifySuccess: boolean = undefined;

  constructor(
    private agentService: AgentService,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private authService: AuthentificationService,
    private router: Router
  ) { }

  ngOnInit() {
    // Get Agent from resolver
    this.agent = this.route.snapshot.data['agent'];
    if (this.agent == null || !this.agent.id) {
      this.router.navigate(['/']);
    } else {
      this.success = undefined;
    }

    // For role specific display
    this.authService.identity.subscribe((identity) => {
      if (identity != null) {
        this.currentUserId = identity.id;
      }
      if (identity && identity.roles) {
        this.accessState = {
          currentUserRoles: identity.roles,
          hasOwnership: this.agent.id === identity.id
        };
      } else {
        this.accessState = {
          currentUserRoles: [],
          hasOwnership: false
        };
      }
    }, () => {
      this.accessState = {
        currentUserRoles: [],
        hasOwnership: false
      };
    });
  }

  /**
   * Refresh Agent datas
   * @param values
   */
  public refresh(values: any): void {
      this.loader.show();

      if (!(values === null || values === undefined)) {
        this.agent = values;
      } else {
        this.agentService.get(this.agent.id).subscribe((value) => {
            this.agent = value;
            this.loader.hide();
          });
      }
  }
}
