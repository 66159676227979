<app-breadcrumb></app-breadcrumb>

<div class="container">
    <!-- begin: PAGE HEADER -->
    <header class="page-title">
        <h1 class="text-uppercase bolded">
          {{ isNew ? 'Nouvel emploi' : emploi.label }}
        </h1>
        <a class="clickable float-right" (click)="backToCollectivite()">
          <span class="aed-icon aed-icon-back" aria-hidden="true"></span>
          <span class="pm-link-text plt-back">Retour</span>
        </a>
    </header>
    <!-- end: PAGE HEADER -->

    <!-- begin: PAGE CONTENT -->
    <div id="content" class="ct-detail aed-form-edition">
        <!-- begin: COLLECTIVITE TABS -->
        <ng-container>
            <div class="aed-tabs">
                <ul ngbNav #nav="ngbNav" [(activeId)]="activeIdString" class="nav-pills">
                    <!-- begin: COLLECTIVITE TAB / IDENTITY -->
                    <li [ngbNavItem]="'emploi-edit'">
                        <a ngbNavLink>Description de l'emploi</a>
                        <ng-template ngbNavContent>
                            <form class="emploi-edit-form" #emploiEditForm="ngForm" (ngSubmit)="onSubmit(emploiEditForm)" novalidate>

                              <div class="row">
                                <div class="form-group col-md-6">

                                  <label for="cotationPoste">
                                    Cotation de poste
                                    <span class="required-field">(obligatoire)</span>
                                  </label>

                                  <angular2-multiselect [data]="cotations"
                                      [(ngModel)]="selectedTypeEmploi"
                                      [ngModelOptions]="{standalone: true}"
                                      [settings]="settingsSingle"
                                      name="cotationPoste"
                                      id="cotationPoste"
                                      required
                                      #cotationPoste="ngModel"
                                      class="single-select"
                                      >
                                  </angular2-multiselect>

                                  <div *ngIf="cotationPoste?.errors && cotationPoste?.invalid && cotationPoste?.dirty && cotationPoste?.touched"
                                       class="form-error alert alert-danger">
                                      <small *ngIf="cotationPoste?.errors?.required">
                                          Ce champ est requis.
                                      </small>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="form-group col-md-6">
                                  <label for="emploiFonction">Fonction
                                    <span class="required-field">(obligatoire)</span>
                                  </label>
                                  <input type="text" class="form-control" id="emploiFonction" name="emploiFonction"
                                        [(ngModel)]="emploi.fonction" required #emploiFonction="ngModel">

                                  <div *ngIf="emploiFonction?.errors && emploiFonction?.invalid && (emploiFonction?.dirty || emploiFonction?.touched)"
                                  class="form-error alert alert-danger">
                                      <small *ngIf="emploiFonction?.errors?.required">
                                          Ce champ est requis.
                                      </small>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="form-group col-md-6">

                                  <label for="filiere">
                                    Filière
                                    <span class="required-field">(obligatoire)</span>
                                  </label>

                                  <angular2-multiselect [data]="filieres"
                                      [(ngModel)]="selectedFiliere"
                                      [ngModelOptions]="{standalone: true}"
                                      [settings]="settingsSingle"
                                      name="filiere"
                                      id="filiere"
                                      required
                                      #cotationPoste="ngModel"
                                      class="single-select"
                                      >
                                  </angular2-multiselect>

                                  <div *ngIf="filiere?.errors && filiere?.invalid && (filiere?.dirty || filiere?.touched)"
                                       class="form-error alert alert-danger">
                                      <small *ngIf="filiere?.errors?.required">
                                          Ce champ est requis.
                                      </small>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="form-group col-md-6">

                                  <label for="domaine">
                                    Domaine
                                    <span class="required-field">(obligatoire)</span>
                                  </label>

                                  <angular2-multiselect [data]="domaines"
                                      [(ngModel)]="selectedDomaine"
                                      [ngModelOptions]="{standalone: true}"
                                      [settings]="settingsSingle"
                                      name="domaine"
                                      id="domaine"
                                      required
                                      #cotationPoste="ngModel"
                                      class="single-select"
                                      >
                                  </angular2-multiselect>

                                  <div *ngIf="domaine?.errors && domaine?.invalid && (domaine?.dirty || domaine?.touched)"
                                       class="form-error alert alert-danger">
                                      <small *ngIf="domaine?.errors?.required">
                                          Ce champ est requis.
                                      </small>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="form-group col-md-4">
                                  <label for="emploiFonctionnel">
                                    Emploi fonctionnel
                                    <span class="required-field">(obligatoire)</span>
                                  </label>

                                  <div ngbRadioGroup name="emploiFonctionnel" name="emploiFonctionnel" [(ngModel)]="emploi.fonctionnel" >
                                    <label ngbButtonLabel>
                                      <input ngbButton type="radio" [value]="true"> Oui
                                    </label>
                                    <label ngbButtonLabel>
                                      <input ngbButton type="radio" [value]="false"> Non
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group form-inline float-left">
                                      <button type="submit" class="arrow-btn btn btn-primary text-uppercase">Valider</button>
                                    </div>
                                </div>
                              </div>
                            </form>
                        </ng-template>
                      </li>
                    <!-- end: COLLECTIVITE TAB / IDENTITY -->

                    <!-- begin: COLLECTIVITE TAB / EMPLOIS -->
                    <li [ngbNavItem]="'emploi-agent'">
                        <a ngbNavLink>Affectation du poste</a>
                        <ng-template ngbNavContent>
                          <div class="row">
                              <div class="col-md-12 col-lg-12">
                                  <h2 class="bordered-title bolded">Situation actuelle</h2>
                              </div>
                          </div>

                          <div class="row">
                            <div class="form-group col-md-12">
                              <div class="btn-group type-emploi btn-group-toggle" ngbRadioGroup name="typeAffectation" [(ngModel)]="type">
                                <label ngbButtonLabel class="btn-light">
                                  <input ngbButton type="radio" [value]="'vacant'" (click)="onTypeChange('vacant')"> Poste vacant
                                </label>
                                <label ngbButtonLabel class="btn-light">
                                  <input ngbButton type="radio" [value]="'unkown'" (click)="onTypeChange('unkown')"> Sans information sur l'affectation
                                </label>
                                <label ngbButtonLabel class="btn-light">
                                  <input ngbButton type="radio" [value]="'occupied'" (click)="onTypeChange('occupied')"> Occupé
                                </label>
                              </div>
                              <!-- <small *ngIf="type == 'vacant'" id="posteVacantHelpBlock" class="form-text text-muted">
                                  Attention : cette information ne vaut pas déclaration de vacance de poste.
                              </small> -->
                            </div>
                          </div>


                          <div class="affectation-history clearfix" >
                            <div class="inner">
                                <h2 class="bordered-title bolded">Historique des affectations</h2>

                                <div class="row">
                                  <div class="form-group col-md-2">
                                    <button type="submit" class="arrow-btn btn btn-third text-uppercase" (click)="openEditAffectationForm()">Ajouter</button>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="form-group col-md-12">
                                    <table class="table table-striped">
                                      <thead>
                                      <tr class="table-header">
                                        <th scope="col">#</th>
                                        <th scope="col">Période</th>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Fonction</th>
                                        <th scope="col">Actions</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr *ngFor="let affectation of emploi.experiences; index as i">
                                        <th scope="row">{{ i + 1 }}</th>
                                        <td class="{{ !affectation.is_valid ? 'italic-font' : '' }}">
                                          <!-- <ngb-highlight [result]="affectation.user_id.label"></ngb-highlight> -->
                                          <app-periode [start]="affectation.debut" [end]="affectation.fin"></app-periode>
                                        </td>
                                        <td class="{{ !affectation.is_valid ? 'italic-font' : '' }}">
                                          <ngb-highlight [result]="affectation.user_id.label"></ngb-highlight>
                                        </td>
                                        <td class="{{ !affectation.is_valid ? 'italic-font' : '' }}">
                                          <ngb-highlight [result]="affectation.field_exp_fpt_fonction"></ngb-highlight>
                                        </td>
                                        <td>
                                          <span class="alv-label aed-icon aed-icon-edit clickable" title="Editer l'affectation" aria-hidden="true" (click)="openEditAffectationForm(affectation)"></span>
                                          <ng-container *ngIf="affectation.is_valid; then isValid else isInvalid;"></ng-container>
                                          <ng-template #isValid>
                                            <span class="alv-label aed-icon aed-icon-eye-crossed clickable" title="Désactiver l'affectation" aria-hidden="true" (click)="openDisableDialog(affectation.id)"></span>
                                          </ng-template>
                                          <ng-template #isInvalid>
                                            <span class="alv-label aed-icon aed-icon-eye clickable" title="Réactiver l'affectation" aria-hidden="true" (click)="openEnableDialog(affectation.id)"></span>
                                          </ng-template>
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                            </div>
                        </div>


                        </ng-template>
                      </li>
                    <!-- end: COLLECTIVITE TAB / EMPLOIS -->
                    </ul>

                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </ng-container>
        <!-- end: COLLECTIVITE TABS -->

    </div>
    <!-- end: PAGE CONTENT -->
</div>
