import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-agent-light',
  templateUrl: './agent-light.component.html',
  styleUrls: ['./agent-light.component.scss']
})
export class AgentLightComponent implements OnInit {

    @Input() agent: any;
    @Input() experience: any;
    @Input() showPeriode = false;

  constructor() { }

  ngOnInit() {
  }

}
