<div class="alc-job-elt">
    <ng-container *ngIf="isAutreAffectation">
        <h3 class="title-secondary  text-uppercase">Autre Affectation</h3>
    </ng-container>

    <ng-container *ngIf="hasExperienceHFPT && experienceHFPT">
        <h3 class="title-secondary  text-uppercase">{{experienceHFPT.label}}</h3>
        <ul class="list-unstyled aed-label-value">
            <li *ngIf="experienceHFPT.collectivite">
                <span class="alv-label">Ville :</span>
                <span class="alv-value">{{experienceHFPT.collectivite.commune}}</span>
            </li>
            <li *ngIf="experienceHFPT.type">
                <span class="alv-label">Type :</span>
                <span class="alv-value">{{experienceHFPT.type.label}}</span>
            </li>
            <li>
                <app-periode [start]="experienceHFPT.debut" [end]="experienceHFPT.fin"></app-periode>
            </li>
        </ul>
    </ng-container>

    <ng-container *ngIf="sansEmploi">
        <h3 class="title-secondary  text-uppercase">Sans emploi</h3>
        <ul class="list-unstyled aed-label-value">
            <li *ngIf="agent.field_cadre_emploi?.label">
                <span class="alv-label">Cadre d'emplois :</span>
                <span class="alv-value">{{agent.field_cadre_emploi?.label}}</span>
            </li>
            <li *ngIf="agent.emploi_statut.statut">
                <span class="alv-label">Statut :</span>
                <span class="alv-value">{{agent.emploi_statut.statut}}</span>
                <span class="alv-value"
                      *ngIf="agent.field_etes_vous_liste_apti">{{agent.field_liste_aptitude_cadre}}</span>
            </li>
        </ul>
    </ng-container>

    <ng-container *ngIf="unknown">
        <h3 class="title-secondary  text-uppercase">Aucune information d'affectation pour l'instant</h3>
        <ul class="list-unstyled aed-label-value">
            <li *ngIf="agent.field_cadre_emploi?.label">
                <span class="alv-label">Cadre d'emplois :</span>
                <span class="alv-value">{{agent.field_cadre_emploi?.label}}</span>
            </li>
        </ul>
    </ng-container>


</div>