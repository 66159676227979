<form class="ct-update-form" (ngSubmit)="onSubmit(coordonneesForm)" #coordonneesForm="ngForm" novalidate>
  <div class="row">
    <div class="form-group col-md-6 col-lg-6">
      <label for="l1_normalisee">
        Ligne adressage 1
        <span class="required-field">(obligatoire)</span>
      </label>
      <input type="text" class="form-control" id="l1_normalisee" name="l1_normalisee"
      [(ngModel)]="collectivite.l1_normalisee" #l1_normalisee="ngModel" required>
      <div *ngIf="l1_normalisee?.errors && l1_normalisee?.invalid && (l1_normalisee?.dirty || l1_normalisee?.touched)" class="form-error alert alert-danger">
        <small *ngIf="l1_normalisee?.errors?.required">
          La première ligne d'adressage est obligatoire
        </small>
      </div>
    </div>

    <div class="form-group col-md-6 col-lg-6">
      <label for="l2_normalisee">
        Ligne adressage 2
      </label>
      <input type="text" class="form-control" id="l2_normalisee" name="l2_normalisee"
      [(ngModel)]="collectivite.l2_normalisee">
    </div>

    <div class="form-group col-md-6 col-lg-6">
      <label for="l3_normalisee">
        Ligne adressage 3
      </label>
      <input type="text" class="form-control" id="l3_normalisee" name="l3_normalisee"
      [(ngModel)]="collectivite.l3_normalisee">
    </div>

    <div class="form-group col-md-6 col-lg-6">
      <label for="l4_normalisee">
        Ligne adressage 4
      </label>
      <input type="text" class="form-control" id="l4_normalisee" name="l4_normalisee"
      [(ngModel)]="collectivite.l4_normalisee">
    </div>

    <div class="form-group col-md-6 col-lg-6">
      <label for="l5_normalisee">
        Ligne adressage 5
      </label>
      <input type="text" class="form-control" id="l5_normalisee" name="l5_normalisee"
      [(ngModel)]="collectivite.l5_normalisee">
    </div>

    <div class="form-group col-md-6 col-lg-6">
      <label for="l6_normalisee">
        Ligne adressage 6
        <span class="required-field">(obligatoire)</span>
      </label>
      <input type="text" class="form-control" id="l6_normalisee" name="l6_normalisee"
      [(ngModel)]="collectivite.l6_normalisee" required #l6_normalisee="ngModel">
      <div *ngIf="l6_normalisee?.errors && l6_normalisee?.invalid && (l6_normalisee?.dirty || l6_normalisee?.touched)" class="form-error alert alert-danger">
        <small *ngIf="l6_normalisee?.errors?.required">
          La 6ème ligne d'adressage est obligatoire
        </small>
      </div>
    </div>
    <div class="form-group col-md-6 col-lg-6">
      <label for="libcom">
        Libellé de commune
      </label>
      <input type="text" class="form-control" id="libcom" name="libcom"
      [(ngModel)]="collectivite.libcom">
    </div>
    <div class="form-group col-md-6 col-lg-6">
      <label for="codpos">
        Code postal
      </label>
      <input type="text" class="form-control" id="codpos" name="codpos"
      [(ngModel)]="collectivite.codpos">
    </div>

    <div class="form-group col-md-12 col-lg-12">
      <button type="submit" class="arrow-btn btn btn-primary text-uppercase" [disabled]="coordonneesForm.invalid">
        Mettre à jour les coordonnées
      </button>
    </div>
  </div>
</form>
