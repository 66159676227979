<div class="aed-banner-slideshow">
    <app-loader-small *ngIf="!banners"></app-loader-small>
    <ngb-carousel *ngIf="banners?.length > 1; else singleSlide">
        <ng-template class="abs-slide" ngbSlide *ngFor="let banner of banners">
            <img class="image-fluid center-block" src="{{banner.image}}" alt="{{banner.title}}">
            <div class="as-caption" *ngIf="banner.body">
                <!--<h3 class="abs-title">{{banner.title}}</h3>-->
                <div class="abs-desc" [innerHTML]="banner.body"></div>
            </div>
        </ng-template>
    </ngb-carousel>
    <ng-template #singleSlide>
        <div class="abs-slide" *ngFor="let banner of banners">
            <img class="image-fluid center-block" src="{{banner.image}}" alt="{{banner.title}}">
            <div class="as-caption" *ngIf="banner.body">
                <!--<h3 class="abs-title">{{banner.title}}</h3>-->
                <div class="abs-desc" [innerHTML]="banner.body"></div>
            </div>
        </div>
    </ng-template>
</div>
