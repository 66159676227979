import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RouterModule } from '@angular/router';
import { adminRoutes } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AnnuaireService } from '../annuaire/annuaire.service';
import { DemandeDeDroitService } from './demande-de-droit.service';
import { ProfilUtilisateurComponent } from './profil-utilisateur/profil-utilisateur.component';
import { ProfilCollectiviteComponent } from './profil-collectivite/profil-collectivite.component';
import { AuthentificationModule } from '../authentification/authentification.module';
import { CollectiviteModule } from '../collectivite/collectivite.module';
import { EmploiModule } from '../emploi/emploi.module';
import { AgentModule } from '../agent/agent.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';
import { IdentiteComponent } from './profil-utilisateur/components/identite/identite.component';
import { ProfilComponent } from './profil-utilisateur/components/profil/profil.component';
import { StatutUtilisateurComponent } from './profil-utilisateur/components/statut/statut.component';
import { CoordonneesUtilisateurComponent } from './profil-utilisateur/components/coordonnees/coordonnees.component';
import { ExperiencesComponent } from './profil-utilisateur/components/experiences/experiences.component';
import { MobiliteComponent } from './profil-utilisateur/components/mobilite/mobilite.component';
import { CollectiviteComponent } from './profil-collectivite/components/collectivite/collectivite.component';
import { StatutCollectiviteComponent } from './profil-collectivite/components/statut/statut.component';
import { CoordonneesCollectiviteComponent } from './profil-collectivite/components/coordonnees/coordonnees.component';
import { ContactComponent } from './profil-collectivite/components/contact/contact.component';
import { FichiersComponent } from './profil-collectivite/components/fichiers/fichiers.component';
import { ReferentsComponent } from './profil-collectivite/components/referents/referents.component';
import { EmploisComponent } from './profil-collectivite/components/emplois/emplois.component';
import { DemandeDeDroitFormComponent } from './demande-de-droit-form/demande-de-droit-form.component';
import { DemandesComponent } from './profil-utilisateur/components/demandes/demandes.component';
import { ArchivageComponent } from './profil-collectivite/components/archivage/archivage.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(
      adminRoutes
    ),
    SharedModule,
    NgbModule,
    AngularMultiSelectModule,
    AuthentificationModule,
    CollectiviteModule,
    EmploiModule,
    AgentModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule
  ],
  providers: [
    AnnuaireService,
    DemandeDeDroitService
  ],
  declarations: [
    DashboardComponent,
    ProfilUtilisateurComponent,
    ProfilCollectiviteComponent,
    IdentiteComponent,
    ProfilComponent,
    ExperiencesComponent,
    MobiliteComponent,
    StatutUtilisateurComponent,
    CoordonneesUtilisateurComponent,
    CollectiviteComponent,
    ContactComponent,
    FichiersComponent,
    ReferentsComponent,
    EmploisComponent,
    StatutCollectiviteComponent,
    CoordonneesCollectiviteComponent,
    DemandeDeDroitFormComponent,
    DemandesComponent,
    ArchivageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminModule { }
