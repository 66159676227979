<div class="alc-job-elt">
    <h3 class="title-secondary  text-uppercase">{{experienceHFPT.label}}</h3>
    <ul class="list-unstyled aed-label-value">
        <li *ngIf="experienceHFPT.collectivite">
            <span class="alv-label">Ville :</span>
            <span class="alv-value">{{experienceHFPT.collectivite.commune}}</span>
        </li>
        <li *ngIf="experienceHFPT.type">
            <span class="alv-label">Type :</span>
            <span class="alv-value">{{experienceHFPT.type.label}}</span>
        </li>
        <li>
            <app-periode [start]="experienceHFPT.debut" [end]="experienceHFPT.fin"></app-periode>
        </li>
    </ul>
</div>