<!-- begin : HOME MAP -->
<div id="home-map" class="aed-map-container" *ngIf="!isMapLightShowable">
  <h2 class="bordered-title bt-white bolded">Cartographie des emplois de direction</h2>
  <div class="row">
    <div class="ahm-map col-md-12 col-lg-12">
      <div class="form-check">
        <label class="form-check-label clickable">
          <input #selectAllDepartmentsCbx id="selectAllDepartmentsCbx" class="form-check-input" type="checkbox" name="all-departments-trigger"
                 [checked]="allDepartments"
                 (change)="selectAllDepartments(selectAllDepartmentsCbx.checked)">
          Tout sélectionner
        </label>
      </div>
      <app-loader-small *ngIf="!mapLoaded"></app-loader-small>
      <svg id="aed-map" height="450" version="1.1" width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 540 570">
        <svg:path
            *ngFor="let department of departments; let i = index"
            [attr.data-index]="i"
            (click)="clickedDepartment(department)"
            class="aed-map-elt"
            [class.selected]="hasDepartment(department) || allDepartments"
            [attr.d]="department.departmentPath"
            style="stroke-linejoin: round; cursor: pointer;" fill="#ffffff" stroke="#dedede" stroke-width="1" stroke-linejoin="round"
            placement="bottom"
            container="body"
            [ngbTooltip]="department.departmentName"
        >
        </svg:path>

        <path
                *ngFor="let region of regions; let i = index"
                [attr.data-index]="i"
                class="aed-map-region"
                [attr.d]="region.regionPath"
                style="stroke-linejoin: round; cursor: pointer;" fill="none" stroke="#484948" stroke-width="1" stroke-linejoin="round"
                transform="translate(0,-40)"
        />

        <path
                *ngFor="let region of regionsDomTom; let i = index"
                [attr.data-index]="i"
                class="aed-map-region"
                [attr.d]="region.regionPath"
                style="stroke-linejoin: round; cursor: pointer;" fill="none" stroke="#484948" stroke-width="1" stroke-linejoin="round"
                transform="translate(0,0)"
        />
        <rect x='450.1' y='2.4' width='92.5' height='92.5' r='0' rx='0' ry='0' fill='none' stroke='#b8b8b8' style=''
              stroke-width='1'></rect>
      </svg>

    </div>
    <div class="ahm-infos col-md-12 col-lg-12">
      <div class="row">
        <div class="ahm-selected col-md-12 col-lg-12">
                        <span class="as-number">
                            {{ selectDepartmentsJobsAmount }}
                        </span>
          <span class="as-icon aed-icon aed-icon-briefcase" aria-hidden="true"></span>
          <div class="as-label">
            <span class="lighter">emplois de direction</span>
            <span class="bolded">sélectionnés</span>
          </div>
        </div>
        <div class="ahm-actions col-md-12 col-lg-12" *ngIf="selectDepartmentsJobsAmount > 0">
          <button type="button" class="btn arrow-btn btn-primary" [disabled]="isSearching" (click)="triggerSearch()">Voir les emplois</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end : HOME MAP -->
