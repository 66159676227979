import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmploiService} from './emploi.service';
import {EmploiResolve} from './emploi.resolve';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {NgbModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import {FicheEmploiLightComponent} from './fiche-emploi-light/fiche-emploi-light.component';
import {AffectationLightComponent} from './affectation-light/affectation-light.component';
import {AgentLightComponent} from '../shared/agent-light/agent-light.component';
import {EmploiErreurComponent} from './emploi-erreur/emploi-erreur.component';
import {AedTypeaheadComponent} from '../shared/forms/aed-typeahead/aed-typeahead.component';
import {EmploiFormComponent} from './emploi-form/emploi-form.component';
import {NgbButtonLabel} from '@ng-bootstrap/ng-bootstrap/buttons/label';
import {FicheAutreEmploiComponent} from './fiche-autre-emploi/fiche-autre-emploi.component';
import {FicheEmploiHfptComponent} from './fiche-emploi-hfpt/fiche-emploi-hfpt.component';
import {DeleteDialogComponent} from '../shared/delete-dialog/delete-dialog.component';
import {PeriodeComponent} from '../shared/periode/periode.component';
import {AuthentificationModule} from '../authentification/authentification.module';
import {HistoriqueAffectationComponent} from './historique-affectation/historique-affectation.component';
import {FicheExperienceLightComponent} from './fiche-experience-light/fiche-experience-light.component';
import {HistoriqueExperienceComponent} from './historique-experience/historique-experience.component';
import {ExperienceFormComponent} from './experience-form/experience-form.component';
import { FicheEditEmploiComponent } from './fiche-edit-emploi/fiche-edit-emploi.component';
import { FicheEditAffectationComponent } from './fiche-edit-affectation/fiche-edit-affectation.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        SharedModule,
        AuthentificationModule,
        NgbTypeaheadModule,
        MultiselectDropdownModule,
        AngularMultiSelectModule,
        NgbModule
    ],
    providers: [
        EmploiService,
        EmploiResolve
    ],
    declarations: [
        FicheEmploiLightComponent,
        AffectationLightComponent,
        EmploiErreurComponent,
        EmploiFormComponent,
        FicheAutreEmploiComponent,
        FicheEmploiHfptComponent,
        FicheExperienceLightComponent,
        HistoriqueAffectationComponent,
        HistoriqueExperienceComponent,
        ExperienceFormComponent,
        FicheEditEmploiComponent,
        FicheEditAffectationComponent
    ],
    exports: [
        FicheEmploiLightComponent,
        AffectationLightComponent,
        EmploiErreurComponent,
        EmploiFormComponent,
        FicheAutreEmploiComponent,
        FicheEmploiHfptComponent,
        FicheExperienceLightComponent,
        ExperienceFormComponent,
        HistoriqueAffectationComponent,
        HistoriqueExperienceComponent,
        FicheEditEmploiComponent,
        FicheEditAffectationComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EmploiModule {
}
