<app-breadcrumb></app-breadcrumb>
<div class="container" *ngIf="collectivite == null">
  <div class="row">
    <div class="col-md-12 col-lg-12 empty-block">
      <div class="inner">
        <p>
          La collectivité n'est pas disponible.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="collectivite">
  <!-- begin: PAGE HEADER -->
  <header class="page-title">
    <h1 class="text-uppercase bolded">
      {{ collectivite.nomen_long }}
    </h1>
    <a class="clickable float-right" back-button>
      <span class="aed-icon aed-icon-back" aria-hidden="true"></span>
      <span class="pm-link-text plt-back">Retour</span>
    </a>
  </header>
  <!-- end: PAGE HEADER -->

  <!-- begin: PAGE CONTENT -->
  <div id="content" class="ct-detail">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <app-alert *ngIf="notifySuccess === true"
        [isAlertClosable]="true"
        [alertType]="'success'"
        [alertMessage]="'Votre message a été envoyé au référent de la collectivité'"
        >
      </app-alert>

      <app-alert *ngIf="notifySuccess === false"
      [isAlertClosable]="true"
      [alertType]="'danger'"
      [alertMessage]="'Impossible d\'envoyer votre message'"
      [listError]="fieldsError"
      >
    </app-alert>
  </div>
</div>
<!-- begin: CT HEADER -->
<div class="row">
  <div class="col-md-12 col-lg-12 ct-detail-header">
    <div class="inner">
      <div class="row">
        <div class="col-md-2 col-lg-2 cdh-thumb">
          <img *ngIf="collectivite.blason_url; else nothumb" class="img-fluid"
          src="{{ collectivite.blason_url }}"
          alt="">

          <ng-template #nothumb>
            <img #nothumb class="img-fluid" src="assets/images/picto_mairie_ct.png" alt="">
          </ng-template>
        </div>
        <div class="col-md-7 col-lg-7 cdh-infos">
          <div class="row">
            <div class="col-md-12 col-lg-12 cdh-infos-title-link"
            *hasRoleReferentCTWithOwnership="accessState">
            <a class="cdh-infos-link" [routerLink]="['/collectivite', collectivite.id, 'edit']">
              <span class="alv-label aed-icon aed-icon-edit" aria-hidden="true"></span>
              <span>Modifier la fiche de la collectivité</span>
            </a>
          </div>
          <div class="col-md-12 col-lg-12 cdh-infos-details">
            <ul class="list-unstyled aed-label-value">
              <app-address [address]="collectivite.adresse"></app-address>
              <li class="aed-label-value-iconic" *ngIf="collectivite.telephone1">
                <span class="alv-label aed-icon aed-icon-phone" aria-hidden="true"></span>
                <span class="alv-value">{{ collectivite.telephone1 }}</span>
              </li>
              <li class="aed-label-value-iconic" *ngIf="collectivite.email1">
                <span class="alv-label aed-icon aed-icon-email" aria-hidden="true"></span>
                <span class="alv-value"><a href="mailto:{{collectivite.email1}}">{{collectivite.email1}}</a></span>
              </li>
              <li class="aed-label-value-iconic" *ngIf="collectivite.web">
                <span class="alv-label aed-icon aed-icon-worldwide"
                aria-hidden="true"></span>
                <span class="alv-value"><a href="{{collectivite.web}}" target="_blank">{{collectivite.web}}</a></span>
              </li>
              <li class="aed-label-value-iconic"
              *ngIf="collectivite.organigramme_file_url; else organigrammeUrl">
              <span class="alv-label aed-icon aed-icon-family-tree"
              aria-hidden="true"></span>
              <span class="alv-value"><a href="{{collectivite.organigramme_file_url}}"
                target="_blank">Télécharger l'organigramme</a></span>
              </li>
              <ng-template #organigrammeUrl>
                <li class="aed-label-value-iconic" *ngIf="collectivite.organigramme_url">
                  <span class="alv-label aed-icon aed-icon-family-tree"
                  aria-hidden="true"></span>
                  <span class="alv-value"><a href="{{collectivite.organigramme_url}}"
                    target="_blank">Visualiser l'organigramme actuellement en ligne</a></span>
                  </li>
                </ng-template>
              </ul>
            </div>
          </div>
        </div>
        <app-social-networks class="col-md-3 col-lg-3 adh-social"
        [owner]="collectivite"></app-social-networks>
      </div>
    </div>
  </div>
</div>
<!-- end: CT HEADER -->

<!-- begin: CT ACCORDION -->
<div class="aed-accordion">
  <ngb-accordion [closeOthers]="true" activeIds="panel-emploi">
    <ngb-panel id="panel-emploi" *ngIf="collectivite.emplois != null && collectivite.emplois.length > 0">
      <ng-template ngbPanelHeader let-opened="opened">
        <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
          <span *ngIf="collectivite.dves == null || collectivite.dves.length == 0"
          class="ct-detail-vacancy d-none d-sm-block d-sm-none d-md-block">Pas de déclaration de vacances d'emploi
        </span>
        Emplois
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="row aed-list-container">
        <div [ngClass]="{ odd: odd, even: even, 'col-md-12 col-lg-12 alc-elt': true}"
        *ngFor="let emploi of collectivite.emplois; let even = even; let odd = odd">
        <div class="inner clearfix">
          <div class="row">
            <app-fiche-emploi-light class="col-md-8 col-lg-8"
            [emploi]="emploi"
            [experience]="emploi?.current_experience"
            [showAgent]="true">
          </app-fiche-emploi-light>

          <div class="col-md-4 col-lg-4 alc-actions-elt">
            <nav class="alc-links">
              <a class="arrow-btn btn btn-third btn-sm text-uppercase"
              (click)="notifyError(emploi)" *isLogged="accessState">Signaler
              une erreur</a>
            </nav>
          </div>

          <ng-container *isLogged="accessState">
            <div class="col-md-12 col-lg-12 card-actions">
              <a class="arrow-btn btn btn-primary btn-sm text-uppercase"
              (click)="emploi.showHistory = !emploi.showHistory"
              *ngIf="emploi?.experiences !== undefined && ((emploi.current_experience && emploi.experiences.length > 1) || (!emploi.current_experience && emploi.experiences.length > 0))"
              >
              Voir l'historique des affectations
            </a>
          </div>
          <app-historique-experience
          class="col-md-12 col-lg-12"
          [history]="emploi?.experiences"
          *ngIf="emploi.showHistory"
          >
        </app-historique-experience>
      </ng-container>
    </div>
  </div>
</div>
</div>
</ng-template>
    </ngb-panel>
    <ngb-panel *ngIf="collectivite.offres != null && collectivite.offres.length > 0">
      <ng-template ngbPanelHeader let-opened="opened">
        <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
          Offres d'emploi
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col-md-12 col-lg-12 alc-elt ct-detail-offer">
            <div class="inner alc-offer aed-jobs clearfix">
              <div class="row">
                <app-offre-teaser *ngFor="let offre of collectivite.offres" [offre]="offre"
                class="col-md-4 col-lg-4 mar-t-15"></app-offre-teaser>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel *ngIf="collectivite.dves != null && collectivite.dves.length > 0">
      <ng-template ngbPanelHeader let-opened="opened">
        <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
          Déclaration de vacances d'emplois
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col-md-12 col-lg-12 alc-elt ct-detail-offer">
            <div class="inner alc-dve aed-jobs clearfix">
              <app-dve-teaser *ngFor="let dve of collectivite.dves" [dve]="dve"
              class="col-md-4 col-lg-4 mar-t-15"></app-dve-teaser>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
<!-- end: CT ACCORDION -->
</div>
<!-- end: PAGE CONTENT -->
</div>
