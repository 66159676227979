import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { HasAnyRoleDirective, ROLE_ADMIN, ROLE_AGENT, ROLE_CNFPT, OwnershipAccessState } from './has-any-role';

@Directive({
    selector: '[hasRoleAgent]'
})
export class HasRoleAgentDirective extends HasAnyRoleDirective {

    constructor(protected templateRef: TemplateRef<any>,
        protected viewContainer: ViewContainerRef) {
        super(templateRef, viewContainer);
    }

    @Input()
    set hasRoleAgent(accessState: OwnershipAccessState) {
        this.currentUserRoles = accessState.currentUserRoles;
        this.checkMasterRoles();
        if (!this.hasView) {
            this.checkRoles([ROLE_AGENT]);
        }
    }
}
