import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FicheComponent} from './fiche/fiche.component';
import {AgentResolve} from './agent.resolve';
import {AgentService} from './agent.service';
import {RouterModule} from '@angular/router';
import {agentRoutes} from './agent-routing.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CollectiviteModule} from '../collectivite/collectivite.module';
import {AuthentificationModule} from '../authentification/authentification.module';
import {IsLoggedDirective} from '../authentification/is-logged.directive';
import {FicheEditComponent} from './fiche-edit/fiche-edit.component';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import {CKEditorModule} from 'ng2-ckeditor';
import {ReferentFormComponent} from './referent-form/referent-form.component';
import {EmploiErreurComponent} from '../emploi/emploi-erreur/emploi-erreur.component';
import {AgentSessionResolve} from './agent-session.resolve';
import {PosteReferentComponent} from './poste-referent/poste-referent.component';
import {AgentLightComponent} from '../shared/agent-light/agent-light.component';
import {StatutComponent} from './statut/statut.component';
import {SocialNetworksComponent} from '../shared/social-networks/social-networks.component';
import {PeriodeComponent} from '../shared/periode/periode.component';
import {ReferentService} from './referent.service';
import {FormationService} from './formation.service';
import {FormationComponent} from './formation/formation.component';
import {FormationFormComponent} from './formation-form/formation-form.component';
import {ConfirmDialogComponent} from '../shared/confirm-dialog/confirm-dialog.component';
import {AvatarComponent} from '../shared/avatar/avatar.component';
import {EmploiModule} from '../emploi/emploi.module';
import { ReferentResolve } from './referent.resolve';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CollectiviteModule,
        AuthentificationModule,
        RouterModule.forChild(
            agentRoutes
        ),
        SharedModule,
        NgbModule,
        EmploiModule,
        MultiselectDropdownModule,
        AngularMultiSelectModule,
        CKEditorModule
    ],
    providers: [
        AgentService,
        AgentResolve,
        AgentSessionResolve,
        ReferentResolve,
        ReferentService,
        FormationService
    ],
    declarations: [
        FicheComponent,
        FicheEditComponent,
        PosteReferentComponent,
        StatutComponent,
        ReferentFormComponent,
        FormationFormComponent,
        FormationComponent
    ],
    exports: [
      FormationComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AgentModule {
}
