import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthentificationService } from '../authentification/authentification.service';
import { NEED_TO_LOG_IN, REGISTRATION_URI } from '../authentification/auth-required.guard';

export const NOT_PROFILE_CAPTURE = [REGISTRATION_URI, NEED_TO_LOG_IN, 'registered', 'form/contact'];

/**
 * Service that listen to navigation and redirect on profile if identity says profile is incomplete.
 */
@Injectable()
export class RegistrationCaptureService {
    private user: any;

    constructor(private router: Router, private authService: AuthentificationService) {
        // We register to store the identity
        this.authService.identity.subscribe((identity) => {
            const oldValue = this.user;
            this.user = identity;
            if ((oldValue === null || oldValue === undefined) && !(this.user === null || this.user === undefined)) {
                // A user get connected we should also chech if we have to redirect him instead of waiting for a navigation.
                this.tryRedirectOnInvalidProfil(this.router.url);
            }
        });

        // On navigation force to go to registration if not full profile
        this.router.events.subscribe(
            (event) => {
                if (event instanceof NavigationStart) {
                    this.tryRedirectOnInvalidProfil(event.url);
                }
            }
        );
    }

    public tryRedirectOnInvalidProfil(currentUrl: string) {
        if (NOT_PROFILE_CAPTURE.findIndex((url) => {
            return currentUrl.indexOf(url) !== -1;
        }) === -1) {
            // Here we need to be instantaneous so we use the current value of authenticate
            if (!(this.user === null || this.user === undefined) && this.user.profilComplet === false) {
                this.router.navigate([REGISTRATION_URI]);
            }
        }
    }
}


