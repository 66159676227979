<app-breadcrumb></app-breadcrumb>

<div class="container">

  <div class="row">
    <div class="col-12">
      <header class="page-title">
        <a class="clickable float-right" back-button>
          <span class="aed-icon aed-icon-back" aria-hidden="true"></span>
          <span class="pm-link-text plt-back">Retour</span>
        </a>
      </header>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-12">
      <app-alert *ngIf="success === true"
                  [isAlertClosable]="true"
                  [alertType]="'success'"
                  [alertMessage]="'La collectivité a été mise à jour avec succès.'">
      </app-alert>

      <app-alert *ngIf="success === false"
                  [isAlertClosable]="true"
                  [alertType]="'danger'"
                  [alertMessage]="'La mise à jour de votre collectivité a échoué.'"
                  [listError]="fieldsError">
      </app-alert>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-12">
      <app-alert *ngIf="archivageSuccess === true"
                  [isAlertClosable]="true"
                  [alertType]="'success'"
                  [alertMessage]="'Votre demande d\'archivage a été réalisée avec succès.'">
      </app-alert>

      <app-alert *ngIf="archivageSuccess === false"
                  [isAlertClosable]="true"
                  [alertType]="'danger'"
                  [alertMessage]="'Votre demande d\'archivage a échoué.'"
                  [listError]="fieldsError">
      </app-alert>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-12">
      <app-alert *ngIf="notifySuccess === true"
                  [isAlertClosable]="true"
                  [alertType]="'success'"
                  [alertMessage]="'Votre message a été envoyé au référent de la collectivité.'">
      </app-alert>

      <app-alert *ngIf="notifySuccess === false"
                  [isAlertClosable]="true"
                  [alertType]="'danger'"
                  [alertMessage]="'Impossible d\'envoyer votre message.'"
                  [listError]="fieldsError">
      </app-alert>
    </div>
  </div>

  <div id="content">

    <div class="aed-accordion">
      <ngb-accordion [activeIds]="'collectivity'">
        <ngb-panel id="collectivity">
          <ng-template ngbPanelHeader let-opened="opened">
            <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
              Collectivité
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <profil-collectivite-collectivite [(collectivite)]="this.collectivite" [(success)]="this.success" [(fieldsError)]="this.fieldsError" (refreshEvent)="refresh()"></profil-collectivite-collectivite>
          </ng-template>
        </ngb-panel>

        <ngb-panel>
          <ng-template ngbPanelHeader let-opened="opened">
            <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
              Coordonnées
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <profil-collectivite-coordonnees [(collectivite)]="this.collectivite" [(success)]="this.success" [(fieldsError)]="this.fieldsError" (refreshEvent)="refresh()"></profil-collectivite-coordonnees>
          </ng-template>
        </ngb-panel>

        <ngb-panel>
          <ng-template ngbPanelHeader let-opened="opened">
            <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
              Contact
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <profil-collectivite-contact [(collectivite)]="this.collectivite" [(success)]="this.success" [(fieldsError)]="this.fieldsError" (refreshEvent)="refresh()"></profil-collectivite-contact>
          </ng-template>
        </ngb-panel>

        <ngb-panel>
          <ng-template ngbPanelHeader let-opened="opened">
            <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
              Fichiers
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <profil-collectivite-fichiers [(collectivite)]="this.collectivite" [(success)]="this.success" (refreshEvent)="refresh()"></profil-collectivite-fichiers>
          </ng-template>
        </ngb-panel>

        <ngb-panel>
          <ng-template ngbPanelHeader let-opened="opened">
            <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
              Statut de publication
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <profil-collectivite-statut [(collectivite)]="this.collectivite" [(success)]="this.success"></profil-collectivite-statut>
          </ng-template>
        </ngb-panel>

        <ngb-panel>
          <ng-template ngbPanelHeader let-opened="opened">
            <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
              Référents
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <profil-collectivite-referents [(collectivite)]="this.collectivite" [(success)]="this.success"></profil-collectivite-referents>
          </ng-template>
        </ngb-panel>

        <ngb-panel>
          <ng-template ngbPanelHeader let-opened="opened">
            <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
              Emplois
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <profil-collectivite-emplois [(collectivite)]="this.collectivite" [(success)]="this.success"></profil-collectivite-emplois>
          </ng-template>
        </ngb-panel>

        <ngb-panel>
          <ng-template ngbPanelHeader let-opened="opened">
            <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
              Archivage
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <profil-collectivite-archivage [(collectivite)]="this.collectivite" [(archivageSuccess)]="this.archivageSuccess" [(fieldsError)]="this.fieldsError" (refreshEvent)="refresh()"></profil-collectivite-archivage>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>

  </div>

</div>
