import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import {RouterModule} from '@angular/router';
import {OAuthModule} from 'angular-oauth2-oidc';
import {HttpClientModule} from '@angular/common/http';
import {NgxWebstorageModule} from 'ngx-webstorage';

import {appRoutes} from './app-routing.module';
import {environment} from '../environments/environment';
import {SharedModule} from './shared/shared.module';
import {CollectiviteModule} from './collectivite/collectivite.module';
import {LayoutComponent} from './shared/layout/layout.component';
import {AuthentificationModule} from './authentification/authentification.module';
import {AgentModule} from './agent/agent.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AccueilModule} from './accueil/accueil.module';
import {AnnuaireModule} from './annuaire/annuaire.module';
import {PageModule} from './page/page.module';
import {AccountModule} from './account/account.module';
import {RecaptchaModule, RecaptchaFormsModule} from 'ng-recaptcha';
  import {NewsModule} from './news/news.module';
import {FormModule} from './form/form.module';
import {OffreModule} from './offre/offre.module';
import { EmploiModule } from './emploi/emploi.module';
import { AdminModule } from './admin/admin.module';
import { NavigationService } from './shared/utils/navigation/navigation.service';
import {MATOMO_CONFIGURATION, MatomoConfiguration, NgxMatomoTrackerModule} from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import {APP_MATOMO_CONFIG} from './matomo/runtime-config';
// French support
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeFrExtra from "@angular/common/locales/extra/fr";
registerLocaleData(localeFr, "fr", localeFrExtra);

@NgModule({
    declarations: [],
    imports: [
        BrowserModule,
        HttpClientModule,
        OAuthModule.forRoot(),
        RouterModule.forRoot(
            appRoutes,
            {
              enableTracing: !environment.production,
              relativeLinkResolution: 'legacy'
            }
        ),
        NgxWebstorageModule.forRoot({
            prefix: 'aed'
        }),
        NgbModule,
        SharedModule,
        AuthentificationModule,
        AccountModule,
        CollectiviteModule,
        AgentModule,
        AccueilModule,
        AnnuaireModule,
        PageModule,
        RecaptchaModule,
        NewsModule,
        FormModule,
        OffreModule,
        EmploiModule,
        AdminModule,
        NgxMatomoTrackerModule.forRoot({
          siteId: '1', // your Matomo's site ID (find it in your Matomo's settings)
          trackerUrl: 'https://matomo-dev.cnfpt.keyconsulting.fr/', // your matomo server root url
        }),
        NgxMatomoRouterModule
    ],
    providers: [
      {
        provide: MATOMO_CONFIGURATION,
        useFactory: (config: MatomoConfiguration) => {
          return config;
        },
        deps: [APP_MATOMO_CONFIG]
      },
      NavigationService,
      { provide: LOCALE_ID, useValue: "fr-FR" }
    ],
    bootstrap: [LayoutComponent]
})
export class AppModule {
}
