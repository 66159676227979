import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../shared/shared.module";
import {FormsModule} from "@angular/forms";
import {ContactComponent} from "./contact/contact.component";
import {FormService} from "./form.service";
import {formRoutes} from "./form-routing.module";
import {RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(
            formRoutes
        ),
        NgbModule,
        SharedModule,
        RecaptchaModule,
        RecaptchaFormsModule,
    ],
    providers: [
        FormService
    ],
    declarations: [
        ContactComponent
    ],
})
export class FormModule {
}
