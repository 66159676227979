<div class="alc-agent-elt"
     *ngIf="affectation && (affectation !== true && !affectation.hidden || isOwner); else sansInfos">
    <a [routerLink]="['/agent', affectation?.user_id?.id]">
        <div class="row">
            <div class="col-md-4 col-lg-4">
                <div class="alc-elt-thumb">
                    <app-avatar [userDisplayName]="affectation?.user_id?.label" [userPhoto]="affectation?.user_id?.view.photo_url" [userTitle]="affectation?.user_id?.view.field_civilite"></app-avatar>
                </div>
            </div>
            <div class="col-md-8 col-lg-8">
                <div class="alc-elt-infos">
                    <h3 class="title-secondary text-uppercase">{{affectation?.user_id?.label }}</h3>
                    <ul class="list-unstyled aed-label-value">
                        <li>
                            <span class="alv-value">{{ affectation?.user_id?.view.field_grade ? affectation?.user_id?.view.field_grade.label : affectation?.user_id?.view.field_cadre_emploi?.label }}</span>
                        </li>
                        <li class="aed-label-value-iconic" *ngIf="affectation?.user_id?.view.field_telephone != null">
                            <span class="alv-label aed-icon aed-icon-phone" aria-hidden="true"></span>
                            <span class="alv-value">{{ affectation?.user_id?.view.field_telephone }}</span>
                        </li>
                        <li class="aed-label-value-iconic" *ngIf="affectation?.user_id?.view.field_mobile != null">
                            <span class="alv-label aed-icon aed-icon-smartphone" aria-hidden="true"></span>
                            <span class="alv-value">{{ affectation?.user_id?.view.field_mobile }}</span>
                        </li>
                        <li class="aed-label-value-iconic" *ngIf="affectation?.user_id?.view.mail != null">
                            <span class="alv-label aed-icon aed-icon-email" aria-hidden="true"></span>
                            <span class="alv-value"><a href="mailto:{{ affectation?.user_id?.view.mail }}">{{ affectation?.user_id?.view.mail }}</a></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </a>
</div>
<ng-template #sansInfos>
    <div class="row">
        <div class="col-md-12 col-lg-12 ct-no-job-infos empty-block">
            <div class="inner">
                <p *ngIf="affectation === true; else noData">
                    L'agent souhaite masquer son affectation
                </p>
                <ng-template #noData>
                    <p *ngIf="!isVacant">
                        Sans information sur l'affectation du poste
                    </p>
                    <p *ngIf="isVacant">
                        Poste vacant<br/>
                        <small>Attention : cette information ne vaut pas déclaration de vacance de poste.</small>
                    </p>
                </ng-template>
            </div>
        </div>
    </div>
</ng-template>