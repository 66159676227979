
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';


import {getDrupalUri} from "../shared/external";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class CsrfService {

    public csrfEndpoint = '/session/token';

    constructor(private http: HttpClient) {
    }

    /**
     * Get Token from Drupal Endpoint
     * @returns {Observable<string>}
     */
    public getToken(): Observable<string> {
        const options = {responseType: 'text' as 'text'};
        return this.http.get(getDrupalUri() + this.csrfEndpoint, options).pipe(map(
            (token) => {
                return token;
            }
        ));
    }

}
