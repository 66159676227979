
<form class="agent-update-form aed-entity-form" (ngSubmit)="onSubmit(coordonneesForm)" #coordonneesForm="ngForm" novalidate>
  <div class="row">

    <div class="form-group col-md-4 col-lg-4">
      <label for="field_linkedin">LinkedIn</label>
      <input type="text" class="form-control" id="field_linkedin"
      name="field_linkedin"
      [(ngModel)]="agent.field_linkedin" #field_linkedin="ngModel"
      linkedin>
      <div *ngIf="field_linkedin?.invalid" class="form-error alert alert-danger">
        <small *ngIf="field_linkedin?.errors?.linkedin">
          L'adresse saisie n'est pas un profil Linkedin valide.
        </small>
      </div>
    </div>

    <div class="form-group col-md-4 col-lg-4">
      <label for="field_viadeo">Viadeo</label>
      <input type="text" class="form-control" id="field_viadeo"
      name="field_viadeo"
      [(ngModel)]="agent.field_viadeo" #field_viadeo="ngModel" viadeo>
      <div *ngIf="field_viadeo?.invalid" class="form-error alert alert-danger">
        <small *ngIf="field_viadeo?.errors?.viadeo">
          L'adresse saisie n'est pas un profil Viadeo valide.
        </small>
      </div>
    </div>

    <div class="form-group col-md-4 col-lg-4">
      <label for="field_facebook">Facebook</label>
      <input type="text" class="form-control" id="field_facebook"
      name="field_facebook"
      [(ngModel)]="agent.field_facebook" #field_facebook="ngModel"
      facebook>
      <div *ngIf="field_facebook?.invalid" class="form-error alert alert-danger">
        <small *ngIf="field_facebook?.errors?.facebook">
          L'adresse saisie n'est pas un profil Facebook valide.
        </small>
      </div>
    </div>

    <div class="form-group col-md-4 col-lg-4">
      <label for="field_twitter">Twitter</label>
      <input type="text" class="form-control" id="field_twitter"
      name="field_twitter"
      [(ngModel)]="agent.field_twitter" #field_twitter="ngModel" twitter>
      <div *ngIf="field_twitter?.invalid" class="form-error alert alert-danger">
        <small *ngIf="field_twitter?.errors?.twitter">
          L'adresse saisie n'est pas un profil Twitter valide.
        </small>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-6 col-lg-6">
      <label class="form-check-label label-alone">
        Souhaitez-vous masquer ces informations au grand public ?
      </label>

      <div class="form-check form-check-inline">
        <label class="form-check-label" for="field_diffusion_coordonnees_n">
          <input class="form-check-input" type="radio"
          name="field_diffusion_coordonnees"
          id="field_diffusion_coordonnees_n"
          value="0"
          [checked]="!agent.field_diffusion_coordonnees"
          (click)="agent.field_diffusion_coordonnees=0">
          Oui
        </label>
      </div>

      <div class="form-check form-check-inline">
        <label class="form-check-label" for="field_diffusion_coordonnees_y">
          <input class="form-check-input" type="radio"
          name="field_diffusion_coordonnees"
          id="field_diffusion_coordonnees_y"
          value="1"
          [checked]="agent.field_diffusion_coordonnees"
          (click)="agent.field_diffusion_coordonnees=1">
          Non
        </label>
      </div>
    </div>


    <div class="form-group col-md-6 col-lg-6">
      <label class="form-check-label label-alone">
        Souhaitez-vous masquer ces informations à la communauté ?
      </label>

      <div class="form-check form-check-inline">
        <label class="form-check-label" for="field_diffusion_communautes_n">
          <input class="form-check-input" type="radio"
          name="field_diffusion_communautes"
          id="field_diffusion_communautes_n"
          value="0"
          [checked]="!agent.field_diffusion_communautes"
          (click)="agent.field_diffusion_communautes=0"
          required>
          Oui
        </label>
      </div>

      <div class="form-check form-check-inline">
        <label class="form-check-label" for="field_diffusion_communautes_y">
          <input class="form-check-input" type="radio"
          name="field_diffusion_communautes"
          id="field_diffusion_communautes_y"
          value="1"
          [checked]="agent.field_diffusion_communautes"
          (click)="agent.field_diffusion_communautes=1"
          required>
          Non
        </label>
      </div>
    </div>

    <div class="form-group col-md-12 col-lg-12">
      <button type="submit" class="arrow-btn btn btn-primary text-uppercase" [disabled]="coordonneesForm.invalid">
        Mettre à jour mes données personnelles
      </button>
    </div>
  </div>
</form>
