import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthentificationService } from '../../authentification/authentification.service';
import { OwnershipAccessState } from '../../authentification/has-any-role';
import { LoaderService } from '../../shared/loader/loader.service';
import { CollectiviteService } from '../collectivite.service';
import { DeleteDialogComponent } from '../../shared/delete-dialog/delete-dialog.component';
import { CsrfService } from '../../authentification/csrf.service';
import { ReferentFormComponent } from '../../agent/referent-form/referent-form.component';
import { AgentService } from '../../agent/agent.service';

@Component({
  selector: "app-liste-ct",
  templateUrl: "./liste-ct.component.html",
  styleUrls: ['./liste-ct.component.scss']
})
export class ListeCtComponent implements OnInit {

  accessState: OwnershipAccessState;
  currentUserId: any;
  notifySuccess: boolean = undefined;
  public csrfToken = '';

  collectivites: any[];
  page: number = 1;
  itemsPerPage: number = 10;
  collectionSize: number = 0;
  fulltextQuery: string = "";

  showAlert: boolean = false;
  alertType: string = 'success';
  alertMessage: string = '';

  metiers: any[];

  constructor(private route: ActivatedRoute,
    private collectiviteService: CollectiviteService,
    private loaderService: LoaderService,
    private modalService: NgbModal,
    private authService: AuthentificationService,
    private loader: LoaderService,
    private agentService: AgentService,
    private csrfService: CsrfService) {}

  ngOnInit(): void {
    this.authService.identity.subscribe(
      (identity) => {
          this.accessState = {
              currentUserRoles: identity && identity.roles ? identity.roles : [],
              hasOwnership: !(identity === null || identity === undefined) && !(identity.collectivites === null || identity.collectivites === undefined)
          };
          if (identity != null) {
              this.currentUserId = identity.id;
          }
      },
      () => {
          this.accessState = {
              currentUserRoles: [],
              hasOwnership: false
          };
      }
    );
    this.searchPaginated();
    this.updateCsrfToken();
    this.getFormDatas();
  }

  /**
   * Refresh the CSRF Token
   */
  private updateCsrfToken() {
      this.csrfService.getToken()
          .subscribe(
              token => {
                  this.csrfToken = token;
              });
  }

  /**
   * Get current agent's data
   */
  public getFormDatas(): void {
    this.agentService.getFormDatas(this.currentUserId)
        .subscribe((data) => {
          this.metiers = data.metiers;
          // this.grades = data.grades;
          // this.cotationsList = data.cotations;
          // this.regionsList = data.regions;
          // this.famillesList = data.familles;
          // this.competencesList = data.competences;
      });
  }

  public openDisableDialog(id: number) {
      const modalRef = this.modalService.open(DeleteDialogComponent, {size: 'sm'});
      modalRef.componentInstance.action = "désactivation";
      modalRef.componentInstance.actionVerb = "désactiver";
      modalRef.componentInstance.element = "cette collectivité";

      modalRef.result.then(() => {
        this.loader.show();
        this.showAlert = false;
        this.collectiviteService.disable(id, this.csrfToken)
            .subscribe(
                () => {
                  this.loader.hide();
                  this.alertType = 'success';
                  this.alertMessage = 'La collectivité a bien été désactivée.';
                  this.showAlert = true;
                  this.searchPaginated();
                  window.scrollTo(0, 0);
                },
                (value) => {
                  this.loader.hide();
                  this.alertType = 'danger';
                  this.alertMessage = 'Impossible de désactiver la collectivité.';
                  this.showAlert = true;
                  modalRef.componentInstance.fieldsError = value.errors;
                }
            );
      },
      () => {
        this.loader.hide();
      });
  }

  /**
   * Display the referent form
   */
  public openAddReferentForm() {
      const modalRef = this.modalService.open(ReferentFormComponent, {windowClass: 'custom-modal-lg'});
      modalRef.componentInstance.collectivite = null;
      modalRef.componentInstance.agentId = this.currentUserId;
      modalRef.componentInstance.isReferent = false;
      modalRef.componentInstance.metiers = this.metiers;
      this.showAlert = false;
      modalRef.result.then((result) => {
          if (result.success) {
            this.alertType = 'success';
            this.alertMessage = 'La collectivité a bien été ajoutée.';
            this.showAlert = true;
            this.searchPaginated();
            window.scrollTo(0, 0);
          } else {
            this.alertType = 'danger';
            this.alertMessage = 'Impossible de d\'ajouter la collectivité.';
            this.showAlert = true;
          }
      });
  }

  /**
   * Query the search service for collectivites with paging
   */
  public searchPaginated(fromFulltext: boolean = false) {
    this.loaderService.show();
    if (fromFulltext) {
      this.page = 1;
    }
    this.collectiviteService.getAllForUser(this.page, this.itemsPerPage, this.fulltextQuery).subscribe(
        (value) => {
          this.collectivites = value.data;
          this.collectionSize = value.count;
          this.loaderService.hide();
        },
        () => {
          this.collectivites = [];
          this.page = 1;
          this.collectionSize = 10;
          this.loaderService.hide();
        }
    );
  }
}
