import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NewsService} from '../news.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';

@Component({
    selector: 'app-news-listing',
    templateUrl: './news-listing.component.html',
    encapsulation: ViewEncapsulation.None
})
export class NewsListingComponent implements OnInit {

    public newsList: any[];
    public page: any = 1;
    public showMoreButton = true;

    constructor(private newsService: NewsService,
                private route: ActivatedRoute,
                private location: Location) {
    }

    ngOnInit() {
        this.loadMore(true);
    }

    loadMore(init: boolean) {
        this.newsService.getByPagination(this.page).subscribe((news) => {
            if (news == null) {
                this.showMoreButton = false;
            } else {
                if (!init) {
                    this.newsList = this.newsList.concat(news);
                    if (news.length < 10) {
                        this.showMoreButton = false;
                    }
                } else {
                    this.newsList = news;
                }
                ++this.page;
            }

        }, () => {
            this.showMoreButton = false;
        });
    }

    cancel() {
        this.location.back(); // <-- go back to previous location on cancel
    }
}
