<div class="affectation-history clearfix" *ngIf="historyAffectations.length > 0">
    <div class="inner">
        <h2 class="bordered-title bolded">Historique des affectations</h2>
        <div class="row">
            <div class="col-md-4 col-lg-4 alc-agent-elt"
                 *ngFor="let affectation of historyAffectations"
            >
                <a [routerLink]="['/agent',affectation.user_id.id]">
                    <!--<div class="current-affect empty-block"-->
                    <!--*ngIf="affectation.user_id.id == currentAgent; else notCurrentAffectation">-->
                    <!--<div class="inner">-->
                    <!--<p>-->
                    <!--Affectation courante-->
                    <!--</p>-->
                    <!--</div>-->
                    <!--</div>-->

                    <!--<ng-template #notCurrentAffectation>-->
                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                            <div class="alc-elt-thumb">
                                <app-avatar [userDisplayName]="affectation.user_id.label"
                                            [userPhoto]="affectation.user_id.view.photo_url"
                                            [userTitle]="affectation.user_id.view.field_civilite"></app-avatar>
                            </div>
                        </div>
                        <div class="col-md-8 col-lg-8">
                            <div class="alc-elt-infos">
                                <h3 class="title-secondary text-uppercase">{{affectation.user_id.label }}</h3>
                                <ul class="list-unstyled aed-label-value">
                                    <li>
                                        <span class="alv-value">{{ affectation.user_id.view.field_grade ? affectation.user_id.view.field_grade.label : affectation.user_id.view.field_cadre_emploi?.label }}</span>
                                    </li>
                                    <li class="aed-label-value-iconic"
                                        *ngIf="affectation.user_id.view.field_telephone != null">
                                        <span class="alv-label aed-icon aed-icon-phone" aria-hidden="true"></span>
                                        <span class="alv-value">{{ affectation.user_id.view.field_telephone }}</span>
                                    </li>
                                    <li class="aed-label-value-iconic"
                                        *ngIf="affectation.user_id.view.field_mobile != null">
                                            <span class="alv-label aed-icon aed-icon-smartphone"
                                                  aria-hidden="true"></span>
                                        <span class="alv-value">{{ affectation.user_id.view.field_mobile }}</span>
                                    </li>
                                    <li class="aed-label-value-iconic"
                                        *ngIf="affectation.user_id.view.mail != null">
                                        <span class="alv-label aed-icon aed-icon-email" aria-hidden="true"></span>
                                        <span class="alv-value"><a href="mailto:{{ affectation.user_id.view.mail }}">{{ affectation.user_id.view.mail }}</a></span>
                                    </li>
                                    <li>
                                        <app-periode [start]="affectation.debut"
                                                     [end]="affectation.fin"></app-periode>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!--</ng-template>-->
                </a>
            </div>
        </div>
    </div>
</div>
