import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {LoaderService} from '../../shared/loader/loader.service';
import {AuthentificationService} from '../../authentification/authentification.service';
import {ReferentService} from '../referent.service';
import {CsrfService} from '../../authentification/csrf.service';

@Component({
    selector: 'app-referent-form',
    templateUrl: './referent-form.component.html',
    styleUrls: ['./referent-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReferentFormComponent implements OnInit {

    public success: boolean;
    public isSubmitting = false;
    public fieldError = false;
    public formModel: any;

    public lastKeyIsDigit = true;

    currentYear = new Date().getFullYear();
    currentMonth = ('00' + new Date().getMonth() + 1).slice(-2);
    datesRange40 = [];

    selectedMetier: any = [];

    /**
     * Multiselect field settings
     */
     public settingsSingle = {
      singleSelection: true,
      text: '-',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      searchPlaceholderText: 'Rechercher',
      enableSearchFilter: true,
      labelKey: 'name',
      primaryKey: 'id',
    };

    @Input() collectivite: any;
    @Input() agent: any;
    @Input() agentId: any;
    @Input() poste: any;
    @Input() isReferent: boolean;
    @Input() metiers: any[];


    public csrfToken = '';

    constructor(public activeModal: NgbActiveModal,
                private referentService: ReferentService,
                private loader: LoaderService,
                private authService: AuthentificationService,
                private csrfService: CsrfService) {
    }

    ngOnInit() {
        this.updateCsrfToken();
        this.formModel = {
            collectivite: this.collectivite ? {id: this.collectivite.id} : null,
            code: '',
            ongoing: 1,
            emploiSinceMois: this.currentMonth,
            emploiSinceAnnee: this.currentYear,
            emploiEndMois: null,
            emploiEndAnnee: null
        };

        for (let i = 0; i < 39; i++) {
            this.datesRange40.push(this.currentYear - i);
        }

    }

    /**
     * Refresh the CSRF Token
     */
    private updateCsrfToken() {
        this.csrfService.getToken()
            .subscribe(
                token => {
                    this.csrfToken = token;
                });
    }

    public onSubmit(submittedForm: NgForm) {
        this.loader.show();
        this.isSubmitting = true;
        if (this.poste == null) {
            const data = submittedForm.value;
            if (!(this.agentId === null || this.agentId === undefined)) {
              data['agent'] = this.agentId;
            } else {
              data['agent'] = this.agent.id;
            }
            if (this.formModel.collectivite) {
                data['collectivite'] = this.formModel.collectivite;
            } else if (this.collectivite) {
                data['collectivite'] = {id: this.collectivite.id};
            }
            data['metier_referentiel_id'] = this.selectedMetier.map(x => x.id);
            this.referentService.create(data, this.csrfToken).subscribe((value) => {
                this.loader.hide();
                this.authService.authenticate();
                this.activeModal.close({success: true, referent: true});
            }, () => {
                this.loader.hide();
                this.activeModal.close({success: false});
            });
        } else {
            this.referentService.delete(this.poste.id, this.csrfToken).subscribe(() => {
                this.loader.hide();
                this.authService.authenticate();
                this.activeModal.close({success: true, referent: false});
            }, () => {
                this.loader.hide();
                this.activeModal.close({success: false});
            });
        }
    }

    public onPlaceSelection(selected: any) {
        if (!(selected === null || selected === undefined) && selected.id != null) {
            this.formModel.collectivite = selected;
        } else {
            this.formModel.collectivite = null;
        }
    }

    /**
     * Tests if character is a number, if not: doesn't allow it
     *
     * @returns {boolean}
     */
    public checkIfNumber(event: any) {
        const keyPressed = event.charCode;  // k = event.keyCode;  (Both can be used)
        this.lastKeyIsDigit = (keyPressed >= 48 && keyPressed <= 57);
        return this.lastKeyIsDigit;
    }
}
