import {Component, Input} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {

    @Input() messages: string[];
    @Input() confirmText = 'Confirmer';
    @Input() cancelText = 'Annuler';
    @Input() title = 'Confirmation';

    constructor(public activeModal: NgbActiveModal) {
    }

    cancel() {
        this.activeModal.dismiss(false);
    }

    confirm() {
        this.activeModal.close(true);
    }
}
