<form>
  <div class="row">
    <div class="form-group col-md-6 col-lg-6">
      <label for="status">Status</label>
      <input type="text" class="form-control" id="status"
      name="status"
      [(ngModel)]="agent.status" [disabled]="true"
      #status="ngModel"
      >
    </div>
    <div class="form-group col-md-6 col-lg-6">
      <label for="date-creation">Membre depuis</label>
      <input type="text" class="form-control" id="date-creation"
      name="date_creation"
      [(ngModel)]="agent.date_creation ? agent.date_creation.formatted : agent.date_creation" [disabled]="true"
      #date_creation="ngModel"
      >
    </div>
    <div class="form-group col-md-6 col-lg-6">
      <label for="rights">Droits</label>
      <input type="text" class="form-control" id="rights"
      name="rights"
      [(ngModel)]="agent.roles" [disabled]="true"
      #rights="ngModel"
      >
    </div>
  </div>
</form>
