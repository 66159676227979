<app-breadcrumb></app-breadcrumb>
<div class="container" *ngIf="page == null">
    <div class="row">
        <div class="col-md-12 col-lg-12 empty-block">
            <div class="inner">
                <p>
                    La page n'est pas disponible.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="page">
    <!-- begin: PAGE HEADER -->
    <header class="page-title">
        <h1 class="text-uppercase bolded">
            {{ page.title }}
        </h1>
    </header>
    <!-- end: PAGE HEADER -->

    <!-- begin: PAGE CONTENT -->
    <div id="content" class="page-detail">
        <div class="row">
            <div class="col-md-12 col-lg-12" [innerHTML]="page.body">
            </div>
        </div>
    </div>
    <!-- end: PAGE CONTENT -->
</div>