<div class="rights-details-table">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Collectivité (demande)</th>
        <th scope="col">Date</th>
        <th scope="col">Status</th>
        <th scope="col">Historique</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let demande of agent.demandes">
        <td class="align-left"><a (click)="goToProfilCollectivite(demande.field_collectivite.id)">{{ demande.field_collectivite.label }}</a></td>
        <td class="align-left">{{ demande.field_date?.formatted }}</td>
        <td class="align-left">{{ getStatusAsString(demande.field_statut) }}</td>
        <td>
          <table class="table" *ngIf="demande.field_historique.length">
            <thead>
              <tr>
                <th scope="col">Statut</th>
                <th scope="col">Date</th>
                <th scope="col">Motif</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let historique of demande.field_historique">
                <td class="align-left">{{ getStatusAsString(historique.statut) }}</td>
                <td class="align-left">{{ historique.date?.formatted }}</td>
                <td class="align-left">{{ historique.motif }}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>
