import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NewsService} from "../news.service";

@Component({
    selector: 'app-news-teaser',
    templateUrl: './news-teaser.component.html',
    styleUrls: ['./news-teaser.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NewsTeaserComponent implements OnInit {

    public newsList: any[];

    constructor(private newsService: NewsService) {
    }

    ngOnInit() {
        this.newsService.getLast().subscribe((news) => {
            this.newsList = news;
        }, () => {
            this.newsList = null;
        });
    }
}
