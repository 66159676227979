import {Component, Input, OnInit} from '@angular/core';
import {OwnershipAccessState} from '../../authentification/has-any-role';
import {EmploiService} from '../emploi.service';
import {AccountService} from '../../account/account.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-fiche-edit-affectation',
  templateUrl: './fiche-edit-affectation.component.html',
  styleUrls: ['./fiche-edit-affectation.component.scss']
})
export class FicheEditAffectationComponent implements OnInit {

  @Input() affectation: any;
  @Input() emploiId: any;
  @Input() collectiviteId: any;

  currentYear = new Date().getFullYear();
  datesRange6 = [];
  datesRange40 = [];

  invalidTimePeriod = false;
  emailExists = false;

  success: boolean = undefined;
  fieldError: boolean;
  isSubmitting: boolean;

  dateDebut: any;
  dateFin: any;
  ongoing: any;

  existingAgent = '1';
  agent: any = {
    id: undefined,
    nom: '',
    prenom: '',
    civilite: '',
    courriel: ''
  };
  fonction = '';

  accessState: OwnershipAccessState;

  constructor(private emploiService: EmploiService,
    private loader: LoaderService,
    private accountService: AccountService,
    public activeModal: NgbActiveModal
    ) {}

  ngOnInit() {

    if (this.affectation) {
      // On déclare des nouveaux objets date pour éviter les effets de bord & la modif en live
      this.dateDebut = Object.assign({}, this.affectation.debut);
      if ((this.affectation.fin === null || this.affectation.fin === undefined)) {
          this.dateFin = Object.assign({}, this.affectation.debut);
          this.ongoing = true;
      } else {
          this.dateFin = Object.assign({}, this.affectation.fin);
          this.ongoing = false;
      }
      this.fonction = this.affectation.field_exp_fpt_fonction;
    } else {
      this.dateDebut = new Date();
      this.dateFin = new Date();
      this.ongoing = false;
    }

    this.success = undefined;
    this.fieldError = false;
    this.isSubmitting = false;
    this.loader.hide();

    for (let i = 0; i < 6; i++) {
        this.datesRange6.push(this.currentYear - i);
    }

    for (let i = 0; i < 39; i++) {
        this.datesRange40.push(this.currentYear - i);
    }

  }

  public onSubmit(submittedForm: NgForm) {
      this.loader.show();
      this.isSubmitting = true;
      let data = submittedForm.value;

      // Informations différentes si l'affectation existe déjà
      if (this.affectation && this.affectation.id) {
        data['id'] = this.affectation.id;
      } else {
        data['collectivite'] = this.collectiviteId;
      }

      if (this.agent && this.agent.id) {
        data['agent'] = this.agent.id;
      }

      data['emploi'] = this.emploiId;

      // Formulaire uniquement accessible au réferents
      data['from_referent'] = true;

      this.emploiService
      .handleExperienceSubmission(data)
      .subscribe(
        () => {
          this.loader.hide();
          this.activeModal.close({success: true});
        },
        (error) => {
          this.loader.hide();
          console.log(error);
          this.activeModal.close({success: false});
        }
      );
  }

  public changeExperienceValidity() {
    this.emploiService
    .changeExperienceValidity(this.affectation.id, !this.affectation.is_valid)
    .subscribe(
      (value) => {
          this.affectation.is_valid = value;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public onAgentSelection(selected: any) {
    if (!(selected === null || selected === undefined) && selected.id != null) {
        this.agent = selected;
    } else {
        this.agent = null;
    }
  }

  public checkEmail() {
    this.accountService
    .checkEmail(this.agent.courriel)
    .subscribe(
      (value) => {
        this.emailExists = value;
      },
      (error) => {
        this.emailExists = false;
      }
    );
  }

  public validateTimePeriod() {
      if (this.affectation.ongoing) {
          return;
      }
      const debut = this.dateDebut;
      const fin = this.dateFin;

      if ((debut === null || debut === undefined)
          || (fin === null || fin === undefined)
          || (debut.month === null || debut.month === undefined)
          || (debut.year === null || debut.year === undefined)
          || (fin.month === null || fin.month === undefined)
          || (fin.year === null || fin.year === undefined)
      ) {
          this.invalidTimePeriod = false;
          return;
      }

      // Date de début (ajout d'un 0 avant le mois si besoin)
      const beginMonthClean = (debut.month < 10 && debut.month.length !== 2)
          ? `0${debut.month}`
          : `${debut.month}`;
      const beginDate = new Date(`${debut.year}-${beginMonthClean}`);

      // Date de fin (ajout d'un 0 avant le mois si besoin)
      const endMonthClean = (fin.month < 10 && fin.month.length !== 2)
          ? `0${fin.month}`
          : `${fin.month}`;
      const endDate = new Date(`${fin.year}-${endMonthClean}`);

      this.invalidTimePeriod = endDate < beginDate;
  }
}
