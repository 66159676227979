
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import {Observable, of} from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {STORAGE_PREVIOUS_URL, AuthentificationService} from './authentification.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {HttpClient} from '@angular/common/http';
import {getDrupalUri} from '../shared/external';





export const REGISTRATION_URI = 'inscription';
export const NEED_TO_LOG_IN = 'login-required';
// export const STORAGE_PREVIOUS_PROFILE_URL = 'previous-url-before-profile';
export const STORAGE_NEED_TO_LOG_IN = 'previous-url-before-login';

@Injectable()
export class AuthRequiredGuard implements CanActivate, CanActivateChild {
    constructor(private storage: SessionStorageService,
                private authentificationService: AuthentificationService,
                private router: Router,
                private http: HttpClient) {
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(childRoute, state);
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const rolesRequired = next.data.roles;
        const rolesWithOwnershipRequired = next.data.rolesWithOwnership;
        const method = next.data.ownershipMethod;
        const excludeProfilComplet = next.data.excludeProfilComplet;

        // Set this to true to activate all console.logs
        const displayLogs = false;

        // Observable that waits first authenticate to be done
        return new Observable((observer) => {
            if (this.authentificationService.firstAuthDone.getValue()) {
                if (displayLogs) { console.log('AUTHORIZATION : Logging you in'); }
                // already done the first auth
                observer.next();
                observer.complete();
            } else {
                const subscription = this.authentificationService.firstAuthDone.subscribe(
                    (value) => {
                        if (value) {
                            if (displayLogs) { console.log('AUTHORIZATION : Authentification successful'); }
                            observer.next();
                            observer.complete();
                            subscription.unsubscribe();
                        }
                    },
                    () => {
                        if (displayLogs) { console.log('AUTHORIZATION : Authentification failed'); }
                        observer.error();
                        observer.complete();
                        subscription.unsubscribe();
                    }
                );
            }
        }).pipe(mergeMap(() => {
            return this.http.get<any>(getDrupalUri() + '/identity').pipe(map(
                (e) => {
                    if (e.result === false) {
                        // Not logged, redirect to need to login
                        if (displayLogs) { console.log('AUTHORIZATION : Not logged'); }
                        this.storage.store(STORAGE_NEED_TO_LOG_IN, '/' + next.url.map(
                            fragment => fragment.path
                        ).join('/'));
                        return this.needToLogin();
                    } else if (excludeProfilComplet && e.profilComplet) {
                        // Logged, but the route doesn't accept user with complete profile
                        if (displayLogs) { console.log('AUTHORIZATION : access denied (profilComplet)'); }
                        this.accessDenied();
                    } else if (!(rolesRequired === null || rolesRequired === undefined) || !(rolesWithOwnershipRequired === null || rolesWithOwnershipRequired === undefined)) {
                        // Logged, the route has some role required
                        if ((e.roles === null || e.roles === undefined)) {
                            // The user doesn't have any role...
                            if (displayLogs) { console.log('AUTHORIZATION : The user doesn\'t have any role...'); }
                            return this.accessDenied();
                        }
                        if (!(rolesRequired === null || rolesRequired === undefined)) {
                            // Checking if the user have any of the required role
                            for (const role of e.roles) {
                                if (rolesRequired.findIndex((value) => value === role) !== -1) {
                                    if (displayLogs) { console.log('AUTHORIZATION : user has role', role); }
                                    return true;
                                }
                            }
                        }
                        if (!(rolesWithOwnershipRequired === null || rolesWithOwnershipRequired === undefined) && this.hasOwnership(method, e, next.params, displayLogs)) {
                            // Checking if the user have any of the required role with ownership
                            for (const role of e.roles) {
                                if (rolesWithOwnershipRequired.findIndex((value) => value === role) !== -1) {
                                    if (displayLogs) { console.log('AUTHORIZATION : user has role (with ownership)', role); }
                                    return true;
                                }
                            }
                        }

                        // Logged, but the user doesn't have a required role or ownership on the ressource
                        if (displayLogs) { console.log('AUTHORIZATION : access denied'); }
                        return this.accessDenied();
                    }
                    return true;
                }),catchError(() => {
                // drupal has gone away navigation denied. Act like authentification failed
                this.storage.store(STORAGE_NEED_TO_LOG_IN, '/' + next.url.map(
                    fragment => fragment.path
                ).join('/'));
                if (displayLogs) { console.log('AUTHORIZATION : API error'); }
                this.router.navigate(['/' + NEED_TO_LOG_IN]);
                return of(false);
            }),);
        }));
    }

    /**
     * Check if the user owned the target resource
     * @param {string} method
     * @param identity
     * @param params
     * @param {boolean} displayLogs
     * @returns {boolean}
     */
    private hasOwnership(method: string, identity: any, params: any, displayLogs = false) {
        if ((method === null || method === undefined)) {
            if (displayLogs) { console.log('OWNERSHIP : empty method'); }
            return true;
        }
        if ((identity === null || identity === undefined) || (params === null || params === undefined)) {
            if (displayLogs) { console.log('OWNERSHIP : not enough params'); }
            return false;
        }

        switch (method) {
            case 'agent':
                if (displayLogs) {
                    console.log('OWNERSHIP : agent method with identity.id=' + identity.id + ' and params.id=' + params.id);
                }
                return identity.id === params.id;

            case 'collectivite':
                if (displayLogs) {
                    console.log('OWNERSHIP : agent method with params.id=' + params.id + ' and collectivites=', identity.collectivites);
                }
                return !(identity.collectivites === null || identity.collectivites === undefined) && identity.collectivites.indexOf(params.id) > -1;

            default:
                if (displayLogs) { console.log('OWNERSHIP : denied'); }
                return false;
        }
    }

    /**
     * Redirect to login method
     * @returns {boolean}
     */
    private needToLogin() {
        this.router.navigate(['/' + NEED_TO_LOG_IN]);
        return false;
    }

    /**
     * Access denied, redirect elsewhere
     * @returns {boolean}
     */
    private accessDenied() {
        this.router.navigate(['/']);
        return false;
    }
}
