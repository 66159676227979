import {AfterContentInit, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {AnnuaireService} from '../annuaire.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {SearchDataService} from '../../shared/search-data/search-data.service';
import {Location} from '@angular/common';
import {AuthentificationService} from '../../authentification/authentification.service';
import { subscribeOn } from 'rxjs-compat/operator/subscribeOn';
import { map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterContentInit {

    public query: string;
    public searchResults: any[];
    public searchResultCount = 0;
    public searchResultLastPage = 0;
    public currentPage = 0;
    public pagerShowPage = 9;
    public searchOrder = 0;

    public agentSelected: any;
    public emploiSelected: any;
    public placeSelected: any;
    public departementsSelected: any = [];
    public cjsSelected: any = [];
    public tcdsSelected: any = [];
    public cotationsSelected: any = [];
    public filieresSelected: any = [];
    public domainesSelected: any = [];

    public departements: any[];
    public cjs: any[];
    public tcds: any[];
    public cotations: any[];
    public filieres: any[];
    public domaines: any[];

    public currentUserId: number = undefined;

    public allTcdsIds: any = [];

    public searching = false;
    public searchFailed = false;

    public autoScroll = false;

    @ViewChild('whoInput') whoInput: ElementRef;
    @ViewChild('whatInput') whatInput: ElementRef;

    /**
     * Multiselect field settings
     */
    public settingsWithCategories = {
      singleSelection: false,
      text: '-',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      searchPlaceholderText: 'Rechercher',
      enableSearchFilter: true,
      groupBy: 'category',
      labelKey: 'name',
      primaryKey: 'id',
    };
    public settingsWithoutCategories = {
      singleSelection: false,
      text: '-',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      searchPlaceholderText: 'Rechercher',
      enableSearchFilter: true,
      labelKey: 'name',
      primaryKey: 'id',
    };

    constructor(
        public annuaireService: AnnuaireService,
        private router: Router,
        private route: ActivatedRoute,
        private loaderService: LoaderService,
        private searchDataService: SearchDataService,
        private location: Location,
        private authService: AuthentificationService
    ) {
        // override the route reuse strategy
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                // trick the Router into believing its last link wasn't previously loaded
                this.router.navigated = false;
                // scrolling back to top
                window.scrollTo(0, 0);
            }
        });
    }

    ngOnInit() {
        this.loaderService.show();
        this.agentSelected = !(this.route.snapshot.queryParams.agent === null || this.route.snapshot.queryParams.agent === undefined) ? this.route.snapshot.queryParams.agent : '';
        this.emploiSelected = !(this.route.snapshot.queryParams.emploi === null || this.route.snapshot.queryParams.emploi === undefined) ? this.route.snapshot.queryParams.emploi : '';
        this.placeSelected = !(this.route.snapshot.queryParams.place === null || this.route.snapshot.queryParams.place === undefined) ? this.route.snapshot.queryParams.place : '';

        this.authService.identity.subscribe((identity) => {
            if (identity !== null) {
                this.currentUserId = identity.id;
            }
        });

        this.route.fragment.subscribe((fragment) => {
            if ('clear' === fragment) {
                this.searchDataService.clearData();
            } else if ('scroll' === fragment) {
                this.autoScroll = true;
            } else {
                setTimeout(() => {
                    if ('personne' === fragment) {
                        this.whoInput.nativeElement.focus();
                    } else if ('fonction' === fragment) {
                        this.whatInput.nativeElement.focus();
                    }
                }, 0);
            }
            this.location.replaceState(this.location.path(false));
        });

        this.query = '';
        if (!this.searchDataService.isEmpty()) {
            this.annuaireService.getSearchFormDatas().subscribe(
                (result) => {
                    this.initSelects(result);
                }
            );

            this.setDataFromService();
        } else {
            this.annuaireService.getSearchFormDatas().pipe(
                mergeMap((result: any) => {
                    this.loaderService.show();
                    this.route
                        .queryParams
                        .subscribe(params => {
                            // départements
                            if (!(params['depts'] === null || params['depts']=== undefined)) {
                                this.departementsSelected = result.departements.filter(dept => params['depts'].includes(dept.id));
                                this.search(false);
                            }
                        });
                    return of(result);
                })
            ).subscribe(
                (result) => {
                    this.initSelects(result);
                }
            );
        }
    }

    private initSelects(result: any) {
        this.departements = result.departements;
        this.domaines = result.domaines;
        this.tcds = result.tcds;
        this.tcds.forEach((tcd) => this.allTcdsIds = this.allTcdsIds.concat(tcd.id));
        this.cjs = result.cjs;
        this.cotations = result.cotations;
        this.filieres = result.filieres;
        this.loaderService.hide();
    }

    ngAfterContentInit() {
        if ((this.searchResults === null || this.searchResults === undefined) || this.searchResults.length === 0) {
            this.search();
        }
    }

    public sortResults(order: number) {
        this.searchOrder = order;
        this.search(true);
    }

    /**
     * Query the search service for emplois
     */
    public search(saveData: boolean = true) {
        if (saveData) {
            this.setDataForService();
        }
        this.searchPaged(1);
    }

    /**
     * Query the search service for emplois with paging
     */
    public searchPaged(page: number) {
        this.loaderService.show();
        this.annuaireService.search(
            this.agentSelected,
            this.emploiSelected,
            this.placeSelected,
            this.departementsSelected.map(({ id }) => id),
            this.cjsSelected.map(({ id }) => id),
            JSON.stringify(this.tcdsSelected.map(({ id }) => id)) === JSON.stringify(this.allTcdsIds) ? 'all' : this.tcdsSelected.map(({ id }) => id),
            this.cotationsSelected.map(({ id }) => id),
            this.filieresSelected.map(({ id }) => id),
            this.domainesSelected.map(({ id }) => id),
            page,
            this.searchOrder
        ).subscribe(
            (value) => {
                this.searchResultCount = this.searchDataService.searchResultCount = +value.count;
                this.searchResultLastPage = this.searchDataService.searchResultLastPage = +value.pages;
                this.searchResults = this.searchDataService.searchResults = value.data;
                this.currentPage = this.searchDataService.currentPage = page;

                if (this.autoScroll) {
                    setTimeout(() => {
                        document.getElementById('list-results').scrollIntoView(true);
                        this.loaderService.hide();
                    }, 750);
                    this.autoScroll = false;
                } else {
                    this.loaderService.hide();
                }
            },
            () => {
                this.searchResults = this.searchDataService.searchResultCount = undefined;
                this.searchResultCount = this.searchDataService.searchResultLastPage = 0;
                this.searchResultLastPage = this.searchDataService.searchResults = 0;
                this.currentPage = this.searchDataService.currentPage = 0;
                this.loaderService.hide();
            }
        );
    }

    /**
     * Go to the first page
     */
    public pagerFirst() {
        this.pagerCommon();
        this.searchPaged(1);
    }

    /**
     * Go to the last page
     */
    public pagerLast() {
        this.pagerCommon();
        this.searchPaged(this.searchResultLastPage);
    }

    /**
     * Go to previous page
     */
    public pagerPrevious() {
        this.pagerCommon();
        const nextPage = Math.max(1, this.currentPage - 1);
        this.searchPaged(nextPage);
    }

    /**
     * Go to next page
     */
    public pagerNext() {
        this.pagerCommon();
        const previousPage = Math.min(this.searchResultLastPage, this.currentPage + 1);
        this.searchPaged(previousPage);
    }

    /**
     * Go to specific page
     * @param {number} page
     */
    public pagerGoTo(page: number) {
        this.pagerCommon();
        this.searchPaged(page);
    }

    /**
     * Get the page list to build the pager
     * @returns {Array}
     */
    public pagerGetPageList() {
        let pageCount = this.currentPage - Math.floor(this.pagerShowPage / 2);
        if (pageCount < 1) {
            pageCount = 1;
        }
        const pageList = [];
        while (pageList.length < this.pagerShowPage && pageCount <= this.searchResultLastPage) {
            pageList.push(pageCount);
            pageCount++;
        }
        return pageList;
    }

    private pagerCommon() {
        window.scrollTo(0, 800);
    }

    public reset() {
        this.searchDataService.clearData();
        this.query = '';
        this.searchResults = undefined;
        this.searchResultCount = 0;
        this.searchResultLastPage = 0;
        this.currentPage = 0;

        this.agentSelected = undefined;
        this.emploiSelected = undefined;
        this.placeSelected = undefined;
        this.departementsSelected = [];
        this.cjsSelected = [];
        this.tcdsSelected = [];
        this.cotationsSelected = [];
        this.filieresSelected = [];
        this.domainesSelected = [];
    }

    private setDataFromService() {
        this.agentSelected = this.searchDataService.agentSelected;
        this.emploiSelected = this.searchDataService.emploiSelected;
        this.placeSelected = this.searchDataService.placeSelected;
        this.departementsSelected = this.searchDataService.departementsSelected;
        this.cjsSelected = this.searchDataService.cjsSelected;
        this.tcdsSelected = this.searchDataService.tcdsSelected;
        this.cotationsSelected = this.searchDataService.cotationsSelected;
        this.filieresSelected = this.searchDataService.filieresSelected;
        this.domainesSelected = this.searchDataService.domainesSelected;
        this.searchResultCount = this.searchDataService.searchResultCount;
        this.searchResultLastPage = this.searchDataService.searchResultLastPage;
        this.searchResults = this.searchDataService.searchResults;
        this.currentPage = this.searchDataService.currentPage;
    }

    private setDataForService() {
        this.searchDataService.placeSelected = this.placeSelected;
        this.searchDataService.agentSelected = this.agentSelected;
        this.searchDataService.emploiSelected = this.emploiSelected;
        this.searchDataService.departementsSelected = this.departementsSelected;
        this.searchDataService.cjsSelected = this.cjsSelected;
        this.searchDataService.tcdsSelected = this.tcdsSelected;
        this.searchDataService.cotationsSelected = this.cotationsSelected;
        this.searchDataService.filieresSelected = this.filieresSelected;
        this.searchDataService.domainesSelected = this.domainesSelected;
    }

    keyDownFunction(event) {
        if (event.keyCode === 13) {
            this.search(true);
        }
    }
}
