<app-breadcrumb></app-breadcrumb>

<div class="container" *ngIf="agent == null">
    <div class="row">
        <div class="col-md-12 col-lg-12 empty-block">
            <div class="inner">
                <p>
                    L'agent n'est pas disponible.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="agent">
    <!-- begin: PAGE HEADER -->
    <header class="page-title">
        <div class="row">
            <h1 class="text-uppercase bolded col-md-9 col-lg-9" *ngIf="agent.label; else identifiedByUsername">
                {{ agent.label }}
            </h1>
            <ng-template #identifiedByUsername>
                <h1 class="text-uppercase bolded col-md-9 col-lg-9">
                    Agent
                </h1>
            </ng-template>
            <a class="public-profile col-md-3 col-lg-3 text-center" [routerLink]="['/agent/', agent.id]"
               target="_blank">
                <span class="bolded">Voir son profil public</span>
            </a>
        </div>
    </header>
    <!-- end: PAGE HEADER -->

    <!-- begin: PAGE CONTENT -->
    <div id="content" class="agent-detail aed-form-edition">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <app-alert *ngIf="cvSuccess === true"
                           [isAlertClosable]="true"
                           [alertType]="'success'"
                           [alertMessage]="'Votre CV a été mis à jour'"
                >
                </app-alert>

                <app-alert *ngIf="cvSuccess === false"
                           [isAlertClosable]="true"
                           [alertType]="'danger'"
                           [alertMessage]="'Impossible de mettre votre CV à jour (le fichier doit être un PDF de moins de 2 Mo)'"
                >
                </app-alert>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <app-alert *ngIf="photoSuccess === true"
                           [isAlertClosable]="true"
                           [alertType]="'success'"
                           [alertMessage]="'Votre photo a été mise à jour'"
                >
                </app-alert>

                <app-alert *ngIf="photoSuccess === false"
                           [isAlertClosable]="true"
                           [alertType]="'danger'"
                           [alertMessage]="'Impossible de mettre votre photo à jour (le fichier doit être une image de moins de 1 Mo)'"
                >
                </app-alert>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <app-alert *ngIf="notifySuccess === true"
                           [isAlertClosable]="true"
                           [alertType]="'success'"
                           [alertMessage]="'Votre message a été envoyé au référent de la collectivité'"
                >
                </app-alert>

                <app-alert *ngIf="notifySuccess === false"
                           [isAlertClosable]="true"
                           [alertType]="'danger'"
                           [alertMessage]="'Impossible d\'envoyer votre message'"
                >
                </app-alert>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <app-alert *ngIf="fieldError"
                           [isAlertClosable]="true"
                           [alertType]="'danger'"
                           [alertMessage]="'La connexion avec le serveur est dégradée,le forumaire de mise à jour n\'est pas disponible.'"
                >
                </app-alert>

                <app-alert *ngIf="success === true"
                           [isAlertClosable]="true"
                           [alertType]="'success'"
                           [alertMessage]="'Le profil a été mis à jour avec succès.'"
                >
                </app-alert>

                <app-alert *ngIf="success === false"
                           [isAlertClosable]="true"
                           [alertType]="'danger'"
                           [alertMessage]="'La mise à jour de votre profil utilisateur a échoué.'"
                >
                </app-alert>
            </div>
        </div>

        <!-- begin: AGENT ACCORDION -->
        <ng-container>
            <div class="aed-tabs">
              <ul ngbNav #nav="ngbNav" [(activeId)]="activeIdString" class="nav-pills">
                  <!-- begin: AGENT TAB / IDENTITY -->
                  <li [ngbNavItem]="'identity'">
                      <a ngbNavLink>Identité</a>
                      <ng-template ngbNavContent>
                            <form class="agent-update-form aed-entity-form">
                                <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                        <h2 class="bordered-title">Identité</h2>
                                    </div>

                                    <!-- begin: ATI - CNFPT ACCOUNT -->
                                    <div class="col-md-12 col-lg-12">
                                        <h3 class="bordered-title bt-secondary">Mon Compte CNFPT</h3>
                                    </div>
                                    <div class="col-md-12 col-lg-12" *ngIf="accessState.hasOwnership">
                                        <div class="alert alert-info">Votre identité et votre mot de passe sont associés
                                            à votre <a href="{{ cnfptAccountUri }}">Compte CNFPT</a>.
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="field_nom">Nom</label>
                                        <input type="text" class="form-control" id="field_nom" name="field_nom"
                                               [(ngModel)]="agent.field_nom" [disabled]="true"
                                               #fieldNom="ngModel"
                                        >
                                    </div>
                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="field_prenom">Prénom</label>
                                        <input type="text" class="form-control" id="field_prenom" name="field_prenom"
                                               [(ngModel)]="agent.field_prenom" [disabled]="true"
                                               #fieldNom="ngModel"
                                        >
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="mail">Courriel</label>
                                        <input type="text" class="form-control" id="mail" name="mail"
                                               [(ngModel)]="agent.mail" [disabled]="true"
                                               #fieldNom="ngModel"
                                        >
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="field_email_de_secours">Courriel de contact</label>
                                        <input type="text" class="form-control" id="field_email_de_secours"
                                               name="field_email_de_secours"
                                               [(ngModel)]="agent.field_email_de_secours" [disabled]="true"
                                               #fieldNom="ngModel"
                                        >
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="field_date_de_naissance">Date de naissance</label>
                                        <input type="text" class="form-control" id="field_date_de_naissance"
                                               name="field_date_de_naissance"
                                               [(ngModel)]="agent.field_date_de_naissance ? agent.field_date_de_naissance.formatted : agent.field_date_de_naissance"
                                               [disabled]="true"
                                               #fieldDateDeNaissance="ngModel"
                                        >
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="field-telephone">Téléphone</label>
                                        <input type="text" class="form-control" id="field-telephone"
                                               name="field_telephone"
                                               [(ngModel)]="agent.field_telephone" [disabled]="true"
                                               #fieldTelephone="ngModel"
                                        >
                                    </div>

                                    <div class="form-group col-md-12 col-lg-12" *ngIf="accessState.hasOwnership">
                                        <a href="{{ cnfptAccountUri }}" class="arrow-btn btn btn-third text-uppercase">
                                            Modifier mon compte CNFPT
                                        </a>
                                    </div>

                                </div>
                            </form>
                        </ng-template>
                      </li>
                    <!-- end: AGENT TAB / IDENTITY -->

                    <!-- begin: AGENT TAB / COORDONNEES -->
                    <li [ngbNavItem]="'tabCoordonnees'">
                        <a ngbNavLink>Coordonnées</a>
                        <ng-template ngbNavContent>

                            <div class="row">
                                <div class="col-md-12 col-lg-12">
                                    <h2 class="bordered-title">Coordonnées</h2>
                                </div>
                            </div>
                            <div class="row" *hasRoleAgent="accessState">
                                <!-- begin: ATI - INSCRIPTION -->
                                <div class="col-md-12 col-lg-12">
                                    <h3 class="bordered-title bt-secondary">Mes données d'inscription</h3>
                                </div>

                                <div class="col-md-12 col-lg-12">
                                    <div class="alert alert-info">Pour modifier les données saisies lors de votre
                                        inscription, vous devez <a [routerLink]="['/form/contact']">contacter le
                                            CNFPT</a>.
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_emploi_fpt != undefined">
                                    <label for="field_emploi_fpt">Travaillez-vous dans une collectivité locale ou un
                                        établissement public local ?</label>
                                    <input type="text" class="form-control" id="field_emploi_fpt"
                                           name="field_emploi_fpt"
                                           [value]="agent.field_emploi_fpt == '1' ? 'Oui' : 'Non'"
                                           [disabled]="true">
                                </div>

                                <div class="form-group col-md-6 col-lg-6"
                                     *ngIf="agent.field_etes_vous_en_emploi != undefined">
                                    <label for="field_etes_vous_en_emploi">Êtes-vous en emploi ?</label>
                                    <input type="text" class="form-control" id="field_etes_vous_en_emploi"
                                           name="field_etes_vous_en_emploi"
                                           [value]="agent.field_etes_vous_en_emploi == '1' ? 'Oui' : 'Non'"
                                           [disabled]="true">
                                </div>

                                <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_pas_emploi_statut">
                                    <label for="field_pas_emploi_statut">Statut sans emploi</label>
                                    <input type="text" class="form-control" id="field_pas_emploi_statut"
                                           name="field_pas_emploi_statut" [value]="agent.field_pas_emploi_statut"
                                           [disabled]="true">
                                </div>

                                <div class="form-group col-md-6 col-lg-6"
                                     *ngIf="agent.field_cadre_emploi && agent.field_cadre_emploi.label">
                                    <label for="field_cadre_emploi">Cadre d'emplois</label>
                                    <input type="text" class="form-control" id="field_cadre_emploi"
                                           name="field_cadre_emploi" [value]="agent.field_cadre_emploi.label"
                                           [disabled]="true">
                                </div>

                                <div class="form-group col-md-6 col-lg-6"
                                     *ngIf="agent.field_etes_vous_liste_apti != undefined">
                                    <label for="field_etes_vous_liste_apti">Êtes-vous inscrit sur liste d’aptitude A+
                                        ?</label>
                                    <input type="text" class="form-control" id="field_etes_vous_liste_apti"
                                           name="field_etes_vous_liste_apti"
                                           [value]="agent.field_etes_vous_liste_apti == '1' ? 'Oui' : 'Non'"
                                           [disabled]="true">
                                </div>

                                <div class="form-group col-md-6 col-lg-6"
                                     *ngIf="agent.field_liste_aptitude_cadre && agent.field_etes_vous_liste_apti == '1'">
                                    <label for="field_liste_aptitude_cadre">Cadre d’emploi de liste d'aptitude</label>
                                    <input type="text" class="form-control" id="field_liste_aptitude_cadre"
                                           name="field_liste_aptitude_cadre"
                                           [value]="agent.field_liste_aptitude_cadre" [disabled]="true">
                                </div>

                                <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_liste_aptitude_since">
                                    <label for="field_liste_aptitude_since">Année liste d'aptitude</label>
                                    <input type="text" class="form-control" id="field_liste_aptitude_since"
                                           name="field_liste_aptitude_since" [value]="agent.field_liste_aptitude_since"
                                           [disabled]="true">
                                </div>

                                <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_etes_vous_ft != undefined">
                                    <label for="field_etes_vous_ft">Etes-vous fonctionnaire territorial ?</label>
                                    <input type="text" class="form-control" id="field_etes_vous_ft"
                                           name="field_etes_vous_ft"
                                           [value]="agent.field_etes_vous_ft == '1' ? 'Oui' : 'Non'"
                                           [disabled]="true">
                                </div>

                                <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_etes_vous_fc != undefined">
                                    <label for="field_etes_vous_fc">Etes-vous fonctionnaire/contractuel ?</label>
                                    <input type="text" class="form-control" id="field_etes_vous_fc"
                                           name="field_etes_vous_fc"
                                           [value]="agent.field_etes_vous_fc"
                                           [disabled]="true">
                                </div>

                                <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_mode_acces">
                                    <label for="field_mode_acces">Mode d'accès FPT</label>
                                    <input type="text" class="form-control" id="field_mode_acces"
                                           name="field_mode_acces" [value]="agent.field_mode_acces" [disabled]="true">
                                </div>

                                <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_annee_de_promotion">
                                    <label for="field_annee_de_promotion">Année de promotion</label>
                                    <input type="text" class="form-control" id="field_annee_de_promotion"
                                           name="field_annee_de_promotion" [value]="agent.field_annee_de_promotion"
                                           [disabled]="true">
                                </div>

                                <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_date_nomination">
                                    <label for="field_date_nomination">Nomination</label>
                                    <input type="text" class="form-control" id="field_date_nomination"
                                           name="field_date_nomination" [value]="agent.field_date_nomination"
                                           [disabled]="true">
                                </div>

                                <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_corps_d_origine">
                                    <label for="field_corps_d_origine">Corps d'origine</label>
                                    <input type="text" class="form-control" id="field_corps_d_origine"
                                           name="field_corps_d_origine" [value]="agent.field_corps_d_origine"
                                           [disabled]="true">
                                </div>
                                <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_corps_origine_autre">
                                    <label for="field_corps_origine_autre">Corps d'origine (autre)</label>
                                    <input type="text" class="form-control" id="field_corps_origine_autre"
                                           name="field_corps_origine_autre" [value]="agent.field_corps_origine_autre"
                                           [disabled]="true">
                                </div>
                                <!-- end: ATI - INSCRIPTION -->

                                <div class="col-md-12 clearfix">
                                    <div class="form-group-separator"></div>
                                </div>
                            </div>

                            <form class="agent-update-form aed-entity-form"
                                  (ngSubmit)="onPersonnalSubmit(agentPersonalForm)" #agentPersonalForm="ngForm"
                                  novalidate>
                                <div class="row">
                                    <!-- begin: ATI - PERSONNAL -->
                                    <div class="col-md-12 col-lg-12">
                                        <h3 class="bordered-title bt-secondary">Mes données personnelles</h3>
                                    </div>

                                    <div class="col-md-12 col-lg-12">
                                        <div class="alert alert-info">Les champs ci-dessous seront en accès public si
                                            vous les renseignez.
                                        </div>
                                    </div>


                                    <div class="form-group col-md-8 col-lg-8" *ngIf="agent.field_cadre_emploi">
                                        <label for="field_grade">Grade</label>
                                        <angular2-multiselect [data]="grades"
                                            [(ngModel)]="selectedGrade"
                                            [ngModelOptions]="{standalone: true}"
                                            [settings]="settingsSingle"
                                            #field_metier_referentiel="ngModel"
                                            class="single-select">
                                            <c-badge>
                                              <ng-template let-item="item">
                                                <label>{{item.label ? item.label : item.name}}</label>
                                              </ng-template>
                                            </c-badge>
                                        </angular2-multiselect>
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6" *hasRoleReferentCT="accessState">
                                        <label for="field_metier_referentiel">Métier (référentiel)</label>
                                        <angular2-multiselect [data]="metiers"
                                            [(ngModel)]="selectedMetier"
                                            [ngModelOptions]="{standalone: true}"
                                            [settings]="settingsSingle"
                                            #field_metier_referentiel="ngModel"
                                            class="single-select">
                                            <c-badge>
                                              <ng-template let-item="item">
                                                <label>{{item.label ? item.label : item.name}}</label>
                                              </ng-template>
                                            </c-badge>
                                        </angular2-multiselect>
                                        <div *ngIf="field_metier_referentiel?.invalid"
                                             class="form-error alert alert-danger">
                                            <small *ngIf="field_metier_referentiel?.errors?.required">
                                                Obligatoire
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6" *hasRoleReferentCT="accessState">
                                        <label for="field_metier_saisie_libre">Métier (saisie libre)</label>
                                        <input type="tel" class="form-control" id="field_metier_saisie_libre"
                                               name="field_metier_saisie_libre"
                                               [(ngModel)]="agent.field_metier_saisie_libre"
                                               #field_metier_saisie_libre="ngModel" required>
                                        <div *ngIf="field_metier_saisie_libre?.invalid"
                                             class="form-error alert alert-danger">
                                            <small *ngIf="field_metier_saisie_libre?.errors?.required">
                                                Obligatoire
                                            </small>
                                        </div>
                                    </div>

                                    <!-- begin: ATI / PHOTO -->
                                    <div class="form-group col-md-12 col-lg-12">
                                        <label for="photo">Photo</label>

                                        <div class="alert alert-info"
                                             *ngIf="uploadData?.Photo.validators?.max_filesize || uploadData?.Photo.validators?.file_extensions">
                                            <span *ngIf="uploadData.Photo.validators?.max_filesize">
                                                La taille maximale autorisée est <strong>{{uploadData.Photo.validators.max_filesize}}</strong>.
                                            </span>
                                            <br *ngIf="uploadData.Photo.validators?.max_filesize"/>
                                            <span *ngIf="uploadData.Photo.validators?.file_extensions">
                                                Les extensions de fichier autorisées sont: <strong>{{uploadData.Photo.validators.file_extensions}}</strong>.
                                            </span>
                                        </div>

                                        <div *ngIf="agent.photo_url" class="user-avatar">
                                            <img class="img-fluid" [src]="agent.photo_url" title="Votre photo"
                                                 alt="Votre photo"/>
                                        </div>
                                        <input type="file" class="form-control" id="photo" name="photo"
                                               (change)="pictureSelected($event)" accept="image/*">

                                        <div class="form-error alert alert-danger"
                                             *ngIf="uploadData.Photo.errors?.length > 0">
                                            <small *ngFor="let error of uploadData.Photo.errors">
                                                {{ error }}
                                            </small>
                                        </div>
                                    </div>
                                    <!-- end: ATI / PHOTO -->

                                    <div class="col-md-12 col-lg-12">
                                        <div class="alert alert-info">Les champs ci-dessous sont facultatifs et peuvent
                                            au choix être rendus publiques, ou uniquement visible pour la communauté de
                                            l’annuaire. Ces informations resteront visibles par les gestionnaires CNFPT
                                            de l’application.
                                        </div>
                                    </div>

                                    <div class="form-group col-md-4 col-lg-4" *hasRoleReferentCT="accessState">
                                        <label for="field_telephone">Téléphone</label>
                                        <input type="tel" class="form-control" id="field_telephone"
                                               name="field_telephone"
                                               [(ngModel)]="agent.field_telephone" #field_telephone="ngModel" phone>
                                        <div *ngIf="field_telephone?.invalid" class="form-error alert alert-danger">
                                            <small *ngIf="field_telephone?.errors?.phone">
                                                Le numéro saisi n'est pas valide.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-4">
                                        <label for="field_mobile">Téléphone portable</label>
                                        <input type="tel" class="form-control" id="field_mobile" name="field_mobile"
                                               [(ngModel)]="agent.field_mobile" #field_mobile="ngModel" mobile>
                                        <div *ngIf="field_mobile?.invalid" class="form-error alert alert-danger">
                                            <small *ngIf="field_mobile?.errors?.phone">
                                                Le numéro saisi n'est pas valide.
                                            </small>
                                        </div>
                                    </div>

                                    <ng-container *hasRoleAgent="accessState">
                                        <div class="form-group col-md-12 col-lg-12">
                                            <label for="field_presentation">Presentation</label>
                                            <ckeditor id="field_presentation" name="field_presentation"
                                                      [(ngModel)]="agent.field_presentation" [config]="ckeditorConfig"
                                                      #field_presentation="ngModel"></ckeditor>
                                        </div>

                                        <div class="form-group col-md-12 col-lg-12">
                                            <label for="field_centres_d_interet">Centres d'intérêt</label>
                                            <ckeditor id="field_centres_d_interet" name="field_centres_d_interet"
                                                      [(ngModel)]="agent.field_centres_d_interet"
                                                      [config]="ckeditorConfig"
                                                      #field_centres_d_interet="ngModel"></ckeditor>
                                        </div>

                                        <div class="form-group col-md-4 col-lg-4">
                                            <label for="field_linkedin">LinkedIn</label>
                                            <input type="text" class="form-control" id="field_linkedin"
                                                   name="field_linkedin"
                                                   [(ngModel)]="agent.field_linkedin" #field_linkedin="ngModel"
                                                   linkedin>
                                            <div *ngIf="field_linkedin?.invalid" class="form-error alert alert-danger">
                                                <small *ngIf="field_linkedin?.errors?.linkedin">
                                                    L'adresse saisie n'est pas un profil Linkedin valide.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4 col-lg-4">
                                            <label for="field_viadeo">Viadeo</label>
                                            <input type="text" class="form-control" id="field_viadeo"
                                                   name="field_viadeo"
                                                   [(ngModel)]="agent.field_viadeo" #field_viadeo="ngModel" viadeo>
                                            <div *ngIf="field_viadeo?.invalid" class="form-error alert alert-danger">
                                                <small *ngIf="field_viadeo?.errors?.viadeo">
                                                    L'adresse saisie n'est pas un profil Viadeo valide.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4 col-lg-4">
                                            <label for="field_facebook">Facebook</label>
                                            <input type="text" class="form-control" id="field_facebook"
                                                   name="field_facebook"
                                                   [(ngModel)]="agent.field_facebook" #field_facebook="ngModel"
                                                   facebook>
                                            <div *ngIf="field_facebook?.invalid" class="form-error alert alert-danger">
                                                <small *ngIf="field_facebook?.errors?.facebook">
                                                    L'adresse saisie n'est pas un profil Facebook valide.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-4 col-lg-4">
                                            <label for="field_twitter">Twitter</label>
                                            <input type="text" class="form-control" id="field_twitter"
                                                   name="field_twitter"
                                                   [(ngModel)]="agent.field_twitter" #field_twitter="ngModel" twitter>
                                            <div *ngIf="field_twitter?.invalid" class="form-error alert alert-danger">
                                                <small *ngIf="field_twitter?.errors?.twitter">
                                                    L'adresse saisie n'est pas un profil Twitter valide.
                                                </small>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6 col-lg-6">
                                        <label class="form-check-label label-alone">
                                            Souhaitez-vous masquer ces informations au grand public ?
                                        </label>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_diffusion_coordonnees_n">
                                                <input class="form-check-input" type="radio"
                                                       name="field_diffusion_coordonnees"
                                                       id="field_diffusion_coordonnees_n"
                                                       value="0"
                                                       [checked]="!agent.field_diffusion_coordonnees"
                                                       (click)="agent.field_diffusion_coordonnees=0">
                                                Oui
                                            </label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_diffusion_coordonnees_y">
                                                <input class="form-check-input" type="radio"
                                                       name="field_diffusion_coordonnees"
                                                       id="field_diffusion_coordonnees_y"
                                                       value="1"
                                                       [checked]="agent.field_diffusion_coordonnees"
                                                       (click)="agent.field_diffusion_coordonnees=1">
                                                Non
                                            </label>
                                        </div>
                                    </div>


                                    <div class="form-group col-md-6 col-lg-6">
                                        <label class="form-check-label label-alone">
                                            Souhaitez-vous masquer ces informations à la communauté ?
                                        </label>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_diffusion_communautes_n">
                                                <input class="form-check-input" type="radio"
                                                       name="field_diffusion_communautes"
                                                       id="field_diffusion_communautes_n"
                                                       value="0"
                                                       [checked]="!agent.field_diffusion_communautes"
                                                       (click)="agent.field_diffusion_communautes=0"
                                                       required>
                                                Oui
                                            </label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_diffusion_communautes_y">
                                                <input class="form-check-input" type="radio"
                                                       name="field_diffusion_communautes"
                                                       id="field_diffusion_communautes_y"
                                                       value="1"
                                                       [checked]="agent.field_diffusion_communautes"
                                                       (click)="agent.field_diffusion_communautes=1"
                                                       required>
                                                Non
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6">
                                        <label class="form-check-label label-alone">
                                            Souhaitez-vous masquer votre nom sur vos affectations ?
                                        </label>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_masquage_nom_y">
                                                <input class="form-check-input" type="radio"
                                                       name="field_masquage_nom"
                                                       id="field_masquage_nom_y"
                                                       value="1"
                                                       [checked]="agent.field_masquage_nom"
                                                       (click)="agent.field_masquage_nom=1">
                                                Oui
                                            </label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_masquage_nom_n">
                                                <input class="form-check-input" type="radio"
                                                       name="field_masquage_nom"
                                                       id="field_masquage_nom"
                                                       value="0"
                                                       [checked]="!agent.field_masquage_nom"
                                                       (click)="agent.field_masquage_nom=1">
                                                Non
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12 col-lg-12">
                                        <button type="submit"
                                                class="arrow-btn btn btn-primary text-uppercase"
                                                [disabled]="agentPersonalForm.invalid">
                                            Mettre à jour mes données personnelles
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                      </li>
                    <!-- end: AGENT TAB / COORDONNEES -->

                    <!-- begin: AGENT TAB / ASSIGNMENTS -->
                    <li [ngbNavItem]="'assignments'">
                        <a ngbNavLink>Experiences</a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-md-12 col-lg-12">
                                    <h2 class="bordered-title">Expériences</h2>

                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12 col-lg-12">
                                    <a class="arrow-btn btn btn-third text-uppercase mar-10"
                                      (click)="openAddEmploiForm()"
                                      *hasRoleAgent="accessState">
                                      Ajouter un emploi occupé
                                    </a>
                                    <a class="arrow-btn btn btn-third text-uppercase mar-10"
                                      (click)="openAddReferentForm()"
                                      *hasRoleReferentCT="accessState">
                                      Ajouter un poste référent
                                    </a>
                                    <a class="arrow-btn btn btn-third text-uppercase mar-10"
                                        (click)="addRoleAgent()"
                                        *hasRoleReferentButNotAgent="accessState">
                                        Je suis cadre de direction, je souhaite ajouter mes expériences
                                    </a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-12">
                                    <div class="row aed-list-container">
                                        <div [ngClass]="{ odd: odd, even: even, 'col-md-12 col-lg-12 alc-elt': true}"
                                             *ngFor="let job of agent.experiences; let even = even; let odd = odd">
                                            <div class="inner clearfix">

                                                <div class="row" *ngIf="job?.type?.id !== undefined">
                                                    <div class="col-md-4 col-lg-4">
                                                        <app-fiche-ct-light
                                                                *ngIf="job?.type?.id === 'exp_affectation_fpt'"
                                                                [collectivite]="job.field_exp_fpt_emploi.collectivite">
                                                        </app-fiche-ct-light>
                                                    </div>
                                                    <app-fiche-emploi-light
                                                            [emploi]="job.type.id === 'exp_affectation_fpt' ? job.field_exp_fpt_emploi : null"
                                                            [experience]="job"
                                                            [showAgent]="false"
                                                            class="col-md-4 col-lg-4">
                                                    </app-fiche-emploi-light>

                                                    <div class="col-md-4 col-lg-4 d-flex align-items-center"
                                                         *isLogged="accessState">
                                                        <nav class="alc-links">
                                                            <a class="arrow-btn btn btn-third btn-sm text-uppercase"
                                                               (click)="notifyError(job.field_exp_fpt_emploi)"
                                                               *ngIf="job?.type?.id === 'exp_affectation_fpt'">
                                                                Signaler une erreur
                                                            </a>

                                                            <a class="arrow-btn btn btn-primary btn-sm text-uppercas"
                                                               (click)="openEditExperienceForm(job, false, job.type.id === 'exp_affectation_fpt')">
                                                                Modifier l'expérience
                                                            </a>

                                                            <div class="btn-group-vertical" data-toggle="buttons"
                                                                 *ngIf="job?.type?.id === 'exp_affectation_fpt'">
                                                                <label for="currentExperienceFlag"
                                                                       class="btn btn-primary active btn-checkbox"
                                                                       *ngIf="job?.isOngoing">
                                                                    <input type="checkbox"
                                                                           name="currentExperienceFlag"
                                                                           id="currentExperienceFlag"
                                                                           value="1" [checked]="true"
                                                                           [disabled]="true"/>
                                                                    Poste actuel
                                                                </label>

                                                                <a name="becomeReferentFlag"
                                                                   id="becomeReferentFlag"
                                                                   (click)="openBecomeReferentForm(job)">
                                                                    <label for="becomeReferentFlag"
                                                                           class="btn btn-primary btn-checkbox"
                                                                           [class.active]="job.isReferent"
                                                                           *ngIf="job?.isOngoing || job.isReferent">
                                                                        {{!job.isReferent ? 'Devenir ' :
                                                                        ''}}Référent de la collectivité
                                                                    </label>
                                                                </a>
                                                            </div>
                                                        </nav>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                      </li>
                    <!-- end: AGENT TAB / ASSIGNMENTS -->

                    <!-- begin: AGENT TAB / TRAININGS -->
                    <li [ngbNavItem]="'trainings'" *hasRoleAgent="accessState">
                        <a ngbNavLink>Formations</a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-md-12 col-lg-12">
                                    <h2 class="bordered-title">Formation</h2>
                                </div>

                                <!-- begin: ATT - FORMATION -->
                                <div class="afuf-elt col-md-12 col-lg-12"
                                     *ngFor="let formation of agent.field_formations;
                                         let ftIndex =index;
                                         let odd=odd;
                                         let even=even"
                                     [class.odd]="odd"
                                     [class.even]="even"
                                >
                                    <div class="inner">
                                        <a class="fg-action fga-edit clickable" (click)="openFormationForm(formation)"
                                           title="Modifier la formation">
                                            <span class="ard-icon aed-icon-edit" aria-hidden="true"></span>
                                            <span class="sr-only">Modifier</span>
                                        </a>
                                        <a class="fg-action fga-remove clickable" (click)="removeFormation(formation)"
                                           title="Supprimer la formation">
                                            <span class="ard-icon aed-icon-close" aria-hidden="true"></span>
                                            <span class="sr-only">Supprimer</span>
                                        </a>
                                        <div class="row ae-formation">
                                            <app-formation [formation]="formation"
                                                           [showVisibility]="true"></app-formation>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-12"
                                     *ngIf="!agent.field_formations ||agent.field_formations.length < 6">
                                    <a class="arrow-btn btn btn-primary text-uppercase"
                                       (click)="openFormationForm(null)"
                                       *hasRoleAgent="accessState">Ajouter une formation</a>
                                </div>
                            </div>
                        </ng-template>
                      </li>
                    <!-- end: AGENT TAB / TRAININGS -->

                    <!-- begin: AGENT TAB / CV -->
                    <li [ngbNavItem]="'cv'" *hasRoleAgent="accessState">
                        <a ngbNavLink>Dépôt du CV</a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-md-12 col-lg-12">
                                    <h2 class="bordered-title">Dépôt du CV</h2>
                                </div>

                                <div class="form-group col-md-12 col-lg-12">

                                    <div class="col-md-12 col-lg-12 alert alert-info"
                                         *ngIf="uploadData?.Cv.validators?.max_filesize || uploadData?.Cv.validators?.file_extensions">
                                        <span *ngIf="uploadData.Cv.validators?.max_filesize">
                                            La taille maximale autorisée est <strong>{{uploadData.Cv.validators.max_filesize}}</strong>.
                                        </span>
                                        <br *ngIf="uploadData.Cv.validators?.max_filesize"/>
                                        <span *ngIf="uploadData.Cv.validators?.file_extensions">
                                            <ng-template
                                                    *ngIf="uploadData.Cv.validators.file_extensions.split(' ').length - 1 > 0; else singleExtension">Les extensions de fichier autorisées sont: </ng-template>
                                            <ng-template
                                                    #singleExtension>La seule extension de fichier autorisée est: </ng-template>
                                            <strong>{{uploadData.Cv.validators.file_extensions}}</strong>.
                                        </span>
                                    </div>

                                    <label for="cv" class="sr-only">CV</label>
                                    <div *ngIf="agent.cv_url" class="user-cv">
                                        <a [href]="agent.cv_url" target="_blank">
                                            <span class="cv-text">Télecharger le cv</span>
                                            <span class="aed-icon aed-icon-cv" aria-hidden="true"></span>
                                        </a>
                                    </div>
                                    <input type="file" class="form-control" id="cv" name="cv"
                                           (change)="cvSelected($event)" accept="application/pdf">

                                    <div class="form-error alert alert-danger" *ngIf="uploadData.Cv.errors.length > 0">
                                        <small *ngFor="let error of uploadData.Cv.errors">
                                            {{ error }}
                                        </small>
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-lg-12">
                                    <button type="submit"
                                            class="arrow-btn btn btn-primary text-uppercase"
                                            (click)="onCvSubmit()" [disabled]="!cv_file">
                                        Mettre à jour mon CV
                                    </button>
                                </div>
                            </div>
                        </ng-template>
                      </li>
                    <!-- end: AGENT TAB / CV -->

                    <!-- begin: AGENT TAB / MOBILITY -->
                    <li [ngbNavItem]="'mobility'" *ngIf="agent.field_etes_vous_liste_apti == true || (agent.field_cadre_emploi && agent.field_cadre_emploi.categorie_a_plus === '1') || agent.is_eleve_inet == true">
                        <a ngbNavLink>Mobilité</a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-md-12 col-lg-12">
                                    <h2 class="bordered-title">Mobilité</h2>
                                </div>
                            </div>
                            <form class="agent-mobilite-update-form"
                                  #agentMobiliteUpdateForm="ngForm"
                                  (ngSubmit)="onMobiliteSubmit(agentMobiliteUpdateForm)">

                                <div class="row">
                                    <div class="form-group col-md-4 col-lg-4">
                                        <label class="form-check-label label-alone">
                                            Se déclarer en recherche de mobilité
                                        </label>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_mobilite_actif_y">
                                                <input class="form-check-input" type="radio"
                                                       name="field_mobilite_actif"
                                                       id="field_mobilite_actif_y"
                                                       value="1"
                                                       [checked]="agent.field_mobilite_actif"
                                                       (click)="agent.field_mobilite_actif=1">
                                                Oui
                                            </label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_mobilite_actif_n">
                                                <input class="form-check-input" type="radio"
                                                       name="field_mobilite_actif"
                                                       id="field_mobilite_actif_n"
                                                       value="0"
                                                       [checked]="!agent.field_mobilite_actif"
                                                       (click)="agent.field_mobilite_actif=0">
                                                Non
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <div class="row" *ngIf="agent.field_mobilite_actif == '1'">
                                    <div class="form-group col-md-4 col-lg-4">
                                        <label class="form-check-label label-alone">
                                            Zone géographique de mobilité
                                        </label>
                                        <angular2-multiselect [data]="regionsList"
                                            [(ngModel)]="fieldMobiliteZoneIds"
                                            [ngModelOptions]="{standalone: true}"
                                            [settings]="settingsMulti">
                                            <c-badge>
                                              <ng-template let-item="item">
                                                <label>{{item.label ? item.label : item.name}}</label>
                                              </ng-template>
                                            </c-badge>
                                        </angular2-multiselect>
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label>Domaine souhaité</label>
                                        <angular2-multiselect [data]="famillesList"
                                            [(ngModel)]="fieldMobiliteDomaineIds"
                                            [ngModelOptions]="{standalone: true}"
                                            [settings]="settingsMulti">
                                            <c-badge>
                                              <ng-template let-item="item">
                                                <label>{{item.label ? item.label : item.name}}</label>
                                              </ng-template>
                                            </c-badge>
                                        </angular2-multiselect>
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label>Fonction souhaitée</label>
                                        <angular2-multiselect [data]="cotationsList"
                                            [(ngModel)]="fieldMobiliteFonctionIds"
                                            [ngModelOptions]="{standalone: true}"
                                            [settings]="settingsMulti">
                                            <c-badge>
                                              <ng-template let-item="item">
                                                <label>{{item.label ? item.label : item.name}}</label>
                                              </ng-template>
                                            </c-badge>
                                        </angular2-multiselect>
                                    </div>
                                </div>
                                <div class="row" *ngIf="agent.field_mobilite_actif == '1'">

                                    <div class="form-group col-md-4 col-lg-4">
                                        <label class="form-check-label label-alone">
                                            Solliciter l'accompagnement du CNFPT dans votre
                                            recherche de mobilité
                                        </label>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_mobilite_cnfpt_y">
                                                <input class="form-check-input" type="radio"
                                                       name="field_mobilite_cnfpt"
                                                       id="field_mobilite_cnfpt_y"
                                                       value="1"
                                                       [checked]="agent.field_mobilite_cnfpt"
                                                       (click)="agent.field_mobilite_cnfpt=1">
                                                Oui
                                            </label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_mobilite_cnfpt_n">
                                                <input class="form-check-input" type="radio"
                                                       name="field_mobilite_cnfpt"
                                                       id="field_mobilite_cnfpt_n"
                                                       value="0"
                                                       [checked]="!agent.field_mobilite_cnfpt"
                                                       (click)="agent.field_mobilite_cnfpt=0">
                                                Non
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="agent.field_mobilite_actif == '1'">
                                    <div class="form-group col-md-4 col-lg-4">
                                        <label class="form-check-label label-alone">
                                            Souhaitez-vous masquer cette information à la communauté ?
                                        </label>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_mobilite_hide_authent_y">
                                                <input class="form-check-input" type="radio"
                                                       name="field_mobilite_hide_authent"
                                                       id="field_mobilite_hide_authent_y"
                                                       value="1"
                                                       [checked]="agent.field_mobilite_hide_authent"
                                                       (click)="agent.field_mobilite_hide_authent=1">
                                                Oui
                                            </label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_mobilite_hide_authent_n">
                                                <input class="form-check-input" type="radio"
                                                       name="field_mobilite_hide_authent"
                                                       id="field_mobilite_hide_authent_n"
                                                       value="0"
                                                       [checked]="!agent.field_mobilite_hide_authent"
                                                       (click)="agent.field_mobilite_hide_authent=0">
                                                Non
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-4 col-lg-4">
                                        <label class="form-check-label label-alone">
                                            Souhaitez-vous masquer cette information au grand public ?
                                        </label>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_mobilite_hide_anonym_y">
                                                <input class="form-check-input" type="radio"
                                                       name="field_mobilite_hide_anonym"
                                                       id="field_mobilite_hide_anonym_y"
                                                       value="1"
                                                       [checked]="agent.field_mobilite_hide_anonym"
                                                       (click)="agent.field_mobilite_hide_anonym=1">
                                                Oui
                                            </label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label" for="field_mobilite_hide_anonym_n">
                                                <input class="form-check-input" type="radio"
                                                       name="field_mobilite_hide_anonym"
                                                       id="field_mobilite_hide_anonym_n"
                                                       value="0"
                                                       [checked]="!agent.field_mobilite_hide_anonym"
                                                       (click)="agent.field_mobilite_hide_anonym=0">
                                                Non
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <button type="button"
                                                class="arrow-btn btn btn-primary text-uppercase"
                                                [disabled]="isSubmitting"
                                                (click)="agent.field_mobilite_actif == '1' ? confirmBeforeSubmit(agentMobiliteUpdateForm) : onMobiliteSubmit(agentMobiliteUpdateForm)">
                                            Valider
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                      </li>
                    <!-- end: AGENT TAB / MOBILITY -->

                    <!-- begin: AGENT TAB / SKILLS -->
                    <li [ngbNavItem]="'skills'" *hasRoleAgent="accessState">
                        <a ngbNavLink>Compétences</a>
                        <ng-template ngbNavContent>
                            <form class="agent-competences-update-form" *ngIf="!fieldError"
                                  #agentCompetencesUpdateForm="ngForm"
                                  (ngSubmit)="onCompetencesSubmit(agentCompetencesUpdateForm)">
                                <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                        <h2 class="bordered-title">Compétences</h2>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-8">
                                        <label>Vos compétences</label>
                                        <angular2-multiselect [data]="competencesList"
                                            [(ngModel)]="fieldCompetencesIds"
                                            [ngModelOptions]="{standalone: true}"
                                            [settings]="settingsMulti">
                                            <c-badge>
                                              <ng-template let-item="item">
                                                <label>{{item.label ? item.label : item.name}}</label>
                                              </ng-template>
                                            </c-badge>
                                        </angular2-multiselect>
                                    </div>

                                    <div class="form-group col-md-12">
                                        <button type="submit" class="arrow-btn btn btn-primary text-uppercase">
                                            Mettre à jour mes compétences
                                        </button>
                                    </div>

                                </div>
                            </form>
                        </ng-template>
                      </li>
                    <!-- end: AGENT TAB / SKILLS -->

                    </ul>

                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </ng-container>
        <!-- end: AGENT ACCORDION -->

    </div>
    <!-- end: PAGE CONTENT -->
</div>
