import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';
import {SessionStorageService} from 'ngx-webstorage';

@Injectable()
export class TextSizeService {
    public cssClass: Subject<string> = new BehaviorSubject<string>('accessibility-text-rem-1-4');
    private currentClass: string;
    private classes = [
        'accessibility-text-rem-1-4',
        'accessibility-text-rem-1-5',
        'accessibility-text-rem-1-6',
        'accessibility-text-rem-1-7',
        'accessibility-text-rem-1-8',
        'accessibility-text-rem-1-9',
        'accessibility-text-rem-2-0',
    ];

    constructor(private $sessionStorage: SessionStorageService) {
        this.currentClass = $sessionStorage.retrieve('text-size');
        if (!this.currentClass) {
            this.currentClass = 'accessibility-text-rem-1-4';
        }
        this.cssClass.next(this.currentClass);
    }

    private getCurrentIndex(): number {
        let result = 1;
        this.classes.forEach((elt, index) => {
            if (elt === this.currentClass) {
                result = index;
            }
        });
        return result;
    }

    public increase() {
        let index = this.getCurrentIndex();
        index = index + 1;
        if (index >= this.classes.length) {
            index = this.classes.length - 1;
        }
        this.currentClass = this.classes[index];
        this.$sessionStorage.store('text-size', this.currentClass);
        this.cssClass.next(this.currentClass);
    }

    public decrease() {
        let index = this.getCurrentIndex();
        index = index - 1;
        if (index < 0) {
            index = 0;
        }
        this.currentClass = this.classes[index];
        this.$sessionStorage.store('text-size', this.currentClass);
        this.cssClass.next(this.currentClass);
    }
}
