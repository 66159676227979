import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../shared/shared.module';

import {DveDetailComponent} from './dve-detail/dve-detail.component';
import {DveTeaserComponent} from './dve-teaser/dve-teaser.component';
import {dveRoutes} from './dve-routing.module';
import {DveService} from './dve.service';
import {DveResolve} from './dve.resolve';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(dveRoutes, { relativeLinkResolution: 'legacy' }),
        SharedModule,
        NgbModule
    ],
    providers: [
        DveService,
        DveResolve
    ],
    declarations: [
        DveDetailComponent,
        DveTeaserComponent
    ],
    exports: [
        DveDetailComponent,
        DveTeaserComponent

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DveModule {
}
