<div class="alc-job-elt">
    <h3 class="title-secondary  text-uppercase">Référent</h3>
    <ul class="list-unstyled aed-label-value">
        <li>
            <span class="alv-value">{{ poste.label }}</span>
        </li>
        <li>
            <span class="alv-label">{{ poste.metier_referentiel }}</span>
        </li>
        <li>
            <app-periode [start]="poste.debut" [end]="poste.fin"></app-periode>
        </li>
    </ul>
</div>