import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {NewsService} from './news.service';

@Injectable()
export class NewsResolve implements Resolve<any> {
    constructor(private newsService: NewsService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const id = route.paramMap.get('id');
        const page = route.paramMap.get('page');

        if (!(id === null || id === undefined)) {
            return this.newsService.getById(id);
        } else if (!(page === null || page === undefined)) {
            return this.newsService.getByPagination(page);
        } else {
            return this.newsService.getByPagination('1');
        }
    }
}
