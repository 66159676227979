<a *ngIf="agent !== null && agent !== undefined" [routerLink]="['/agent', agent.id]">
    <div class="row">
        <div class="col-md-4 col-lg-4">
            <div class="alc-elt-thumb">
                <app-avatar [userDisplayName]="agent.label"
                            [userPhoto]="agent.photo_url"
                            [userTitle]="agent.field_civilite">
                </app-avatar>
            </div>
        </div>
        <div class="col-md-8 col-lg-8">
            <div class="alc-elt-infos">
                <h3 class="title-secondary text-uppercase">{{ agent.label }}</h3>
                <ul class="list-unstyled aed-label-value">
                    <li>
                        <span class="alv-value">{{ agent.field_grade && agent.field_grade.label ? agent.field_grade.label : agent.field_cadre_emploi?.label }}</span>
                    </li>
                    <li class="aed-label-value-iconic" *ngIf="agent.field_telephone != null">
                        <span class="alv-label aed-icon aed-icon-phone" aria-hidden="true"></span>
                        <span class="alv-value">{{ agent.field_telephone }}</span>
                    </li>
                    <li class="aed-label-value-iconic" *ngIf="agent.field_mobile != null">
                        <span class="alv-label aed-icon aed-icon-smartphone" aria-hidden="true"></span>
                        <span class="alv-value">{{ agent.field_mobile }}</span>
                    </li>
                    <li class="aed-label-value-iconic" *ngIf="agent.mail != null">
                        <span class="alv-label aed-icon aed-icon-email" aria-hidden="true"></span>
                        <span class="alv-value"><a href="mailto:{{ agent.mail }}">{{ agent.mail }}</a></span>
                    </li>
                    <li *ngIf="showPeriode && experience !== undefined">
                        <app-periode [start]="experience.debut" [end]="experience.fin"></app-periode>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</a>
