import {Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS, Validator, AbstractControl, Validators} from '@angular/forms';


const TWITTER_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => TwitterValidatorDirective),
    multi: true
};

@Directive({
    selector: '[twitter][formControlName],[twitter][formControl],[twitter][ngModel]',
    providers: [TWITTER_VALIDATOR]
})
export class TwitterValidatorDirective implements Validator {

    validate(c: AbstractControl): { [key: string]: any } {
        let v: string = c.value;

        if ((v === null || v === undefined) || v.trim() == '') {
            return null;
        }
        const regexp = new RegExp('^http(?:s)?:\\/\\/(?:www\\.)?twitter\\.com\\/([a-zA-Z0-9_]+)$');
        return regexp.test(v) ? null : {twitter: true};
    }
}
