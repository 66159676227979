<div class="row">

    <div class="alc-job-elt col-md-6"
         *ngIf="experience && (experience.type.id === 'exp_eleve_inet' || experience.type.id === 'exp_autre_affectation' || experience.type.id === 'exp_sans_emploi' || experience.type.id === 'exp_liste_apt')">
        <h3 class="title-secondary  text-uppercase">{{ experience.type.label }}</h3>
        <ul class="list-unstyled aed-label-value">
            <li>
                <app-periode [start]="experience.debut" [end]="experience.fin"></app-periode>
            </li>
        </ul>
    </div>

    <div class="alc-job-elt col-md-6" *ngIf="experience && (experience.type.id === 'exp_hors_fpt')">
        <h3 class="title-secondary  text-uppercase">{{experience.field_exp_hfpt_employeur}}</h3>
        <ul class="list-unstyled aed-label-value">
            <li *ngIf="experience.field_exp_fpt_commune">
                <span class="alv-label">Ville :</span>
                <span class="alv-value">{{experience.field_exp_fpt_commune.libcom}}</span>
            </li>
            <li *ngIf="experience.field_exp_hfpt_type_empl">
                <span class="alv-label">Type :</span>
                <span class="alv-value">{{experience.field_exp_hfpt_type_empl.label}}</span>
            </li>
            <li>
                <app-periode [start]="experience.debut" [end]="experience.fin"></app-periode>
            </li>
        </ul>
    </div>

    <div class="alc-job-elt col-md-6" *ngIf="!experience">
        <h3 class="title-secondary  text-uppercase">Aucune information d'affectation pour l'instant</h3>
    </div>


    <div class="alc-agent-elt col-md-6" *ngIf="!experience || !experience.user_id">
        <div class="row">
            <div class="col-md-12 col-lg-12 ct-no-job-infos empty-block">
                <div class="inner">
                    <p>Inconnu</p>
                </div>
            </div>
        </div>
    </div>
    <div class="alc-agent-elt col-md-6" *ngIf="experience && experience.user_id">
        <a [routerLink]="['/agent', experience.user_id.id]">
            <div class="row">
                <div class="col-md-4 col-lg-4">
                    <div class="alc-elt-thumb">
                        <app-avatar [userDisplayName]="experience.user_id.label"
                                    [userPhoto]="experience.user_id.photo_url"
                                    [userTitle]="experience.user_id.field_civilite"></app-avatar>
                    </div>
                </div>
                <div class="col-md-8 col-lg-8">
                    <div class="alc-elt-infos">
                        <h3 class="title-secondary text-uppercase">{{experience.user_id.label }}</h3>
                        <ul class="list-unstyled aed-label-value">
                            <li>
                                <span class="alv-value">{{ experience.user_id.field_grade ? experience.user_id.field_grade.label : experience.user_id.field_cadre_emploi?.label }}</span>
                            </li>
                            <li class="aed-label-value-iconic"
                                *ngIf="experience.user_id.field_telephone != null">
                                <span class="alv-label aed-icon aed-icon-phone" aria-hidden="true"></span>
                                <span class="alv-value">{{ experience.user_id.field_telephone }}</span>
                            </li>
                            <li class="aed-label-value-iconic"
                                *ngIf="experience.user_id.field_mobile != null">
                                <span class="alv-label aed-icon aed-icon-smartphone" aria-hidden="true"></span>
                                <span class="alv-value">{{ experience.user_id.field_mobile }}</span>
                            </li>
                            <li class="aed-label-value-iconic"
                                *ngIf="experience.user_id.mail != null">
                                <span class="alv-label aed-icon aed-icon-email" aria-hidden="true"></span>
                                <span class="alv-value"><a
                                        href="mailto:{{ experience.user_id.mail }}">{{ experience.user_id.mail }}</a></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>