<div class="container" id="login-required">
    <div class="card" *ngIf="tooManyRedirect === false">
        <div class="card-body" *ngIf="tooManyRedirect === false">
            <h4 class="card-title">Il est nécessaire de vous connecter pour continuer.<br/>Vous allez être redirigé vers le
                formulaire de Mon Compte CNFPT.</h4>
            <ngb-progressbar type="info" [value]="progressLevel" [striped]="false"></ngb-progressbar>
            <br/>
            <button (click)="fastLogin()" class="btn btn-primary">Me rendre sans attendre sur Mon Compte CNFPT</button>
        </div>
    </div>

    <ng-container *ngIf="tooManyRedirect === true">
        <div class="alert alert-warning">
            <p class="lead">Nous rencontrons des difficultés à vous authentifier. Merci de vous <a href="/login">connecter de nouveau</a>.</p>
        </div>
    </ng-container>
</div>
