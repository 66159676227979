import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchComponent} from './search/search.component';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {annuaireRoutes} from './annuaire-routing.module';
import {AnnuaireService} from './annuaire.service';
import {CollectiviteModule} from '../collectivite/collectivite.module';
import {EmploiModule} from '../emploi/emploi.module';
import {SearchEmploiAgentResolve} from './search-emploi-agent.resolve';
import {SearchPlaceResolve} from './search-place.resolve';
import {AgentModule} from '../agent/agent.module';
import {SearchDataService} from '../shared/search-data/search-data.service';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(
            annuaireRoutes
        ),
        SharedModule,
        NgbTypeaheadModule,
        AngularMultiSelectModule,
        EmploiModule,
        AgentModule,
        CollectiviteModule
    ],
    providers: [
        AnnuaireService,
        SearchEmploiAgentResolve,
        SearchPlaceResolve,
        SearchDataService
    ],
    declarations: [SearchComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AnnuaireModule {
}
