import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AgentService } from '../../../../agent/agent.service';
import { ConfirmDialogComponent } from '../../../..//shared/confirm-dialog/confirm-dialog.component';
import { LoaderService } from '../../../../shared/loader/loader.service';

@Component({
  selector: 'profil-utilisateur-mobilite',
  templateUrl: './mobilite.component.html',
  styleUrls: ['./mobilite.component.scss']
})
export class MobiliteComponent implements OnInit {

  @Input() agent: any;
  @Output() agentChange = new EventEmitter<any>();

  @Input() success: boolean;
  @Output() successChange = new EventEmitter<boolean>();

  cotationsList: any[];
  regionsList: any[];
  famillesList: any[];
  fieldMobiliteZoneIds: any = [];
  fieldMobiliteDomaineIds: any = [];
  fieldMobiliteFonctionIds: any = [];

  /**
  * Multiselect field settings
  */
  public settings = {
    singleSelection: false,
    text: '-',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout désélectionner',
    searchPlaceholderText: 'Rechercher',
    enableSearchFilter: true,
    labelKey: 'name',
    primaryKey: 'id',
  };

  constructor(
    private agentService: AgentService,
    private loader: LoaderService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.extractIdsForMultiSelect();
    this.getFormDatas();
  }

  ngOnChanges() {
    this.extractIdsForMultiSelect();
    this.getFormDatas();
  }

  /**
   * Get the requested agent for edition
   */
  public getFormDatas(): void {
      if (this.agent != null) {
          this.agentService.getFormDatas(this.agent.id)
              .subscribe((data) => {
                  this.cotationsList = data.cotations;
                  this.regionsList = data.regions;
                  this.famillesList = data.familles;
                  this.loader.hide();
              }, () => {
                  this.loader.hide();
              });
      }
  }

  public extractIdsForMultiSelect() {
      if (this.agent.field_mobilite_zone) {
          this.fieldMobiliteZoneIds = this.agent.field_mobilite_zone;
      }
      if (this.agent.field_mobilite_domaine) {
          this.fieldMobiliteDomaineIds = this.agent.field_mobilite_domaine;
      }
      if (this.agent.field_mobilite_fonction) {
          this.fieldMobiliteFonctionIds = this.agent.field_mobilite_fonction;
      }
  }

  public confirmBeforeSubmit(submittedForm: NgForm) {
    let modalRef: NgbModalRef;
    modalRef = this.modalService.open(ConfirmDialogComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'Confirmer votre saisie';

    const messages = ['Vous souhaitez vous déclarer en mobilité dans l’annuaire.'];
    if (this.agent.field_mobilite_cnfpt) {
      messages.push('Vous allez solliciter l’accompagnement du CNFPT dans votre recherche de mobilité.');
    }
    if (!this.agent.field_mobilite_hide_authent) {
      messages.push('Vous ne souhaitez pas masquer aux membres de l’annuaire votre souhait de mobilité.');
    }
    if (!this.agent.field_mobilite_hide_anonym) {
      messages.push('Vous ne souhaitez pas masquer au grand public votre souhait de mobilité.');
    }
    modalRef.componentInstance.messages = messages;

    modalRef.result.then(
      (result) => {
        if (result === true) {
            this.onSubmit(submittedForm);
        }
      }
    );
  }

  /**
   * Send the mobilité form data to backend
   * @param {NgForm} submittedForm
   */
  public onSubmit(submittedForm: NgForm) {
      this.loader.show();
      const data = submittedForm.value;
      data['id'] = this.agent.id;
      data['field_mobilite_actif'] = this.agent.field_mobilite_actif;
      data['field_mobilite_cnfpt'] = this.agent.field_mobilite_cnfpt;
      data['field_mobilite_hide_authent'] = this.agent.field_mobilite_hide_authent;
      data['field_mobilite_hide_anonym'] = this.agent.field_mobilite_hide_anonym;
      data['field_mobilite_zone'] = this.fieldMobiliteZoneIds.map(x => x.id);
      data['field_mobilite_domaine'] = this.fieldMobiliteDomaineIds.map(x => x.id);
      data['field_mobilite_fonction'] = this.fieldMobiliteFonctionIds.map(x => x.id);
      this.agentService.updateAgent(data, null, null).subscribe(
          (value) => {
              this.loader.hide();
              this.agentChange.emit(value);
              this.successChange.emit(true);
          },
          () => {
              this.loader.hide();
              this.successChange.emit(false);
          }
      );
  }
}
