
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';


import {HttpClient, HttpHeaders} from '@angular/common/http';
import {getDrupalUri} from '../shared/external';

@Injectable()
export class CollectiviteService {

  private apiPath = '/api/collectivite';
  private customapiPath = '/customapi/collectivite';
  private getAllPath = '/customapi/collectivites';
    private filePath = '/customapi/files';

    constructor(private http: HttpClient) {
    }

    /**
     *
     * @param id
     * @returns {Observable<any>}
     */
    public get(id: number): Observable<any> {
        return this.getWithRelationships(id, []);
    }

    /**
     * Get the collectivite with its relationships
     * @param {number} id
     * @param {string[]} relationships
     * @returns {Observable<any>}
     */
    public getWithRelationships(id: number, relationships: string[]) {
        let url = getDrupalUri() + this.apiPath + '/' + id + '?_format=json';
        for (const rel of relationships) {
            url = url + '&_rel[]=' + rel;
        }
        return this.http.get<any>(url).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     * Get all collectivites of the current user
     */
    public getAllForUser(page: number, itemsPerPage: number, fulltextQuery: string) {

      let params = '?';
      params = this.addParamToQuery(params, 'page', page, 1, true);
      params = this.addParamToQuery(params, 'itemsPerPage', itemsPerPage, 50, true);
      if (fulltextQuery !== "") {
        params = this.addParamToQuery(params, 'fulltextQuery', fulltextQuery, "", true);
      }
      params = this.addParamToQuery(params, '_format', null, 'json', false);

      let url = getDrupalUri() + this.getAllPath + params;
      return this.http.get<any>(url).pipe(
          map(
              (value) => {
                  return value;
              }
          ));
    }

    private addParamToQuery(query: string, param: string, value: any, backup: any, hasNext: boolean) {
      const finalValue = (value === null || value === undefined) ? backup : value;
      query += param + "=" + finalValue;
      if (hasNext) {
        query += '&';
      }
      return query;
    }

    /**
     *
     * @param id
     * @returns {Observable<any>}
     */
    public getWithEmplois(id: any): Observable<any> {
        return this.getWithRelationships(id, ['emplois']);
    }

    /**
     *
     * @param id
     * @returns {Observable<any>}
     */
    public getWithOffres(id: any): Observable<any> {
        return this.getWithRelationships(id, ['offres']);
    }

    /**
     *
     * @param id
     * @returns {Observable<any>}
     */
    public getWithDves(id: any): Observable<any> {
        return this.getWithRelationships(id, ['dves']);
    }

    /**
     *
     * @param message
     * @returns {Observable<any>}
     */
    public update(id: number, csrf: string): Observable<any> {
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'X-CSRF-Token': csrf});
        return this.http.post<any>(
            getDrupalUri() + this.apiPath + '?_format=json', id, {headers: headers}).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     *
     * @param message
     * @returns {Observable<any>}
     */
    public disable(id: number, csrf: string): Observable<any> {
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'X-CSRF-Token': csrf});
        return this.http.post<any>(
            getDrupalUri() + this.customapiPath + '/disable' + '?_format=json', {'id': id}, {headers: headers}).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     *
     * @param agent
     * @returns {Observable<any>}
     */
    public uploadFile(id: any, file: any, field: string): Observable<any> {
        const fd = new FormData();
        fd.append('data', JSON.stringify({id: id}));
        fd.append(field, file, file.name);

        return this.http.post<any>(
            getDrupalUri() + this.filePath + '/' + field + '/' + id, fd).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    public removeFile(id: any, field: string): Observable<any> {
        return this.http.delete<any>(
            getDrupalUri() + this.filePath + '/' + field + '/' + id).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     *
     * @param message
     * @returns {Observable<any>}
     */
    public notifyError(message: any): Observable<any> {
        const headers = new Headers();

        return this.http.post<any>(
            getDrupalUri() + this.apiPath + 'notifyerror', message).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }
}
