
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getDrupalUri} from '../shared/external';
import { Observable } from 'rxjs';


import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class ReferentService {

    private resourcePath = '/api/poste_referent';

    constructor(private http: HttpClient) {
    }

    /**
     * Create a poste referent
     * @param data
     * @returns {Observable<any>}
     */
    public create(data: any, csrfToken: string): Observable<any> {
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken});
        return this.http.post<any>(
            getDrupalUri() + this.resourcePath + '?_format=json', data, {headers: headers}).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    /**
     * Remove a poste referent
     * @param datas
     * @returns {Observable<any>}
     */
    public delete(id: any, csrfToken: string): Observable<any> {
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken});
        return this.http.delete<any>(getDrupalUri() + this.resourcePath + '/' + id + '?_format=json', {headers: headers}).pipe(map(
            (result) => {
                return result;
            }
        ));
    }
}
