<div class="modal-header">
  <h2 class="bordered-title bolded">
      Modification d'une expérience
  </h2>

  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span class="aed-icon aed-icon-close" aria-hidden="true"></span>
    <span class="sr-only" aria-hidden="true">Fermer</span>
  </button>
</div>
<div class="modal-body">
  <div class="inner">
    <form class="add-emploi-form" #experienceForm="ngForm" (ngSubmit)="onSubmit(experienceForm)" novalidate>

      <div class="row">
        <div class="form-group col-md-5 month-selector">
          <label for="debut_month">
            Date de prise de poste
            <span class="required-field">(obligatoire)</span>
          </label>
          <div>
            <select id="debut_month" class="form-control" name="debut_mois"
                    [(ngModel)]="dateDebut.month" #debut_month="ngModel" (change)="validateTimePeriod()" required>
              <option value="01">Janvier</option>
              <option value="02">Février</option>
              <option value="03">Mars</option>
              <option value="04">Avril</option>
              <option value="05">Mai</option>
              <option value="06">Juin</option>
              <option value="07">Juillet</option>
              <option value="08">Août</option>
              <option value="09">Septembre</option>
              <option value="10">Octobre</option>
              <option value="11">Novembre</option>
              <option value="12">Décembre</option>
            </select>
            <select id="debut_year" class="form-control" name="debut_annee"
                    [(ngModel)]="dateDebut.year" #debut_year="ngModel" (change)="validateTimePeriod()" required>
              <option [value]="dr40Date" *ngFor="let dr40Date of datesRange40">{{ dr40Date }}
              </option>
            </select>
            <div *ngIf="(debut_month?.errors && debut_month?.invalid && (debut_month?.dirty || debut_month?.touched)) || (debut_year?.errors && debut_year?.invalid && (debut_year?.dirty || debut_year?.touched))"
                 class="form-error alert alert-danger">
              <small *ngIf="debut_month?.errors?.required">
                Le champ mois est requis.
              </small>
              <small *ngIf="debut_year?.errors?.required">
                Le champ année est requis.
              </small>
            </div>
          </div>
        </div>

        <div class="form-group col-md-2" id="ongoing-container">
          <label class="form-check-label" for="ongoing">
            <input class="form-check-input" type="checkbox" name="ongoing" id="ongoing"
                   [(ngModel)]="experience.ongoing" #ongoing="ngModel" (change)="validateTimePeriod()">
            Poste actuel
          </label>
        </div>

        <div class="form-group col-md-5 month-selector" *ngIf="!experience.ongoing">
          <label for="fin_month">
            Date de fin du poste
            <span class="required-field">(obligatoire)</span>
          </label>
          <div>
            <select id="fin_month" class="form-control" name="fin_mois"
                    [(ngModel)]="dateFin.month" #fin_month="ngModel" (change)="validateTimePeriod()" required>
              <option value="01">Janvier</option>
              <option value="02">Février</option>
              <option value="03">Mars</option>
              <option value="04">Avril</option>
              <option value="05">Mai</option>
              <option value="06">Juin</option>
              <option value="07">Juillet</option>
              <option value="08">Août</option>
              <option value="09">Septembre</option>
              <option value="10">Octobre</option>
              <option value="11">Novembre</option>
              <option value="12">Décembre</option>
            </select>
            <select id="fin_year" class="form-control" name="fin_annee"
                    [(ngModel)]="dateFin.year" #fin_year="ngModel" (change)="validateTimePeriod()" required>
              <option [value]="dr40Date" *ngFor="let dr40Date of datesRange40">{{ dr40Date }}
              </option>
            </select>
            <div *ngIf="(fin_month?.errors && fin_month?.invalid && (fin_month?.dirty || fin_month?.touched)) || (fin_year?.errors && fin_year?.invalid && (fin_year?.dirty || fin_year?.touched)) || invalidTimePeriod"
                 class="form-error alert alert-danger">
              <small *ngIf="fin_month?.errors?.required">
                Le champ mois est requis.
              </small>
              <small *ngIf="fin_year?.errors?.required">
                Le champ année est requis.
              </small>
              <small *ngIf="invalidTimePeriod">
                La date de fin doit être postérieure ou égale à la date de début
              </small>
            </div>
          </div>
        </div>

        <div class="form-group col-md-6 col-lg-6" *ngIf="isExperienceFpt">
          <label for="fonction">Fonction <span class="required-field">(obligatoire)</span></label>
          <input type="text" class="form-control" id="fonction" name="fonction" [(ngModel)]="experience.fonction" #fonction="ngModel" required>
          <div *ngIf="fonction?.errors && fonction?.invalid && (fonction?.dirty || fonction?.touched)" class="form-error alert alert-danger">
              <small *ngIf="fonction?.errors?.required">
                  Ce champ est requis.
              </small>
          </div>
        </div>

        <div class="form-group col-md-6 col-lg-6" *ngIf="isHFPT === false && (isOwner || experience.isReferent)">
          <label class="form-check-label label-alone">
            Souhaitez-vous masquer vos experiences ?
          </label>

          <div class="form-check form-check-inline">
            <label class="form-check-label" for="hidden_y">
              <input class="form-check-input" type="radio"
                     name="hidden"
                     id="hidden_y"
                     [(ngModel)]="experience.hidden"
                     #hidden="ngModel"
                     value="1">
              Oui
            </label>
          </div>

          <div class="form-check form-check-inline">
            <label class="form-check-label" for="hidden_n">
              <input class="form-check-input" type="radio"
                     name="hidden"
                     id="hidden_n"
                     [(ngModel)]="experience.hidden"
                     #hidden="ngModel"
                     value="0">
              Non
            </label>
          </div>
        </div>
      </div>
      <!-- end: PAGE CONTENT / EMPLOYER -->


      <div class="row">
        <div class="col-md-12 col-lg-12">
          <button type="submit"
                  class="arrow-btn btn btn-primary text-uppercase"
                  [disabled]="experienceForm.invalid || invalidTimePeriod">
            Valider
          </button>
          <button type="button" id="delete-button"
                  class="arrow-btn btn btn-danger text-uppercase float-right" (click)="openDeleteDialog()"
                  *ngIf="experience?.id">
            Supprimer
          </button>
          <ng-container *hasRoleReferentCT="accessState">
            <ng-container *ngIf="experience?.id && experience?.is_valid">
              <button type="button" class="arrow-btn btn btn-danger text-uppercase float-right" (click)="changeExperienceValidity()">
                <span>Désactiver</span>
              </button>
            </ng-container>
            <ng-container *ngIf="experience?.id && !experience?.is_valid">
              <button type="button" class="arrow-btn btn btn-success text-uppercase float-right" (click)="changeExperienceValidity()">
                <span>Activer</span>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
</div>
