import {Component, OnInit} from '@angular/core';
import {AuthentificationService} from '../authentification.service';
import {getRegistrationUrl, getSsoBaseUrl} from '../../shared/external';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginAvailable = false;
    public displayName = null;

    constructor(private authentificationService: AuthentificationService) {
    }

    ngOnInit() {
        this.authentificationService.isReady.subscribe(
            (value) => {
                this.loginAvailable = value;
            }
        );
        this.authentificationService.identity.subscribe(
            (identity) => {
                if (identity == null) {
                    this.displayName = null;
                } else {
                    this.displayName = identity.displayname;
                }
            },
            () => {
                this.displayName = null;
            }
        );
    }

    login() {
        this.authentificationService.login();
    }

    createAccount() {
        window.location.href = getRegistrationUrl();
    }

    logout() {
        this.authentificationService.logout();
    }

    toRemove() {
        this.authentificationService.silentRefresh();
        return false;
    }
}
