import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from "rxjs";

@Injectable()
export class SearchPlaceResolve implements Resolve<any> {
    constructor() {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const colQuery = route.queryParams.col;
        const colId = route.queryParams.colid;
        if ((colQuery === null || colQuery === undefined) && (colId === null || colId === undefined)) {
            return null;
        }
        return of({label: colQuery, id: colId});
    }
}
