
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';


import {HttpClient} from '@angular/common/http';
import {getDrupalUri} from '../shared/external';

@Injectable()
export class AnnuaireService {

    readonly customApiPath: string = '/customapi';

    constructor(private http: HttpClient) {
    }

    /**
     * search
     *
     * @param {string} agent
     * @param {string} emploi
     * @param {string} place
     * @param {string} departements
     * @param {string} cjs
     * @param {string} tcds
     * @param {string} cotations
     * @param {string} filieres
     * @param {string} domaines
     * @param {number} page
     * @returns {any}
     */
    search(agent: string,
           emploi: string,
           place: string,
           departements: string,
           cjs: string,
           tcds: string,
           cotations: string,
           filieres: string,
           domaines: string,
           page: number,
           order: number): any {
        if ((departements == null || departements.length === 0)
            && agent == null
            && emploi == null
            && place == null
            && (cjs == null || cjs.length === 0)
            && (tcds == null || tcds.length === 0)
            && (cotations == null || cotations.length === 0)
            && (filieres == null || filieres.length === 0)
            && (domaines == null || domaines.length === 0)) {
            return of([]);
        }

        const query = [];

        query.push(this.getQueryStringForParam(departements, 'dep[]'));
        query.push(this.getQueryStringForParam(tcds, 'tcd[]'));
        query.push(this.getQueryStringForParam(cotations, 'cot[]'));
        query.push(this.getQueryStringForParam(filieres, 'fil[]'));
        query.push(this.getQueryStringForParam(domaines, 'dom[]'));
        query.push(this.getQueryStringForParam(cjs, 'cj[]'));
        if (!(agent === null || agent === undefined) && '' !== agent) {
            query.push(this.getQueryStringForParam(agent, 'agent'));
        }
        if (!(emploi === null || emploi === undefined) && '' !== emploi) {
            query.push(this.getQueryStringForParam(emploi, 'emploi'));
        }
        if (!(place === null || place === undefined) && '' !== place) {
            query.push(this.getQueryStringForParam(place, 'place'));
        }

        const queryStr = (query.filter(x => x != null) as string[]).join('&');

        return this.http.get<any>(
            getDrupalUri() + this.customApiPath + '/search/annuaire?page=' + page + '&sort=' + order + '&' + queryStr).pipe(map(
            (emplois) => {
                return emplois;
            }
        ));
    }

    /**
     * Build the query string width form params
     * @param param
     * @param {string} key
     * @returns {string}
     */
    private getQueryStringForParam(param: any, key: string) {
        if (param == null) {
            return null;
        }

        if (typeof param === 'string') {
            return key + '=' + encodeURIComponent(param);
        }

        if (param.length > 0) {
            const q = [];
            for (const item of param) {
                if (typeof item.indexOf !== 'function') {
                    for (const subItem of item) {
                        q.push(key + '=' + encodeURIComponent(subItem));
                    }
                } else if (item.indexOf(',') !== -1) {
                    for (const subItem of item.split(',')) {
                        q.push(key + '=' + encodeURIComponent(subItem));
                    }
                } else {
                    q.push(key + '=' + encodeURIComponent(item));
                }
            }
            return q.join('&');
        }
        return null;
    }

    /**
     * Return form datas to fill select tags
     *
     * @returns {Observable<any>}
     */
    public getSearchFormDatas(): Observable<any> {
        return this.http.get<any>(getDrupalUri() + this.customApiPath + '/search/referentiel').pipe(map(
            (val) => {
                return val;
            }
        ));
    }
}
