<app-breadcrumb></app-breadcrumb>
<div class="container" *ngIf="offre == null">
    <div class="row">
        <div class="col-md-12 col-lg-12 empty-block">
            <div class="inner">
                <p>
                    Aucune offre n'a été trouvée.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <!-- begin: PAGE HEADER -->
    <header class="page-title">
        <h1 class="text-uppercase bolded">
            {{view.title}}
        </h1>
    </header>
    <!-- end: PAGE HEADER -->

    <!-- begin: PAGE CONTENT / METAS -->
    <div class="page-metas clearfix">
        <nav>
            <div class="float-left">
                <ul class="list-unstyled">
                    <li>
                        <span class="aed-icon aed-icon-clock" aria-hidden="true"></span>
                        <span class="">
                            Date de publication :  <span class="bolded">{{view.datePublication}}</span>
                        </span>
                    </li>
                </ul>
            </div>
            <a class="clickable float-right" back-button>
                <span class="aed-icon aed-icon-back" aria-hidden="true"></span>
                <span class="pm-link-text plt-back">Retour</span>
            </a>
        </nav>
    </div>
    <!-- end: PAGE CONTENT / METAS -->

    <!-- begin: PAGE CONTENT -->
    <div id="content" class="offer-detail">

        <!-- begin: offre DETAIL  HEADER -->
        <div class="row">

            <div class="col-md-6 col-lg-6 offre-detail-contacts">
                <div class="card od-card">
                    <div class="card-body">
                        <h2 class="bordered-title bolded">Synthèse de l'offre</h2>
                        <div class="card-text">
                            <ul class="list-unstyled aed-label-value">
                                <li class="aed-label-value">
                                    <span class="alv-label">Numéro : </span>
                                    <span class="alv-value">
                                        {{view.numero}}
                                    </span>
                                </li>
                                <li class="aed-label-value mar-b-15">
                                    <span class="alv-label">Employeur : </span>
                                    <span class="alv-value">
                                        {{view.employeur}}
                                    </span>
                                </li>

                                <li class="aed-label-value">
                                    <span class="alv-label">Département de travail :</span>
                                    <span class="alv-value">
                                        {{view.departement}}
                                    </span>
                                </li>

                                <li class="aed-label-value">
                                    <span class="alv-label">Poste à pourvoir le :</span>
                                    <span class="alv-value">{{view.datePourvoir}}</span>
                                </li>

                                <li class="aed-label-value">
                                    <span class="alv-label">Date limite de candidature :</span>
                                    <span class="alv-value">{{view.dateCandidature}}</span>
                                </li>

                                <li class="aed-label-value">
                                    <span class="alv-label">Nombre de postes :</span>
                                    <span class="alv-value">{{view.nombrePoste}}</span>
                                </li>

                                <li class="top-separator">
                                    <p class="bubble-tags">
                                        Grade(s):
                                        <span class="bubble-tag" *ngFor="let grade of view.grades">
                                            {{grade.label}}
                                        </span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-6 offre-detail-contacts">
                <div class="card od-card">
                    <div class="card-body">
                        <h2 class="bordered-title bolded">Contact</h2>
                        <div class="card-text">
                            <ul class="list-unstyled aed-label-value">
                                <li class="aed-label-value-iconic" *ngIf="view.telephone">
                                    <span class="sr-only">Téléphone collectivité :</span>
                                    <span class="alv-label aed-icon aed-icon-phone" aria-hidden="true"></span>
                                    <span class="alv-value">{{view.telephone}}</span>
                                </li>

                                <li class="aed-label-value-iconic" *ngIf="view.email">
                                    <span class="sr-only">Adresse e-mail :</span>
                                    <span class="alv-label aed-icon aed-icon-email" aria-hidden="true"></span>
                                    <span class="alv-value">{{view.email}}</span>
                                </li>

                                <li class="aed-label-value-iconic" *ngIf="view.lien">
                                    <span class="sr-only">Lien de publication :</span>
                                    <span class="alv-label aed-icon aed-icon-worldwide" aria-hidden="true"></span>
                                    <span class="alv-value">{{view.lien}}</span>
                                </li>

                                <app-address [address]="view.adresse"></app-address>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- end: offre DETAIL HEADER -->

        <!-- begin: offre DETAIL ACCORDION -->
        <div class="aed-accordion mar-t-30">
            <ngb-accordion [closeOthers]="true">
                <ngb-panel id="offre-detail-infos">
                    <ng-template ngbPanelHeader let-opened="opened">
                      <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
                        Détails de l'offre
                      </button>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="row">
                            <div class="col-md-6 col-lg-6  mar-t-20">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item odd">
                                        <span class="lgi-label">Grade(s) recherché(s) :</span>
                                        <span class="lgi-value">
                                            <ul>
                                                <li *ngFor="let grade of view.grades">{{grade.label}}</li>
                                            </ul>
                                        </span>
                                    </li>
                                    <li class="list-group-item">
                                        <span class="lgi-label">Temps de travail :</span>
                                        <span class="lgi-value">{{view.typeTemps}}{{view.dureeHeure}}</span>
                                    </li>
                                </ul>
                            </div>

                            <div class="col-md-12 col-lg-12 od-desc-para mar-t-30">
                                <h3 class="bordered-title bolded">Descriptif de l'emploi</h3>
                                <div class="odp-para pre-line">{{view.descriptif}}</div>
                            </div>

                            <div class="col-md-12 col-lg-12 od-desc-para">
                                <h3 class="bordered-title bolded">Missions</h3>
                                <div class="odp-para pre-line">{{view.missions}}</div>
                            </div>

                            <div class="col-md-12 col-lg-12 od-desc-para">
                                <h3 class="bordered-title bolded">Profils demandés</h3>
                                <div class="odp-para pre-line">{{view.profils}}</div>

                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
        <!-- end: offre DETAIL ACCORDION -->

    </div>
    <!-- end: PAGE CONTENT -->
</div>
