<app-breadcrumb></app-breadcrumb>

<div class="container" *ngIf="agent == null">
    <div class="row">
        <div class="col-md-12 col-lg-12 empty-block">
            <div class="inner">
                <p>
                    L'agent n'est pas disponible.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="agent">
    <!-- begin: PAGE HEADER -->
    <header class="page-title">
        <h1 class="text-uppercase bolded" *ngIf="agent.label; else identifiedByUsername">
            {{ agent.label }}
        </h1>
        <ng-template #identifiedByUsername>
            <h1 class="text-uppercase bolded">
                Agent
            </h1>
        </ng-template>
        <a class="clickable float-right" back-button>
            <span class="aed-icon aed-icon-back" aria-hidden="true"></span>
            <span class="pm-link-text plt-back">Retour</span>
        </a>
    </header>
    <!-- end: PAGE HEADER -->

    <!-- begin: PAGE CONTENT -->
    <div id="content" class="agent-detail">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <app-alert *ngIf="notifySuccess === true"
                           [isAlertClosable]="true"
                           [alertType]="'success'"
                           [alertMessage]="'Votre message a été envoyé au référent de la collectivité'">
                </app-alert>

                <app-alert *ngIf="notifySuccess === false"
                           [isAlertClosable]="true"
                           [alertType]="'danger'"
                           [alertMessage]="'Impossible d\'envoyer votre message'">
                </app-alert>
            </div>
        </div>
        <!-- begin: AGENT HEADER -->
        <div class="row">
            <div class="col-md-12 col-lg-12 agent-detail-header">
                <div class="inner">
                    <div class="row">
                        <div class="col-md-8 col-lg-8 adh-thumb-infos">
                            <div class="row">
                                <div class="col-md-3 col-lg-3 adh-thumb">
                                    <app-avatar [userDisplayName]="agent.label" [userPhoto]="agent.photo_url"
                                                [userTitle]="agent.field_civilite"></app-avatar>
                                </div>

                                <div class="col-md-9 col-lg-9 adh-infos">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-12 adh-infos-title-link"
                                             *hasRoleAgentWithOwnership="accessState">
                                            <a class="adh-infos-link" [routerLink]="['/agent/edit/', agent.id]">
                                                <span class="alv-label aed-icon aed-icon-edit"
                                                      aria-hidden="true"></span>
                                                <span>Modifier l'agent</span>
                                            </a>
                                        </div>

                                        <div class="col-md-12 col-lg-12  adh-infos-details">
                                            <ul class="list-unstyled aed-label-value">
                                                <app-statut [agent]="agent"></app-statut>
                                                <li class="aed-label-value-iconic"
                                                    *ngIf="canSeeCoordonnees(agent, agent.field_telephone)">
                                                <span class="alv-label aed-icon aed-icon-phone"
                                                      aria-hidden="true"></span>
                                                    <span class="alv-value">{{ agent.field_telephone }}</span>
                                                </li>
                                                <li class="aed-label-value-iconic"
                                                    *ngIf="canSeeCoordonnees(agent, agent.field_mobile)">
                                                <span class="alv-label aed-icon aed-icon-smartphone"
                                                      aria-hidden="true"></span>
                                                    <span class="alv-value">{{ agent.field_mobile }}</span>
                                                </li>
                                                <li class="aed-label-value-iconic"
                                                    *ngIf="canSeeCoordonnees(agent, agent.mail)">
                                                <span class="alv-label aed-icon aed-icon-email"
                                                      aria-hidden="true"></span>
                                                    <span class="alv-value">
                                                        <a href="mailto:{{ agent.mail }}">{{ agent.mail }}</a>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 col-lg-3 adh-social">
                            <app-social-networks [owner]="agent"></app-social-networks>
                        </div>

                        <div class="col-md-10 col-lg-10  offset-md-2  offset-lg-2 adh-bio"
                             *ngIf="agent.field_presentation">
                            <div class="ab-inner">
                                <span class="aed-icon aed-icon-feather" aria-hidden="true"></span>
                                <div [innerHTML]="agent.field_presentation"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end: AGENT HEADER -->

        <!-- begin: AGENT ACCORDION -->
        <ng-container>
            <div class="aed-accordion">
                <ngb-accordion [closeOthers]="true" activeIds="panel-emploi">
                    <!-- begin: AGENT ACCORDION / JOBS -->
                    <ngb-panel id="panel-emploi" *ngIf="agent.experiences !== undefined">
                      <ng-template ngbPanelHeader let-opened="opened">
                        <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
                          Emplois actuels et passés
                        </button>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <div class="row aed-list-container">
                              <div [ngClass]="{ odd: odd, even: even, 'col-md-12 col-lg-12 alc-elt': true}"
                                    *ngFor="let job of agent.experiences; let even = even; let odd = odd;">
                                  <div class="inner clearfix">

                                      <div class="row" *ngIf="job?.type?.id !== undefined">
                                          <div class="col-md-4 col-lg-4">
                                              <app-fiche-ct-light *ngIf="job?.type?.id === 'exp_affectation_fpt'"
                                                                  [collectivite]="job?.field_exp_fpt_emploi.collectivite">
                                              </app-fiche-ct-light>
                                          </div>
                                          <app-fiche-emploi-light class="col-md-4 col-lg-4"
                                                                  [emploi]="job.type.id === 'exp_affectation_fpt' ? job.field_exp_fpt_emploi : null"
                                                                  [experience]="job"
                                                                  [showAgent]="false">
                                          </app-fiche-emploi-light>


                                          <ng-container *isLogged="accessState">
                                              <div class="col-md-4 col-lg-4 alc-actions-elt">
                                                  <nav class="alc-links">
                                                      <a class="arrow-btn btn btn-third btn-sm text-uppercase"
                                                          (click)="notifyError(job?.field_exp_fpt_emploi)"
                                                          *ngIf="job?.type?.id === 'exp_affectation_fpt'">
                                                          Signaler une erreur
                                                      </a>
                                                  </nav>
                                              </div>
                                          </ng-container>

                                          <ng-container *isLogged="accessState">
                                              <div class="col-md-12 col-lg-12 card-actions">
                                                  <a class="arrow-btn btn btn-primary btn-sm text-uppercase"
                                                      (click)="job?.field_exp_fpt_emploi.showHistory = !job?.field_exp_fpt_emploi?.showHistory"
                                                      *ngIf="job?.field_exp_fpt_emploi?.history.experience_history !== undefined && job?.field_exp_fpt_emploi?.history.experience_history.length > 0">
                                                      Voir l'historique des affectations
                                                  </a>
                                              </div>
                                              <app-historique-experience
                                                  class="col-md-12 col-lg-12"
                                                  [history]="job?.field_exp_fpt_emploi?.history.experience_history"
                                                  *ngIf="job?.field_exp_fpt_emploi?.showHistory">
                                              </app-historique-experience>
                                          </ng-container>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </ng-template>
                    </ngb-panel>
                    <!-- end: AGENT ACCORDION / JOBS -->

                    <!-- begin: AGENT ACCORDION / TRAININGS -->
                    <ngb-panel *ngIf="agent.field_formations">
                      <ng-template ngbPanelHeader let-opened="opened">
                        <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
                          Formations
                        </button>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <div class="row" *ngIf="agent.field_formations && agent.field_formations.length > 0">
                              <div class="agent-training-elt col-md-6 col-lg-6"
                                    *ngFor="let formation of agent.field_formations.slice(0, 4)">
                                  <app-formation [formation]="formation" [showVisibility]="false"></app-formation>
                              </div>
                          </div>
                      </ng-template>
                    </ngb-panel>
                    <!-- end: AGENT ACCORDION / TRAININGS -->

                    <!-- begin: AGENT ACCORDION / SKILLS -->
                    <ngb-panel *ngIf="agent.field_competences && agent.field_competences.length > 0">
                      <ng-template ngbPanelHeader let-opened="opened">
                        <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
                          Compétences
                        </button>
                      </ng-template>
                      <ng-template ngbPanelContent>
                          <ul class="list-unstyled items-list mar-t-20">
                              <li *ngFor="let competence of agent.field_competences">
                                  {{ competence.label }}
                              </li>
                          </ul>
                      </ng-template>
                    </ngb-panel>
                    <!-- end: AGENT ACCORDION / SKILLS -->

                    <!-- begin: AGENT ACCORDION / INFOS -->
                    <ngb-panel *ngIf="agent.field_facebook || agent.field_linkedin || agent.field_viadeo || agent.field_twitter || agent.field_centres_d_interet || agent.field_cv">
                      <ng-template ngbPanelHeader let-opened="opened">
                        <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
                          Informations complémentaires
                        </button>
                      </ng-template>
                      <ng-template ngbPanelContent>

                          <div class="card-elt clearfix mar-t-20"
                                *ngIf="agent.field_cv || agent.field_facebook || agent.field_twitter || agent.field_linkedin || agent.field_viadeo">
                              <div class="row">
                                  <div class="col-md-12 col-lg-12">
                                      <h2 class="bordered-title">Réseaux sociaux</h2>
                                  </div>
                                  <div class="col-md-12 col-lg-12 as-social">
                                      <app-social-networks [owner]="agent"></app-social-networks>
                                  </div>
                              </div>
                          </div>

                          <div class="card-elt clearfix" *ngIf="agent.field_centres_d_interet">
                              <div class="row">
                                  <div class="col-md-12 col-lg-12">
                                      <h2 class="bordered-title">Centres d'intérets</h2>
                                  </div>
                                  <div class="col-md-12 col-lg-12">
                                      <div [innerHTML]="agent.field_centres_d_interet"></div>
                                  </div>
                              </div>
                          </div>

                      </ng-template>
                    </ngb-panel>
                    <!-- end: AGENT ACCORDION / SKILLS -->
                </ngb-accordion>
            </div>
        </ng-container>
        <!-- end: AGENT ACCORDION -->

    </div>
    <!-- end: PAGE CONTENT -->
</div>
