<!-- begin : HOME MAP -->
<div class="aed-home-map d-none d-sm-block d-sm-none d-md-block">
    <div class="row">
        <div class="ahm-map col-md-6 col-lg-6">
            <div class="form-check">
                <label class="form-check-label">
                    <input #selectAllDepartmentsCbx id="selectAllDepartmentsCbx" class="form-check-input" type="checkbox" name="all-departments-trigger"
                           [checked]="allDepartments"
                           (change)="selectAllDepartments(selectAllDepartmentsCbx.checked)">
                    Tout sélectionner
                </label>
            </div>
            <app-loader-small *ngIf="!mapLoaded"></app-loader-small>
            <!--<ul>-->
                <!--<li *ngFor="let region of regions; let i = index">-->
                    <!--{{ region }}-->
                <!--</li>-->
            <!--</ul>-->
            <svg id="aed-map" height="650" version="1.1" width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 540 570">
                <svg:path
                        *ngFor="let department of departments; let i = index"
                        [attr.data-index]="i"
                        (click)="clickedDepartment(department)"
                        class="aed-map-elt"
                        [class.selected]="hasDepartment(department) || allDepartments"
                        [attr.d]="department.departmentPath"
                        style="position: relative; z-index: 2; stroke-linejoin: round; cursor: pointer;" fill="#d5d6d5" stroke="#f4f4f4" stroke-width="1" stroke-linejoin="round"
                        placement="bottom"
                        container="body"
                        [attr.data-region]="department.region"
                        [ngbTooltip]="department.departmentName"
                >
                </svg:path>

                <path
                        *ngFor="let region of regions; let i = index"
                        [attr.data-index]="i"
                        class="aed-map-region"
                        [attr.d]="region.regionPath"
                        style="stroke-linejoin: round; cursor: pointer;" fill="none" stroke="#484948" stroke-width="1" stroke-linejoin="round"
                        transform="translate(0,-40)"
                />

                <path
                        *ngFor="let region of regionsDomTom; let i = index"
                        [attr.data-index]="i"
                        class="aed-map-region"
                        [attr.d]="region.regionPath"
                        style="stroke-linejoin: round; cursor: pointer;" fill="none" stroke="#484948" stroke-width="1" stroke-linejoin="round"
                        transform="translate(0,0)"
                />
                <rect x='450.1' y='2.4' width='92.5' height='92.5' r='0' rx='0' ry='0' fill='none' stroke='#b8b8b8' style=''
                      stroke-width='1'></rect>
            </svg>

        </div>
        <div class="ahm-infos col-md-6 col-lg-6">
            <h2 class="bordered-title bolded">Cartographie des emplois de direction</h2>
            <div class="row">
                <div class="u-are-manager col-md-12 col-lg-6">
                    <h3 class="uppercased">Vous êtes un cadre de direction</h3>
                    <a [routerLink]="['contenu/vous-etes-un-cadre-de-direction']" class="btn btn-third iconic-btn">
                        <span class="aed-icon-user" aria-hidden="true"></span>
                        S'inscrire
                    </a>
                </div>
                <div class="u-are-recruiter col-md-12 col-lg-6">
                    <h3 class="uppercased">Vous êtes employeur</h3>
                    <a [routerLink]="['contenu/vous-etes-employeur']" class="btn btn-third iconic-btn">
                        <span class="aed-icon-family-tree" aria-hidden="true"></span>
                        Répertorier vos emplois
                    </a>
                </div>
                <div class="col-md-12 clearfix">
                    <div class="form-group-separator"></div>
                </div>
                <div class="ahm-selected col-md-12 col-lg-12">
                    <span class="as-number">
                        {{ selectDepartmentsJobsAmount }}
                    </span>
                    <span class="as-icon aed-icon aed-icon-briefcase add-margin-bottom" aria-hidden="true"></span>
                    <div class="as-label">
                        <span class="lighter">emplois de direction</span>
                        <span class="bolded">sélectionnés</span>
                    </div>
                </div>
                <div class="ahm-actions col-md-12 col-lg-12" *ngIf="selectDepartmentsJobsAmount > 0">
                    <button type="button" class="btn arrow-btn btn-primary" (click)="triggerSearch()">Voir les emplois</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end : HOME MAP -->
