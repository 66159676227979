
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {
    HttpInterceptor, HttpHandler, HttpRequest,
    HttpHeaderResponse, HttpProgressEvent, HttpResponse,
    HttpUserEvent, HttpSentEvent, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment';
import {getTokenDomain} from '../shared/external';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UnauthorizedDialogComponent} from '../shared/unauthorized-dialog/unauthorized-dialog.component';

@Injectable()
export class BearerHttpInterceptor implements HttpInterceptor {
    private domains: RegExp[];

    constructor(private oauthService: OAuthService,
                private router: Router,
                private modalService: NgbModal) {
        this.domains = [];
        for (const domain of getTokenDomain()) {
            const regex = new RegExp(domain, 'ig');
            this.domains.push(regex);
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent
        | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        if (this.oauthService.getAccessToken() !== null) {
            let match = false;
            this.domains.forEach((regex) => {
                match = match || req.url.match(regex) !== null;
            });
            const authReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + this.oauthService.getAccessToken()),
                withCredentials: true
            });

            return next.handle(authReq).pipe(tap((event: HttpEvent<any>) => {

            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.modalService.open(UnauthorizedDialogComponent);
                    }
                }
            }));
        } else {
            return next.handle(req);
        }
    }

}
