<ng-template #myTemplate let-r="result" let-t="term">
    {{r.label}}
</ng-template>
<div class="form-group">
    <label class="{{showLabel ? '' : 'sr-only'}}">{{text}}</label>
    <ng-container *ngIf="description !== undefined && description !== null && description.length">
        <br/>
        <i class="{{showLabel ? '' : 'sr-only'}}">{{description}}</i>
    </ng-container>
    <input type="text" name="search_input" class="form-control" id="searchInput"
           [(ngModel)]="selectedValue" [ngbTypeahead]="search" (ngModelChange)="exportValue($event)"
           [inputFormatter]="formatter"
           [resultTemplate]="myTemplate"
           placeholder="{{placeholder ? placeholder : (showLabel ? '' : text)}}"
           [required]="required"
           [disabled]="disabled"
           #searchInput
           #search_input="ngModel"
    >

    <div *ngIf="required && (search_input?.errors && search_input?.invalid && (search_input?.dirty || search_input?.touched))"
         class="form-error alert alert-danger">
        <small *ngIf="search_input?.errors?.required">
            Ce champ est requis.
        </small>
    </div>
</div>
<!--div style="border: 1px solid red; color: red">{{selectedValue}}</div-->