import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

export const ROLE_ANONYMOUS = 'anonymous';
export const ROLE_LOGGED = 'authenticated';
export const ROLE_AGENT = 'agent';
export const ROLE_REFERENT_CT = 'referent_ct';
export const ROLE_CNFPT = 'cnfpt';
export const ROLE_ADMIN = 'administrateur_fonctionnel';

export class OwnershipAccessState {
    currentUserRoles: string[];
    hasOwnership: boolean;
}

export abstract class HasAnyRoleDirective {

    protected hasView: boolean;
    protected userRoles: string[];

    constructor(protected templateRef: TemplateRef<any>,
                protected viewContainer: ViewContainerRef) {
        this.viewContainer.clear();
        this.hasView = false;
    }

    set currentUserRoles(currentUserRoles: string[]) {
        this.userRoles = currentUserRoles;
    }

    protected checkMasterRoles() {
        this.checkRoles([ROLE_CNFPT, ROLE_ADMIN]);
    }

    protected checkRoles(roles: string[]) {
        if (!(this.userRoles === null || this.userRoles === undefined) && this.userRoles.length > 0) {
            for (const role of this.userRoles) {
                if (roles.findIndex((value) => value === role) !== -1) {
                    this.show();
                }
            }
        } else {
            this.hide();
        }
    }

    protected checkIfRoleAButNotRoleB(roleA: string, roleB: string) {
      let hasRoleA = false;
      let hasNotRoleB = true;
      if (!(this.userRoles === null || this.userRoles === undefined) && this.userRoles.length > 0) {
          for (const role of this.userRoles) {
            if (roleA === role) {
              hasRoleA = true;
            }
            if (roleB === role) {
              hasNotRoleB = false;
            }
          }
        }
        if (hasRoleA && hasNotRoleB) {
          this.show();
        } else {
          this.hide();
        }
    }

    protected show() {
        if (!this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        }
    }

    protected hide() {
        if (this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

}
