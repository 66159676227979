import {Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS, Validator, AbstractControl} from '@angular/forms';

import {isValidNumber, parse, parsePhoneNumber} from "libphonenumber-js";

const MOBILE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MobileValidatorDirective),
    multi: true
};

@Directive({
    selector: '[mobile][formControlName],[mobile][formControl],[mobile][ngModel]',
    providers: [MOBILE_VALIDATOR]
})
export class MobileValidatorDirective implements Validator {

    validate(c: AbstractControl): { [key: string]: any } {
        let v: string = c.value;

        if ((v === null || v === undefined) || v.trim() == '') {
            return null;
        }

        const parsedValue = parse(v, {defaultCountry: 'FR'});
        return isValidNumber(parsedValue) && (parsedValue.phone.lastIndexOf('6', 0) === 0 || parsedValue.phone.lastIndexOf('7', 0) === 0) ? null : {phone: true};
    }
}
