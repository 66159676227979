import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html'
})
export class DeleteDialogComponent {

  @Input() action = 'suppression';
  @Input() actionVerb = 'supprimer';
  @Input() element = 'cet élément';

  constructor(public activeModal: NgbActiveModal) {
  }

  cancel() {
      this.activeModal.dismiss(false);
  }

  confirmDelete() {
      this.activeModal.close(true);
  }
}
