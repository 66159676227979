import {SearchComponent} from "./search/search.component";

export const annuaireRoutes = [
    {
        path: 'annuaire',
        component: SearchComponent,
        data: {
            title: "Recherche avancée"
        }
    },
    {
        path: 'annuaire?refresh',
        component: SearchComponent,
        data: {
            title: "Recherche avancée"
        }
    },
];
