<div class="modal-header">
    <h2 class="bordered-title bolded">
        {{isEdition ? 'Modifier' : 'Ajouter'}} une formation
    </h2>

    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span class="aed-icon aed-icon-close" aria-hidden="true"></span>
        <span class="sr-only" aria-hidden="true">Fermer</span>
    </button>
</div>
<div class="modal-body">
    <div class="inner">
        <form class="formation-form-form" #addNewFormationForm="ngForm" novalidate>
            <div class="row">
                <div class="form-group col-md-12 col-lg-12">
                    <label for="formation-diplome">
                        Diplôme
                        <span class="required-field">(obligatoire)</span>
                    </label>
                    <input type="text" class="form-control"
                           id="formation-diplome" name="diplome"
                           [(ngModel)]="formation.diplome"
                           required #diplome="ngModel"
                    >
                    <div *ngIf="diplome?.errors && diplome?.invalid && (diplome?.dirty || diplome?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="diplome?.errors?.required">
                            Le champ diplôme est requis.
                        </small>
                    </div>
                </div>
                <div class="form-group col-md-12 col-lg-12">
                    <label for="formation-organisme">
                        Organisme
                        <span class="required-field">(obligatoire)</span>
                    </label>
                    <input type="text" class="form-control"
                           id="formation-organisme" name="organisme"
                           [(ngModel)]="formation.organisme"
                           required #organisme="ngModel"
                    >
                    <div *ngIf="organisme?.errors && organisme?.invalid && (organisme?.dirty || organisme?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="organisme?.errors?.required">
                            Le champ organisme est requis.
                        </small>
                    </div>
                </div>
                <div class="form-group col-md-4 col-lg-4 month-selector">
                    <app-aed-year-selector [(ngModel)]="formation.debut" name="debut" id="debut" #debut="ngModel" [label]="'Année de début'" required (ngModelChange)="fin.control.reset(fin.control.value)"></app-aed-year-selector>
                    <div *ngIf="debut?.errors && debut?.invalid && (debut?.dirty || debut?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="debut?.errors?.required">
                            L'année de début est obligatoire
                        </small>
                    </div>
                </div>
                <div class="form-group col-md-4 col-lg-4 month-selector">
                    <app-aed-year-selector [(ngModel)]="formation.fin" name="fin" id="fin" #fin="ngModel" [label]="'Année de fin'" required yearPeriode beginField="debut"></app-aed-year-selector>
                    <div *ngIf="fin?.errors && fin?.invalid && (fin?.dirty || fin?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="fin?.errors?.required">
                            L'année de fin est obligatoire
                        </small>
                        <small *ngIf="fin?.errors?.yearPeriode">
                            L'année de fin doit être postérieure ou égale à l'année de début
                        </small>
                    </div>
                </div>

                <div class="form-group col-md-12 col-lg-12">
                    <label for="formation-missions">
                        Missions
                    </label>
                    <textarea type="text" class="form-control"
                              id="formation-missions"
                              name="missions"
                              [(ngModel)]="formation.missions"
                              #missions="ngModel"
                    ></textarea>
                </div>

                <div class="form-group col-md-4">
                    <label class="form-check-label label-alone">
                        Publier sur mon profil public
                    </label>

                    <div class="form-check form-check-inline">
                        <label class="form-check-label" for="visibleOui">
                            <input class="form-check-input" type="radio"
                                   name="visible"
                                   id="visibleOui"
                                   [(ngModel)]="formation.visible"
                                   #visible="ngModel"
                                   value="1"
                                   required
                            >
                            Oui
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <label class="form-check-label" for="visibleNon">
                            <input class="form-check-input" type="radio"
                                   name="visible"
                                   id="visibleNon"
                                   [(ngModel)]="formation.visible"
                                   #visible="ngModel"
                                   value="0"
                                   required
                            >
                            Non
                        </label>
                    </div>
                </div>
                <div class="form-group col-md-12 col-lg-12">
                    <ng-template #popAideFormationContent><p class="lead">La description de votre formation peut comprendre des informations sur vos diplômes, les organismes de formation fréquentés, pour que les membres qui consultent votre profil puissent avoir une meilleure idée de votre parcours académique.</p></ng-template>
                    <button type="button" class="btm btn-outline-primary btm-sm" placement="top"
                            [ngbPopover]="popAideFormationContent" popoverTitle="Aide">
                        Voir des exemples
                    </button>
                </div>
                <div class="form-group col-md-12 col-lg-12">

                    <button type="submit"
                            class="arrow-btn btn btn-primary text-uppercase"
                            [disabled]="addNewFormationForm.invalid"
                            (click)="onSubmit(addNewFormationForm)"
                    >
                        {{isEdition ? 'Modifier' : 'Ajouter'}}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>