import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgForm} from '@angular/forms';
import {LoaderService} from '../../shared/loader/loader.service';
import {FormService} from '../form.service';
import {CsrfService} from '../../authentification/csrf.service';
import {getReCaptcha} from "../../shared/external";


@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    public contact: any = {};
    public success: boolean;
    public isSubmitting = false;
    public fieldError = false;
    public csrfToken = '';
    public contactEndpoint = '/aed_contact_message?_format=json';
    public reCaptchaKey: string;

    constructor(private route: ActivatedRoute,
                private formService: FormService,
                private loaderService: LoaderService,
                private csrfService: CsrfService,
    ) {
      this.reCaptchaKey = getReCaptcha();
    }

    ngOnInit() {
        this.updateCsrfToken();
    }

    /**
     * Refresh the CSRF Token
     */
    private updateCsrfToken() {
        this.csrfService.getToken()
            .subscribe(
                token => {
                    this.csrfToken = token;
                });
    }


    /**
     *
     * @param {NgForm} submittedForm
     */
    public onSubmit(submittedForm: any): void {
        const data: any = {};
        data.contact_form = [{'target_id': 'contact_general'}];
        data.uid = [{'target_id': 'contact_general' }],
        data.name = [{'value': submittedForm.value.name}];
        data.mail = [{'value': submittedForm.value.mail}];
        data.subject = [{'value': submittedForm.value.subject}];
        data.message = [{'value': submittedForm.value.message}];

        if (submittedForm.valid) {
            this.loaderService.show();
            this.formService.submit(this.contactEndpoint, data, this.csrfToken)
                .subscribe(
                    value => {
                        this.loaderService.hide();
                        this.isSubmitting = false;
                        this.success = true;
                        this.contact = {};
                        submittedForm.form.markAsPristine();
                        submittedForm.form.markAsUntouched();
                        submittedForm.form.updateValueAndValidity();
                        this.updateCsrfToken();
                    }, () => {
                        this.contact = submittedForm.value;
                        this.loaderService.hide();
                        this.isSubmitting = false;
                        this.success = false;
                        this.updateCsrfToken();
                    }
                );
        } else {
            this.fieldError = true;
        }
        this.isSubmitting = false;
    }
   public resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

}
