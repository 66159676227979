
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';


import {HttpClient, HttpHeaders} from '@angular/common/http';
import {getDrupalUri} from '../shared/external';

import {CsrfService} from "../authentification/csrf.service";

@Injectable()
export class FormService {

    private defaultHeaders: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
    //private urlencondedHeaders: HttpHeaders = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

    constructor(private http: HttpClient) {
    }

    /**
     * Submit a form
     * @param {string} path
     * @param data
     * @returns {Observable<any>}
     */
    public submit(path: string, data: any, csrf: string): Observable<any> {
        let headers = new HttpHeaders({'Content-Type': 'application/json', 'X-CSRF-Token': csrf});
        return this.http.post<any>(
            getDrupalUri() + path, data, {headers: headers}).pipe(
            map(
                (formResponse) => {
                    return formResponse;
                }
            ));
    }
}
