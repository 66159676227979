<p class="search-description">Pour filtrer la liste des emplois, vous pouvez saisir une fonction, une cotation de poste ou le nom/prénom d'un agent.</p>

<form>
  <div class="form-group form-inline float-left">
    <input class="form-control ml-2" type="text" placeholder="Saisir le texte recherché" name="fulltextSearch" [(ngModel)]="fulltextQuery"/>
    <button class="arrow-btn btn btn-third text-uppercase filter-button" (click)="refreshJobs(true)">Rechercher</button>
  </div>
</form>
<div class="float-right">
  <button class="arrow-btn btn btn-primary text-uppercase filter-button"
    [routerLink]="['/collectivite', collectivite.id, 'emploi', 'new']">
    Ajouter un emploi
  </button>
</div>

<table class="table table-striped">
  <thead>
    <tr class="table-header">
      <th scope="col">#</th>
      <th scope="col">Fonction</th>
      <th scope="col">Cotation de poste</th>
      <th scope="col">Affectation</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let emploi of collectivite.emplois; index as i">
      <th class="{{ !emploi.is_valid ? 'italic-font' : '' }}" scope="row">{{ i + 1 }}</th>
      <td class="{{ !emploi.is_valid ? 'italic-font' : '' }}" >
        <ngb-highlight [result]="emploi.fonction"></ngb-highlight>
      </td>
      <td class="{{ !emploi.is_valid ? 'italic-font' : '' }}" >
        <ngb-highlight [result]="emploi.type_emploi?.label"></ngb-highlight>
      </td>
      <td class="{{ !emploi.is_valid ? 'italic-font' : '' }}" >
        <ng-container *ngIf="emploi.is_vacant; then isVacant;"></ng-container>
        <ng-container *ngIf="!emploi.is_vacant && emploi?.current_experience && emploi?.current_experience?.id; then isAssigned;"></ng-container>
        <ng-container *ngIf="!emploi.is_vacant && (!emploi?.current_experience || !emploi?.current_experience?.id); then notAssignedNorVacant;"></ng-container>

        <ng-template #isVacant>
          <ngb-highlight [result]="'Poste vacant'"></ngb-highlight>
        </ng-template>

        <ng-template #isAssigned>
          <ngb-highlight [result]="emploi.current_experience.user_id.label"></ngb-highlight>
        </ng-template>

        <ng-template #notAssignedNorVacant>
          <ngb-highlight [result]="'Sans information sur l\'affectation du poste'"></ngb-highlight>
        </ng-template>
      </td>
      <td>
        <span class="alv-label aed-icon aed-icon-edit clickable" aria-hidden="true" title="Editer l'emploi" [routerLink]="['/collectivite', collectivite.id, 'emploi', emploi.id]"></span>
        <ng-container *ngIf="emploi.is_valid; then isValid else isInvalid;"></ng-container>
        <ng-template #isValid>
          <span class="alv-label aed-icon aed-icon-eye-crossed clickable" title="Désactiver l'emploi" aria-hidden="true" (click)="openDisableDialog(emploi.id)"></span>
        </ng-template>
        <ng-template #isInvalid>
          <span class="alv-label aed-icon aed-icon-eye clickable" title="Réactiver l'emploi" aria-hidden="true" (click)="openEnableDialog(emploi.id)"></span>
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>

<div class="d-flex justify-content-between p-2">
  <ngb-pagination
    [collectionSize]="collectionSize" [(page)]="page" [pageSize]="itemsPerPage"
    [maxSize]="5" [boundaryLinks]="true" (pageChange)="refreshJobs()">
  </ngb-pagination>

  <select class="custom-select" style="width: auto" name="itemsPerPage" [(ngModel)]="itemsPerPage" (change)="refreshJobs()">
    <option [ngValue]="10">10 emplois par page</option>
    <option [ngValue]="20">20 emplois par page</option>
    <option [ngValue]="50">50 emplois par page</option>
  </select>
</div>
