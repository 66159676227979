import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {HasAnyRoleDirective, ROLE_ADMIN, ROLE_CNFPT, OwnershipAccessState} from './has-any-role';

@Directive({
  selector: '[hasRoleCnfpt]'
})
export class HasRoleCnfptDirective extends HasAnyRoleDirective {

    constructor(protected templateRef: TemplateRef<any>,
        protected viewContainer: ViewContainerRef) {
        super(templateRef, viewContainer);
    }

    @Input()
    set hasRoleCnfpt(accessState: OwnershipAccessState) {
        this.currentUserRoles = accessState.currentUserRoles;
        this.checkRoles([ROLE_CNFPT, ROLE_ADMIN]);
    }

}
