import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EmploiService } from './emploi.service';

@Injectable()
export class EmploiResolve implements Resolve<any> {
    constructor(
        private emploiService: EmploiService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.emploiService.get(route.paramMap.get('id'));
    }
}
