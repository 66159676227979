<app-breadcrumb></app-breadcrumb>

<div class="container">

  <ng-container>
    <div class="aed-tabs">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeIdString" class="nav-pills" (navChange)="fetchIfNeeded($event)">
        <li [ngbNavItem]="'dashboard'">
          <a ngbNavLink>Tableau de bord</a>
          <ng-template ngbNavContent>

            <div class="rights-table">
              <h4 class="dashboard-title bt-secondary">Demandes de droits</h4>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">A traiter</th>
                    <th scope="col">En attente</th>
                    <th scope="col">Rejetées</th>
                    <th scope="col">Validées</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ demandesTodo.length }}</td>
                    <td>{{ demandesIdle.length }}</td>
                    <td>{{ demandesDeclined.length }}</td>
                    <td>{{ demandesAccepted.length }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <table class="table stats-table">
              <thead>
                <tr>
                  <th scope="col">Agents inscrits</th>
                  <th scope="col">Collectivités</th>
                  <th scope="col">Emplois</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ usersCount }}</td>
                  <td>{{ collectivitesCount }}</td>
                  <td>4925</td>
                </tr>
              </tbody>
            </table>

          </ng-template>
        </li>

        <li [ngbNavItem]="'rights'">
          <a ngbNavLink>Demandes de droits</a>
          <ng-template ngbNavContent>

            <div class="row mar-t-20 mar-b-20">
              <div class="col-12">
                <form class="form-dashboard row" (ngSubmit)="initSearch()">
                  <div class="col-3">
                    <h3 class="dashboard-title bt-secondary">Demandes de droits</h3>
                  </div>
                  <div class="col-6">
                    <input type="text" class="form-control" name="fulltextQueryDemandes" [(ngModel)]="fulltextQueryDemandes" placeholder="Recherche">
                  </div>
                  <div class="col-3">
                    <button type="submit" class="btn arrow-btn btn-primary">Rechercher</button>
                  </div>
                </form>
              </div>
            </div>

            <ul ngbNav #navRights="ngbNav" [(activeId)]="selectedDemandesTab" class="nav-pills">
              <li [ngbNavItem]="'todo'">
                <a ngbNavLink>A traiter</a>
                <ng-template ngbNavContent>

                  <div class="rights-details-table">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Identité</th>
                          <th scope="col">Collectivité (demande)</th>
                          <th scope="col">Courriel</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let demande of demandesTodo | slice: (pageTodo - 1) * pageSizeTodo : pageTodo * pageSizeTodo">
                          <td class="align-left"><a class="clickable" (click)="goToProfilUtilisateur(demande.field_agent.id)">{{ demande.field_agent.label }}</a></td>
                          <td class="align-left"><a class="clickable" (click)="goToProfilCollectivite(demande.field_collectivite.id)">{{ demande.field_collectivite.label }}</a></td>
                          <td class="align-left">{{ demande.field_email }}</td>
                          <td>
                            <div ngbDropdown class="d-inline-block">
                              <button class="btn btn-primary pad-1 dropdown-toggle" ngbDropdownToggle>Valider</button>
                              <div ngbDropdownMenu>
                                <button class="btn btn-primary pad-1 mar-1" ngbDropdownItem (click)="changeDemandeStatus(demande.id, '4')">Valider</button>
                                <button class="btn btn-primary pad-1 mar-1" ngbDropdownItem (click)="openDemandeForm(demande, '2')">Mettre en attente</button>
                                <button class="btn btn-primary pad-1 mar-1" ngbDropdownItem (click)="openDemandeForm(demande, '3')">Rejeter</button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination [collectionSize]="demandesTodo.length" [(page)]="pageTodo" [pageSize]="pageSizeTodo"></ngb-pagination>

                      <select class="custom-select" style="width: auto" [(ngModel)]="pageSizeTodo">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                  </div>

                </ng-template>
              </li>

              <li [ngbNavItem]="'idle'">
                <a ngbNavLink>En attente</a>
                <ng-template ngbNavContent>

                  <div class="rights-details-table">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Identité</th>
                          <th scope="col">Collectivité (demande)</th>
                          <th scope="col">Courriel</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let demande of demandesIdle | slice: (pageIdle - 1) * pageSizeIdle : pageIdle * pageSizeIdle">
                          <td class="align-left"><a class="clickable" (click)="goToProfilUtilisateur(demande.field_agent.id)">{{ demande.field_agent.label }}</a></td>
                          <td class="align-left"><a class="clickable" (click)="goToProfilCollectivite(demande.field_collectivite.id)">{{ demande.field_collectivite.label }}</a></td>
                          <td class="align-left">{{ demande.field_email }}</td>
                          <td>
                            <div ngbDropdown class="d-inline-block">
                              <button class="btn btn-primary pad-1 dropdown-toggle" ngbDropdownToggle>Valider</button>
                              <div ngbDropdownMenu>
                                <button class="btn btn-primary pad-1 mar-1"  ngbDropdownItem (click)="changeDemandeStatus(demande.id, '4')">Valider</button>
                                <button class="btn btn-primary pad-1 mar-1"  ngbDropdownItem (click)="openDemandeForm(demande, '3')">Rejeter</button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination [collectionSize]="demandesIdle.length" [(page)]="pageIdle" [pageSize]="pageSizeIdle"></ngb-pagination>

                      <select class="custom-select" style="width: auto" [(ngModel)]="pageSizeIdle">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                  </div>

                </ng-template>
              </li>

              <li [ngbNavItem]="'declined'">
                <a ngbNavLink>Rejetées</a>
                <ng-template ngbNavContent>

                  <div class="rights-details-table">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Identité</th>
                          <th scope="col">Collectivité (demande)</th>
                          <th scope="col">Courriel</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let demande of demandesDeclined | slice: (pageDeclined - 1) * pageSizeDeclined : pageDeclined * pageSizeDeclined">
                          <td class="align-left"><a class="clickable" (click)="goToProfilUtilisateur(demande.field_agent.id)">{{ demande.field_agent.label }}</a></td>
                          <td class="align-left"><a class="clickable" (click)="goToProfilCollectivite(demande.field_collectivite.id)">{{ demande.field_collectivite.label }}</a></td>
                          <td class="align-left">{{ demande.field_email }}</td>
                          <td>
                            <div ngbDropdown class="d-inline-block">
                              <button class="btn btn-primary pad-1 dropdown-toggle" ngbDropdownToggle>Valider</button>
                              <div ngbDropdownMenu>
                                <button class="btn btn-primary pad-1 mar-1"  ngbDropdownItem (click)="changeDemandeStatus(demande.id, '4')">Valider</button>
                                <button class="btn btn-primary pad-1 mar-1"  ngbDropdownItem (click)="openDemandeForm(demande, '2')">Mettre en attente</button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination [collectionSize]="demandesDeclined.length" [(page)]="pageDeclined" [pageSize]="pageSizeDeclined"></ngb-pagination>

                      <select class="custom-select" style="width: auto" [(ngModel)]="pageSizeDeclined">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                  </div>

                </ng-template>
              </li>

              <li [ngbNavItem]="'accepted'">
                <a ngbNavLink>Validées</a>
                <ng-template ngbNavContent>

                  <div class="rights-details-table">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Identité</th>
                          <th scope="col">Collectivité (demande)</th>
                          <th scope="col">Courriel</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let demande of demandesAccepted | slice: (pageAccepted - 1) * pageSizeAccepted : pageAccepted * pageSizeAccepted">
                          <td class="align-left"><a class="clickable" (click)="goToProfilUtilisateur(demande.field_agent.id)">{{ demande.field_agent.label }}</a></td>
                          <td class="align-left"><a class="clickable" (click)="goToProfilCollectivite(demande.field_collectivite.id)">{{ demande.field_collectivite.label }}</a></td>
                          <td class="align-left">{{ demande.field_email }}</td>
                          <td>
                            <div ngbDropdown class="d-inline-block">
                              <button class="btn btn-primary dropdown-toggle" ngbDropdownToggle>Rejeter</button>
                              <div ngbDropdownMenu>
                                <button class="btn btn-primary pad-1 mar-1"  ngbDropdownItem (click)="openDemandeForm(demande, '2')">Mettre en attente</button>
                                <button class="btn btn-primary pad-1 mar-1"  ngbDropdownItem (click)="openDemandeForm(demande, '3')">Rejeter</button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination [collectionSize]="demandesAccepted.length" [(page)]="pageAccepted" [pageSize]="pageSizeAccepted"></ngb-pagination>

                      <select class="custom-select" style="width: auto" [(ngModel)]="pageSizeAccepted">
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="25">25</option>
                        <option [ngValue]="50">50</option>
                      </select>
                    </div>
                  </div>

                </ng-template>
              </li>
            </ul>

            <div [ngbNavOutlet]="navRights" class="mt-2"></div>
          </ng-template>
        </li>

        <li [ngbNavItem]="'users'">
          <a ngbNavLink>Utilisateurs</a>
          <ng-template ngbNavContent>

            <div class="rights-details-table">

              <div class="row mar-t-20 mar-b-20">
                <div class="col-12">
                  <form class="form-dashboard row" (ngSubmit)="searchUtilisateurs()">
                    <div class="col-3">
                      <h3 class="dashboard-title bt-secondary">Utilisateurs</h3>
                    </div>
                    <div class="col-6">
                      <input type="text" class="form-control" name="fulltextQueryUtilistateurs" [(ngModel)]="fulltextQueryUtilistateurs" placeholder="Recherche">
                    </div>
                    <div class="col-3">
                      <button type="submit" class="btn arrow-btn btn-primary">Rechercher</button>
                    </div>
                  </form>
                </div>
              </div>

              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">NOM Prénom</th>
                    <th scope="col">Droits</th>
                    <th scope="col">Collectivité</th>
                    <th scope="col">Adresse mail</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let user of searchDataUtilisateurs">
                    <td class="align-left"><a class="clickable" (click)="goToProfilUtilisateur(user?.id)">{{ user?.label }}</a></td>
                    <td>{{ getRolesAsString(user?.roles) }}</td>
                    <td><a class="clickable" (click)="goToProfilCollectivite(user?.collectivite?.id)">{{ user?.collectivite?.name }}</a></td>
                    <td>{{ user?.email }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                [collectionSize]="collectionSizeUtilistateurs" [(page)]="pageUtilistateurs" [pageSize]="itemsPerPageUtilistateurs"
                [boundaryLinks]="true" (pageChange)="searchUtilisateurs()" [maxSize]="15">
              </ngb-pagination>

              <select class="custom-select" style="width: auto" name="itemsPerPageUtilistateurs" [(ngModel)]="itemsPerPageUtilistateurs" (change)="searchUtilisateurs()">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>

          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="'collectivities'">
        <a ngbNavLink>Collectivités</a>
        <ng-template ngbNavContent>

          <div class="rights-details-table">

            <div class="row mar-t-20 mar-b-20">
              <div class="col-12">
                <form class="form-dashboard row" (ngSubmit)="searchCollectivites()">
                  <div class="col-3">
                    <h3 class="dashboard-title bt-secondary">Collectivités</h3>
                  </div>
                  <div class="col-6">
                    <input type="text" class="form-control" name="fulltextQueryCollectivites" [(ngModel)]="fulltextQueryCollectivites" placeholder="Recherche">
                  </div>
                  <div class="col-3">
                    <button type="submit" class="btn arrow-btn btn-primary">Rechercher</button>
                  </div>
                </form>
              </div>
            </div>

            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Intitulé</th>
                  <th scope="col">Code postal</th>
                  <th scope="col">Référent(s) CT</th>
                  <th scope="col">Statut (activée/archivée)</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let collectivite of searchDataCollectivites">
                  <td class="align-left"><a class="clickable" (click)="goToProfilCollectivite(collectivite.id)">{{ collectivite?.name }}</a></td>
                  <td>{{ collectivite?.codpos }}</td>
                  <td>
                    <span *ngFor="let referent of collectivite?.referents; let isFirst = first; let isLast = last;">
                      {{ !(isFirst || isLast) ? ', ' : '' }} <a class="clickable" (click)="goToProfilUtilisateur(referent?.id)">{{ referent?.name }}</a>
                    </span>
                  </td>
                  <td>{{ collectivite?.status == "1" ? "Activée" : "Archivée" }}</td>
                </tr>
              </tbody>
            </table>

            <div class="d-flex justify-content-between p-2">
              <ngb-pagination
              [collectionSize]="collectionSizeCollectivites" [(page)]="pageCollectivites" [pageSize]="itemsPerPageCollectivites"
              [boundaryLinks]="true" (pageChange)="searchCollectivites()" [maxSize]="15">
            </ngb-pagination>

            <select class="custom-select" style="width: auto" name="itemsPerPageCollectivites" [(ngModel)]="itemsPerPageCollectivites" (change)="searchCollectivites()">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>

        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="'extractions'">
      <a ngbNavLink>Extractions</a>
      <ng-template ngbNavContent>

        <div class="row mar-t-20 mar-b-20">
          <div class="col-2">
            <h3 class="dashboard-title bt-secondary">Extractions</h3>
          </div>
          <div class="col-5 form-group">
            <label>Début de la période de recherche</label>
            <div class="input-group">
              <input class="form-control" [(ngModel)]="this.periodStart" ngbDatepicker #dd="ngbDatepicker" [placement]="'bottom'" name="period_start">
              <div class="input-group-append">
                <button class="btn btn-primary aed-icon aed-icon-calendar" (click)="dd.toggle()" type="button">
                  <span class="sr-only sr-only-focusable">Ouvrir le calendrier</span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-5 form-group">
            <label>Fin de la période de recherche</label>
            <div class="input-group">
              <input class="form-control" [(ngModel)]="this.periodEnd" ngbDatepicker #df="ngbDatepicker" [placement]="'bottom'" name="period_end">
              <div class="input-group-append">
                <button class="btn btn-primary aed-icon aed-icon-calendar" (click)="df.toggle()" type="button">
                  <span class="sr-only sr-only-focusable">Ouvrir le calendrier</span>
                </button>
              </div>
            </div>
            <div class="form-error alert alert-danger" *ngIf="periodIsInvalid">
              <small>
                La date de fin doit être postérieure à la date de début
              </small>
            </div>
          </div>
        </div>

        <div class="row mar-t-20 mar-b-20">
          <div class="col-3">
            <button type="button" class="btn btn-primary" [disabled]="periodIsInvalid"><a class="button-export-link" [href]="exportAsCsv(1)" target="_blank">Utilisateurs</a></button>
          </div>
          <div class="col-3">
            <button type="button" class="btn btn-primary" [disabled]="periodIsInvalid"><a class="button-export-link" [href]="exportAsCsv(2)" target="_blank">Collectivités</a></button>
          </div>
          <div class="col-3">
            <button type="button" class="btn btn-primary" [disabled]="periodIsInvalid"><a class="button-export-link" [href]="exportAsCsv(3)" target="_blank">Emplois</a></button>
          </div>
          <div class="col-3">
            <button type="button" class="btn btn-primary" [disabled]="periodIsInvalid"><a class="button-export-link" [href]="exportAsCsv(4)" target="_blank">Demandes</a></button>
          </div>
        </div>

      </ng-template>
    </li>

    <ngb-tab title="Contenus" id="contents">
      <ng-template nbgTabTitle></ng-template>
      <ng-template nbgTabContent></ng-template>
    </ngb-tab>

  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
</ng-container>

</div>
