<div class="row">

  <div class="col-md-12 col-lg-12">
    <h3 class="bordered-title bolded">Logo</h3>
  </div>

  <div class="form-group col-md-12 col-lg-12">
    <label for="blason">Blason</label>
    <div *ngIf="collectivite.blason_url" class="user-avatar">
      <img class="img-fluid" [src]="collectivite.blason_url" title="Votre blason"
      alt="Votre blason"/>
    </div>
    <input type="file" class="form-control" id="blason" name="blason"
    (change)="fileSelected($event, 'blason')" accept="image/*">

    <div class="form-error alert alert-danger" *ngIf="errorsBlason?.length > 0">
      <small *ngFor="let error of errorsBlason">
        {{ error }}
      </small>
    </div>
  </div>

  <div class="form-group col-md-12 col-lg-12">
    <button type="button" class="arrow-btn btn btn-primary text-uppercase" [disabled]="null === files || null === files['blason']" (click)="uploadFile('blason')">
      Transférer
    </button>

    <button type="button" class="arrow-btn btn btn-danger text-uppercase" (click)="removeFile('blason')" *ngIf="collectivite.blason_url">
      Retirer
    </button>
  </div>

  <div class="col-md-12 col-lg-12">
    <h3 class="bordered-title bolded">Organigramme</h3>
  </div>

  <form class="organigramme-form" [formGroup]="ogranigrammeForm" (ngSubmit)="onSubmit(ogranigrammeForm)" novalidate>

    <div class="form-group col-md-12 col-lg-12">
      <label for="organigramme_url">Lien</label>
      <input type="text" class="form-control" formControlName="organigramme_url" id="organigramme_url" name="organigramme_url">
      <div *ngIf="controls?.organigramme_url.errors && controls?.organigramme_url.invalid && (controls?.organigramme_url.dirty || controls?.organigramme_url.touched)" class="form-error alert alert-danger">
        <small *ngIf="controls?.organigramme_url.errors?.pattern">
          Le lien spécifié est invalide
        </small>
        <small *ngIf="controls?.organigramme_url.errors?.required">
          Le lien est obligatoire
        </small>
      </div>
    </div>

    <div class="form-group col-md-12 col-lg-12">
      <button type="submit" class="arrow-btn btn btn-primary text-uppercase"
      [disabled]="ogranigrammeForm.invalid">
        Mettre à jour le lien de l'organigramme
      </button>
    </div>

  </form>

  <div class="col-md-12 col-lg-12">
    <div class="alert alert-info">
      <span>
        Les extensions de fichier autorisées sont: <strong>PDF, PNG, JPEG</strong>.
      </span>
      <br/>
      <span>
        La taille maximale autorisée est <strong>5Mo</strong>.
      </span>
    </div>
  </div>

  <div class="form-group col-md-12 col-lg-12">

    <label for="blason">Fichier</label>
    <div *ngIf="collectivite.organigramme_file_url; else showInput">
      <span class="cv-text">Visualiser l'organigramme actuellement en ligne</span>
      <a [href]="collectivite.organigramme_file_url" target="_blank">
        <span class="btn btn-primary aed-icon aed-icon-eye" aria-hidden="true"></span>
      </a>
      <button type="button" class="btn btn-primary aed-icon aed-icon-trash" (click)="removeFile('organigramme')">
        <span class="sr-only sr-only-focusable">Retirer l'organigramme</span>
      </button>
    </div>

    <ng-template #showInput>
      <input type="file" class="form-control" id="organigramme" name="organigramme" (change)="fileSelected($event, 'organigramme')" accept="application/pdf,image/jpeg,image/png">
      <div class="form-error alert alert-danger" *ngIf="errorsOrganigramme?.length > 0">
        <small *ngFor="let error of errorsOrganigramme">
          {{ error }}
        </small>
      </div>

      <button type="button" class="arrow-btn btn btn-primary text-uppercase mar-t-30" [disabled]="null === files || null === files['organigramme']" (click)="uploadFile('organigramme')">
        Charger un organigramme
      </button>
    </ng-template>

  </div>
</div>
