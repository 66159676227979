import {HomeComponent} from './home/home.component';
import {AuthRequiredGuard} from '../authentification/auth-required.guard';
import { ReferentResolve } from '../agent/referent.resolve';

export const accueilRoutes = [
    {
        path: '',
        component: HomeComponent,
        data: {isNewlyRegistered: false}
    },
    {
        path: 'registered',
        component: HomeComponent,
        resolve: {
            collectiviteId: ReferentResolve
        },
        data: {
          isNewlyRegistered: true
        }
    },
    {
        path: 'registered-demande',
        component: HomeComponent,
        resolve: {
            collectiviteId: ReferentResolve
        },
        data: {
          isNewlyRegisteredFromDemande: true
        }
    },
    {
        path: 'login',
        canActivate: [AuthRequiredGuard],
        component: HomeComponent
    }
];
