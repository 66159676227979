import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {APP_MATOMO_CONFIG} from "./app/matomo/runtime-config";

if (environment.production) {
  enableProdMode();
}
function bootstrap(configuration: any = { disabled: true }) {
  platformBrowserDynamic([
    { provide: APP_MATOMO_CONFIG, useValue: configuration }
  ]).bootstrapModule(AppModule)
    .catch(err => console.error(err));
};

fetch('./jsonapi/matomo/config')
  .then(response => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  })
  .then((config: any) => {
    bootstrap(config);
  })
  .catch((error) => {
    console.error('Error while retrieving matomo config from Drupal', error);
    console.warn('matomo is disabled');
    bootstrap({ disabled: true });
  });
