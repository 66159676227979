import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {OffreService} from '../offre.service';

@Component({
    selector: 'app-offre-last',
    templateUrl: './offre-last.component.html',
    styleUrls: ['./offre-last.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OffreLastComponent implements OnInit {

    public offres: any[];

    constructor(private offreService: OffreService) {
    }

    ngOnInit() {
        this.offreService.getLast().subscribe((values) => {
            this.offres = values;
        }, () => {
            this.offres = undefined;
        });
    }

}
