import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-offre-detail',
    templateUrl: './offre-detail.component.html',
    styleUrls: ['./offre-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OffreDetailComponent implements OnInit {

    public offre: any;

    public view: any;

    public unknown = 'Non précisé';

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        // Get Agent from resolver
        this.offre = this.route.snapshot.data['offre'];

        const addrArray = [];
        if (!(this.offre.field_offre_o16 === null || this.offre.field_offre_o16 === undefined)) {
            addrArray.push(this.offre.field_offre_o16);
        }
        if (!(this.offre.field_offre_o17  === null || this.offre.field_offre_o17 === undefined)) {
            addrArray.push(this.offre.field_offre_o17);
        }
        if (!(this.offre.field_offre_o18  === null || this.offre.field_offre_o18 === undefined)) {
            addrArray.push(this.offre.field_offre_o18);
        }

        const grades = [];

        if (this.offre.field_offre_o46 && this.offre.field_offre_o46.id != null) {
            grades.push(this.offre.field_offre_o46);
        }
        if (this.offre.field_offre_o49 && this.offre.field_offre_o49.id != null) {
            grades.push(this.offre.field_offre_o49);
        }
        if (this.offre.field_offre_o52 && this.offre.field_offre_o52.id != null) {
            grades.push(this.offre.field_offre_o52);
        }
        if (this.offre.field_offre_o55 && this.offre.field_offre_o55.id != null) {
            grades.push(this.offre.field_offre_o55);
        }
        if (this.offre.field_offre_o58 && this.offre.field_offre_o58.id != null) {
            grades.push(this.offre.field_offre_o58);
        }

        this.view = {
            title: this.offre.title,
            numero: this.offre.field_offre_o2,
            datePublication: this.offre.field_offre_o23 ? this.offre.field_offre_o23.formatted : this.unknown,
            datePourvoir: this.offre.field_offre_o26 ? this.offre.field_offre_o26.formatted : this.unknown,
            dateCandidature: this.offre.field_offre_o25 ? this.offre.field_offre_o25.formatted : this.unknown,
            employeur: this.offre.field_offre_o15 ? this.offre.field_offre_o15 : this.unknown,
            departement: this.offre.field_offre_o28 ? this.offre.field_offre_o28.label : this.unknown,
            nombrePoste: this.offre.field_offre_o10 ? this.offre.field_offre_o10 : this.unknown,
            telephone: this.offre.field_offre_o19 ? this.offre.field_offre_o19 : this.unknown,
            email: this.offre.field_offre_o21 ? this.offre.field_offre_o21 : this.unknown,
            lien: this.offre.field_offre_o7,
            adresse: addrArray,
            grades: grades,
            typeTemps: this.offre.field_offre_o11 ? 'Complet' : 'Non complet',
            dureeHeure: this.offre.field_offre_o12 ? ', ' + this.offre.field_offre_o12 + 'h' : '',
            descriptif: this.offre.field_offre_o3 ? this.offre.field_offre_o3 : this.unknown,
            missions: this.offre.field_offre_o5 ? this.offre.field_offre_o5 : this.unknown,
            profils: this.offre.field_offre_o4 ? this.offre.field_offre_o4 : this.unknown,
        };
    }
}
