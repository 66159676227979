import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AgentService } from '../../../../agent/agent.service';
import { LoaderService } from '../../../../shared/loader/loader.service';

@Component({
  selector: 'profil-utilisateur-identite',
  templateUrl: './identite.component.html',
  styleUrls: ['./identite.component.scss']
})
export class IdentiteComponent implements OnInit {

  @Input() agent: any;
  @Output() agentChange = new EventEmitter<any>();

  @Input() success: boolean;
  @Output() successChange = new EventEmitter<boolean>();

  constructor(
    private agentService: AgentService,
    private loader: LoaderService
    ) { }

  ngOnInit() { }

  /**
   * Send the identite form data to backend
   * @param {NgForm} submittedForm
   */
   public onSubmit(submittedForm: NgForm) {
    this.loader.show();
    const data = submittedForm.value;
    data['id'] = this.agent.id;
    this.agentService.updateAgent(data, null, null).subscribe(
        (value) => {
            this.loader.hide();
            this.agentChange.emit(value);
            this.successChange.emit(true);
        },
        (errors) => {
            this.loader.hide();
            this.successChange.emit(false);
        }
    );
  }
}
