import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { HasAnyRoleDirective, ROLE_AGENT, OwnershipAccessState, ROLE_REFERENT_CT } from './has-any-role';

@Directive({
    selector: '[hasRoleReferentButNotAgent]'
})
export class HasRoleReferentButNotAgentDirective extends HasAnyRoleDirective {

    constructor(protected templateRef: TemplateRef<any>,
        protected viewContainer: ViewContainerRef) {
        super(templateRef, viewContainer);
    }

    @Input()
    set hasRoleReferentButNotAgent(accessState: OwnershipAccessState) {
        this.currentUserRoles = accessState.currentUserRoles;
        this.checkMasterRoles();
        if (!this.hasView) {
          this.checkIfRoleAButNotRoleB(ROLE_REFERENT_CT, ROLE_AGENT);
        }
    }
}
