import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AgentService} from './agent.service';
import {AuthentificationService} from '../authentification/authentification.service';
import {getDrupalUri} from '../shared/external';
import {HttpClient} from '@angular/common/http';
import {LoaderService} from '../shared/loader/loader.service';

@Injectable()
export class AgentSessionResolve implements Resolve<any> {
    constructor(private agentService: AgentService,
                private http: HttpClient,
                private loader: LoaderService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.loader.show();
        return this.agentService.getCurrent();
    }
}
