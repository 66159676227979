import {Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS, Validator, AbstractControl, Validators} from '@angular/forms';


const CUSTOM_EMAIL_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CustomEmailValidatorDirective),
    multi: true
};

@Directive({
    selector: '[customEmail][formControlName],[customEmail][formControl],[customEmail][ngModel]',
    providers: [CUSTOM_EMAIL_VALIDATOR]
})
export class CustomEmailValidatorDirective implements Validator {

    validate(c: AbstractControl): { [key: string]: any } {
        return ((c.value === null || c.value === undefined) || c.value.trim() == '')
            ? null
            : Validators.email(c);
    }
}
