import {Component, OnInit} from '@angular/core';
import {MapService} from './map.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoaderSmallService} from '../loader-small/loader-small.service';
import {SearchDataService} from "../search-data/search-data.service";

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

    public allDepartments: boolean;
    public departments: any[];
    public regions: any[];
    public regionsDomTom: any[];
    public domToms = ['01', '02', '03', '04', '06'];
    public selectedDepartments = [];
    public selectDepartmentsJobsAmount = 0;
    public mapLoaded = false;

    constructor(private loaderSmallService: LoaderSmallService,
                private mapService: MapService,
                private router: Router,
                private searchDataService: SearchDataService) {
    }

    ngOnInit() {
        this.departments = [];
        this.loaderSmallService.show();
        this.mapLoaded = false;

        this.mapService.getMapInfos().subscribe(
            (result) => {
                this.loaderSmallService.hide();
                this.mapLoaded = true;
                this.departments = result;
            }
        );

        this.mapService.getMapRegions().subscribe(
            (result) => {
                this.loaderSmallService.hide();
                this.mapLoaded = true;
                this.regions = result.filter(reg => !this.inArray(reg.regionCode, this.domToms));
                this.regionsDomTom = result.filter(reg => this.inArray(reg.regionCode, this.domToms));
            }
        );
    }

    /**
     * Select a clicked department and update the jobs amount for eadh selected
     *
     * @param department
     * @param i
     */
    public clickedDepartment(department) {

        if (this.hasDepartment(department)) {
            this.removeDepartment(department);
        } else if (this.hasOneDepartmentSelectedAtLeast(department.regionCode)) {
            this.addDepartment(department);
        }  else {
            const regionDepts = this.getRegionAllDepartments(department.regionCode);

            for(var cii = 0; cii < regionDepts.length;  cii++) {
                this.addDepartment(regionDepts[cii]);
            }
        }
        this.updateJobCount();
    }

    /**
     * Remove a department from the selected list
     *
     * @param department
     * @param dIndex
     */
    public removeDepartment(department) {
        if (this.hasDepartment(department)) {

            this.selectedDepartments = this.selectedDepartments.filter(dep => dep != department);
            this.allDepartments = false;
        }
    }

    /**
     * Add a department to the selected list
     *
     * @param department
     * @param dIndex
     */
    public addDepartment(department) {
        if (!this.hasDepartment(department)) {
            this.selectedDepartments.push(department);
        }
    }

    /**
     *Check if the department is already selected
     * @param dIndex
     * @returns {boolean}
     */
    public hasDepartment(department): boolean {

        const index: number =  this.selectedDepartments.indexOf(department);
        return index >= 0;
    }

    /**
     * Select all the departments
     *
     * @param {boolean} flag
     */
    public selectAllDepartments(flag: boolean) {
        this.allDepartments = flag;

        this.selectedDepartments = [];

        if (this.allDepartments) {
            for (const depIndex in this.departments) {
                const dep = this.departments[depIndex];
                this.addDepartment(dep);
            }
        }

        this.updateJobCount();
    }

    /**
     * Update job counter
     */
    public updateJobCount() {
        if (this.selectedDepartments.length === 0) {
            this.selectDepartmentsJobsAmount = 0;
        } else {

            const selectedDepartmentsIds = [];

            for(var uii = 0; uii < this.selectedDepartments.length; uii++) {
                selectedDepartmentsIds.push(this.selectedDepartments[uii].departmentId);
            }

            this.mapService.getJobCountByDepartments(selectedDepartmentsIds).subscribe(
                (result) => {
                    this.selectDepartmentsJobsAmount = result.count;
                    if (this.selectDepartmentsJobsAmount < 0) {
                        this.selectDepartmentsJobsAmount = 0;
                    }
                }
            );
        }
    }

    /**
     * Go to search component
     */
    public triggerSearch() {
        this.searchDataService.clearData();

        const selectedDepartmentsIds = [];

        for(var uii = 0; uii < this.selectedDepartments.length; uii++) {
            selectedDepartmentsIds.push(this.selectedDepartments[uii].departmentId);
        }

        this.router.navigate(['/annuaire'], {queryParams: {depts: selectedDepartmentsIds}});
    }

    /**
     *  Get the given region code all departments
     * @param code
     */
    public getRegionAllDepartments(code : number): any {
        return this.departments.filter(department  => department.regionCode == code);
    }

    /**
     *  Check if the given region has at least one department selected
     * @param region
     */
    public hasOneDepartmentSelectedAtLeast(region: number): boolean {
        const filteredDepts = this.selectedDepartments.filter(dept => dept.regionCode == region);

        return  filteredDepts.length >= 1;
    }

    /**
     *
     * @param needle
     * @param haystack
     */
    public  inArray(needle, haystack): boolean {
        for(let ii in haystack) {
            if(haystack[ii] == needle) return true;
        }
        return false;
    }
}
