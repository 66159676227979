
import {switchMap} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Params} from '@angular/router';
import {PageService} from './page.service';
import {LoaderService} from '../shared/loader/loader.service';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

    public page: any;

    constructor(private route: ActivatedRoute, private pageService: PageService,
                private loaderService: LoaderService) {
    }

    ngOnInit() {
        this.loaderService.show();

        this.route.params.pipe(
            switchMap((params: Params) => this.pageService.getPageBySlug(params['slug'])))
            .subscribe(value => {
                    this.page = value;
                    this.loaderService.hide();
                },
                () => {
                    this.loaderService.hide();
                });
    }

}
