<app-breadcrumb></app-breadcrumb>

<div class="container">

  <!-- begin: ALERTS -->
  <div class="row">
    <div class="col-md-12 col-lg-12">
        <app-alert *ngIf="showAlert === true"
                   [isAlertClosable]="true"
                   [alertType]="alertType"
                   [alertMessage]="alertMessage"
        >
        </app-alert>
    </div>
  </div>
  <!-- end: ALERTS -->

  <!-- begin: PAGE HEADER -->
  <header class="page-title">
      <div class="row">
          <h1 class="text-uppercase bolded col-md-9 col-lg-9">
              Mes collectivités
          </h1>
          <a class="clickable float-right" back-button>
            <span class="aed-icon aed-icon-back" aria-hidden="true"></span>
            <span class="pm-link-text plt-back">Retour</span>
          </a>
      </div>
  </header>
  <!-- end: PAGE HEADER -->

  <!-- begin: PAGE CONTENT -->
  <div id="content" class="collectivites-list">
    <div class="row">
        <div class="col-md-12 col-lg-12">

          <p>Pour filtrer la liste de collectivité, vous pouvez saisir un libellé de collectivité, un code postal ou un libellé de commune.</p>

          <div>
            <form>
              <div class="form-group form-inline float-left">
                  <div class="input-group mr-1">
                    <input class="form-control ml-2" type="text" name="fulltextQuery" [(ngModel)]="fulltextQuery" placeholder="Texte à rechercher"/>
                    <!-- <button type="button" class="btn bg-transparent">
                      <span class="alv-label aed-icon aed-icon-close" aria-hidden="true"></span>
                    </button> -->
                  </div>
                  <!-- [disabled]="fulltextQuery.length < 3" -->
                  <button class="arrow-btn btn btn-third text-uppercase filter-button" (click)="searchPaginated(true)">
                    Rechercher
                  </button>
              </div>
            </form>
            <div class="float-right">
                <button class="arrow-btn btn btn-primary text-uppercase filter-button" (click)="openAddReferentForm()">Ajouter une autre collectivité</button>
            </div>
          </div>

          <table class="table table-striped">
            <thead>
            <tr class="table-header">
              <th scope="col">#</th>
              <th scope="col">Libellé</th>
              <th scope="col">Code postal</th>
              <th scope="col">Libellé de la commune</th>
              <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let collectivite of collectivites; index as i">
              <th scope="row">{{ (page - 1) * itemsPerPage + i + 1 }}</th>
              <td>
                <ngb-highlight [result]="collectivite.name"></ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="collectivite.codpos"></ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="collectivite.libcom"></ngb-highlight>
              </td>
              <td>
                <span class="alv-label aed-icon aed-icon-edit clickable" title="Editer les emplois et coordonnées" aria-hidden="true"
                  *hasRoleReferentCTWithOwnership="accessState" [routerLink]="['/collectivite', collectivite.id, 'edit']" fragment="emplois"></span>
                <span class="alv-label aed-icon aed-icon-stop clickable" title="Désactiver la collectivité" aria-hidden="true"
                  *hasRoleCnfpt="accessState" (click)="openDisableDialog(collectivite.id)"></span>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="d-flex justify-content-between p-2">
            <ngb-pagination
              [collectionSize]="collectionSize" [(page)]="page" [pageSize]="itemsPerPage"
              [maxSize]="5" [boundaryLinks]="true" (pageChange)="searchPaginated()">
            </ngb-pagination>

            <select class="custom-select" style="width: auto" name="itemsPerPage" [(ngModel)]="itemsPerPage" (change)="searchPaginated()">
              <option [ngValue]="10">10 collectivités par page</option>
              <option [ngValue]="20">20 collectivités par page</option>
              <option [ngValue]="50">50 collectivités par page</option>
            </select>
          </div>

        </div>
    </div>
  </div>
  <!-- end: PAGE CONTENT -->

</div>
