import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { JwksValidationHandler, NullValidationHandler } from 'angular-oauth2-oidc';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import {getClientId, getSsoBaseUrl, getIssuer, getDrupalUri, getJWKS, getLogoutUrl} from '../shared/external';
import { Router } from '@angular/router';
import { LoaderService } from '../shared/loader/loader.service';

export const STORAGE_PREVIOUS_URL = 'previous-url-before-login';
export const REDIRECT_KEY = 'numberRedirect';

const oauthConfig: AuthConfig = {
    // url of the identity
    redirectUri: window.location.origin + '/login-callback',
    clientId: environment.clientId,
    scope: 'openid profile email',
    silentRefreshRedirectUri: window.location.origin + '/login-callback',
    sessionChecksEnabled: false, // Does not work for now
    // When no discovery
    loginUrl: environment.ssobaseurl + '/oauth2/authorize',
    issuer: environment.issuer,
    userinfoEndpoint: environment.ssobaseurl + '/oauth2/userinfo?schema=openid',
    sessionCheckIFrameUrl: environment.ssobaseurl + '/is/oidc/checksession',
    // moncompte session time does not match token lifespan but we need to be log in moncompte to work with silent refresh
    timeoutFactor: 0.15,
    logoutUrl: getLogoutUrl(),
};

@Injectable()
export class AuthentificationService {
    private configured = false;
    // Protect from multiple authenticate at same time
    // (multiple places have legit reason to trigger it but sometimes all at the same moment)
    private authenticateInProgress = false;
    isReady = new BehaviorSubject(false);
    identity = new BehaviorSubject(null);
    firstAuthDone = new BehaviorSubject(false);

    constructor(private http: HttpClient,
        private storage: SessionStorageService,
        private oauthService: OAuthService,
        private router: Router,
        private loader: LoaderService) {
        let jwks = storage.retrieve('jwks');
        if (!jwks) {
            jwks = getJWKS();
        }
        if (!jwks) {
            // First we collect jwks for login
            this.http.get<any>(getSsoBaseUrl() + '/oauth2/jwks/carbon.super').subscribe(
                (result) => {
                    jwks = result.json();
                    oauthConfig.jwks = jwks;
                    this.oauthService.jwks = jwks;
                    sessionStorage.setItem('jwks', JSON.stringify(jwks));
                    this.isReady.next(this.configured && this.oauthService.jwks !== null);
                },
                () => {
                    // console.log('JWKS is not available');
                }
            );
        } else {
            oauthConfig.jwks = JSON.parse(jwks);
            this.oauthService.jwks = JSON.parse(jwks);
        }

        // Subscribe to events of auth lib / Don't know if we need it
        if (this.oauthService.events) {
            this.oauthService.events.subscribe(e => {
                if (e.type === 'token_received' || e.type === 'token_refreshed' || e.type === 'silently_refreshed') {
                    // refresh on new token
                    this.authenticate();
                } else {
                    this.authenticate();
                }
            });
        }
    }

    public getConfig() {
        return oauthConfig;
    }

    /**
     * Configure the oauth service, return immediately if it is already configured.
     */
    public configure() {
        if (this.configured) {
            return;
        }
        this.configured = true;
        // Override oauth config from external
        oauthConfig.clientId = getClientId();
        oauthConfig.loginUrl = getSsoBaseUrl() + '/oauth2/authorize';
        oauthConfig.issuer = getIssuer();
        oauthConfig.userinfoEndpoint = getSsoBaseUrl() + '/oauth2/userinfo?schema=openid';
        oauthConfig.sessionCheckIFrameUrl = getSsoBaseUrl() + '/is/oidc/checksession';

        this.oauthService.configure(oauthConfig);
        if (!environment.production) {
            this.oauthService.requireHttps = false;
            this.oauthService.tokenValidationHandler = new NullValidationHandler();
            this.isReady.next(this.configured);
        } else {
            this.oauthService.tokenValidationHandler = new JwksValidationHandler();
            this.isReady.next(this.configured && this.oauthService.jwks !== null);
        }
        this.oauthService.setupAutomaticSilentRefresh();
    }

    public tryDecodeUrlFromLoginOrSession() {
        this.configure();
        this.oauthService.tryLogin();
        this.authenticate();
    }

    public login(path?: string) {
        this.storage.store(STORAGE_PREVIOUS_URL, path != null ? path : window.location.pathname);
        this.oauthService.initImplicitFlow();
    }

    /**
     * Logout from everything
     */
    public logout() {
        this.logoutTo(this.oauthService.logoutUrl);
    }

    /**
     * Logout from everything and go to destination
     */
    public logoutTo(destination: string) {
        this.loader.show();
        this.http.get<any>(getDrupalUri() + '/user/logout?_format=json').subscribe(
            (val) => {
                this.logoutOauth(destination);
            }, () => {
                this.logoutOauth(destination);
            });
    }

    /**
     * Logout from OIDC
     */
    public logoutOauth(destination: string) {
        this.oauthService.logOut();
        this.identity.next(null);
        this.loader.hide();
        window.location.href = destination;
    }

    /**
     * Refresh tokens
     */
    public silentRefresh() {
        this.oauthService.silentRefresh();
        this.authenticate();
    }

    /**
     * Drupal authentication with OIDC token
     */
    public authenticate() {
        if (!this.authenticateInProgress) {
            this.authenticateInProgress = true;
            this.http.get<any>(getDrupalUri() + '/authenticate').subscribe(
                (response) => {
                    const currentValue = this.identity.getValue();
                    if ((response == null && currentValue != null)) {
                        this.oauthService.logOut();
                        this.identity.next(null);
                    } else if (!response.result) {
                        this.identity.next(null);
                    } else {
                        this.identity.next(response);
                        this.storage.store(REDIRECT_KEY, 0);
                    }
                    // }
                    this.authenticateInProgress = false;
                    this.firstAuthDone.next(true);
                },
                () => {
                    this.oauthService.logOut();
                    this.identity.next(null);
                    this.authenticateInProgress = false;
                }
            );
        }
    }

    private identityEquals(oldValue: any, newValue: any) {
        return ((newValue == null && oldValue === newValue)
            || (oldValue.username === newValue.username || oldValue)
        );


    }
}
