import {Component} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthentificationService} from '../../authentification/authentification.service';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized-dialog.component.html'
})
export class UnauthorizedDialogComponent {

    constructor(public activeModal: NgbActiveModal,
                private authService: AuthentificationService) {
    }

    confirmLogout() {
        this.activeModal.close(true);
        this.authService.logoutTo('/login');
    }
}
