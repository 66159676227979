<div class="aj-job">
    <a class="clickable clearfix" [routerLink]="['/offre-emploi', offre.field_offre_o2]">
        <h3>{{offre.title}}</h3>
        <div class="aj-job-metas">
            <ul class="list-unstyled">
                <li>
                    <span class="aed-icon aed-icon-hash" aria-hidden="true"></span>
                    <span class="sr-only">Numéro de l'offre</span>
                    <span>{{offre.field_offre_o2}}</span>
                </li>
                <li>
                    <span class="aed-icon aed-icon-clock" aria-hidden="true"></span>
                    <span class="sr-only">Date de publication</span>
                    <span>{{offre.field_offre_o23.formatted}}</span>
                </li>
            </ul>
        </div>

        <ul class="list-unstyled aed-label-value">
            <li *ngIf="offre.field_offre_o3">
                <span class="alv-label">{{offre.field_offre_o3.substr(0, 150)}}...</span>
            </li>
            <li *ngIf="offre.field_offre_o15" class="mar-t-15">
                <span class="alv-value">{{offre.field_offre_o15}}</span>
            </li>
            <li *ngIf="offre.field_offre_o16" class="mar-t-5">
            <app-address [address]="address" [separator]="', '"></app-address>
        </ul>
    </a>
</div>