import {Component, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'app-aed-year-selector',
    templateUrl: './aed-year-selector.component.html',
    styleUrls: ['./aed-year-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AedYearSelectorComponent),
            multi: true
        }
    ]

})
export class AedYearSelectorComponent implements OnInit, ControlValueAccessor {

    year: any;
    @Input() yearRange: any;
    @Input() label = 'Date';
    @Input() id = 'date';
    @Input() name = 'date';

    public disabled = false;

    currentYear = new Date().getFullYear();
    years = [];

    constructor() {

    }

    @HostListener('blur') onBlur() {
        this.onChange(this.year);
        this.onTouched();
    }


    ngOnInit() {
        if (this.yearRange === undefined) {
            this.yearRange = {before: 40, after: 0};
        }

        for (let i = this.currentYear + this.yearRange.after; i >= this.currentYear - this.yearRange.before; i--) {
            this.years.push(i);
        }
    }

    // Function to call when the rating changes.
    onChange = (year: number) => {};

    // Function to call when the input is touched (when a star is clicked).
    onTouched = () => {};

    public exportValue(event: any) {
        // this.onChoose.emit(this.year);
        this.onChange(this.year);
    }

    writeValue(obj: any): void {
        this.year = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

}
