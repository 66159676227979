import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from '../../shared/loader/loader.service';
import { DemandeDeDroitService } from '../demande-de-droit.service';

@Component({
  selector: 'demande-de-droit-form',
  templateUrl: './demande-de-droit-form.component.html',
  styleUrls: ['./demande-de-droit-form.component.scss']
})
export class DemandeDeDroitFormComponent implements OnInit {

  public formModel: any;

  @Input() dddId: any;
  @Input() dddLabel: any;
  @Input() oldStatus: any;
  @Input() newStatus: any;

  constructor(
    public activeModal: NgbActiveModal,
    private loader: LoaderService,
    private demandeDeDroitService: DemandeDeDroitService
    ) { }

  ngOnInit() {
    this.formModel = {
        motif: ''
    };
  }

  newStatusText() {
    return (this.newStatus === "3" ? "du rejet" : "de la mise en attente") + " de la demande";
  }

  public onSubmit(submittedForm: NgForm) {
    this.loader.show();

    this.demandeDeDroitService.update(this.dddId, this.newStatus, submittedForm.value['motif']).subscribe(
      (value) => {
        this.loader.hide();
        this.activeModal.close({success: true});
      }, () => {
          this.loader.hide();
          this.activeModal.close({success: false});
      });
  }
}
