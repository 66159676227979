<nav>
    <a class="as-cv" href="{{ owner.cv_url }}" *ngIf="owner.cv_url" target="_blank">
        <span class="sr-only">Télécharger le cv</span>
        <span class="aed-icon aed-icon-cv" aria-hidden="true"></span>
    </a>
    <a class="as-facebook" href="{{ owner.field_facebook }}" target="_blank" *ngIf="owner.field_facebook">
        <span class="sr-only">Facebook</span>
    </a>
    <a class="as-twitter" href="{{ owner.field_twitter }}" target="_blank" *ngIf="owner.field_twitter">
        <span class="sr-only">Twitter</span>
    </a>
    <a class="as-linkedin" href="{{ owner.field_linkedin }}" target="_blank" *ngIf="owner.field_linkedin">
        <span class="sr-only">Linkedin</span>
    </a>
    <a class="as-viadeo" href="{{ owner.field_viadeo }}" target="_blank" *ngIf="owner.field_viadeo">
        <span class="sr-only">Viadeo</span>
    </a>
    <a class="as-facebook" href="{{ owner.facebook }}" target="_blank" *ngIf="owner.facebook">
        <span class="sr-only">Facebook</span>
    </a>
    <a class="as-twitter" href="{{ owner.twitter }}" target="_blank" *ngIf="owner.twitter">
        <span class="sr-only">Twitter</span>
    </a>
    <a class="as-linkedin" href="{{ owner.linkedin }}" target="_blank" *ngIf="owner.linkedin">
        <span class="sr-only">Linkedin</span>
    </a>
    <a class="as-viadeo" href="{{ owner.viadeo }}" target="_blank" *ngIf="owner.viadeo">
        <span class="sr-only">Viadeo</span>
    </a>
</nav>