import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AgentService } from '../../../../agent/agent.service';
import { LoaderService } from '../../../../shared/loader/loader.service';

@Component({
  selector: 'profil-utilisateur-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

  @Input() agent: any;
  @Output() agentChange = new EventEmitter<any>();

  @Input() success: boolean;
  @Output() successChange = new EventEmitter<boolean>();

  @Input() cvSuccess: boolean;
  @Output() cvSuccessChange = new EventEmitter<boolean>();

  @Input() photoSuccess: boolean;
  @Output() photoSuccessChange = new EventEmitter<boolean>();

  // Validators & Errors for Photo/CV upload
  uploadData: any = {
    Photo: {
      validators: undefined,
      errors: []
    },
    Cv: {
      validators: undefined,
      errors: []
    }
  };

  // Competences
  competencesList: any[];
  fieldCompetencesIds: any = [];

  selectedMetier: any = [];
  selectedGrade: any = [];

  // Form
  photo_file: any;
  cv_file: any;
  grades: any[];
  metiers: any[];
  competences: any;
  formations: any;
  ckeditorConfig: any = {
    removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Replace,Find,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,Strike,CopyFormatting,NumberedList,Outdent,Indent,Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,Format,Font,FontSize,TextColor,BGColor,ShowBlocks,About',
    toolbarGroups: [
      {name: 'document', groups: ['mode', 'document', 'doctools']},
      {name: 'clipboard', groups: ['clipboard', 'undo']},
      {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
      {name: 'forms', groups: ['forms']},
      {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
      {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
      {name: 'links', groups: ['links']},
      {name: 'insert', groups: ['insert']},
      {name: 'styles', groups: ['styles']},
      {name: 'colors', groups: ['colors']},
      {name: 'tools', groups: ['tools']},
      {name: 'others', groups: ['others']},
      {name: 'about', groups: ['about']}
    ]
  };

  /**
  * Multiselect field settings
  */
  public settingsSingle = {
    singleSelection: true,
    text: '-',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout désélectionner',
    searchPlaceholderText: 'Rechercher',
    enableSearchFilter: true,
    labelKey: 'name',
    primaryKey: 'id',
  };
  public settingsMulti = {
    singleSelection: false,
    text: '-',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout désélectionner',
    searchPlaceholderText: 'Rechercher',
    enableSearchFilter: true,
    labelKey: 'name',
    primaryKey: 'id',
  };

  constructor(
    private agentService: AgentService,
    private loader: LoaderService
  ) {
    this.competences = [];
    this.formations = [];
  }

  ngOnInit() {
    this.extractIdsForMultiSelect();
    this.getFormDatas();
    this.getValidators();
  }

  ngOnChanges() {
    this.extractIdsForMultiSelect();
    this.getFormDatas();
  }

  /**
   * Get the requested agent for edition
   */
  public getFormDatas(): void {
    if (this.agent != null) {
      this.agentService.getFormDatas(this.agent.id)
        .subscribe(
          (data) => {
            this.grades = data.grades;
            this.metiers = data.metiers;
            this.competencesList = data.competences;
            this.loader.hide();
          },
          () => {
              this.loader.hide();
          }
      );
    }
  }

  public extractIdsForMultiSelect() {
    if (this.agent.field_competences) {
        this.fieldCompetencesIds = this.agent.field_competences;
    }
    if (this.agent.field_metier_referentiel) {
      this.selectedMetier = [this.agent.field_metier_referentiel];
    }
    if (this.agent.field_grade.id) {
      this.selectedGrade = [this.agent.field_grade.id];
    }
  }

  /**
   * Request validators for file field fieldName
   */
  public getValidators(): void {
    this.agentService.getFieldValidators('field_photo')
      .subscribe(
        (value) => {
            this.uploadData.Photo.validators = value;
        }
      );
    this.agentService.getFieldValidators('field_cv')
      .subscribe(
        (value) => {
            this.uploadData.Cv.validators = value;
        }
      );
  }

  public pictureSelected($event: any) {
    if ($event.target && $event.target.files && $event.target.files.length > 0) {
        this.photo_file = $event.target.files[0];
    } else {
        this.photo_file = undefined;
    }
  }

  public cvSelected($event: any) {
    if ($event.target && $event.target.files && $event.target.files.length > 0) {
        this.cv_file = $event.target.files[0];
    } else {
        this.cv_file = undefined;
    }
  }

  public onCompetencesSubmit(submittedForm: NgForm) {
    this.loader.show();
    const data = submittedForm.value;
    data['id'] = this.agent.id;
    data['field_competences'] = this.fieldCompetencesIds.map(x => x.id);
    data['field_metier_referentiel'] = this.selectedMetier.map(x => x.id);
    this.agentService.updateAgent(data, null, null).subscribe(
      (value) => {
          this.loader.hide();
          this.agentChange.emit(value);
          this.successChange.emit(true);
      },
      () => {
          this.loader.hide();
          this.successChange.emit(false);
      }
    );
  }

  public onGradeSubmit(submittedForm: NgForm) {
    this.loader.show();
    const data = submittedForm.value;
    data['id'] = this.agent.id;
    data['field_grade'] = this.selectedGrade.map(x => x.id);
    this.agentService.updateAgent(data, null, null).subscribe(
      (value) => {
          this.loader.hide();
          this.agentChange.emit(value);
          this.successChange.emit(true);
      },
      () => {
          this.loader.hide();
          this.successChange.emit(false);
      }
    );
  }

  public onCvSubmit() {
    this.loader.show();
    this.agentService.updateAgent({'id': this.agent.id}, null, this.cv_file).subscribe(
      (value) => {
        this.loader.hide();
        this.cv_file = null;
        this.agentChange.emit(value);
        this.successChange.emit(true);
        this.cvSuccessChange.emit(true);
        this.uploadData.Cv.errors = [];
      },
      (errors) => {
        this.loader.hide();
        this.cvSuccessChange.emit(false);
        this.uploadData.Cv.errors = errors.error;
      }
    );
  }

  public onPhotoSubmit() {
    this.loader.show();
    this.agentService.updateAgent({'id': this.agent.id}, this.photo_file, null).subscribe(
      (value) => {
        this.loader.hide();
        this.photo_file = null;
        this.agentChange.emit(value);
        this.successChange.emit(true);
        this.photoSuccessChange.emit(true);
        this.uploadData.Photo.errors = [];
      },
      (errors) => {
        this.loader.hide();
        this.photoSuccessChange.emit(false);
        this.uploadData.Photo.errors = errors.error;
      }
    );
  }
}
