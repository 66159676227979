import {Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS, Validator, AbstractControl, Validators} from '@angular/forms';

const LINKEDIN_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => LinkedinValidatorDirective),
    multi: true
};

@Directive({
    selector: '[linkedin][formControlName],[linkedin][formControl],[linkedin][ngModel]',
    providers: [LINKEDIN_VALIDATOR]
})
export class LinkedinValidatorDirective implements Validator {

    validate(c: AbstractControl): { [key: string]: any } {
        let v: string = c.value;

        if ((v === null || v === undefined) || v.trim() == '') {
            return null;
        }
        const regexp = new RegExp('^http(s)?:\/\/([a-z]{2,3}\.)?linkedin\.com\/.*$');
        return regexp.test(v) ? null : {linkedin: true};
    }
}
