import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {PageComponent} from "./page.component";
import {RouterModule} from "@angular/router";
import {pageRoutes} from "./page-routing.module";
import {SharedModule} from "../shared/shared.module";
import {PageService} from "./page.service";

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(
            pageRoutes
        ),
        NgbModule,
        SharedModule
    ],
    providers: [
        PageService
    ],
    declarations: [
        PageComponent
    ],
})
export class PageModule {
}
