import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-fiche-emploi-light',
    templateUrl: './fiche-emploi-light.component.html',
    styleUrls: ['./fiche-emploi-light.component.scss']
})
export class FicheEmploiLightComponent implements OnInit {
    @Input() emploi: any;
    @Input() experience: any;
    @Input() showAgent = true;

    fonction: string;

    constructor() {
    }

    ngOnInit() {
        if (this.emploi !== undefined) {
            if (this.experience === undefined) {
                if (this.emploi.current_experience !== undefined) {
                    this.experience = this.emploi.current_experience;
                }
            }
        } else {
            this.emploi = this.experience.field_exp_fpt_emploi;
        }
        if (!(this.experience === null || this.experience === undefined) && !(this.experience.fonction === null || this.experience.fonction === undefined)) {
          this.fonction = this.experience.fonction
        } else if (!(this.emploi === null || this.emploi === undefined) && !(this.emploi.fonction === null || this.emploi.fonction === undefined)) {
          this.fonction = this.emploi.fonction
        }
    }
}
