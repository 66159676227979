<div class="row">
    <div class="alc-job-elt col-md-{{ showAgent ? 6 : 12 }}">
        <h3 class="title-secondary  text-uppercase">{{ fonction }}</h3>
        <ul class="list-unstyled aed-label-value">
            <li class="mb-3">
                <span class="alv-value">{{ emploi ? emploi.type_emploi?.label : experience?.name }}</span>
            </li>
            <li *ngIf="emploi && emploi.filiere">
                <span class="alv-label">Fillière :</span>
                <span class="alv-value">{{ emploi.filiere.label }} </span>
            </li>
            <li *ngIf="emploi && emploi.domaine_famille">
                <span class="alv-label">Domaine :</span>
                <span class="alv-value">{{ emploi.domaine_famille.label }}</span>
            </li>
            <li *ngIf="emploi && emploi.fonctionnel === true">
                <span class="alv-value">Fonctionnel</span>
            </li>
            <li *ngIf="experience">
                <app-periode [start]="experience.debut" [end]="experience.fin"></app-periode>
            </li>
        </ul>
    </div>
    <div class="alc-agent-elt col-md-{{ showAgent ? 6 : 12 }}"
         *ngIf="showAgent && emploi && emploi.is_vacant">
        <div class="row">
            <div class="col-md-12 col-lg-12 ct-no-job-infos empty-block">
                <div class="inner">
                    <p>
                        Poste vacant<br/>
                        <small>Attention : cette information ne vaut pas déclaration de vacance de poste.</small>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 alc-agent-elt" *ngIf="showAgent && !emploi.is_vacant && experience && experience.id">
        <app-agent-light [agent]="experience.user_id"
                         [experience]="experience">
        </app-agent-light>
    </div>
    <div class="alc-agent-elt col-md-6"
         *ngIf="showAgent && !emploi.is_vacant && (!experience || !experience.id)">
        <div class="row">
            <div class="col-md-12 col-lg-12 ct-no-job-infos empty-block">
                <div class="inner">
                    <p>
                        Sans information sur l'affectation du poste
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
