<form class="agent-mobilite-update-form" #mobiliteUpdateForm="ngForm" (ngSubmit)="onSubmit(mobiliteUpdateForm)">
  <div class="row">
    <div class="form-group col-md-4 col-lg-4">
      <label class="form-check-label label-alone">
        Se déclarer en recherche de mobilité
      </label>

      <div class="form-check form-check-inline">
        <label class="form-check-label" for="field_mobilite_actif_y">
          <input class="form-check-input" type="radio"
          name="field_mobilite_actif"
          id="field_mobilite_actif_y"
          value="1"
          [checked]="agent.field_mobilite_actif"
          (click)="agent.field_mobilite_actif=1">
          Oui
        </label>
      </div>

      <div class="form-check form-check-inline">
        <label class="form-check-label" for="field_mobilite_actif_n">
          <input class="form-check-input" type="radio"
          name="field_mobilite_actif"
          id="field_mobilite_actif_n"
          value="0"
          [checked]="!agent.field_mobilite_actif"
          (click)="agent.field_mobilite_actif=0">
          Non
        </label>
      </div>

    </div>
  </div>
  <div class="row" *ngIf="agent.field_mobilite_actif == '1'">
    <div class="form-group col-md-4 col-lg-4">
      <label class="form-check-label label-alone">
        Zone géographique de mobilité
      </label>
      <angular2-multiselect [data]="regionsList"
          [(ngModel)]="fieldMobiliteZoneIds"
          [ngModelOptions]="{standalone: true}"
          [settings]="settings">
          <c-badge>
            <ng-template let-item="item">
              <label>{{item.label ? item.label : item.name}}</label>
            </ng-template>
          </c-badge>
      </angular2-multiselect>
    </div>

    <div class="form-group col-md-4">
      <label>Domaine souhaité</label>
      <angular2-multiselect [data]="famillesList"
          [(ngModel)]="fieldMobiliteDomaineIds"
          [ngModelOptions]="{standalone: true}"
          [settings]="settings">
          <c-badge>
            <ng-template let-item="item">
              <label>{{item.label ? item.label : item.name}}</label>
            </ng-template>
          </c-badge>
      </angular2-multiselect>
    </div>

    <div class="form-group col-md-4">
      <label>Fonction souhaitée</label>
      <angular2-multiselect [data]="cotationsList"
          [(ngModel)]="fieldMobiliteFonctionIds"
          [ngModelOptions]="{standalone: true}"
          [settings]="settings">
          <c-badge>
            <ng-template let-item="item">
              <label>{{item.label ? item.label : item.name}}</label>
            </ng-template>
          </c-badge>
      </angular2-multiselect>
    </div>
  </div>
  <div class="row" *ngIf="agent.field_mobilite_actif == '1'">

    <div class="form-group col-md-4 col-lg-4">
      <label class="form-check-label label-alone">
        Solliciter l'accompagnement du CNFPT dans votre
        recherche de mobilité
      </label>

      <div class="form-check form-check-inline">
        <label class="form-check-label" for="field_mobilite_cnfpt_y">
          <input class="form-check-input" type="radio"
          name="field_mobilite_cnfpt"
          id="field_mobilite_cnfpt_y"
          value="1"
          [checked]="agent.field_mobilite_cnfpt"
          (click)="agent.field_mobilite_cnfpt=1">
          Oui
        </label>
      </div>

      <div class="form-check form-check-inline">
        <label class="form-check-label" for="field_mobilite_cnfpt_n">
          <input class="form-check-input" type="radio"
          name="field_mobilite_cnfpt"
          id="field_mobilite_cnfpt_n"
          value="0"
          [checked]="!agent.field_mobilite_cnfpt"
          (click)="agent.field_mobilite_cnfpt=0">
          Non
        </label>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="agent.field_mobilite_actif == '1'">
    <div class="form-group col-md-4 col-lg-4">
      <label class="form-check-label label-alone">
        Souhaitez-vous masquer cette information à la communauté ?
      </label>

      <div class="form-check form-check-inline">
        <label class="form-check-label" for="field_mobilite_hide_authent_y">
          <input class="form-check-input" type="radio"
          name="field_mobilite_hide_authent"
          id="field_mobilite_hide_authent_y"
          value="1"
          [checked]="agent.field_mobilite_hide_authent"
          (click)="agent.field_mobilite_hide_authent=1">
          Oui
        </label>
      </div>

      <div class="form-check form-check-inline">
        <label class="form-check-label" for="field_mobilite_hide_authent_n">
          <input class="form-check-input" type="radio"
          name="field_mobilite_hide_authent"
          id="field_mobilite_hide_authent_n"
          value="0"
          [checked]="!agent.field_mobilite_hide_authent"
          (click)="agent.field_mobilite_hide_authent=0">
          Non
        </label>
      </div>
    </div>

    <div class="form-group col-md-4 col-lg-4">
      <label class="form-check-label label-alone">
        Souhaitez-vous masquer cette information au grand public ?
      </label>

      <div class="form-check form-check-inline">
        <label class="form-check-label" for="field_mobilite_hide_anonym_y">
          <input class="form-check-input" type="radio"
          name="field_mobilite_hide_anonym"
          id="field_mobilite_hide_anonym_y"
          value="1"
          [checked]="agent.field_mobilite_hide_anonym"
          (click)="agent.field_mobilite_hide_anonym=1">
          Oui
        </label>
      </div>

      <div class="form-check form-check-inline">
        <label class="form-check-label" for="field_mobilite_hide_anonym_n">
          <input class="form-check-input" type="radio"
          name="field_mobilite_hide_anonym"
          id="field_mobilite_hide_anonym_n"
          value="0"
          [checked]="!agent.field_mobilite_hide_anonym"
          (click)="agent.field_mobilite_hide_anonym=0">
          Non
        </label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-12">
      <button type="button"
      class="arrow-btn btn btn-primary text-uppercase"
      (click)="agent.field_mobilite_actif == '1' ? confirmBeforeSubmit(mobiliteUpdateForm) : onSubmit(mobiliteUpdateForm)">
      Valider
    </button>
  </div>
  </div>
</form>
