import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-fiche-emploi-hfpt',
    templateUrl: './fiche-emploi-hfpt.component.html',
    styleUrls: ['./fiche-emploi-hfpt.component.scss']
})
export class FicheEmploiHfptComponent implements OnInit {
    @Input() experienceHFPT: any;

    constructor() {
    }

    ngOnInit() {

    }

}
