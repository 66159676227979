import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {AuthentificationModule} from '../authentification/authentification.module';
import {FormsModule} from '@angular/forms';
import {accountRoutes} from './account-routing.module';
import {AccountService} from './account.service';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import {AnnuaireModule} from '../annuaire/annuaire.module';
import {RegistrationCaptureService} from './registration-capture.service';
import {RegistrationComponent} from './registration/registration.component';
import {FormEncadrantComponent} from './form-encadrant/form-encadrant.component';
import {FormReferentComponent} from './form-referent/form-referent.component';
import {EmploiModule} from '../emploi/emploi.module';
import {ContactCnfptComponent} from './contact-cnfpt/contact-cnfpt.component';
import {NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(
            accountRoutes
        ),
        AuthentificationModule,
        SharedModule,
        MultiselectDropdownModule,
        AngularMultiSelectModule,
        AnnuaireModule,
        EmploiModule,
        NgbTypeaheadModule
    ],
    providers: [
        AccountService,
        RegistrationCaptureService
    ],
    declarations: [FormEncadrantComponent, FormReferentComponent, RegistrationComponent, ContactCnfptComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AccountModule {
}
