
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getDrupalUri} from '../shared/external';
import { Observable } from 'rxjs';


import {HttpClient} from '@angular/common/http';

@Injectable()
export class AccueilService {

    readonly apiPath: string = '/customapi/home';
    readonly bandeauPath: string = '/customapi/bandeau';

    constructor(private http: HttpClient) {
    }

    public getCounters(): Observable<any> {
        return this.http.get<any>(getDrupalUri() + this.apiPath + '/counters').pipe(map(
            (val) => {
                return val;
            }
        ));
    }

    /**
     *  Get all banners
     * @returns {Observable<any>}
     */
    public getAllBanners(): Observable<any> {
        return this.http.get<any>(getDrupalUri() + this.bandeauPath + '/all').pipe(map(
            (val) => {
                return val;
            }
        ));
    }

}
