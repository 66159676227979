
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';


import {getDrupalUri} from '../shared/external';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class NewsService {

    private apiPath = '/api/news?_format=json';

    constructor(private http: HttpClient) {
    }

    public getLast(): Observable<any> {
        return this.http.get<any>(
            getDrupalUri() + this.apiPath).pipe(
            map(
                (news) => {
                    return news;
                }
            ));
    }

    public getById(id: string): Observable<any> {
        return this.http.get<any>(
            getDrupalUri() + this.apiPath + '&id=' + id).pipe(
            map(
                (news) => {
                    return news;
                }
            ));
    }

    public getByPagination(page: string): Observable<any> {
        return this.http.get<any>(
            getDrupalUri() + this.apiPath + '&page=' + page).pipe(
            map(
                (news) => {
                    return news;
                }
            ));
    }
}
