import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-poste-referent',
    templateUrl: './poste-referent.component.html',
    styleUrls: ['./poste-referent.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PosteReferentComponent implements OnInit {

    @Input() poste: any;

    constructor() {
    }

    ngOnInit() {
    }

}
