import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AgentService } from '../../../..//agent/agent.service';
import { LoaderService } from '../../../..//shared/loader/loader.service';

@Component({
  selector: 'profil-utilisateur-coordonnees',
  templateUrl: './coordonnees.component.html',
  styleUrls: ['./coordonnees.component.scss']
})
export class CoordonneesUtilisateurComponent implements OnInit {

  @Input() agent: any;
  @Output() agentChange = new EventEmitter<any>();

  @Input() success: boolean;
  @Output() successChange = new EventEmitter<boolean>();

  constructor(
    private agentService: AgentService,
    private loader: LoaderService
  ) { }

  ngOnInit() {
  }

  /**
   * Send the personnal form data to backend
   * @param {NgForm} submittedForm
   */
  public onSubmit(submittedForm: NgForm) {
      this.loader.show();
      const data = submittedForm.value;
      data['id'] = this.agent.id;
      data['field_diffusion_communautes'] = this.agent.field_diffusion_communautes;
      data['field_diffusion_coordonnees'] = this.agent.field_diffusion_coordonnees;
      this.agentService.updateAgent(data, null, null).subscribe(
          (value) => {
              this.loader.hide();
              this.agentChange.emit(value);
              this.successChange.emit(true);
          },
          (errors) => {
              this.loader.hide();
              this.success = false;
          }
      );
  }
}
