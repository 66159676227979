import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CollectiviteService } from './collectivite.service';

@Injectable()
export class CollectiviteResolve implements Resolve<any> {
    constructor(
        private collectiviteService: CollectiviteService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      if (route.paramMap.has('idCollectivite')) {
        return this.collectiviteService.get(+route.paramMap.get('idCollectivite'));
      }

      return this.collectiviteService.getWithRelationships(+route.paramMap.get('id'), ['emplois', 'offres', 'dves', 'referents']);
    }
}
