import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'profil-collectivite-statut',
  templateUrl: './statut.component.html',
  styleUrls: ['./statut.component.scss']
})
export class StatutCollectiviteComponent implements OnInit {

  @Input() collectivite: any;
  @Output() collectiviteChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

}
