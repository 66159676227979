<!--<script src="emploi-erreur.component.ts"></script>-->
<div class="modal-header">
    <h2 class="bordered-title bolded">
        Signaler une erreur
    </h2>

    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span class="aed-icon aed-icon-close" aria-hidden="true"></span>
        <span class="sr-only" aria-hidden="true">Fermer</span>
    </button>
</div>
<div class="modal-body">
    <div class="inner">
        <form class="notify-emploi-error-form" #notifyEmploiErrorForm="ngForm" (ngSubmit)="onSubmit(notifyEmploiErrorForm)" novalidate>
            <div class="row">
                <div class="form-group col-md-12 col-lg-12">
                    <label for="message">Votre message</label>
                    <textarea class="form-control form-control-lg" style="height: 300px" id="message" name="message" [(ngModel)]="reporting.message" required #message="ngModel"></textarea>
                </div>

                <div class="form-group col-md-12 col-lg-12">
                    <button type="submit"
                            class="arrow-btn btn btn-primary text-uppercase"
                            [disabled]="notifyEmploiErrorForm.invalid">
                        Signaler
                    </button>
                </div>

            </div>
        </form>
    </div>
</div>