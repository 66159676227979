import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AgentService} from '../agent.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {AuthentificationService} from '../../authentification/authentification.service';
import {OwnershipAccessState} from '../../authentification/has-any-role';
import {EmploiErreurComponent} from '../../emploi/emploi-erreur/emploi-erreur.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AvatarComponent} from '../../shared/avatar/avatar.component';

@Component({
    selector: 'app-fiche',
    templateUrl: './fiche.component.html',
    styleUrls: ['./fiche.component.scss']
})
export class FicheComponent implements OnInit {
    notifySuccess: boolean = undefined;
    agent: any;
    currentUserId: any = null;
    accessState: OwnershipAccessState = {
        currentUserRoles: [],
        hasOwnership: false
    };
    public isCollapsed = false;
    public showHistory = false;

    constructor(private agentService: AgentService,
                private loaderService: LoaderService,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private authService: AuthentificationService,
                private router: Router) {
    }

    ngOnInit() {
        this.loaderService.show();

        // Get Agent from resolver
        this.agent = this.route.snapshot.data['agent'];

        this.authService.identity.subscribe(
            (identity) => {
                this.accessState = {
                    currentUserRoles: identity && identity.roles ? identity.roles : [],
                    hasOwnership: !(identity === null || identity === undefined) && !(identity.id === null || identity.id === undefined) && identity.id === this.agent.id
                };
                if (identity != null) {
                    this.currentUserId = identity.id;
                }
                this.loaderService.hide();
            },
            () => {
                this.accessState = {
                    currentUserRoles: [],
                    hasOwnership: false
                };
                this.loaderService.hide();
            }
        );
    }

    canSeeCoordonnees(agent: any, data: any): boolean {
        return !(data === null || data === undefined);
    }

    /**
     * Display the error notification form
     */
    public notifyError(emploi: any): void {
        const modalRef = this.modalService.open(EmploiErreurComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.emploi = emploi;
        modalRef.componentInstance.agent_id = this.currentUserId;
        modalRef.result.then((result) => {
            this.notifySuccess = result.success;
            window.scrollTo(0, 0);
        }, (result) => {
            this.notifySuccess = undefined;
        });
    }
}
