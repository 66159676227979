import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'profil-collectivite-referents',
  templateUrl: './referents.component.html',
  styleUrls: ['./referents.component.scss']
})
export class ReferentsComponent implements OnInit {

  @Input() collectivite: any;
  @Output() collectiviteChange = new EventEmitter<any>();

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  goToProfilUtilisateur(id: string) {
    this.router.navigate(['dashboard/profil-utilisateur', id]);
  }
}
