
<app-breadcrumb></app-breadcrumb>

<div class="container">

  <div class="row">
    <div class="col-12">
      <header class="page-title">
        <a class="clickable float-right" back-button>
          <span class="aed-icon aed-icon-back" aria-hidden="true"></span>
          <span class="pm-link-text plt-back">Retour</span>
        </a>
      </header>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-12">
      <app-alert *ngIf="cvSuccess === true"
                  [isAlertClosable]="true"
                  [alertType]="'success'"
                  [alertMessage]="'Votre CV a été mis à jour'"
      >
      </app-alert>

      <app-alert *ngIf="cvSuccess === false"
                  [isAlertClosable]="true"
                  [alertType]="'danger'"
                  [alertMessage]="'Impossible de mettre votre CV à jour (le fichier doit être un PDF de moins de 2 Mo)'"
      >
      </app-alert>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <app-alert *ngIf="photoSuccess === true"
                [isAlertClosable]="true"
                [alertType]="'success'"
                [alertMessage]="'Votre photo a été mise à jour'"
      >
      </app-alert>

      <app-alert *ngIf="photoSuccess === false"
                [isAlertClosable]="true"
                [alertType]="'danger'"
                [alertMessage]="'Impossible de mettre votre photo à jour (le fichier doit être une image de moins de 1 Mo)'"
      >
      </app-alert>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <app-alert *ngIf="notifySuccess === true"
                [isAlertClosable]="true"
                [alertType]="'success'"
                [alertMessage]="'Votre message a été envoyé au référent de la collectivité'"
      >
      </app-alert>

      <app-alert *ngIf="notifySuccess === false"
                [isAlertClosable]="true"
                [alertType]="'danger'"
                [alertMessage]="'Impossible d\'envoyer votre message'"
      >
      </app-alert>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <app-alert *ngIf="success === true"
                [isAlertClosable]="true"
                [alertType]="'success'"
                [alertMessage]="'Le profil a été mis à jour avec succès.'"
      >
      </app-alert>

      <app-alert *ngIf="success === false"
                [isAlertClosable]="true"
                [alertType]="'danger'"
                [alertMessage]="'La mise à jour de votre profil utilisateur a échoué.'"
      >
      </app-alert>
    </div>
  </div>

  <div class="aed-accordion">
    <ngb-accordion [activeIds]="'identity'">
      <ngb-panel id="identity">
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
            Identité
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <profil-utilisateur-identite [(agent)]="this.agent" [(success)]="this.success"></profil-utilisateur-identite>
        </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
            Statut utilisateur
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <profil-utilisateur-statut [(agent)]="this.agent"></profil-utilisateur-statut>
        </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
            Profil professionnel
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <profil-utilisateur-profil [(agent)]="this.agent" [(success)]="this.success" [(cvSuccess)]="this.cvSuccess"></profil-utilisateur-profil>
        </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
            Coordonnées professionnelles
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <profil-utilisateur-coordonnees [(agent)]="this.agent" [(success)]="this.success"></profil-utilisateur-coordonnees>
        </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
            Expériences et formations
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <profil-utilisateur-experiences
            [(agent)]="this.agent" [(success)]="this.success" [(notifySuccess)]="this.notifySuccess"
            [accessState]="this.accessState" (refreshEvent)="refresh()">
          </profil-utilisateur-experiences>
        </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
            Mobilité
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <profil-utilisateur-mobilite [(agent)]="this.agent" [(success)]="this.success"></profil-utilisateur-mobilite>
        </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
            Demandes de droit
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <profil-utilisateur-demandes [(agent)]="this.agent"></profil-utilisateur-demandes>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>

</div>
