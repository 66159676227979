import { Injectable } from '@angular/core';

@Injectable()
export class SearchDataService {

    public agentSelected: string = '';
    public emploiSelected: string = '';
    public placeSelected: string = '';
    public departementsSelected: string;
    public cjsSelected: string;
    public tcdsSelected: string;
    public cotationsSelected: string;
    public filieresSelected: string;
    public domainesSelected: string;
    public searchResultCount: number;
    public searchResultLastPage: number;
    public searchResults: any;
    public currentPage: number;

    constructor() {}

    isEmpty() {
        return (this.agentSelected === undefined || this.agentSelected === '')
            && (this.emploiSelected === undefined || this.agentSelected === '')
            && (this.placeSelected === undefined || this.agentSelected === '')
            && this.departementsSelected === undefined
            && this.cjsSelected === undefined
            && this.tcdsSelected === undefined
            && this.cotationsSelected === undefined
            && this.filieresSelected === undefined
            && this.domainesSelected === undefined
            && this.searchResultCount === undefined
            && this.searchResultLastPage === undefined
            && this.searchResults === undefined
            && this.currentPage === undefined;
    }

    clearData() {
        this.agentSelected = '';
        this.emploiSelected = '';
        this.placeSelected = '';
        this.departementsSelected = undefined;
        this.cjsSelected = undefined;
        this.tcdsSelected = undefined;
        this.cotationsSelected = undefined;
        this.filieresSelected = undefined;
        this.domainesSelected = undefined;
        this.searchResultCount = undefined;
        this.searchResultLastPage = undefined;
        this.searchResults = undefined;
        this.currentPage = undefined;
    }
}
