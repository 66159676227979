<div class="row">
  <div class="form-group col-md-12 col-lg-12">
    <a class="arrow-btn btn btn-third text-uppercase mar-10" (click)="openAddEmploiForm()" *hasRoleAgent="accessState">Ajouter un emploi occupé</a>
    <a class="arrow-btn btn btn-third text-uppercase mar-10" (click)="openAddReferentForm()" *hasRoleReferentCT="accessState">Ajouter un poste référent</a>
  </div>
</div>
<div class="row">
  <div class="col-md-12 col-lg-12">
    <div class="row aed-list-container">
      <div [ngClass]="{ odd: odd, even: even, 'col-md-12 col-lg-12 alc-elt': true}" *ngFor="let job of agent.experiences; let even = even; let odd = odd">
        <div class="inner clearfix">

          <div class="row" *ngIf="job?.type?.id !== undefined">

            <div class="col-md-4 col-lg-4">
              <app-fiche-ct-light *ngIf="job?.type?.id === 'exp_affectation_fpt'" [collectivite]="job.field_exp_fpt_emploi.collectivite" [newDisplay]="true"></app-fiche-ct-light>
            </div>

            <app-fiche-emploi-light
              [emploi]="job.type.id === 'exp_affectation_fpt' ? job.field_exp_fpt_emploi : null"
              [experience]="job"
              [showAgent]="false"
              class="col-md-4 col-lg-4">
            </app-fiche-emploi-light>

            <div class="col-md-4 col-lg-4 d-flex align-items-center" *isLogged="accessState">

              <nav class="alc-links">
                <a class="arrow-btn btn btn-third btn-sm text-uppercase" (click)="notifyError(job.field_exp_fpt_emploi)" *ngIf="job?.type?.id === 'exp_affectation_fpt'">
                  Signaler une erreur
                </a>

                <a class="arrow-btn btn btn-primary btn-sm text-uppercas" (click)="openEditExperienceForm(job, false, job.type.id === 'exp_affectation_fpt')">
                  Modifier l'expérience
                </a>

                <div class="btn-group-vertical" data-toggle="buttons" *ngIf="job?.type?.id === 'exp_affectation_fpt'">
                  <label for="currentExperienceFlag" class="btn btn-primary active btn-checkbox" *ngIf="job?.isOngoing">
                    <input type="checkbox" name="currentExperienceFlag" id="currentExperienceFlag" value="1" [checked]="true" [disabled]="true"/>
                    Poste actuel
                  </label>

                  <a name="becomeReferentFlag" id="becomeReferentFlag" (click)="openBecomeReferentForm(job)">
                    <label for="becomeReferentFlag" class="btn btn-primary btn-checkbox" [class.active]="job.isReferent" *ngIf="job?.isOngoing || job.isReferent">
                      {{ !job.isReferent ? 'Devenir ' : '' }}Référent de la collectivité
                    </label>
                  </a>

                </div>

              </nav>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="row aed-formations-container agent-detail aed-form-edition">
  <div class="afuf-elt col-md-12 col-lg-12" [class.odd]="odd" [class.even]="even"
    *ngFor="let formation of agent.field_formations; let ftIndex =index; let odd=odd; let even=even">

    <div class="inner">

      <a class="fg-action fga-edit clickable" (click)="openFormationForm(formation)" title="Modifier la formation">
        <span class="ard-icon aed-icon-edit" aria-hidden="true"></span>
        <span class="sr-only">Modifier</span>
      </a>

      <a class="fg-action fga-remove clickable" (click)="removeFormation(formation)" title="Supprimer la formation">
        <span class="ard-icon aed-icon-close" aria-hidden="true"></span>
        <span class="sr-only">Supprimer</span>
      </a>

      <div class="row ae-formation">
        <app-formation [formation]="formation" [showVisibility]="true"></app-formation>
      </div>

    </div>
  </div>

  <div class="col-md-12 col-lg-12" *ngIf="!agent.field_formations ||agent.field_formations.length < 6">
    <a class="arrow-btn btn btn-primary text-uppercase" (click)="openFormationForm(null)" *hasRoleAgent="accessState">Ajouter une formation</a>
  </div>
</div>
