<form class="delete-dialog-form" name="deleteForm">
    <div class="modal-header">
        <h2 class="modal-title bordered-title">Erreur de Connexion</h2>
    </div>
    <div class="modal-body">
        <div class="alert alert-warning"><h3>Attention !</h3>
            Un problème technique a bloqué votre action, merci de vous connecter de nouveau.
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-dialog-sm" data-dismiss="modal" (click)="confirmLogout()">
            Me reconnecter
        </button>
    </div>
</form>
