import {Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS, Validator, AbstractControl, Validators} from '@angular/forms';


const VIADEO_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => ViadeoValidatorDirective),
    multi: true
};

@Directive({
    selector: '[viadeo][formControlName],[viadeo][formControl],[viadeo][ngModel]',
    providers: [VIADEO_VALIDATOR]
})
export class ViadeoValidatorDirective implements Validator {

    validate(c: AbstractControl): { [key: string]: any } {
        const v: string = c.value;

        if ((v === null || v === undefined) || v.trim() === '') {
            return null;
        }
        const regexp = new RegExp('^http(?:s)?:\\/\\/(?:www\\.)?viadeo\\.com\\/p\\/([a-zA-Z0-9]+)$');
        return regexp.test(v) ? null : {viadeo: true};
    }
}
