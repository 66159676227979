import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from './layout/layout.component';
import {RouterModule} from '@angular/router';
import {AuthentificationModule} from '../authentification/authentification.module';
import {HttpClientModule} from '@angular/common/http';
import {LoaderComponent} from './loader/loader.component';
import {LoaderService} from './loader/loader.service';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {PageModule} from '../page/page.module';
import {MapLightComponent} from './map/map-light/map-light.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {AlertComponent} from './alert/alert.component';
import {AccountModule} from '../account/account.module';
import {TextSizeService} from './utils/text-size.service';
import {PhoneValidatorDirective} from './forms/validators/phone-validator.directive';
import {MobileValidatorDirective} from './forms/validators/mobile-validator.directive';
import {TwitterValidatorDirective} from './forms/validators/twitter-validator.directive';
import {FacebookValidatorDirective} from './forms/validators/facebook-validator.directive';
import {LinkedinValidatorDirective} from './forms/validators/linkedin-validator.directive';
import {ViadeoValidatorDirective} from './forms/validators/viadeo-validator.directive';
import {CustomEmailValidatorDirective} from './forms/validators/custom-email-validator.directive';
import {AedTypeaheadComponent} from './forms/aed-typeahead/aed-typeahead.component';
import {FormsModule} from '@angular/forms';
import {NgbModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {LoaderSmallComponent} from './loader-small/loader-small.component';
import {LoaderSmallService} from './loader-small/loader-small.service';
import {MapService} from './map/map.service';
import {MapComponent} from './map/map.component';
import {SocialNetworksComponent} from './social-networks/social-networks.component';
import {DeleteDialogComponent} from './delete-dialog/delete-dialog.component';
import {PeriodeComponent} from './periode/periode.component';
import {AedYearSelectorComponent} from './forms/aed-year-selector/aed-year-selector.component';
import {YearPeriodeValidatorDirective} from './forms/validators/year-periode-validator.directive';
import {UrlValidatorDirective} from './forms/validators/url-validator.directive';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {AddressComponent} from './address/address.component';
import {AvatarComponent} from './avatar/avatar.component';
import {UnauthorizedDialogComponent} from "./unauthorized-dialog/unauthorized-dialog.component";
import {TimePeriodeValidatorDirective} from "./forms/validators/time-periode-validator.directive";
import {SearchDataService} from "./search-data/search-data.service";
import {AgentLightComponent} from "./agent-light/agent-light.component";
import { BackButtonDirective } from './back-button/back-button.directive';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        AuthentificationModule,
        PerfectScrollbarModule,
        FormsModule,
        NgbTypeaheadModule,
        NgbModule
    ],
    providers: [
        LoaderService,
        TextSizeService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        LoaderSmallService,
        MapService,
        SearchDataService
    ],
    declarations: [
        LayoutComponent,
        LoaderComponent,
        BreadcrumbComponent,
        AlertComponent,
        PhoneValidatorDirective,
        MobileValidatorDirective,
        TwitterValidatorDirective,
        FacebookValidatorDirective,
        LinkedinValidatorDirective,
        ViadeoValidatorDirective,
        CustomEmailValidatorDirective,
        UrlValidatorDirective,
        YearPeriodeValidatorDirective,
        TimePeriodeValidatorDirective,
        AedTypeaheadComponent,
        LoaderSmallComponent,
        MapComponent,
        MapLightComponent,
        SocialNetworksComponent,
        DeleteDialogComponent,
        ConfirmDialogComponent,
        PeriodeComponent,
        AedYearSelectorComponent,
        AddressComponent,
        AvatarComponent,
        UnauthorizedDialogComponent,
        AgentLightComponent,
        BackButtonDirective
    ],
    exports: [
        LayoutComponent,
        BreadcrumbComponent,
        AlertComponent,
        PhoneValidatorDirective,
        MobileValidatorDirective,
        TwitterValidatorDirective,
        FacebookValidatorDirective,
        LinkedinValidatorDirective,
        ViadeoValidatorDirective,
        CustomEmailValidatorDirective,
        YearPeriodeValidatorDirective,
        TimePeriodeValidatorDirective,
        UrlValidatorDirective,
        AedTypeaheadComponent,
        LoaderSmallComponent,
        MapComponent,
        MapLightComponent,
        SocialNetworksComponent,
        DeleteDialogComponent,
        ConfirmDialogComponent,
        PeriodeComponent,
        AedYearSelectorComponent,
        AddressComponent,
        AvatarComponent,
        UnauthorizedDialogComponent,
        AgentLightComponent,
        BackButtonDirective
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
