<label class="form-check-label label-alone" for="actif">
  Actif
</label>
<div class="form-check form-check-inline">
  <label class="form-check-label" for="actif_y">
    <input class="form-check-input" type="radio"
    name="actif" id="actif_y" value="1"
    [checked]="this.collectivite.actif"
    [disabled]="true">
    Oui
  </label>
</div>
<div class="form-check form-check-inline">
  <label class="form-check-label" for="actif_n">
    <input class="form-check-input" type="radio"
    name="actif" id="actif_n" value="0"
    [checked]="!this.collectivite.actif"
    [disabled]="true">
    Non
  </label>
</div>

<label class="form-check-label label-alone" for="archivage_effectue">
  Archivé
</label>
<div class="form-check form-check-inline">
  <label class="form-check-label" for="archivage_effectue_y">
    <input class="form-check-input" type="radio"
    name="archivage_effectue" id="archivage_effectue_y" value="1"
    [checked]="this.collectivite.fields_archivage.archivage_effectue"
    [disabled]="true">
    Oui
  </label>
</div>
<div class="form-check form-check-inline">
  <label class="form-check-label" for="archivage_effectue_n">
    <input class="form-check-input" type="radio"
    name="archivage_effectue" id="archivage_effectue_n" value="0"
    [checked]="!this.collectivite.fields_archivage.archivage_effectue"
    [disabled]="true">
    Non
  </label>
</div>
