import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from '../authentification.service';

@Component({
  selector: 'app-configure-auth',
  templateUrl: './configure-auth.component.html'
})
export class ConfigureAuthComponent implements OnInit {

  constructor(
    private authentificationService: AuthentificationService
  ) { }

  ngOnInit() {
    this.authentificationService.tryDecodeUrlFromLoginOrSession();
  }

}
