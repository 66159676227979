import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-fiche-ct-light',
    templateUrl: './fiche-ct-light.component.html',
    styleUrls: ['./fiche-ct-light.component.scss']
})
export class FicheCtLightComponent implements OnInit {

  @Input() collectivite: any;

  @Input() newDisplay: boolean = false;
  collectiviteEditLink: string = '/collectivite';

    constructor() {
    }

    ngOnInit() {
      if (this.newDisplay) {
        this.collectiviteEditLink = "/dashboard/profil-collectivite"
      }
    }

}
