import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AgentService } from '../../../../agent/agent.service';
import { ReferentFormComponent } from '../../../../agent/referent-form/referent-form.component';
import { OwnershipAccessState } from '../../../../authentification/has-any-role';
import { EmploiFormComponent } from '../../../../emploi/emploi-form/emploi-form.component';
import { LoaderService } from '../../../../shared/loader/loader.service';
import { EmploiErreurComponent } from '../../../../emploi/emploi-erreur/emploi-erreur.component';
import { CsrfService } from '../../../../authentification/csrf.service';
import { DeleteDialogComponent } from '../../../../shared/delete-dialog/delete-dialog.component';
import { FormationService } from '../../../../agent/formation.service';
import { FormationFormComponent } from '../../../../agent/formation-form/formation-form.component';
import { ExperienceFormComponent } from '../../../../emploi/experience-form/experience-form.component';

@Component({
  selector: 'profil-utilisateur-experiences',
  templateUrl: './experiences.component.html',
  styleUrls: ['./experiences.component.scss']
})
export class ExperiencesComponent implements OnInit {

  @Input() agent: any;
  @Output() agentChange = new EventEmitter<any>();

  @Input() success: boolean;
  @Output() successChange = new EventEmitter<boolean>();

  @Input() notifySuccess: boolean;
  @Output() notifySuccessChange = new EventEmitter<boolean>();

  @Input() accessState: OwnershipAccessState;

  @Output() refreshEvent = new EventEmitter<any>();

  metiers: any[];

  public csrfToken = '';

  constructor(
    private modalService: NgbModal,
    private agentService: AgentService,
    private formationService: FormationService,
    private loader: LoaderService,
    private csrfService: CsrfService
  ) { }

  ngOnInit() {
    this.getFormDatas();
    this.updateCsrfToken();
  }

  public getFormDatas(): void {
    if (this.agent != null) {
        this.agentService.getFormDatas(this.agent.id)
            .subscribe((data) => {
                this.metiers = data.metiers;
                this.loader.hide();
            }, () => {
                this.loader.hide();
            });
    }
  }

  /**
  * Refresh the CSRF Token
  */
  private updateCsrfToken() {
    this.csrfService.getToken().subscribe(
      token => {
        this.csrfToken = token;
      });
  }

  /**
   * Display the "devenir referent" form for a real referent
   */
  public openReferentForm(poste: any): void {
      this.success = undefined;
      poste.isReferent = true;
      const modalRef = this.modalService.open(ReferentFormComponent, {windowClass: 'custom-modal-lg'});
      modalRef.componentInstance.collectivite = poste.collectivite;
      modalRef.componentInstance.poste = poste;
      modalRef.componentInstance.agent = this.agent;
      modalRef.componentInstance.isReferent = poste.isReferent;
      modalRef.componentInstance.metiers = this.metiers;
      modalRef.result.then((result) => {
          if (result.success) {
            poste.isReferent = result.referent;
            this.successChange.emit(true);
            this.refresh();
          } else {
            poste.isReferent = !poste.isReferent;
            this.successChange.emit(false);
          }
          if (!(result.result === null || result.result === undefined)) {
              result.result.then(() => {
              }, () => {
              });
          }
      }, () => {
          poste.isReferent = !poste.isReferent;
      });
  }

  /**
   * Display the "devenir referent" form
   */
  public openBecomeReferentForm(experience: any): void {
      this.success = undefined;
      const modalRef = this.modalService.open(ReferentFormComponent, {windowClass: 'custom-modal-lg'});
      modalRef.componentInstance.collectivite = experience.field_exp_fpt_emploi.collectivite;
      modalRef.componentInstance.agent = this.agent;
      modalRef.componentInstance.isReferent = experience.isReferent;
      modalRef.componentInstance.metiers = this.metiers;
      if (experience.isReferent) {
          const posteRefs = this.agent.experiences.filter(x => x.isOngoing && x.field_exp_pct_collectivite && x.field_exp_pct_collectivite.id === experience.field_exp_fpt_emploi.collectivite.id);
          if (posteRefs != null && posteRefs.length >= 1) {
              modalRef.componentInstance.poste = posteRefs[0];
          }
      }
      modalRef.result.then((result) => {
          if (result.success) {
            experience.isReferent = result.referent;
            this.successChange.emit(true);
            this.refresh();
          } else {
            this.successChange.emit(false);
          }
          if (!(result.result === null || result.result === undefined)) {
              result.result.then(() => {
              }, () => {
              });
          }
      }, () => {
      });
  }

  /**
   * Display the add emploi form
   */
  public openAddEmploiForm() {
      this.success = undefined;
      const modalRef = this.modalService.open(EmploiFormComponent, {windowClass: 'custom-modal-lg'});
      modalRef.componentInstance.agent = this.agent;
      modalRef.result.then((result) => {
          if (result.success) {
            this.successChange.emit(true);
            this.refresh();
          } else {
            this.successChange.emit(false);
          }
      });
  }

  /**
   * Display the referent form
   */
  public openAddReferentForm() {
      this.success = undefined;
      const modalRef = this.modalService.open(ReferentFormComponent, {windowClass: 'custom-modal-lg'});
      modalRef.componentInstance.collectivite = null;
      modalRef.componentInstance.agent = this.agent;
      modalRef.componentInstance.isReferent = false;
      modalRef.componentInstance.metiers = this.metiers;
      modalRef.result.then((result) => {
          if (result.success) {
            this.successChange.emit(true);
            this.refresh();
          } else {
            this.successChange.emit(false);
          }
      });
  }

    /**
     *  Remove a formation matching the given index
     * @param formation
     */
     public removeFormation(formation): void {
      let modalRef: NgbModalRef;
      modalRef = this.modalService.open(DeleteDialogComponent, {size: 'sm'});
      modalRef.result.then((result) => {
          if (result === true) {
              this.loader.show();
              this.formationService.delete(formation.id, this.csrfToken).subscribe(() => {
                this.loader.hide();
                this.successChange.emit(true);
                this.refresh();
              }, () => {
                this.loader.hide();
                this.successChange.emit(false);
              });
          }
      });
  }

  /**
   * Open Add Formation form
   */
  public openFormationForm(formation: any) {
      this.success = undefined;
      const modalRef = this.modalService.open(FormationFormComponent, {windowClass: 'custom-modal-lg'});
      modalRef.componentInstance.agent = this.agent;
      modalRef.componentInstance.formation = formation;
      modalRef.result.then((result) => {
          if (result.success) {
            this.successChange.emit(true);
            this.refresh();
          } else {
            this.successChange.emit(false);
          }
      });
  }

  /**
   * Display the emploi edition form
   */
  public openEditExperienceForm(experience: any, isHFPT = false, isExperienceFpt = false): void {
      const modalRef = this.modalService.open(ExperienceFormComponent, {windowClass: 'custom-modal-lg'});
      modalRef.componentInstance.experience = experience;
      modalRef.componentInstance.isHFPT = isHFPT;
      modalRef.componentInstance.isExperienceFpt = isExperienceFpt;
      modalRef.result.then((result) => {
          if (result.success) {
            this.loader.show();
            this.successChange.emit(true);
            this.refresh();
          } else {
            this.successChange.emit(false);
          }
      }).catch(e => {
      });
  }

  /**
   * Display the error notification form
   */
  public notifyError(emploi: any): void {
      const modalRef = this.modalService.open(EmploiErreurComponent, {windowClass: 'custom-modal-lg'});
      modalRef.componentInstance.emploi = emploi;
      modalRef.componentInstance.agent_id = this.agent.id;
      modalRef.result.then((result) => {
        this.notifySuccessChange.emit(result.success);
      }, () => {
        this.notifySuccessChange.emit(undefined);
      });
  }

  refresh() {
    this.refreshEvent.emit(true);
  }
}
