
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';


import {getDrupalUri} from "../shared/external";

export class UserCustomField {
    value: any;
    type: string;
    multiple: boolean;
    mandatory: boolean;
    label: string;
    machine_name: string;
}

@Injectable()
export class AccountService {

    readonly customApiPath: string = '/customapi';
    readonly contactCnfptPath: string = '/api/contact-cnfpt?_format=json';

    constructor(private http: HttpClient) {
    }

    /**
     * Return Agent form datas to fill select tags
     *
     * @returns {Observable<any>}
     */
    public getAgentFormDatas(email: any): Observable<any> {
        return this.http.post<any>(getDrupalUri() + this.customApiPath + '/registration/agent/referentiel', {email: email}).pipe(map(
            (val) => {
                return val;
            }
        ));
    }

    /**
     * Return Referent form datas to fill select tags
     *
     * @returns {Observable<any>}
     */
    public getReferentFormDatas(): Observable<any> {
        return this.http.get<any>(getDrupalUri() + this.customApiPath + '/registration/referent/referentiel').pipe(map(
            (val) => {
                return val;
            }
        ));
    }

    /**
     * Check if provided email already exists
     *
     * @param {string} email
     * @returns {any}
     */
    public checkEmail(email: string): Observable<boolean> {
        return this.http.post<any>(getDrupalUri() + this.customApiPath + '/registration/agent/email', { courriel: email }).pipe(
            map(
                (res) => {
                    return res;
                },
                () => {
                    return false;
                }
            ));
    }

    /**
     * Request custom fields of agent profile.
     */

    /*public getForm(): Observable<UserCustomField[]> {
        return this.http.get<UserCustomField[]>(getDrupalUri() + '/profile/update').map(
            (value) => {
                return value;
            }
        );
    }*/

    /**
     * Submit new value, expected to return the new state of the profile.
     */
    public postForm(fields: any): Observable<any[]> {
        return this.http.post<any>(getDrupalUri() + this.customApiPath + '/agent/register', fields).pipe(map(
            (value) => {
                return value;
            }
        ));
    }

    /**
     * Submit the form to the CNFPT
     *
     * @param {string} type
     * @param {number} agentId
     * @param {string} message
     * @returns {Observable<any>}
     */
    public notifyCnfpt(type: string, message: string, csrf: string): Observable<any> {
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'X-CSRF-Token': csrf});
        return this.http.post<any>(
            getDrupalUri() + this.contactCnfptPath, {
                'type': type,
                'message': message
            }, {headers: headers}).pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }
}
