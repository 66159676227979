<app-breadcrumb></app-breadcrumb>
<div class="container" *ngIf="dve == null">
    <div class="row">
        <div class="col-md-12 col-lg-12 empty-block">
            <div class="inner">
                <p>
                    Aucune déclaration n'a été trouvée.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <!-- begin: PAGE HEADER -->
    <header class="page-title">
        <h1 class="text-uppercase bolded">
            <span class="aed-icon aed-icon-hash" aria-hidden="true"></span>
            <span class="alv-value">{{view.number}}</span>
        </h1>
    </header>
    <!-- end: PAGE HEADER -->

    <!-- begin: PAGE CONTENT / METAS -->
    <div class="page-metas clearfix">
        <nav>
            <div class="float-left">
                <ul class="list-unstyled">
                    <li>
                        <span class="aed-icon aed-icon-clock" aria-hidden="true"></span>
                        <span class="">
                            Date de la déclaration :  <span class="bolded">{{view.dateDeclaration}}</span>
                        </span>
                    </li>
                    <li>
                        <span class="aed-icon aed-icon-clock" aria-hidden="true"></span>
                        <span class="">
                            Date de recrutement prévu :  <span class="bolded">{{view.dateRecrutement}}</span>
                        </span>
                    </li>
                </ul>
            </div>
            <a class="clickable float-right" back-button>
                <span class="aed-icon aed-icon-back" aria-hidden="true"></span>
                <span class="pm-link-text plt-back">Retour</span>
            </a>
        </nav>
    </div>
    <!-- end: PAGE CONTENT / METAS -->

    <!-- begin: PAGE CONTENT -->
    <div id="content" class="dve-detail">

        <!-- begin: DVE SYNTHESE  HEADER -->
        <div class="row">

            <div class="col-md-12 col-lg-12 dve-detail">
                <div class="card od-card">
                    <div class="card-body">
                        <h3 class="bordered-title bolded">Détails de la déclaration</h3>
                        <div class="card-text">
                            <ul class="list-unstyled aed-label-value">
                                <li class="aed-label-value">
                                    <span class="alv-label">Numéro de la déclaration : </span>
                                    <span class="alv-value">
                                        {{view.number}}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <span class="lgi-label">Grade(s) recherché(s) :</span>
                        <span *ngIf="view.noGrade != null" class="alv-value">{{view.noGrade}}</span>
                        <div class="lgi-value">
                            <ul>
                                <li *ngFor="let grade of view.grades">{{grade.label}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- end: DVE DETAIL HEADER -->

        <!-- begin: DVE DETAIL ACCORDION -->
        <div class="aed-accordion mar-t-30">
            <ngb-accordion [closeOthers]="true">
                <ngb-panel id="dve-detail-infos">
                    <ng-template ngbPanelHeader let-opened="opened">
                      <button ngbPanelToggle class="btn btn-link" [ngClass]="{'open' : opened}">
                        Description du poste
                      </button>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="row">
                            <div *ngIf="view.description" class="col-md-12 col-lg-12 od-desc-para mar-t-30">
                                <div class="odp-para pre-line">{{view.description}}</div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
        <!-- end: offre DETAIL ACCORDION -->

    </div>
</div>
