import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AgentService} from '../agent/agent.service';
import {EmploiService} from '../emploi/emploi.service';
import {Observable, of} from 'rxjs';

@Injectable()
export class SearchEmploiAgentResolve implements Resolve<any> {
    constructor(private agentService: AgentService, private emploiService: EmploiService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const agtid = route.queryParams.agtid;
        const empid = route.queryParams.empid;
        const agtemp = route.queryParams.agtemp;

        if (agtid != null) {
            return this.agentService.getForAutocomplete(agtid);
        } else if (empid != null) {
            return this.emploiService.getForAutocomplete(empid);
        } else if (agtemp != null) {
            return of({label: agtemp, id: null, type: null});
        } else {
            return null;
        }
    }
}
