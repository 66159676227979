import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddressComponent implements OnInit {
    @Input() address = [];
    @Input() separator = '\n';

    constructor() {
    }

    ngOnInit() {
    }

}
