<app-breadcrumb></app-breadcrumb>

<div class="container">


    <header class="page-title">
        <h1 class="text-uppercase bolded">
            Recherche avancée
        </h1>
    </header>

    <div id="content">
        <div class="search-job-form aed-highlighted-form">
            <div class="row">
                <div class="form-group col-md-4 col-lg-4 center-text">
                    <label>Qui ?</label>
                    <input class="form-control" placeholder="Rechercher une personne" #whoInput
                           (keydown)="keyDownFunction($event)" [(ngModel)]="agentSelected">
                </div>
                <div class="form-group col-md-4 col-lg-4 center-text">
                    <label>Quoi ?</label>
                    <input class="form-control" placeholder="Rechercher un emploi" #whatInput
                           (keydown)="keyDownFunction($event)" [(ngModel)]="emploiSelected">
                </div>
                <div class="form-group col-md-4 col-lg-4 center-text">
                    <label>Où ?</label>
                    <input class="form-control" placeholder="Rechercher un lieu" #whereInput
                           (keydown)="keyDownFunction($event)" [(ngModel)]="placeSelected">
                </div>
                <div class="form-group col-md-4">
                    <label>Région/Département</label>
                    <angular2-multiselect [data]="departements"
                        [(ngModel)]="departementsSelected"
                        [settings]="settingsWithCategories">
                    </angular2-multiselect>
                </div>
                <div class="form-group col-md-4">
                    <label>Type de collectivité</label>
                    <angular2-multiselect [data]="cjs"
                        [(ngModel)]="cjsSelected"
                        [settings]="settingsWithoutCategories">
                    </angular2-multiselect>
                </div>
                <div class="form-group col-md-4">
                    <label>Taille de la collectivité</label>
                    <angular2-multiselect [data]="tcds"
                        [(ngModel)]="tcdsSelected"
                        [settings]="settingsWithoutCategories">
                    </angular2-multiselect>
                </div>
                <div class="form-group col-md-4">
                    <label>Fonction</label>
                    <angular2-multiselect [data]="cotations"
                        [(ngModel)]="cotationsSelected"
                        [settings]="settingsWithCategories">
                    </angular2-multiselect>
                </div>
                <div class="form-group col-md-4">
                    <label>Filière de l’emploi</label>
                    <angular2-multiselect [data]="filieres"
                        [(ngModel)]="filieresSelected"
                        [settings]="settingsWithoutCategories">
                    </angular2-multiselect>
                </div>
                <div class="form-group col-md-4">
                    <label>Domaines de l’emploi</label>
                    <angular2-multiselect [data]="domaines"
                        [(ngModel)]="domainesSelected"
                        [settings]="settingsWithCategories">
                    </angular2-multiselect>
                </div>

                <div class="form-group col-md-6">
                    <button type="submit" (click)="search()" class="arrow-btn btn btn-primary text-uppercase">
                        Rechercher
                    </button>
                </div>

                <div class="form-group col-md-6 justify-content-end">
                    <div class="reset-search bolded">
                        <span class="aed-icon aed-icon-trash"></span>
                        <a (click)="reset()" class="clickable"> Réinitialiser la recherche</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="searchResultCount > 0" id="list-results">
            <div class="col-md-12 col-lg-12">
                <h3 class="text-uppercase bolded bordered-title results">
                    Résultats de recherche
                </h3>
            </div>
            <div class="col-md-12 col-lg-12 search-job-count">
                <div class="inner">
                    <div class="row">
                        <div class="col-md-7">
                            <p>
                                <span class="bolded">{{searchResultCount}}</span>
                                <span class="aed-icon aed-icon-briefcase" aria-hidden="true"></span>
                                emploi<span *ngIf="searchResultCount > 1">s</span>
                                trouvé<span *ngIf="searchResultCount > 1">s</span>
                            </p>
                        </div>
                        <div class="col-md-5 sort-results">
                            <label for="sortSelector">Trier par :</label>
                            <select id="sortSelector" name="sortSelector" (change)="sortResults($event.target.value)">
                                <option value="0"></option>
                                <option value="1">Collectivité</option>
                                <option value="2">Fonction</option>
                                <option value="3">Type de collectivité</option>
                            </select>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- begin: SEARCH RESULTS -->
        <ng-container *ngIf="searchResults !== undefined">
            <!-- begin: SEARCH NO RESULT -->
            <ng-container *ngIf="searchResults.length === 0">
                <div class="row">
                    <div class="col-md-12 col-lg-12 empty-block">
                        <div class="inner">
                            <p class="no-job">
                                Aucun résultat n'a été trouvé, veuillez préciser votre recherche.
                            </p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- end: SEARCH NO RESULT -->

            <!-- begin: SEARCH ONE RESULT AT LEAST -->
            <ng-container>
                <div class="row aed-list-container">
                    <div [ngClass]="{ odd: odd, even: even, 'col-md-12 col-lg-12 alc-elt': true}"
                         *ngFor="let searchResult of searchResults; let even = even; let odd = odd">
                        <div class="inner clearfix">
                            <div class="row">
                                <ng-container *ngIf="searchResult.sapi_type == 'entity:aed_emploi_direction'">
                                    <app-fiche-ct-light class="col-md-4 col-lg-4"
                                                        [collectivite]="searchResult.collectivite">
                                    </app-fiche-ct-light>
                                    <app-fiche-emploi-light class="col-md-8 col-lg-8"
                                                            [emploi]="searchResult">
                                    </app-fiche-emploi-light>
                                </ng-container>
                                <ng-container *ngIf="searchResult.sapi_type == 'entity:aed_experience'">
                                    <app-fiche-ct-light class="col-md-4 col-lg-4" [collectivite]="searchResult.field_exp_fpt_emploi?.collectivite">
                                    </app-fiche-ct-light>
                                    <app-fiche-experience-light *ngIf="!searchResult.field_exp_fpt_emploi" class="col-md-8 col-lg-8" [experience]="searchResult">
                                    </app-fiche-experience-light>
                                    <app-fiche-emploi-light *ngIf="searchResult.field_exp_fpt_emploi" class="col-md-8 col-lg-8" [emploi]="searchResult.field_exp_fpt_emploi">
                                    </app-fiche-emploi-light>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="searchResultLastPage > 1" class="col-md-12 col-lg-12">
                        <nav aria-label="Page navigation">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link" [attr.href]="null" (click)="pagerFirst()" aria-label="Premier">
                                        <span class="aed-icon aed-icon-rewind" aria-hidden="true"></span>
                                        <span class="sr-only">Premier</span>
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" *ngIf="currentPage > 1" [attr.href]="null"
                                       (click)="pagerPrevious()" aria-label="Précédent">
                                        <span class="aed-icon aed-icon-left-arrow" aria-hidden="true"></span>
                                        <span class="sr-only">Précédent</span>
                                    </a>
                                </li>
                                <li [ngClass]="{ 'active': pageNum == currentPage, 'page-item': true}"
                                    *ngFor="let pageNum of pagerGetPageList();"><a class="page-link" [attr.href]="null"
                                                                                   (click)="pagerGoTo(pageNum)">{{pageNum}}</a>
                                </li>
                                <li class="page-item" *ngIf="currentPage < searchResultLastPage">
                                    <a class="page-link" [attr.href]="null" (click)="pagerNext()" aria-label="Suivant">
                                        <span class="sr-only">Suivant</span>
                                        <span class="aed-icon aed-icon-right-arrow" aria-hidden="true"></span>
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" [attr.href]="null" (click)="pagerLast()" aria-label="Dernier">
                                        <span class="sr-only">Dernier</span>
                                        <span class="aed-icon aed-icon-fast-forward" aria-hidden="true"></span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </ng-container>
            <!-- end: SEARCH ONE RESULT AT LEAST -->
        </ng-container>
        <!-- end: SEARCH RESULTS -->
    </div>
</div>
