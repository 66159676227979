import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'profil-utilisateur-statut',
  templateUrl: './statut.component.html',
  styleUrls: ['./statut.component.scss']
})
export class StatutUtilisateurComponent implements OnInit {

  @Input() agent: any;

  collapsed = true;

  constructor() { }

  ngOnInit() {
    this.agent.roles = this.getRolesAsString(this.agent.roles);
  }

  getRolesAsString(roles: []) {
    let rolesAsString = "";
    roles.forEach(function (role) {
      if (rolesAsString.length > 0) {
        rolesAsString += ", ";
      }
      switch (role) {
        case "anonymous":
          rolesAsString += "Utilisateur anonyme";
          break;
        case "authenticated":
          rolesAsString += "Utilisateur authentifié";
          break;
        case "referent_ct":
          rolesAsString += "Référent CT";
          break;
        case "cnfpt":
          rolesAsString += "CNFPT";
          break;
        case "authenticated":
          rolesAsString += "Administrateur fonctionnel";
          break;
        case "agent":
          rolesAsString += "Agent A+";
          break;
      }
    });
    return rolesAsString;
  }
}
