<section id="sidebar" [ngClass]="isCollapsed ? 'slided clearfix' : 'clearfix'">
    <perfect-scrollbar style="max-width: 420px; max-height: 100%;">
        <nav>
          <ul *hasRoleReferentCT="accessState" class="admin-links list-unstyled">
            <div class="header"><span class="aed-icon aed-icon-user" aria-hidden="true"></span> Accès gestionnaire</div>
            <li>
                <a [routerLink]="'mes-collectivites'">
                    <span [class]="'aed-icon aed-icon-briefcase'" aria-hidden="true"></span>
                     Mes collectivités
                </a>
            </li>
          </ul>
          <ul *hasRoleAdmin="accessState" class="admin-links list-unstyled">
            <div class="header"><span class="aed-icon aed-icon-user" aria-hidden="true"></span> Accès administrateur</div>
            <li>
                <a [routerLink]="'dashboard'">
                    <span [class]="'aed-icon aed-icon-family-tree'" aria-hidden="true"></span>
                     Mon tableau de bord
                </a>
            </li>
          </ul>
          <ul class="base-links list-unstyled">
              <li *ngFor="let sidebarMenuLink  of sidebarMenuLinks">
                  <a *ngIf="sidebarMenuLink.slug; else slugLess" [routerLink]="['contenu', sidebarMenuLink.slug]">{{ sidebarMenuLink.title }}</a>
                  <ng-template #slugLess>
                      <a href="{{ sidebarMenuLink.url }}" target="_blank">{{ sidebarMenuLink.title }}</a>
                  </ng-template>
              </li>
          </ul>
        </nav>

        <app-map-light></app-map-light>
    </perfect-scrollbar>
</section>

<section id="slidable-wrapper" [ngClass]="isCollapsed ? 'slided clearfix' : 'clearfix'">
    <!-- begin : WRAPPER -->
    <div id="wrapper">
        <!-- begin : HEADER -->
        <header id="masterhead" role="banner">
            <nav class="navbar navbar-light bg-light">

                <button id="navbar-toggler" type="button" (click)="toggleSidebar()"
                        [ngClass]="isCollapsed ? 'collapsed' : ''"
                        aria-controls="sidebar"
                        aria-expanded="false" aria-label="Toggle sidebar">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>

                <div class="header-separator"></div>

                <a *isLogged="accessState" class="user-infos" [routerLink]="['/profil']"  ngbPopover="Mettre à jour votre profil" triggers="mouseenter:mouseleave" placement="bottom">

                    <app-avatar [userDisplayName]="userDisplayName" [userPhoto]="userPhoto" [userTitle]="userTitle"></app-avatar>

                    <span class="user-displayname">{{userDisplayName}}</span>
                </a>

                <div *isLogged="accessState" class="header-separator"></div>

                <a class="navbar-brand" [routerLink]="'/'">
                    <img class="img-fluid" src="assets/images/logo.svg" alt=""/>
                </a>

                <div id="headerMenu">
                    <div class="float-lg-right">
                        <app-login></app-login>

                        <div class="header-separator"></div>

                        <div class="aed-accessibility">
                            <a class="aa-constrast clickable" (click)="changeContrast($event)">
                                <span class="sr-only">Changer le contraste</span>
                                <span class="aed-icon-contrast" aria-hidden="true"></span>
                            </a>
                            <div class="aa-font-resizer">
                                <span class="afs-label">A : </span>
                                <a id="inc-font"  (click)="increaseTextSize()" title="Augmenter la taille de la police">
                                    <span class="sr-only">Augmenter la taille de la police</span>
                                </a>
                                <a id="dec-font"  (click)="decreaseTextSize()" title="Diminuer la taille de la police">
                                    <span class="sr-only">Diminuer la taille de la police</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
        <!-- end : HEADER -->

        <main id="main" class="clearfix"  [ngClass]="accessibility">
            <!--<div class="dev-ribbon">-->
                <!--<span class="sr-only">En développement</span>-->
            <!--</div>-->

            <!-- begin : MAIN CONTENT -->
            <router-outlet></router-outlet>
            <!-- end : MAIN CONTENT -->
        </main>

    </div>
    <!-- end : WRAPPER -->

    <!-- begin : FOOTER -->
    <footer id="colophon" [ngClass]="accessibility" role="contentinfo">
        <div class="footer-top">
            <div class="container">
                <a (click)="scrollToTop()" class="scrolltotop text-center bolded clickable" title="Aller en haut">
                    <span>Haut</span>
                </a>

                <div class="row">
                    <div class="ft-desc col-md-7 col-sm-12 animated fadeIn delay6">
                        <p>{{ footerMessage }}</p>
                    </div>

                    <div class="ft-links col-md-3 col-sm-6">
                        <ul class="list-unstyled">
                            <li *ngFor="let footerMenuLink  of footerMenuLinks; let ii = index" class="animated bounceInUp delay{{ ii + 2}}">
                                <a *ngIf="footerMenuLink.slug" [routerLink]="['/contenu/' + footerMenuLink.slug]">{{ footerMenuLink.title }}</a>
                                <a *ngIf="!footerMenuLink.slug" [routerLink]="[footerMenuLink.url]">{{ footerMenuLink.title }}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="ft-logo col-md-2 col-sm-6">
                        <a href="http://www.cnfpt.fr">
                            <img class="img-fluid" src="assets/images/logo-cnfpt.png" alt="Logo du CNFPT">
                        </a>
                    </div>
                </div>

            </div>
        </div>
        <div class="footer-bottom">
            <div class="container-fluid left-right-container">
                <div class="row">
                    <div class="col-md-6 col-lg-6">
                        <div class="left">
                            Copyright &copy; CNFPT - Tous les droits réservés 2019
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <nav class="fb-social-network" *ngIf="socialMenuLinks != ''">
                            <ul class="list-unstyled">
                                <li *ngFor="let socialMenuLink  of socialMenuLinks; let ii = index;" class="fb-{{ socialMenuLink.title | lowercase }} bounceInUp animated delay{{ ii  + 1}}">
                                    <a href="{{ socialMenuLink.url }}">
                                        <span class="sr-only"> {{ socialMenuLink.title }}</span>
                                        <ng-template *ngIf="socialMenuLink.title === 'Youtube'; else otherSocial;">
                                            <span class="aed-icon aed-icon-play"></span>
                                        </ng-template>

                                        <ng-template #otherSocial>
                                            <span class="aed-icon aed-icon-{{ socialMenuLink.title | lowercase }}"></span>
                                        </ng-template>
                                    </a>
                                </li>
                            </ul>
                            <span class="fsn-label">Suivez-nous</span>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- end : FOOTER -->
</section>
<!-- background and hiding component -->
<app-configure-auth></app-configure-auth>
<app-loader></app-loader>
