import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-fiche-autre-emploi',
    templateUrl: './fiche-autre-emploi.component.html',
    styleUrls: ['./fiche-autre-emploi.component.scss']
})
export class FicheAutreEmploiComponent implements OnInit {
    @Input() agent: any;

    isAutreAffectation = false;
    hasExperienceHFPT = false;
    sansEmploi = false;
    unknown = false;
    experienceHFPT: any;

    constructor() {
    }

    ngOnInit() {
        this.isAutreAffectation = this.agent.field_autre_affectation;
        this.hasExperienceHFPT = !this.isAutreAffectation
            && this.agent.field_emploi_hors_fpt
            && this.agent.field_emploi_hors_fpt.length > 0;
        this.sansEmploi = !this.isAutreAffectation && !this.hasExperienceHFPT && this.agent.field_pas_emploi_status != null;
        this.unknown = !this.sansEmploi && !this.isAutreAffectation && !this.hasExperienceHFPT;

        if (this.hasExperienceHFPT) {
            this.experienceHFPT = this.agent.field_emploi_hors_fpt[0];
        }
    }

}
