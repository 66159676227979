import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-offre-teaser',
    templateUrl: './offre-teaser.component.html',
    styleUrls: ['./offre-teaser.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OffreTeaserComponent implements OnInit {

    @Input() offre: any;
    public address = [];


    constructor() {
    }

    ngOnInit() {
        if (!(this.offre.field_offre_o16 === null || this.offre.field_offre_o16 === undefined)) {
            this.address.push(this.offre.field_offre_o16);
        }
        if (!(this.offre.field_offre_o17 === null || this.offre.field_offre_o17 === undefined)) {
            this.address.push(this.offre.field_offre_o17);
        }
        if (!(this.offre.field_offre_o18 === null || this.offre.field_offre_o18 === undefined)) {
            this.address.push(this.offre.field_offre_o18);
        }

    }

}
