<!-- begin: PAGE HEADER -->
<header class="page-title">
    <h1 class="text-uppercase bolded">
        Inscription d'un Cadre de Direction
    </h1>
</header>
<!-- end: PAGE HEADER -->

<!-- begin: PAGE CONTENT -->
<div id="content" class="aed-registration-step2 aed-form-edition">
    <app-alert *ngIf="fieldError"
               [isAlertClosable]="false"
               [alertType]="'danger'"
               [alertMessage]="'La connexion avec le serveur est dégradée,le forumaire de mise à jour n\'est pas disponible.'"
    >
    </app-alert>

    <app-alert *ngIf="success === true"
               [isAlertClosable]="false"
               [alertType]="'success'"
               [alertMessage]="'Le profil a été mis à jour avec succès.'"
    >
    </app-alert>

    <app-alert *ngIf="success === false"
               [isAlertClosable]="true"
               [alertType]="'danger'"
               [alertMessage]="'La mise à jour de votre profil utilisateur a échoué.'"
    >
    </app-alert>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <app-alert *ngIf="notifySuccess === true"
                       [isAlertClosable]="true"
                       [alertType]="'success'"
                       [alertMessage]="'Votre message a été envoyé au CNFPT'"
            >
            </app-alert>

            <app-alert *ngIf="notifySuccess === false"
                       [isAlertClosable]="true"
                       [alertType]="'danger'"
                       [alertMessage]="'Impossible d\'envoyer votre message'"
                       [listError]="fieldsError"
            >
            </app-alert>
        </div>
    </div>

    <form class="profile-update-form aed-entity-form" #registrationEncadrantForm="ngForm">
        <div class="row">
            <div class="form-group col-md-4 col-lg-4">
                <label for="fieldCadreEmploi">Cadre d'emplois
                    <span class="required-field">(obligatoire)</span></label>
                    <angular2-multiselect [data]="cEmploisList"
                                      [(ngModel)]="selectedCadreEmploi"
                                      [ngModelOptions]="{standalone: true}"
                                      [settings]="settingsSingle"
                                      name="fieldCadreEmploi"
                                      id="fieldCadreEmploi"
                                      required
                                      #fieldCadreEmploi="ngModel"
                                      (ngModelChange)="applyCadreEmploiRules($event)"
                                      >
                                  </angular2-multiselect>
            </div>
        </div>
        <div class="row">

            <div class="form-group col-md-4" *ngIf="showListeAptitude">
                <label class="form-check-label label-alone">
                    Êtes-vous inscrit sur liste d’aptitude A+ ?<span class="required-field">(obligatoire)</span>
                </label>

                <div class="form-check form-check-inline">
                    <label class="form-check-label" for="fieldEtesVousListeAptiYes">
                        <input class="form-check-input" type="radio"
                               name="fieldEtesVousListeApti"
                               id="fieldEtesVousListeAptiYes"
                               [(ngModel)]="registration.fieldEtesVousListeApti"
                               #fieldEtesVousListeApti="ngModel"
                               value="1" [disabled]="isConfirmStage"
                        >
                        Oui
                    </label>
                </div>

                <div class="form-check form-check-inline">
                    <label class="form-check-label" for="fieldEtesVousListeAptiNo">
                        <input class="form-check-input" type="radio"
                               name="fieldEtesVousListeApti"
                               id="fieldEtesVousListeAptiNo"
                               [(ngModel)]="registration.fieldEtesVousListeApti"
                               #fieldEtesVousListeApti="ngModel"
                               value="0" [disabled]="isConfirmStage"
                        >
                        Non
                    </label>
                </div>
            </div>

            <div class="form-group col-md-4" *ngIf="registration.fieldEtesVousListeApti === '1'">
                <label for="fieldListeAptitudeCadre">
                    Pour quel cadre d'emplois ?
                    <span class="required-field">(obligatoire)</span>
                </label>

                <select id="fieldListeAptitudeCadre" name="fieldListeAptitudeCadre" class="form-control"
                        [(ngModel)]="registration.fieldListeAptitudeCadre" required [disabled]="isConfirmStage"
                        #fieldListeAptitudeCadre="ngModel"
                >
                    <option *ngFor="let cadreEmp of getCadreEmploiAPlus()" value="{{cadreEmp.id}}">{{cadreEmp.name}}
                    </option>
                </select>

                <div *ngIf="fieldListeAptitudeCadre?.errors && fieldListeAptitudeCadre?.invalid && (fieldListeAptitudeCadre?.dirty || fieldListeAptitudeCadre?.touched)"
                     class="form-error alert alert-danger">
                    <small *ngIf="fieldListeAptitudeCadre?.errors?.required">
                        Ce champ est requis.
                    </small>
                </div>
            </div>

            <div class="form-group col-md-4" *ngIf="registration.fieldEtesVousListeApti === '1'">
                <label for="fieldListeAptitudeSince">
                    Depuis
                    <span class="required-field">(obligatoire)</span>
                </label>

                <select id="fieldListeAptitudeSince" name="fieldListeAptitudeSince" class="form-control"
                        [(ngModel)]="registration.fieldListeAptitudeSince" required [disabled]="isConfirmStage"
                        #fieldListeAptitudeSince="ngModel"
                >
                    <option value="{{ dr6Date }}" *ngFor="let dr6Date of datesRange6"
                            [attr.selected]="dr6Date == currentYear ? true : false">{{ dr6Date }}
                    </option>
                </select>
                <div *ngIf="fieldListeAptitudeSince?.errors && fieldListeAptitudeSince?.invalid && (fieldListeAptitudeSince?.dirty || fieldListeAptitudeSince?.touched)"
                     class="form-error alert alert-danger">
                    <small *ngIf="fieldListeAptitudeSince?.errors?.required">
                        Ce champ est requis.
                    </small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4"
                 *ngIf="registration.fieldCadreEmploi != undefined && registration.fieldCadreEmploi != null && registration.fieldCadreEmploi != ''">
                <label class="form-check-label label-alone">
                    Travaillez-vous dans une collectivité locale ou un établissement public local ?
                    <span class="required-field">(obligatoire)</span>
                </label>

                <div class="form-check form-check-inline">
                    <label class="form-check-label" for="fieldEmploiFptYes">
                        <input class="form-check-input" type="radio" name="fieldEmploiFpt" id="fieldEmploiFptYes"
                               value="1"
                               required [(ngModel)]="registration.fieldEmploiFpt" #fieldEmploiFpt="ngModel"
                               [disabled]="isConfirmStage" (ngModelChange)="applyWorkInFptRules($event)">
                        Oui
                    </label>
                </div>

                <div class="form-check form-check-inline">
                    <label class="form-check-label" for="fieldEmploiFptNo">
                        <input class="form-check-input" type="radio" name="fieldEmploiFpt" id="fieldEmploiFptNo"
                               value="0"
                               required [(ngModel)]="registration.fieldEmploiFpt" #fieldEmploiFpt="ngModel"
                               [disabled]="isConfirmStage">
                        Non
                    </label>
                </div>
                <div *ngIf="fieldEmploiFpt?.errors && fieldEmploiFpt?.invalid && (fieldEmploiFpt?.dirty || fieldEmploiFpt?.touched)"
                     class="form-error alert alert-danger">
                    <small *ngIf="fieldEmploiFpt?.errors?.required">
                        Ce champ est requis.
                    </small>
                </div>
            </div>

            <div class="form-group col-md-4" *ngIf="registration.fieldEmploiFpt != null">
                <label class="form-check-label label-alone">
                    Êtes-vous en emploi ?
                    <span class="required-field">(obligatoire)</span>
                </label>

                <div class="form-check form-check-inline">
                    <label class="form-check-label" for="fieldEtesVousEnEmploiYes">
                        <input class="form-check-input" type="radio" name="fieldEtesVousEnEmploi"
                               id="fieldEtesVousEnEmploiYes"
                               value="1" [(ngModel)]="registration.fieldEtesVousEnEmploi"
                               #fieldEtesVousEnEmploi="ngModel"
                               required [disabled]="isConfirmStage">
                        Oui
                    </label>
                </div>

                <div class="form-check form-check-inline">
                    <label class="form-check-label" for="fieldEtesVousEnEmploiNo">
                        <input class="form-check-input" type="radio" name="fieldEtesVousEnEmploi"
                               id="fieldEtesVousEnEmploiNo"
                               value="0" [(ngModel)]="registration.fieldEtesVousEnEmploi"
                               #fieldEtesVousEnEmploi="ngModel"
                               required [disabled]="isConfirmStage">
                        Non
                    </label>
                </div>

                <div *ngIf="fieldEtesVousEnEmploi?.errors && fieldEtesVousEnEmploi?.invalid && (fieldEtesVousEnEmploi?.dirty || fieldEtesVousEnEmploi?.touched)"
                     class="form-error alert alert-danger">
                    <small *ngIf="fieldEtesVousEnEmploi?.errors?.required">
                        Ce champ est requis.
                    </small>
                </div>
            </div>

            <div class="form-group col-md-4" *ngIf="registration.fieldEtesVousEnEmploi === '0'">
                <label for="experienceSansEmploi">
                    Vous êtes ?
                    <span class="required-field">(obligatoire)</span>
                </label>

                <select id="experienceSansEmploi" name="experienceSansEmploi" class="form-control"
                        [(ngModel)]="registration.experienceSansEmploi"
                        required #fieldPasEmploiStatut="ngModel" [disabled]="isConfirmStage">
                    <option *ngFor="let noJobStatus of noJobsList" value="{{noJobStatus.id}}">{{noJobStatus.name}}
                    </option>
                </select>

                <div *ngIf="experienceSansEmploi?.errors && experienceSansEmploi?.invalid && (experienceSansEmploi?.dirty || experienceSansEmploi?.touched)"
                     class="form-error alert alert-danger">
                    <small *ngIf="experienceSansEmploi?.errors?.required">
                        Ce champ est requis.
                    </small>
                </div>
            </div>
        </div>
        <div class="row">

            <!-- begin: PAGE CONTENT / EMPLOYER -->
            <div class="col-md-12"
                 *ngIf="registration.fieldEmploiFpt != null && registration.fieldEtesVousEnEmploi === '1'">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="text-uppercase bolded bordered-title bt-secondary">
                            Choix de l'employeur actuel
                        </h2>
                    </div>

                    <div class="form-group col-md-6 col-lg-6" *ngIf="registration.fieldEmploiFpt === '1'">
                        <app-aed-typeahead (onChoose)="onPlaceSelection($event)"
                                           (keypress)="checkIfNumber($event)"
                                           [retriever]="isConservateur ? 'searchPlaces' : 'searchCollectiviteRefAplus'"
                                           [text]="'Dans quelle collectivité travaillez-vous ? Code postal (obligatoire)'"
                                           [placeholder]="'ex: 34800'"
                                           [defaultValue]="registration.collectivite"
                                           [showLabel]="true">
                        </app-aed-typeahead>
                        <div class="form-error alert alert-danger" *ngIf="!lastKeyIsDigit">
                            <small>Ce champ ne peut contenir que des chiffres.</small>
                        </div>
                        <p class="field-info">
                            Votre collectivité n’apparait pas dans cette liste ? Assurez-vous que vous avez saisi le bon
                            code postal. En cas d’absence avérée, vous pouvez <a href="javascript:void(0);"
                                                                                 class="clickable"
                                                                                 (click)="notifyCnfpt('contact_cnfpt_missing_ct')">contacter
                            le CNFPT</a> pour que
                            la collectivité soit rajoutée.
                        </p>

                    </div>

                    <div class="form-group col-md-4 col-lg-4" *ngIf="registration.fieldEmploiFpt === '0'">
                        <label for="employeur">Quel employeur ?<span class="required-field">(obligatoire)</span></label>
                        <input type="text" class="form-control" id="employeur" name="employeur"
                               [(ngModel)]="registration.employeur" required [disabled]="isConfirmStage"
                               #employeur="ngModel"
                        >
                        <div *ngIf="employeur?.errors && employeur?.invalid && (employeur?.dirty || employeur?.touched)"
                             class="form-error alert alert-danger">
                            <small *ngIf="employeur?.errors?.required">
                                Ce champ est requis.
                            </small>
                        </div>
                    </div>

                    <div class="form-group col-md-4 col-lg-4" *ngIf="registration.fieldEmploiFpt === '0'">
                        <label for="employeurType">
                            Type d’employeur
                            <span class="required-field">(obligatoire)</span>
                        </label>
                        <select id="employeurType" name="employeurType" class="form-control"
                                [(ngModel)]="registration.employeurType" required [disabled]="isConfirmStage"
                                #employeurType="ngModel"
                        >
                            <option *ngFor="let typeEmp of typeEmployeurList" [value]="typeEmp.id">{{ typeEmp.name }}
                            </option>
                        </select>
                        <div *ngIf="employeurType?.errors && employeurType?.invalid && (employeurType?.dirty || employeurType?.touched)"
                             class="form-error alert alert-danger">
                            <small *ngIf="employeurType?.errors?.required">
                                Ce champ est requis.
                            </small>
                        </div>
                    </div>

                    <div class="form-group col-md-4 col-lg-4" *ngIf="registration.fieldEmploiFpt === '0'">
                        <app-aed-typeahead (onChoose)="onEmployeurPlaceSelection($event)"
                                           [retriever]="'searchCities'"
                                           [text]="'Code postal (obligatoire)'"
                                           [showLabel]="true" [disabled]="isConfirmStage">
                        </app-aed-typeahead>
                    </div>

                    <div class="form-group col-md-4 month-selector" *ngIf="registration.fieldEmploiFpt === '0'">
                        <label for="employeurSinceMois">
                            Depuis quand ?
                            <span class="required-field">(obligatoire)</span>
                        </label>
                        <div>
                            <select id="employeurSinceMois" class="form-control" name="employeurSinceMois"
                                    [(ngModel)]="registration.employeurSinceMois" [disabled]="isConfirmStage"
                                    #employeurSinceMois="ngModel"
                            >
                                <option value="01">Janvier</option>
                                <option value="02">Février</option>
                                <option value="03">Mars</option>
                                <option value="04">Avril</option>
                                <option value="05">Mai</option>
                                <option value="06">Juin</option>
                                <option value="07">Juillet</option>
                                <option value="08">Août</option>
                                <option value="09">Septembre</option>
                                <option value="10">Octobre</option>
                                <option value="11">Novembre</option>
                                <option value="12">Décembre</option>
                            </select>
                            <select id="employeurSinceAnnee"
                                    class="form-control"
                                    name="employeurSinceAnnee"
                                    [(ngModel)]="registration.employeurSinceAnnee" [disabled]="isConfirmStage"
                                    #employeurSinceAnnee="ngModel"
                            >
                                <option [value]="dr40Date" *ngFor="let dr40Date of datesRange40">{{ dr40Date }}
                                </option>
                            </select>

                            <div *ngIf="
                            (employeurSinceMois?.errors &&
                            employeurSinceMois?.invalid &&
                             (employeurSinceMois?.dirty ||
                             employeurSinceMois?.touched)) &&
                              (employeurSinceAnnee?.errors &&
                            employeurSinceAnnee?.invalid &&
                             (employeurSinceAnnee?.dirty ||
                             employeurSinceAnnee?.touched))"
                                 class="form-error alert alert-danger"
                            >
                                <small *ngIf="employeurSinceMois?.errors?.required">
                                    Ce champ est requis.
                                </small>
                                <small *ngIf="employeurSinceAnnee?.errors?.required">
                                    Ce champ est requis.
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 clearfix">
                        <div class="form-group-separator"></div>
                    </div>
                </div>
            </div>

            <!-- end: PAGE CONTENT / EMPLOYER -->

            <!-- begin: PAGE CONTENT / ACCESS MODE -->
            <div class="form-group col-md-4" *ngIf="registration.fieldEtesVousEnEmploi === '1'">
                <label class="form-check-label label-alone">
                    Etes-vous fonctionnaire territorial ? <span class="required-field">(obligatoire)</span>
                </label>

                <div class="form-check form-check-inline">
                    <label class="form-check-label" for="fieldEtesVousFtYes">
                        <input class="form-check-input" type="radio" name="fieldEtesVousFt" id="fieldEtesVousFtYes"
                               value="1"
                               [(ngModel)]="registration.fieldEtesVousFt" [disabled]="isConfirmStage"
                               #fieldEtesVousFt="ngModel"
                        >
                        Oui
                    </label>
                </div>

                <div class="form-check form-check-inline">
                    <label class="form-check-label" for="fieldEtesVousFtNo">
                        <input class="form-check-input" type="radio" name="fieldEtesVousFt" id="fieldEtesVousFtNo"
                               value="0"
                               [(ngModel)]="registration.fieldEtesVousFt" [disabled]="isConfirmStage"
                               #fieldEtesVousFt="ngModel"
                        >
                        Non
                    </label>
                </div>
            </div>

            <div class="conditional-form-group col-md-12" *ngIf="registration.fieldEtesVousFt === '1'">
                <div class="row">
                    <div class="form-group col-md-4" *ngIf="registration.fieldEtesVousFt === '1'">
                        <label for="fieldModeAcces">
                            Mode d'accès
                            <span class="required-field">(obligatoire)</span>
                        </label>

                        <select id="fieldModeAcces" class="form-control" name="modeAcces"
                                [(ngModel)]="registration.fieldModeAcces" required [disabled]="isConfirmStage"
                                (ngModelChange)="applyModeAccesRules($event)" #modeAcces="ngModel">
                            <option *ngFor="let modeAcces of modeAccesList" value="{{modeAcces.id}}">
                                {{modeAcces.name}}
                            </option>
                        </select>
                        <div *ngIf="modeAcces?.errors && modeAcces?.invalid && (modeAcces?.dirty || modeAcces?.touched)"
                             class="form-error alert alert-danger">
                            <small *ngIf="modeAcces?.errors?.required">
                                Ce champ est requis.
                            </small>
                        </div>
                    </div>

                    <div class="form-group col-md-4" *ngIf="showDetailPromotion">
                        <label for="fieldAnneeDePromotion">Année de promotion</label>

                        <select id="fieldAnneeDePromotion" class="form-control" name="fieldAnneeDePromotion"
                                [(ngModel)]="registration.fieldAnneeDePromotion" [disabled]="isConfirmStage"
                                #fieldAnneeDePromotion="ngModel">
                            <option [value]="dr40Date" *ngFor="let dr40Date of datesRange40">{{ dr40Date }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-5 month-selector" *ngIf="registration.fieldEtesVousFt === '1'">
                        <label for="nominationMois">Date de nomination dans le cadre d'emplois <span
                                class="required-field">(obligatoire)</span></label>
                        <div>
                            <select id="nominationMois" class="form-control" name="nominationMois"
                                    [(ngModel)]="registration.nominationMois" [disabled]="isConfirmStage"
                                    #nominationMois="ngModel">
                                <option value="01">Janvier</option>
                                <option value="02">Février</option>
                                <option value="03">Mars</option>
                                <option value="04">Avril</option>
                                <option value="05">Mai</option>
                                <option value="06">Juin</option>
                                <option value="07">Juillet</option>
                                <option value="08">Août</option>
                                <option value="09">Septembre</option>
                                <option value="10">Octobre</option>
                                <option value="11">Novembre</option>
                                <option value="12">Décembre</option>
                            </select>
                            <select id="nominationAnnee" class="form-control" name="nominationAnnee"
                                    [(ngModel)]="registration.nominationAnnee" [disabled]="isConfirmStage"
                                    #nominationAnnee="ngModel">
                                <option [value]="dr40Date" *ngFor="let dr40Date of datesRange40">{{ dr40Date }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="conditional-form-group col-md-12" *ngIf="registration.fieldEtesVousFt === '0'">
                <div class="row">
                    <div class="form-group col-md-4">
                        <label class="form-check-label label-alone">
                            Etes-vous ?
                            <span class="required-field">(obligatoire)</span>
                        </label>

                        <div class="form-check form-check-inline">
                            <label class="form-check-label" for="fieldEtesVousFcF">
                                <input class="form-check-input" type="radio" name="fieldEtesVousFc"
                                       id="fieldEtesVousFcF"
                                       value="Fonctionnaire" [(ngModel)]="registration.fieldEtesVousFc" required
                                       [disabled]="isConfirmStage" #fieldEtesVousFc="ngModel">
                                Fonctionnaire
                            </label>
                        </div>

                        <div class="form-check form-check-inline">
                            <label class="form-check-label" for="fieldEtesVousFcC">
                                <input class="form-check-input" type="radio" name="fieldEtesVousFc"
                                       id="fieldEtesVousFcC"
                                       value="Contractuel" [(ngModel)]="registration.fieldEtesVousFc" required
                                       [disabled]="isConfirmStage" #fieldEtesVousFc="ngModel">
                                Contractuel
                            </label>
                        </div>
                        <div *ngIf="fieldEtesVousFc?.errors && fieldEtesVousFc?.invalid && (fieldEtesVousFc?.dirty || fieldEtesVousFc?.touched)"
                             class="form-error alert alert-danger">
                            <small *ngIf="fieldEtesVousFc?.errors?.required">
                                Ce champ est requis.
                            </small>
                        </div>
                    </div>

                    <div class="form-group col-md-4" *ngIf="registration.fieldEtesVousFc === 'Fonctionnaire'">
                        <label for="fieldCorpsDOrigine">
                            Corps d'origine
                            <span class="required-field">(obligatoire)</span>
                        </label>

                        <select id="fieldCorpsDOrigine" class="form-control" name="fieldCorpsDOrigine"
                                [(ngModel)]="registration.fieldCorpsDOrigine" required [disabled]="isConfirmStage"
                                (ngModelChange)="applyCorpsOrigineRules($event)" #fieldCorpsDOrigine="ngModel">
                            <option *ngFor="let corpsOrigine of corpsOrigineList" value="{{corpsOrigine.id}}">
                                {{corpsOrigine.name}}
                            </option>
                        </select>

                        <div *ngIf="fieldCorpsDOrigine?.errors && fieldCorpsDOrigine?.invalid && (fieldCorpsDOrigine?.dirty || fieldCorpsDOrigine?.touched)"
                             class="form-error alert alert-danger">
                            <small *ngIf="fieldCorpsDOrigine?.errors?.required">
                                Ce champ est requis.
                            </small>
                        </div>
                    </div>

                    <div class="form-group col-md-4" *ngIf="registration.fieldEtesVousFc === 'Fonctionnaire' && showAutreCorps">
                        <label for="fieldCorpsOrigineAutre">
                            Autre
                            <span class="required-field">(obligatoire)</span>
                        </label>
                        <input type="text" class="form-control" id="fieldCorpsOrigineAutre"
                               name="fieldCorpsOrigineAutre"
                               [(ngModel)]="registration.fieldCorpsOrigineAutre" required [disabled]="isConfirmStage"
                               #fieldCorpsOrigineAutre="ngModel">

                        <div *ngIf="fieldCorpsOrigineAutre?.errors && fieldCorpsOrigineAutre?.invalid && (fieldCorpsOrigineAutre?.dirty || fieldCorpsOrigineAutre?.touched)"
                             class="form-error alert alert-danger">
                            <small *ngIf="fieldCorpsOrigineAutre?.errors?.required">
                                Ce champ est requis.
                            </small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 clearfix">
                <div class="form-group-separator"></div>
            </div>

            <!-- begin: PAGE CONTENT / POSITION -->
            <div class="col-md-12 clearfix"
                 *ngIf="registration.fieldEtesVousEnEmploi === '1' && registration.fieldEmploiFpt === '1'">
                <div class="row">
                    <div class="form-group col-md-4">
                        <label for="emploi">Quel emploi occupez-vous aujourd’hui ? <span class="required-field">(obligatoire)</span></label>
                        <angular2-multiselect [data]="colEmploisList"
                            [(ngModel)]="selectedEmploi"
                            [ngModelOptions]="{standalone: true}"
                            [settings]="settingsSingle"
                            (ngModelChange)="applyEmploiRules($event)"
                            [disabled]="isConfirmStage" *ngIf="showCollectiviteEmploiList"
                            class="single-select"
                            name="emploi" id="emploi"
                            >
                            <c-badge>
                              <ng-template let-item="item">
                                <label>{{item.label ? item.label : item.name}}</label>
                              </ng-template>
                            </c-badge>
                        </angular2-multiselect>
                    </div>
                    <div class="form-group col-md-4" *ngIf="showFonction">
                        <label for="emploiFonction">
                            Titre de l'emploi
                            <span class="required-field">(obligatoire)</span>
                        </label>
                        <input type="text" class="form-control" id="emploiFonction" name="emploiFonction"
                               [(ngModel)]="registration.emploiFonction" required [disabled]="isConfirmStage"
                               #emploiFonction="ngModel">

                        <div *ngIf="emploiFonction?.errors && emploiFonction?.invalid && (emploiFonction?.dirty || emploiFonction?.touched)"
                             class="form-error alert alert-danger">
                            <small *ngIf="emploiFonction?.errors?.required">
                                Ce champ est requis.
                            </small>
                        </div>
                    </div>

                    <div class="form-group col-md-4 month-selector">
                        <label for="emploiSinceMois">
                            Depuis quand ?
                            <span class="required-field">(obligatoire)</span>
                        </label>
                        <div>
                            <select id="emploiSinceMois" class="form-control" name="emploiSinceMois"
                                    [(ngModel)]="registration.emploiSinceMois" [disabled]="isConfirmStage"
                                    #emploiSinceMois="ngModel" required>
                                <option value="01">Janvier</option>
                                <option value="02">Février</option>
                                <option value="03">Mars</option>
                                <option value="04">Avril</option>
                                <option value="05">Mai</option>
                                <option value="06">Juin</option>
                                <option value="07">Juillet</option>
                                <option value="08">Août</option>
                                <option value="09">Septembre</option>
                                <option value="10">Octobre</option>
                                <option value="11">Novembre</option>
                                <option value="12">Décembre</option>
                            </select>
                            <select id="emploiSinceAnnee" class="form-control" name="emploiSinceAnnee"
                                    [(ngModel)]="registration.emploiSinceAnnee" [disabled]="isConfirmStage"
                                    #emploiSinceAnnee="ngModel" required>
                                <option [value]="dr40Date" *ngFor="let dr40Date of datesRange40">{{ dr40Date }}
                                </option>
                            </select>
                            <div *ngIf="
                            (emploiSinceMois?.errors
                             && emploiSinceMois?.invalid
                             && (emploiSinceMois?.dirty
                              || emploiSinceMois?.touched))
                              || (emploiSinceAnnee?.errors
                              && emploiSinceAnnee?.invalid
                              && (emploiSinceAnnee?.dirty
                              || emploiSinceAnnee?.touched))"
                                 class="form-error alert alert-danger">
                                <small *ngIf="emploiSinceMois?.errors?.required">
                                    Le champ mois est requis.
                                </small>
                                <small *ngIf="emploiSinceAnnee?.errors?.required">
                                    Le champ année est requis.
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-md-4">
                        <label class="form-check-label label-alone">
                            Emploi fonctionnel
                            <span class="required-field">(obligatoire)</span>
                        </label>

                        <div class="form-check form-check-inline">
                            <label class="form-check-label" for="emploiFonctionnelYes">
                                <input class="form-check-input" type="radio" name="emploiFonctionnel"
                                       id="emploiFonctionnelYes" value="1"
                                       [(ngModel)]="registration.emploiFonctionnel" required
                                       [disabled]="isConfirmStage">
                                Oui
                            </label>
                        </div>

                        <div class="form-check form-check-inline">
                            <label class="form-check-label" for="emploiFonctionnelNo">
                                <input class="form-check-input" type="radio" name="emploiFonctionnel"
                                       id="emploiFonctionnelNo" value="0"
                                       [(ngModel)]="registration.emploiFonctionnel" required
                                       [disabled]="isConfirmStage" #emploiFonctionnel="ngModel">
                                Non
                            </label>
                        </div>

                        <div *ngIf="emploiFonctionnel?.errors && emploiFonctionnel?.invalid && (emploiFonctionnel?.dirty || emploiFonctionnel?.touched)"
                             class="form-error alert alert-danger">
                            <small *ngIf="emploiFonctionnel?.errors?.required">
                                Ce champ est requis.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end: PAGE CONTENT / POSITION -->

            <div class="form-group col-md-12">

                <div *ngIf="isConfirmStage" class="alert alert-info">
                    <p>Vous vous déclarez <strong>{{getConfirmationMessage()}}</strong> sur le cadre d'emplois <strong>{{getCurrentCadreEmploi()}}</strong>.
                    </p>
                    <p>Confirmez-vous ces informations ?</p></div>
                <div *ngIf="isConfirmStage && showProvidedAlert" class="alert alert-warning">
                    <p>Une personne occupe actuellement ce poste, confirmez-vous votre affectation sur ce poste ?</p>
                </div>

                <button type="button" (click)="toggleConfirm(true)"
                        class="arrow-btn btn btn-primary text-uppercase mar-r-10"
                        [disabled]="registrationEncadrantForm.invalid
                        || (registration.fieldEmploiFpt == '1' && registration.fieldEtesVousEnEmploi == '1' && registration.collectivite?.id == null)
                        || (registration.fieldEmploiFpt === '0' && !collectiviteIsSelected && (registration.fieldEtesVousEnEmploi === '1' && registration.experienceSansEmploi !== null))
                        || (registration.fieldEmploiFpt === '0' && registration.fieldEtesVousEnEmploi === '0' && registration.experienceSansEmploi == null)"
                        *ngIf="!isConfirmStage">Valider le profil
                </button>
                <button type="submit" (click)="onSubmit()" class="arrow-btn btn btn-success text-uppercase mar-r-10"
                        [disabled]="registrationEncadrantForm.invalid || (registration.fieldEmploiFpt == '1' && registration.fieldEtesVousEnEmploi == '1' && registration.collectivite?.id == null)"
                        *ngIf="isConfirmStage">Confirmer mon inscription
                </button>
                <button type="button" (click)="toggleConfirm(false)"
                        class="arrow-btn btn btn-primary text-uppercase"
                        *ngIf="isConfirmStage">Modifier
                </button>
                <p class="mar-10 text-warning"
                   *ngIf="!isConfirmStage && (registrationEncadrantForm.invalid || (registration.fieldEmploiFpt == '1' && registration.fieldEtesVousEnEmploi == '1' && registration.collectivite?.id == null))">
                    <small>Vous devez remplir tous les champs obligatoires pour pouvoir valider votre profil.</small>
                </p>
            </div>
        </div>
    </form>
</div>
<!-- end: PAGE CONTENT -->
