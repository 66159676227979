import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

    @Input() title:string;

    constructor(private route:ActivatedRoute,private router:Router) { }

    ngOnInit() {
        if ((this.title === null || this.title === undefined)) {
            this.title = this.route.snapshot.data['title'];
        }
        document.body.classList.add('content-slided');
    }

}
