import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LoaderService} from '../../shared/loader/loader.service';
import {ContactCnfptComponent} from '../contact-cnfpt/contact-cnfpt.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RegistrationComponent implements OnInit {

    public currentForm = 'profil';

    constructor(private loader: LoaderService) {
    }

    ngOnInit() {
        this.loader.hide();
    }

    public switchForm(target: string) {
        if (target !== 'profil') {
            this.loader.show();
        }
        this.currentForm = target;
    }
}
