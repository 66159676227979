import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {AgentService} from '../agent.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {AuthentificationService} from '../../authentification/authentification.service';
import {getAccountUrl} from '../../shared/external';
import '../../shared/ckeditor/ckeditor.loader';
import 'ckeditor';
import {EmploiFormComponent} from '../../emploi/emploi-form/emploi-form.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ReferentFormComponent} from '../referent-form/referent-form.component';
import {OwnershipAccessState} from '../../authentification/has-any-role';
import {FormationFormComponent} from '../formation-form/formation-form.component';
import {DeleteDialogComponent} from '../../shared/delete-dialog/delete-dialog.component';
import {FormationService} from '../formation.service';
import {CsrfService} from '../../authentification/csrf.service';
import {ConfirmDialogComponent} from '../../shared/confirm-dialog/confirm-dialog.component';
import {EmploiErreurComponent} from '../../emploi/emploi-erreur/emploi-erreur.component';
import {ExperienceFormComponent} from '../../emploi/experience-form/experience-form.component';

@Component({
    selector: 'app-fiche-edit',
    templateUrl: './fiche-edit.component.html',
    styleUrls: ['./fiche-edit.component.scss']
})
export class FicheEditComponent implements OnInit {
    // Agent
    agent: any;

    // User
    currentUserId: any;

    // Validators & Errors for Photo/CV upload
    uploadData: any = {
        Photo: {
            validators: undefined,
            errors: []
        },
        Cv: {
            validators: undefined,
            errors: []
        }
    };

    public activeIdString: string;

    // Form
    photo_file: any;
    cv_file: any;
    grades: any[];
    metiers: any[];
    success: boolean;
    notifySuccess: boolean = undefined;
    cvSuccess: boolean = undefined;
    photoSuccess: boolean = undefined;
    isSubmitting = false;
    fieldError = false;
    competences: any;
    formations: any;
    cnfptAccountUri: string;
    ckeditorConfig: any = {
        removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Replace,Find,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,Strike,CopyFormatting,NumberedList,Outdent,Indent,Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,Format,Font,FontSize,TextColor,BGColor,ShowBlocks,About',
        toolbarGroups: [
            {name: 'document', groups: ['mode', 'document', 'doctools']},
            {name: 'clipboard', groups: ['clipboard', 'undo']},
            {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
            {name: 'forms', groups: ['forms']},
            {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
            {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
            {name: 'links', groups: ['links']},
            {name: 'insert', groups: ['insert']},
            {name: 'styles', groups: ['styles']},
            {name: 'colors', groups: ['colors']},
            {name: 'tools', groups: ['tools']},
            {name: 'others', groups: ['others']},
            {name: 'about', groups: ['about']}
        ]
    };

    // Mobility
    cotationsList: any[];
    regionsList: any[];
    famillesList: any[];
    fieldMobiliteZoneIds: any = [];
    fieldMobiliteDomaineIds: any = [];
    fieldMobiliteFonctionIds: any = [];

    // Competences
    competencesList: any[];
    fieldCompetencesIds: any = [];

    selectedMetier: any = [];
    selectedGrade: any = [];

    /**
     * Multiselect field settings
     */
     public settingsSingle = {
      singleSelection: true,
      text: '-',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      searchPlaceholderText: 'Rechercher',
      enableSearchFilter: true,
      labelKey: 'name',
      primaryKey: 'id',
    };
    public settingsMulti = {
      singleSelection: false,
      text: '-',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      searchPlaceholderText: 'Rechercher',
      enableSearchFilter: true,
      labelKey: 'name',
      primaryKey: 'id',
    };

    accessState: OwnershipAccessState;

    public csrfToken = '';

    constructor(private agentService: AgentService,
                private loader: LoaderService,
                private route: ActivatedRoute,
                private authService: AuthentificationService,
                private router: Router,
                private modalService: NgbModal,
                private csrfService: CsrfService,
                private formationService: FormationService) {

        this.competences = [];
        this.formations = [];
    }

    ngOnInit() {
        // Get Agent from resolver
        this.agent = this.route.snapshot.data['agent'];
        if (this.agent == null || !this.agent.id) {
            this.router.navigate(['/']);
        } else {
            this.loader.show();

            this.success = undefined;
            this.fieldError = false;
            this.isSubmitting = false;
            this.extractIdsForMultiSelect();
            this.getFormDatas();
            this.getValidators();
            this.cnfptAccountUri = getAccountUrl();

            if (this.agent.field_metier_referentiel) {
              this.selectedMetier = [this.agent.field_metier_referentiel];
            }
            if (this.agent.field_grade.id) {
              this.selectedGrade = [this.agent.field_grade.id];
            }
        }

        this.route.fragment.subscribe((fragment) => {
            this.activeIdString = fragment;
        });

        // For role specific display
        this.authService.identity.subscribe((identity) => {
            if (identity != null) {
                this.currentUserId = identity.id;
            }
            if (identity && identity.roles) {
                this.accessState = {
                    currentUserRoles: identity.roles,
                    hasOwnership: this.agent.id === identity.id
                };
            } else {
                this.accessState = {
                    currentUserRoles: [],
                    hasOwnership: false
                };
            }
        }, () => {
            this.accessState = {
                currentUserRoles: [],
                hasOwnership: false
            };
        });

        this.updateCsrfToken();
    }

    /**
     * Send the personnal form data to backend
     * @param {NgForm} submittedForm
     */
    public onPersonnalSubmit(submittedForm: NgForm) {
        this.loader.show();
        this.isSubmitting = true;
        const data = submittedForm.value;
        data['id'] = this.agent.id;
        data['field_diffusion_communautes'] = this.agent.field_diffusion_communautes;
        data['field_diffusion_coordonnees'] = this.agent.field_diffusion_coordonnees;
        data['field_masquage_nom'] = this.agent.field_masquage_nom;
        data['field_metier_referentiel'] = this.selectedMetier.map(x => x.id);
        data['field_grade'] = this.selectedGrade.map(x => x.id);
        this.agentService.updateAgent(data, this.photo_file, null).subscribe(
            (value) => {
                this.refresh(value);
                this.loader.hide();
                this.isSubmitting = false;
                this.photo_file = null;
                this.success = true;
                this.uploadData.Photo.errors = [];
                window.scrollTo(0, 0);
            },
            (errors) => {
                this.loader.hide();
                this.isSubmitting = false;
                this.success = false;
                this.uploadData.Photo.errors = errors.error;
                window.scrollTo(0, 0);
            }
        );
    }


    /**
     * Send the mobilité form data to backend
     * @param {NgForm} submittedForm
     */
    public onMobiliteSubmit(submittedForm: NgForm) {
        this.loader.show();
        this.isSubmitting = true;
        const data = submittedForm.value;
        data['id'] = this.agent.id;
        data['field_mobilite_actif'] = this.agent.field_mobilite_actif;
        data['field_mobilite_cnfpt'] = this.agent.field_mobilite_cnfpt;
        data['field_mobilite_hide_authent'] = this.agent.field_mobilite_hide_authent;
        data['field_mobilite_hide_anonym'] = this.agent.field_mobilite_hide_anonym;
        data['field_mobilite_zone'] = this.fieldMobiliteZoneIds.map(x => x.id);;
        data['field_mobilite_domaine'] = this.fieldMobiliteDomaineIds.map(x => x.id);;
        data['field_mobilite_fonction'] = this.fieldMobiliteFonctionIds.map(x => x.id);;
        this.agentService.updateAgent(data, null, null).subscribe(
            () => {
                this.loader.hide();
                this.isSubmitting = false;
                this.success = true;
                window.scrollTo(0, 0);
            },
            () => {
                this.loader.hide();
                this.isSubmitting = false;
                this.success = false;
                window.scrollTo(0, 0);
            }
        );
    }

    /**
     * Send the competence form data to backend
     * @param {NgForm} submittedForm
     */
    public onCompetencesSubmit(submittedForm: NgForm) {
        this.loader.show();
        this.isSubmitting = true;
        const data = submittedForm.value;
        data['id'] = this.agent.id;
        data['field_competences'] = this.fieldCompetencesIds.map(x => x.id);
        this.agentService.updateAgent(data, null, null).subscribe(
            () => {
                this.loader.hide();
                this.isSubmitting = false;
                this.success = true;
                window.scrollTo(0, 0);
            },
            () => {
                this.loader.hide();
                this.isSubmitting = false;
                this.success = false;
                window.scrollTo(0, 0);
            }
        );
    }

    /**
     * Send the CV form to backend
     */
    public onCvSubmit() {
        this.loader.show();
        this.isSubmitting = true;

        this.agentService.updateAgent({'id': this.agent.id}, null, this.cv_file).subscribe(
            (value) => {
                this.refresh(value);
                this.cv_file = null;
                this.isSubmitting = false;
                this.cvSuccess = true;
                this.loader.hide();
                this.uploadData.Cv.errors = [];
                window.scrollTo(0, 0);
            },
            (errors) => {
                this.isSubmitting = false;
                this.cvSuccess = false;
                this.loader.hide();
                this.uploadData.Cv.errors = errors.error;
                window.scrollTo(0, 0);
            }
        );
    }

    /**
     * Get the requested agent for edition
     */
    public getFormDatas(): void {
        if (this.agent != null) {
            this.agentService.getFormDatas(this.agent.id)
                .subscribe((data) => {
                    this.grades = data.grades;
                    this.metiers = data.metiers;
                    this.cotationsList = data.cotations;
                    this.regionsList = data.regions;
                    this.famillesList = data.familles;
                    this.competencesList = data.competences;
                    this.loader.hide();
                }, () => {
                    this.loader.hide();
                });
        }
    }

    /**
     * Refresh Agent datas
     * @param values
     */
    public refresh(values: any): void {
        this.loader.show();

        if (!(values === null || values === undefined)) {
            this.agent = values;
            location.reload();
        } else {
            this.agentService.get(this.agent.id).subscribe((value) => {
                this.agent = value;
                this.extractIdsForMultiSelect();
                this.loader.hide();
            });
        }

        this.getFormDatas();
    }

    /**
     * Request validators for file field fieldName
     *
     */
    public getValidators(): void {
        this.agentService.getFieldValidators('field_photo')
            .subscribe(
                (value) => {
                    this.uploadData.Photo.validators = value;
                });
        this.agentService.getFieldValidators('field_cv')
            .subscribe(
                (value) => {
                    this.uploadData.Cv.validators = value;
                });
    }

    public extractIdsForMultiSelect() {
        if (this.agent.field_mobilite_zone) {
            this.fieldMobiliteZoneIds = this.agent.field_mobilite_zone;
        }
        if (this.agent.field_mobilite_domaine) {
            this.fieldMobiliteDomaineIds = this.agent.field_mobilite_domaine;
        }
        if (this.agent.field_mobilite_fonction) {
            this.fieldMobiliteFonctionIds = this.agent.field_mobilite_fonction;
        }
        if (this.agent.field_competences) {
            this.fieldCompetencesIds = this.agent.field_competences;
        }
    }

    public pictureSelected($event: any) {
        if ($event.target && $event.target.files && $event.target.files.length > 0) {
            this.photo_file = $event.target.files[0];
        } else {
            this.photo_file = undefined;
        }

    }

    public cvSelected($event: any) {
        if ($event.target && $event.target.files && $event.target.files.length > 0) {
            this.cv_file = $event.target.files[0];
        } else {
            this.cv_file = undefined;
        }
    }


    /**
     * Display the "devenir referent" form for a real referent
     */
    public openReferentForm(poste: any): void {
        this.success = undefined;
        poste.isReferent = true;
        const modalRef = this.modalService.open(ReferentFormComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.collectivite = poste.collectivite;
        modalRef.componentInstance.poste = poste;
        modalRef.componentInstance.agent = this.agent;
        modalRef.componentInstance.isReferent = poste.isReferent;
        modalRef.componentInstance.metiers = this.metiers;
        modalRef.result.then((result) => {
            if (result.success) {
                this.success = true;
                poste.isReferent = result.referent;
                this.authService.authenticate();
                this.refresh(null);
            } else {
                this.success = false;
                poste.isReferent = !poste.isReferent;
            }
            if (!(result.result === null || result.result === undefined)) {
                result.result.then(() => {
                }, () => {
                });
            }
        }, () => {
            poste.isReferent = !poste.isReferent;
        });
    }

    /**
     * Display the "devenir referent" form
     */
    public openBecomeReferentForm(experience: any): void {
        this.success = undefined;
        const modalRef = this.modalService.open(ReferentFormComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.collectivite = experience.field_exp_fpt_emploi.collectivite;
        modalRef.componentInstance.agent = this.agent;
        modalRef.componentInstance.isReferent = experience.isReferent;
        modalRef.componentInstance.metiers = this.metiers;
        if (experience.isReferent) {
            console.log('experiences ref', this.agent.experiences);
            const posteRefs = this.agent.experiences.filter(x => x.isOngoing && x.field_exp_pct_collectivite && x.field_exp_pct_collectivite.id === experience.field_exp_fpt_emploi.collectivite.id);
            console.log('poste', posteRefs);
            if (posteRefs != null && posteRefs.length >= 1) {
                modalRef.componentInstance.poste = posteRefs[0];
            }
        }
        modalRef.result.then((result) => {
            if (result.success) {
                this.success = true;
                experience.isReferent = result.referent;
                this.authService.authenticate();
                this.refresh(null);
            } else {
                this.success = false;
            }
            if (!(result.result === null || result.result === undefined)) {
                result.result.then(() => {
                }, () => {
                });
            }
        }, () => {
        });
    }

    /**
     * Display the add emploi form
     */
    public openAddEmploiForm() {
        this.success = undefined;
        const modalRef = this.modalService.open(EmploiFormComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.agent = this.agent;
        modalRef.result.then((result) => {
            if (result.success) {
                this.success = true;
                window.scrollTo(0, 0);
                this.refresh(null);
            } else {
                this.success = false;
                window.scrollTo(0, 0);
            }
        });
    }

    /**
     * Display the referent form
     */
    public openAddReferentForm() {
        this.success = undefined;
        const modalRef = this.modalService.open(ReferentFormComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.collectivite = null;
        modalRef.componentInstance.agent = this.agent;
        modalRef.componentInstance.isReferent = false;
        modalRef.componentInstance.metiers = this.metiers;
        modalRef.result.then((result) => {
            if (result.success) {
                this.success = true;
                window.scrollTo(0, 0);
                this.authService.authenticate();
                this.refresh(null);
            } else {
                this.success = false;
                window.scrollTo(0, 0);
            }
        });
    }

    public confirmBeforeSubmit(submittedForm: NgForm) {
        let modalRef: NgbModalRef;
        modalRef = this.modalService.open(ConfirmDialogComponent, {size: 'lg'});
        modalRef.componentInstance.title = 'Confirmer votre saisie';
        const messages = ['Vous souhaitez vous déclarer en mobilité dans l’annuaire.'];
        if (this.agent.field_mobilite_cnfpt) {
            messages.push('Vous allez solliciter l’accompagnement du CNFPT dans votre recherche de mobilité.');
        }

        if (!this.agent.field_mobilite_hide_authent) {
            messages.push('Vous ne souhaitez pas masquer aux membres de l’annuaire votre souhait de mobilité.');
        }
        if (!this.agent.field_mobilite_hide_anonym) {
            messages.push('Vous ne souhaitez pas masquer au grand public votre souhait de mobilité.');
        }
        modalRef.componentInstance.messages = messages;

        modalRef.result.then((result) => {
            if (result === true) {
                this.onMobiliteSubmit(submittedForm);
            }
        });
    }

    /**
     *  Remove a formation matching the given index
     * @param formation
     */
    public removeFormation(formation): void {
        let modalRef: NgbModalRef;
        modalRef = this.modalService.open(DeleteDialogComponent, {size: 'sm'});
        modalRef.result.then((result) => {
            if (result === true) {
                this.loader.show();
                this.formationService.delete(formation.id, this.csrfToken).subscribe(() => {
                    this.loader.hide();
                    this.success = true;
                    window.scrollTo(0, 0);
                    this.refresh(null);
                }, () => {
                    this.success = false;
                    window.scrollTo(0, 0);
                    this.loader.hide();
                });
            }
        });
    }

    /**
     * Open Add Formation form
     */
    public openFormationForm(formation: any) {
        this.success = undefined;
        const modalRef = this.modalService.open(FormationFormComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.agent = this.agent;
        modalRef.componentInstance.formation = formation;
        modalRef.result.then((result) => {
            if (result.success) {
                this.success = true;
                window.scrollTo(0, 0);
                this.authService.authenticate();
                this.refresh(null);
            } else {
                this.success = false;
                window.scrollTo(0, 0);
            }
        });
    }

    /**
     * Display the emploi edition form
     */
    public openEditExperienceForm(experience: any, isHFPT = false, isExperienceFpt = false): void {
        const modalRef = this.modalService.open(ExperienceFormComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.experience = experience;
        modalRef.componentInstance.isHFPT = isHFPT;
        modalRef.componentInstance.isExperienceFpt = isExperienceFpt;
        modalRef.result.then((result) => {
            if (result.success) {
                this.success = true;
                this.loader.show();
                this.refresh(null);
                window.scrollTo(0, 0);
            } else {
                this.success = false;
                window.scrollTo(0, 0);
            }
        }).catch(e => {
        });
    }

    /**
     * Refresh the CSRF Token
     */
    private updateCsrfToken() {
        this.csrfService.getToken()
            .subscribe(
                token => {
                    this.csrfToken = token;
                });
    }

    /**
     * Display the error notification form
     */
    public notifyError(emploi: any): void {
        const modalRef = this.modalService.open(EmploiErreurComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.emploi = emploi;
        modalRef.componentInstance.agent_id = this.currentUserId;
        modalRef.result.then((result) => {
            this.notifySuccess = result.success;
            window.scrollTo(0, 0);
        }, () => {
            this.notifySuccess = undefined;
        });
    }

    /**
     * Adds role agent to a referent
     */
    public addRoleAgent() {
        this.loader.show();
        this.isSubmitting = true;
        this.success = undefined;

        this.agentService.addRoleAgent()
          .subscribe(
            (value) => {
              this.success = true;
              this.isSubmitting = false;
              this.loader.hide();
              this.refresh(value);
              window.scrollTo(0, 0);
            },
            () => {
              this.success = false;
              this.isSubmitting = false;
              this.loader.hide();
              window.scrollTo(0, 0);
            }
          );
    }
}
