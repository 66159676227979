<!-- begin: ATI / PHOTO -->
<div class="form-group col-md-12 col-lg-12">
  <label for="photo">Photo</label>

  <div class="alert alert-info" *ngIf="uploadData?.Photo.validators?.max_filesize || uploadData?.Photo.validators?.file_extensions">
    <span *ngIf="uploadData.Photo.validators?.max_filesize">
      La taille maximale autorisée est <strong>{{uploadData.Photo.validators.max_filesize}}</strong>.
    </span>
    <br *ngIf="uploadData.Photo.validators?.max_filesize"/>
    <span *ngIf="uploadData.Photo.validators?.file_extensions">
      Les extensions de fichier autorisées sont: <strong>{{uploadData.Photo.validators.file_extensions}}</strong>.
    </span>
  </div>

  <div *ngIf="agent.photo_url" class="user-avatar">
    <img class="img-fluid" [src]="agent.photo_url" title="Votre photo" alt="Votre photo"/>
  </div>
  <input type="file" class="form-control" id="photo" name="photo" (change)="pictureSelected($event)" accept="image/*">

  <div class="form-error alert alert-danger" *ngIf="uploadData.Photo.errors?.length > 0">
    <small *ngFor="let error of uploadData.Photo.errors">
      {{ error }}
    </small>
  </div>
</div>

<div class="form-group col-md-12 col-lg-12">
  <button type="submit" class="arrow-btn btn btn-primary text-uppercase" (click)="onPhotoSubmit()" [disabled]="!photo_file">
    Mettre à jour la photo
  </button>
</div>
<!-- end: ATI / PHOTO -->

<div class="form-group col-md-12 col-lg-12">
  <label for="field_presentation">Presentation</label>
  <ckeditor id="field_presentation" name="field_presentation"
  [(ngModel)]="agent.field_presentation" [config]="ckeditorConfig"
  #field_presentation="ngModel"></ckeditor>
</div>

<div class="form-group col-md-12 col-lg-12">
  <div class="col-md-12 col-lg-12 alert alert-info" *ngIf="uploadData?.Cv.validators?.max_filesize || uploadData?.Cv.validators?.file_extensions">
    <span *ngIf="uploadData.Cv.validators?.max_filesize">
      La taille maximale autorisée est <strong>{{uploadData.Cv.validators.max_filesize}}</strong>.
    </span>
    <br *ngIf="uploadData.Cv.validators?.max_filesize"/>
    <span *ngIf="uploadData.Cv.validators?.file_extensions">
      <ng-template
      *ngIf="uploadData.Cv.validators.file_extensions.split(' ').length - 1 > 0; else singleExtension">Les extensions de fichier autorisées sont: </ng-template>
      <ng-template
      #singleExtension>La seule extension de fichier autorisée est: </ng-template>
      <strong>{{uploadData.Cv.validators.file_extensions}}</strong>.
    </span>
  </div>

  <label for="cv" class="sr-only">CV</label>
  <div *ngIf="agent.cv_url" class="user-cv">
    <a [href]="agent.cv_url" target="_blank">
      <span class="cv-text">Télecharger le cv</span>
      <span class="aed-icon aed-icon-cv" aria-hidden="true"></span>
    </a>
  </div>
  <input type="file" class="form-control" id="cv" name="cv" (change)="cvSelected($event)" accept="application/pdf">

  <div class="form-error alert alert-danger" *ngIf="uploadData.Cv.errors.length > 0">
    <small *ngFor="let error of uploadData.Cv.errors">
      {{ error }}
    </small>
  </div>
</div>

<div class="form-group col-md-12 col-lg-12">
  <button type="submit" class="arrow-btn btn btn-primary text-uppercase" (click)="onCvSubmit()" [disabled]="!cv_file">
    Mettre à jour le CV
  </button>
</div>

<form class="agent-competences-update-form" #competencesUpdateForm="ngForm" (ngSubmit)="onCompetencesSubmit(competencesUpdateForm)">
  <div class="row">
    <div class="form-group col-md-6 col-lg-6">
      <label for="field_metier_referentiel">Métier (référentiel)</label>
      <angular2-multiselect [data]="metiers"
          [(ngModel)]="selectedMetier"
          [ngModelOptions]="{standalone: true}"
          [settings]="settingsSingle"
          #field_metier_referentiel="ngModel"
          class="single-select">
          <c-badge>
            <ng-template let-item="item">
              <label>{{item.label ? item.label : item.name}}</label>
            </ng-template>
          </c-badge>
      </angular2-multiselect>
      <div *ngIf="field_metier_referentiel?.invalid"
        class="form-error alert alert-danger">
        <small *ngIf="field_metier_referentiel?.errors?.required">
          Obligatoire
        </small>
      </div>
    </div>

    <div class="form-group col-md-6 col-lg-6">
      <label for="field_metier_saisie_libre">Métier (saisie libre)</label>
      <input type="tel" class="form-control" id="field_metier_saisie_libre"
        name="field_metier_saisie_libre"
        [(ngModel)]="agent.field_metier_saisie_libre"
        #field_metier_saisie_libre="ngModel" required>
      <div *ngIf="field_metier_saisie_libre?.invalid"
        class="form-error alert alert-danger">
        <small *ngIf="field_metier_saisie_libre?.errors?.required">
          Obligatoire
        </small>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-12">
      <label>Vos compétences</label>
      <angular2-multiselect [data]="competencesList"
          [(ngModel)]="fieldCompetencesIds"
          [ngModelOptions]="{standalone: true}"
          [settings]="settingsMulti">
          <c-badge>
            <ng-template let-item="item">
              <label>{{item.label ? item.label : item.name}}</label>
            </ng-template>
          </c-badge>
      </angular2-multiselect>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-12">
      <button type="submit" class="arrow-btn btn btn-primary text-uppercase" [disabled]="competencesUpdateForm.invalid">
        Mettre à jour
      </button>
    </div>
  </div>
</form>

<form class="agent-grade-update-form" #gradeUpdateForm="ngForm" (ngSubmit)="onGradeSubmit(gradeUpdateForm)">

  <div class="row">
    <div class="form-group col-md-12 col-lg-12">
      <label for="field_centres_d_interet">Centres d'intérêt</label>
      <ckeditor id="field_centres_d_interet" name="field_centres_d_interet"
      [(ngModel)]="agent.field_centres_d_interet"
      [config]="ckeditorConfig"
      #field_centres_d_interet="ngModel"></ckeditor>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_cadre_emploi">
      <label for="field_grade">Grade</label>
      <angular2-multiselect [data]="grades"
          [(ngModel)]="selectedGrade"
          [ngModelOptions]="{standalone: true}"
          [settings]="settingsSingle"
          class="single-select">
          <c-badge>
            <ng-template let-item="item">
              <label>{{item.label ? item.label : item.name}}</label>
            </ng-template>
          </c-badge>
      </angular2-multiselect>
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_emploi_fpt != undefined">
      <label for="field_emploi_fpt">Travaillez-vous dans une collectivité locale ou un
        établissement public local ?</label>
      <input type="text" class="form-control" id="field_emploi_fpt"
              name="field_emploi_fpt"
              [value]="agent.field_emploi_fpt == '1' ? 'Oui' : 'Non'"
              [disabled]="true">
      <!-- <select id="field_emploi_fpt" name="field_emploi_fpt" class="form-control"
      [(ngModel)]="agent.field_emploi_fpt" #field_emploi_fpt="ngModel">
        <option value="1">Oui</option>
        <option value="0">Non</option>
      </select> -->
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_etes_vous_en_emploi != undefined">
      <label for="field_etes_vous_en_emploi">Êtes-vous en emploi ?</label>
      <input type="text" class="form-control" id="field_etes_vous_en_emploi"
             name="field_etes_vous_en_emploi"
             [value]="agent.field_etes_vous_en_emploi == '1' ? 'Oui' : 'Non'"
             [disabled]="true">
      <!-- <select id="field_etes_vous_en_emploi" name="field_etes_vous_en_emploi" class="form-control"
      [(ngModel)]="agent.field_etes_vous_en_emploi" #field_etes_vous_en_emploi="ngModel">
        <option value="1">Oui</option>
        <option value="0">Non</option>
      </select> -->
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_pas_emploi_statut">
      <label for="field_pas_emploi_statut">Statut sans emploi</label>
      <input type="text" class="form-control" id="field_pas_emploi_statut"
      name="field_pas_emploi_statut" [value]="agent.field_pas_emploi_statut" [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_cadre_emploi && agent.field_cadre_emploi.label">
      <label for="field_cadre_emploi">Cadre d'emplois</label>
      <input type="text" class="form-control" id="field_cadre_emploi"
      name="field_cadre_emploi" [value]="agent.field_cadre_emploi.label" [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_etes_vous_liste_apti != undefined">
      <label for="field_etes_vous_liste_apti">Êtes-vous inscrit sur liste d’aptitude A+ ?</label>
      <input type="text" class="form-control" id="field_etes_vous_liste_apti"
             name="field_etes_vous_liste_apti"
             [value]="agent.field_etes_vous_liste_apti == '1' ? 'Oui' : 'Non'"
             [disabled]="true">
      <!-- <select id="field_etes_vous_liste_apti" name="field_etes_vous_liste_apti" class="form-control"
      [(ngModel)]="agent.field_etes_vous_liste_apti" #field_etes_vous_liste_apti="ngModel">
        <option value="1">Oui</option>
        <option value="0">Non</option>
      </select> -->
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_liste_aptitude_cadre && agent.field_etes_vous_liste_apti == '1'">
      <label for="field_liste_aptitude_cadre">Cadre d’emploi de liste d'aptitude</label>
      <input type="text" class="form-control" id="field_liste_aptitude_cadre"
      name="field_liste_aptitude_cadre"
      [value]="agent.field_liste_aptitude_cadre" [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_liste_aptitude_since">
      <label for="field_liste_aptitude_since">Année liste d'aptitude</label>
      <input type="text" class="form-control" id="field_liste_aptitude_since"
      name="field_liste_aptitude_since" [value]="agent.field_liste_aptitude_since" [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_etes_vous_ft != undefined">
      <label for="field_etes_vous_ft">Etes-vous fonctionnaire territorial ?</label>
      <input type="text" class="form-control" id="field_etes_vous_ft"
      name="field_etes_vous_ft"
      [value]="agent.field_etes_vous_ft == '1' ? 'Oui' : 'Non'" [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_etes_vous_fc != undefined">
      <label for="field_etes_vous_fc">Etes-vous fonctionnaire/contractuel ?</label>
      <input type="text" class="form-control" id="field_etes_vous_fc"
      name="field_etes_vous_fc"
      [value]="agent.field_etes_vous_fc" [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_mode_acces">
      <label for="field_mode_acces">Mode d'accès FPT</label>
      <input type="text" class="form-control" id="field_mode_acces"
      name="field_mode_acces" [value]="agent.field_mode_acces" [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_annee_de_promotion">
      <label for="field_annee_de_promotion">Année de promotion</label>
      <input type="text" class="form-control" id="field_annee_de_promotion"
      name="field_annee_de_promotion" [value]="agent.field_annee_de_promotion" [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_date_nomination">
      <label for="field_date_nomination">Nomination</label>
      <input type="text" class="form-control" id="field_date_nomination"
      name="field_date_nomination" [value]="agent.field_date_nomination" [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_corps_d_origine">
      <label for="field_corps_d_origine">Corps d'origine</label>
      <input type="text" class="form-control" id="field_corps_d_origine"
      name="field_corps_d_origine" [value]="agent.field_corps_d_origine" [disabled]="true">
    </div>

    <div class="form-group col-md-6 col-lg-6" *ngIf="agent.field_corps_origine_autre">
      <label for="field_corps_origine_autre">Corps d'origine (autre)</label>
      <input type="text" class="form-control" id="field_corps_origine_autre"
      name="field_corps_origine_autre" [value]="agent.field_corps_origine_autre" [disabled]="true">
    </div>
  </div>

  <div class="form-group col-md-12">
    <button type="submit" class="arrow-btn btn btn-primary text-uppercase">
      Mettre à jour
    </button>
  </div>

</form>
