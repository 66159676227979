import {Component, Input, OnInit} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {CollectiviteService} from '../../collectivite/collectivite.service';
import {EmploiService} from "../emploi.service";
import {LoaderService} from "../../shared/loader/loader.service";

@Component({
    selector: 'app-emploi-erreur',
    templateUrl: './emploi-erreur.component.html',
    styleUrls: ['./emploi-erreur.component.scss']
})
export class EmploiErreurComponent implements OnInit {

    @Input() emploi: any;
    @Input() agent_id: any;

    public success: boolean;
    public isSubmitting = false;
    public reporting = {
        message: ''
    };

    constructor(
        public activeModal: NgbActiveModal,
        private loader: LoaderService,
        private emploiService: EmploiService) {
    }

    ngOnInit() {
    }

    public onSubmit(submittedForm: NgForm) {
        if (!(this.reporting.message === null || this.reporting.message === undefined)) {
            this.isSubmitting = true;
            this.loader.show();
            this.emploiService.notifyError(this.agent_id, this.emploi.id, this.reporting.message).subscribe((result) => {
                this.loader.hide();
                this.activeModal.close({success: true});
            }, () => {
                this.loader.hide();
                this.activeModal.close({success: false});
            });
        }
    }

}
