import {Attribute, Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS, Validator, AbstractControl, Validators} from '@angular/forms';


const TIME_PERIOD_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => TimePeriodeValidatorDirective),
    multi: true
};

@Directive({
    selector: '[timePeriode][formControlName],[timePeriode][formControl],[timePeriode][ngModel]',
    providers: [TIME_PERIOD_VALIDATOR]
})
export class TimePeriodeValidatorDirective implements Validator {
    constructor(@Attribute('beginMonthField') private beginMonthField: string,
                @Attribute('beginYearField') private beginYearField: string,
                @Attribute('endMonthField') private endMonthField: string,
                @Attribute('endYearField') private endYearField: string) {
    }

    validate(control: AbstractControl): { [key: string]: any } {
        const beginMonth = +control.root.get(this.beginMonthField).value;
        const beginYear = +control.root.get(this.beginYearField).value;
        const endMonth = +control.root.get(this.endMonthField).value;
        const endYear = +control.root.get(this.endYearField).value;
        if ((beginMonth === null || beginMonth === undefined)
          || (beginYear === null || beginYear === undefined)
          || (endMonth === null || endMonth ===  undefined)
          || (endYear === null || endYear === undefined)
        ) {
            return null;
        }

        // Date de début (ajout d'un 0 avant le mois si besoin)
        const beginMonthClean = beginMonth < 10 ? `0${beginMonth}` : `${beginMonth}`;
        const beginDate = new Date(`${beginYear}-${beginMonthClean}`);

        // Date de fin (ajout d'un 0 avant le mois si besoin)
        const endMonthClean = endMonth < 10 ? `0${endMonth}` : `${endMonth}`;
        const endDate = new Date(`${endYear}-${endMonthClean}`);

        return endDate > beginDate ? null : {timePeriode: true};
    }
}
