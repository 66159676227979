import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EmploiErreurComponent} from '../../emploi/emploi-erreur/emploi-erreur.component';
import {AuthentificationService} from '../../authentification/authentification.service';
import {OwnershipAccessState} from '../../authentification/has-any-role';

@Component({
    selector: 'app-fiche-ct',
    templateUrl: './fiche-ct.component.html',
    styleUrls: ['./fiche-ct.component.scss']
})
export class FicheCtComponent implements OnInit {
    collectivite: any;
    accessState: OwnershipAccessState;
    currentUserId: any;

    address: any[];

    success: boolean = undefined;
    notifySuccess: boolean = undefined;

    public showHistory = false;

    constructor(private route: ActivatedRoute,
                private modalService: NgbModal,
                private authService: AuthentificationService) {
    }

    ngOnInit(): void {
        this.collectivite = this.route.snapshot.data['collectivite'];
        this.authService.identity.subscribe(
            (identity) => {
                this.accessState = {
                    currentUserRoles: identity && identity.roles ? identity.roles : [],
                    hasOwnership: !(identity === null || identity === undefined) && !(identity.collectivites === null || identity.collectivites === undefined)
                    && identity.collectivites.indexOf(this.collectivite.id) > -1
                };
                if (identity != null) {
                    this.currentUserId = identity.id;
                }
            },
            () => {
                this.accessState = {
                    currentUserRoles: [],
                    hasOwnership: false
                };
            }
        );
    }

    /**
     * Display the error notification form
     */
    public notifyError(emploi: any): void {
        const modalRef = this.modalService.open(EmploiErreurComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.emploi = emploi;
        modalRef.componentInstance.agent_id = this.currentUserId;
        modalRef.result.then((result) => {
            this.notifySuccess = result.success;
            window.scrollTo(0, 0);
        }, (result) => {
            this.notifySuccess = undefined;
        });
    }
}
