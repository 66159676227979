import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { STORAGE_PREVIOUS_URL, AuthentificationService } from './authentification.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class LoginCallbackRedirectGuard implements CanActivate {
  constructor(
    private storage: SessionStorageService,
    private authentificationService: AuthentificationService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const inIframe = this.inIframe();
    if (!inIframe) {
      const redirectUrl = this.storage.retrieve(STORAGE_PREVIOUS_URL);
      this.authentificationService.tryDecodeUrlFromLoginOrSession();
      this.router.navigate([redirectUrl ? redirectUrl : '/']);
    } else {
      parent.postMessage(location.hash, location.origin);
    }
    return false;
  }

  inIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
}
