import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getDrupalUri } from '../shared/external';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private referentielExportPath = '/referentiel/export/';
  private agentCsvPath = 'agents.csv';
  private collectiviteCsvPath = 'collectivities.csv';
  private jobCsvPath = 'jobs.csv';
  private demandeCsvPath = 'demandes.csv';

  constructor(private http: HttpClient) { }

  /**
   * return the agent
   * @param dateBefore
   * @param dateAfter
   * @returns string
   */
  public exportAgents(dateBefore: string, dateAfter: string): string {
    return getDrupalUri() + this.referentielExportPath + this.agentCsvPath
    + '?mail=&uid=&roles=All&field_mobilite_actif_value=All&field_mobilite_cnfpt_value=All'
    + '&period%5Bmin%5D=' + dateBefore + '&period%5Bmax%5D=' + dateAfter;
  }

  /**
   * return the agent
   * @param dateBefore
   * @param dateAfter
   * @returns string
   */
  public exportCollectivites(dateBefore: string, dateAfter: string): string {
      return getDrupalUri() + this.referentielExportPath + this.collectiviteCsvPath
            + '?period%5Bmin%5D=' + dateBefore + '&period%5Bmax%5D=' + dateAfter;
  }

  /**
   * return the agent
   * @param dateBefore
   * @param dateAfter
   * @returns string
   */
  public exportEmplois(dateBefore: string, dateAfter: string): string {
      return getDrupalUri() + this.referentielExportPath + this.jobCsvPath
            + '?period%5Bmin%5D=' + dateBefore + '&period%5Bmax%5D=' + dateAfter;
  }

  /**
   * return the agent
   * @param dateBefore
   * @param dateAfter
   * @returns string
   */
  public exportDemandes(dateBefore: string, dateAfter: string): string {
      return getDrupalUri() + this.referentielExportPath + this.demandeCsvPath
            + '?period%5Bmin%5D=' + dateBefore + '&period%5Bmax%5D=' + dateAfter;
  }
}
