import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AgentService } from './agent.service';

@Injectable()
export class AgentResolve implements Resolve<any> {
    constructor(
        private agentService: AgentService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.agentService.get(route.paramMap.get('id'));
    }
}
