import {Component, OnInit} from '@angular/core';
import {AuthentificationService, REDIRECT_KEY} from '../authentification.service';
import {SessionStorageService} from 'ngx-webstorage';
import {STORAGE_NEED_TO_LOG_IN} from '../auth-required.guard';
import {Router} from '@angular/router';

const MAX_TIME = 5000;
const STEP = 30;
const MAX_REDIRECT = 2;

@Component({
    selector: 'app-login-required',
    templateUrl: './login-required.component.html',
    styleUrls: ['./login-required.component.scss']
})
export class LoginRequiredComponent implements OnInit {
    private timer = 0;
    public progressLevel = 0;
    public tooManyRedirect: boolean;

    constructor(private authentificationService: AuthentificationService,
                private storage: SessionStorageService, private router: Router) {
    }

    ngOnInit() {
        this.tooManyRedirect = false;
        let redirectAmount = this.storage.retrieve(REDIRECT_KEY);
        if (redirectAmount === undefined || redirectAmount === null) {
            redirectAmount = 0;
        }
        if (redirectAmount <= MAX_REDIRECT) {
            this.storage.store(REDIRECT_KEY, redirectAmount + 1);
            const ctrl = this;
            const progress = function () {
                ctrl.timer = ctrl.timer + STEP;
                ctrl.progressLevel = (ctrl.timer / MAX_TIME) * 100;
                if (ctrl.timer >= MAX_TIME) {
                    // Redirect
                    ctrl.authentificationService.login(ctrl.storage.retrieve(STORAGE_NEED_TO_LOG_IN));
                } else {
                    setTimeout(progress, STEP);
                }
            };
            setTimeout(progress, STEP);
        } else {
            this.tooManyRedirect = true;
        }
    }


    fastLogin() {
        this.authentificationService.login(this.storage.retrieve(STORAGE_NEED_TO_LOG_IN));
    }

    sendTo(path: string) {
        window.location.href = path;
    }
}
