import {Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS, Validator, AbstractControl, Validators} from '@angular/forms';

const FACEBOOK_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => FacebookValidatorDirective),
    multi: true
};

@Directive({
    selector: '[facebook][formControlName],[facebook][formControl],[facebook][ngModel]',
    providers: [FACEBOOK_VALIDATOR]
})
export class FacebookValidatorDirective implements Validator {

    validate(c: AbstractControl): { [key: string]: any } {
        let v: string = c.value;

        if ((v === null || v === undefined) || v.trim() == '') {
            return null;
        }
        const regexp = new RegExp('^(?:https?:\\/\\/)?(?:www\\.)?facebook\\.com\\/(?:(?:\\w)*#!\\/)?(?:pages\\/)?(?:[\\w\\-]*\\/)*([\\w\\-\\.]*)?$');
        return regexp.test(v) ? null : {facebook: true};
    }
}
