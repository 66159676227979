import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { DeleteDialogComponent } from '../../shared/delete-dialog/delete-dialog.component';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { LoaderService } from '../../shared/loader/loader.service';
import { EmploiService } from '../emploi.service';
import { FicheEditAffectationComponent } from '../fiche-edit-affectation/fiche-edit-affectation.component';


@Component({
  selector: 'app-fiche-edit-emploi',
  templateUrl: './fiche-edit-emploi.component.html',
  styleUrls: ['./fiche-edit-emploi.component.scss'],
  providers: [ NgbActiveModal ]
})
export class FicheEditEmploiComponent implements OnInit {
  collectivite: any;
  emploi: any;
  type = 'unkown';
  isNew: boolean = false;

  cotations: any[];
  filieres: any[];
  domaines: any[];

  public activeIdString: string;

  selectedTypeEmploi: any = [];
  selectedFiliere: any = [];
  selectedDomaine: any = [];

    /**
    * Singleselect field settings
    */
  public settingsSingle = {
    singleSelection: true,
    text: '-',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout désélectionner',
    searchPlaceholderText: 'Rechercher',
    enableSearchFilter: true,
    labelKey: 'name',
    primaryKey: 'id',
  };
  public settingsMulti = {
    singleSelection: false,
    text: '-',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout désélectionner',
    searchPlaceholderText: 'Rechercher',
    enableSearchFilter: true,
    labelKey: 'name',
    primaryKey: 'id',
  };
  singleSelectSettings: IMultiSelectSettings = {
      enableSearch: true,
      checkedStyle: 'checkboxes',
      buttonClasses: 'btn btn-block',
      dynamicTitleMaxItems: 1,
      displayAllSelectedText: true,
      containerClasses: 'cds-single',
      showCheckAll: false,
      showUncheckAll: false,
      selectionLimit: 1,
      autoUnselect: true,
      closeOnSelect: true,
  };

  /**
    * Multiselect field traductions
    */
  multiSelectTexts: IMultiSelectTexts = {
      checkAll: 'Tout sélectionner',
      uncheckAll: 'Tout désélectionner',
      checked: 'Sélectionné',
      checkedPlural: 'Sélectionnés',
      searchPlaceholder: 'Rechercher',
      searchEmptyResult: 'Pas de résultat...',
      searchNoRenderText: 'Rechercher...',
      defaultTitle: '-',
      allSelected: 'Tous les éléments',
  };

  constructor(private route: ActivatedRoute,
    private modalService: NgbModal,
    private loader: LoaderService,
    private emploiService: EmploiService,
    private router: Router,
    public activeModal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef) {
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {

    this.isNew = (this.route.snapshot.data['emploi'] === null || this.route.snapshot.data['emploi'] === undefined);
    this.collectivite = this.route.snapshot.data['collectivite'];
    this.emploi = {
      type_emploi: {},
      fonction: '',
      filiere: {},
      domaine_famille: {},
      fonctionnel: undefined,
      experiences: [],
    }

    this.route.fragment.subscribe((fragment) => {
      this.activeIdString = fragment;
    });

    /* if (!this.isNew) {
      let emploiData = this.route.snapshot.data['emploi'];
      this.emploi = emploiData;
      // récupération des ids pour positionnement des multiselects
      this.emploi.fonction = emploiData.fonction;
      if (!(emploiData.type_emploi === null || emploiData.type_emploi === undefined)) {
        this.emploi.type_emploi = [emploiData.type_emploi.id];
      }
      if (!(emploiData.filiere === null || emploiData.filiere === undefined)) {
        this.emploi.filiere = [emploiData.filiere.id];
      }
      if (!(emploiData.domaine_famille === null || emploiData.domaine_famille === undefined)) {
        this.emploi.domaine_famille = [emploiData.domaine_famille.id];
      }
      this.setType(emploiData);
   } */

    this.emploiService.getFormDatas().subscribe(
      (value) => {
        this.cotations = value.cotations;
        this.filieres = value.filieres;
        this.domaines = value.domaines;

        if (!this.isNew) {
          let emploiData = this.route.snapshot.data['emploi'];
          this.emploi = emploiData;
          // récupération des ids pour positionnement des multiselects
          this.emploi.fonction = emploiData.fonction;
          if (!(emploiData.type_emploi === null || emploiData.type_emploi === undefined)) {
            this.selectedTypeEmploi = this.cotations.filter(x => x.id === emploiData.type_emploi.id);
          }
          if (!(emploiData.filiere === null || emploiData.filiere === undefined)) {
            this.selectedFiliere = this.filieres.filter(x => x.id === emploiData.filiere.id);
          }
          if (!(emploiData.domaine_famille === null || emploiData.domaine_famille === undefined)) {
            this.selectedDomaine = this.domaines.filter(x => x.id === emploiData.domaine_famille.id);
          }
          this.setType(emploiData);
       }
      },
      (error) => {
        console.error(error);
      },
    );
  }

  /**
   * Display the emploi edition form
   */
  public openEditAffectationForm(affectation: any = {}): void {
      const modalRef = this.modalService.open(FicheEditAffectationComponent, {windowClass: 'custom-modal-lg'});
      modalRef.componentInstance.affectation = affectation;
      modalRef.componentInstance.emploiId = this.emploi.id;
      modalRef.componentInstance.collectiviteId = this.collectivite.id;
      modalRef.result.then((result) => {
        this.refreshEmploi();
      }, () => {
        this.setType(this.emploi);
      });
  }

  public openComfirmVacantDialog() {
      let modalRef: NgbModalRef;
      modalRef = this.modalService.open(DeleteDialogComponent, {size: 'sm'});
      modalRef.componentInstance.action = "déclaration de vacance de poste";
      modalRef.componentInstance.actionVerb = "déclarer vacant";
      modalRef.componentInstance.element = "ce poste";
      modalRef.result.then((result) => {
          if (result === true) {
              this.loader.show();
              this.emploiService
              .declareVacant({
                'id': this.emploi.id,
                'vacant': true
              })
              .subscribe(
                () => {
                  this.refreshEmploi();
                  this.loader.hide();
                  this.activeModal.close({success: true});
                },
                (error) => {
                  console.log(error);
                  this.activeModal.close({success: false});
                }
              );
          }
      }, () => {
        this.setType(this.emploi);
      });
  }

  public openComfirmUnkownDialog() {
      let modalRef: NgbModalRef;
      modalRef = this.modalService.open(DeleteDialogComponent, {size: 'sm'});
      modalRef.componentInstance.action = "manque d'affectation";
      modalRef.componentInstance.actionVerb = "déclarer sans affectation";
      modalRef.componentInstance.element = "ce poste";
      modalRef.result.then((result) => {
          if (result === true) {
              this.loader.show();
              this.emploiService
              .declareVacant({
                'id': this.emploi.id,
                'vacant': false
              })
              .subscribe(
                () => {
                  this.refreshEmploi();
                  this.loader.hide();
                  this.activeModal.close({success: true});
                },
                (error) => {
                  console.log(error);
                  this.activeModal.close({success: false});
                }
              );
          }
      }, () => {
        this.setType(this.emploi);
      });
  }

  public openDisableDialog(id: number) {
    const modalRef = this.modalService.open(DeleteDialogComponent, {size: 'sm'});
    modalRef.componentInstance.action = "désactivation";
    modalRef.componentInstance.actionVerb = "désactiver";
    modalRef.componentInstance.element = "cette expérience";
    modalRef.result.then((result) => {
      this.loader.show();
      this.emploiService.changeExperienceValidity(id, false)
          .subscribe(
              () => {
                this.refreshEmploi();
                this.loader.hide();
                this.activeModal.close({success: true});
              },
              (error) => {
                console.log(error);
                this.activeModal.close({success: false});
              }
          );
    });
}

public openEnableDialog(id: number) {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {size: 'sm'});
    modalRef.componentInstance.title = "Confirmation de réactivation";
    modalRef.componentInstance.confirmText = "Réactiver";
    modalRef.componentInstance.messages = ["Êtes-vous sûr de vouloir réactiver cette expérience ?"];
    modalRef.result.then((result) => {
      this.loader.show();
      this.emploiService.changeExperienceValidity(id, true)
          .subscribe(
              () => {
                this.refreshEmploi();
                this.loader.hide();
                this.activeModal.close({success: true});
              },
              (error) => {
                console.log(error);
                this.activeModal.close({success: false});
              }
          );
    });
}

  // public openDeleteDialog(affectation: any = {}) {
  //     let modalRef: NgbModalRef;
  //     modalRef = this.modalService.open(DeleteDialogComponent, {size: 'sm'});
  //     modalRef.result.then((result) => {
  //         if (result === true) {
  //             this.loader.show();
  //             this.emploiService
  //             .deleteExperience(affectation.id)
  //             .subscribe(
  //               () => {
  //                 this.refreshEmploi();
  //                 this.loader.hide();
  //                 this.activeModal.close({success: true});
  //               },
  //               (error) => {
  //                 console.log(error);
  //                 this.activeModal.close({success: false});
  //               }
  //             );
  //         }
  //     });
  // }

  private refreshEmploi() {
    this.emploiService.get(this.emploi.id).subscribe(
      (res) => {
        this.emploi.experiences = res.experiences;
        this.setType(res);
      }
    );
  }

  private setType(emploi: any) {
    if (!(emploi.is_vacant === null || emploi.is_vacant === undefined) && emploi.is_vacant) {
      this.type = 'vacant';
    } else if (!(emploi.current_experience === null || emploi.current_experience === undefined) && emploi.current_experience.id) {
      this.type = 'occupied';
    } else {
      this.type = 'unkown';
    }
  }

  public onTypeChange(type: any) {
    if (type === 'vacant') {
      this.openComfirmVacantDialog();
    } else if (type === 'unkown') {
      this.openComfirmUnkownDialog();
    } else if (type === 'occupied') {
      this.openEditAffectationForm();
    }
  }

  public onSubmit(submittedForm: NgForm) {
    this.loader.show();
    let data = submittedForm.value;
    data.collectivite = this.collectivite.id;
    data.type = "1";
    data.cotationPoste = this.selectedTypeEmploi.map(x => x.id);
    data.filiere = this.selectedFiliere.map(x => x.id);
    data.domaine = this.selectedDomaine.map(x => x.id);
    if (this.isNew) {
      this.emploiService.createEmploi(data).subscribe(
        (value) => {
          this.loader.hide();
          this.router.navigate(['/collectivite/', this.collectivite.id, 'emploi', value], {fragment: 'emploi-agent'});
        },
        (error) => {
          console.log(error);
        },
        () => {
          this.loader.hide();
        }
      );
    } else {
      data.id = this.emploi.id;
      this.emploiService.editEmploi(data).subscribe(
        (value) => {
          this.loader.hide();
          this.router.navigate(['/collectivite/', this.collectivite.id, 'edit'], {fragment: 'emplois'});
        },
        (error) => {
          console.log(error);
        },
        () => {
          this.loader.hide();
        }
      );
    }
  }

  public backToCollectivite() {
    this.router.navigate(['/collectivite/', this.collectivite.id, 'edit'], {fragment: 'emplois'});
  }
}
