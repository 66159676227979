
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getDrupalUri} from '../shared/external';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';



@Injectable()
export class OffreService {

    private apiPath = '/api/offre_emploi';

    constructor(private http: HttpClient) {
    }

    public get(num: string) {
        return this.http.get<any>(
            getDrupalUri() + this.apiPath + '/' + num + '?_format=json').pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }

    public getLast() {
        return this.http.get<any>(
            getDrupalUri() + this.apiPath + '/last?_format=json').pipe(
            map(
                (value) => {
                    return value;
                }
            ));
    }
}
