import {AuthRequiredGuard, REGISTRATION_URI} from '../authentification/auth-required.guard';
import {RegistrationComponent} from "./registration/registration.component";

export const accountRoutes = [
    {
        path: REGISTRATION_URI,
        canActivate: [AuthRequiredGuard],
        component: RegistrationComponent,
        data: {
            title : "Inscription",
            excludeProfilComplet: true
        }
    }
];
