<div class="alc-collectivite-elt">
    <ng-container *ngIf="collectivite">
        <a [routerLink]="[this.collectiviteEditLink, collectivite.id]">
            <div class="row">
                <div class="col-md-4 col-lg-4">
                    <div class="alc-elt-thumb">
                        <img *ngIf="collectivite.blason_url; else ctNothumb"
                             class="img-fluid"
                             src="{{ collectivite.blason_url }}"
                             alt="">

                        <ng-template #ctNothumb>
                            <img #nothumb class="img-fluid" src="assets/images/picto_mairie_ct.png" alt="">
                        </ng-template>
                    </div>
                </div>
                <div class="col-md-8 col-lg-8">
                    <div class="alc-elt-infos">
                        <h3 class="title-primary text-uppercase">{{
                            collectivite.nomen_long }}</h3>
                        <ul class="list-unstyled aed-label-value">
                            <li>
                                <span class="alv-label">Type de collectivité :</span>
                                <span class="alv-value">{{ collectivite.nj?.label }}</span>
                            </li>
                        </ul>
                        <address
                                *ngIf="collectivite != null && (collectivite.l1_normalisee || collectivite.l2_normalisee || collectivite.l3_normalisee || collectivite.l4_normalisee || collectivite.l5_normalisee  || collectivite.l6_normalisee)">
                                                        <span class="aed-icon aed-icon-placeholder"
                                                              aria-hidden="true"></span>
                            <span *ngIf="collectivite.l2_normalisee">{{ collectivite.l2_normalisee + ',  ' }}</span>
                            <span *ngIf="collectivite.l3_normalisee">{{ collectivite.l3_normalisee + ',  ' }}</span>
                            <span *ngIf="collectivite.l4_normalisee">{{ collectivite.l4_normalisee + ',  ' }}</span>
                            <span *ngIf="collectivite.l5_normalisee">{{ collectivite.l5_normalisee + ',  ' }}</span>
                            <span *ngIf="collectivite.l6_normalisee">{{ collectivite.l6_normalisee + ',  ' }}</span>
                        </address>
                    </div>
                </div>
            </div>
        </a>
    </ng-container>
</div>
