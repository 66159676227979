<div class="alert alert-block alert-{{alertType}} {{isAlertVisible === true ? 'invisible d-none' : ''}}" role="alert">
    <a class="alert-close-btn" (click)="closeAlert()" data-dismiss="alert" title="Fermer" *ngIf="isAlertClosable === true">
        <span class="aed-icon aed-icon-close" aria-hidden="true"></span>
        <span class="sr-only">Fermer</span>
    </a>
    <div class="inner" >
        <div [innerHTML]="alertMessage"></div>
        <ul *ngIf="listError">
            <li *ngFor="let message of listError" [innerHTML]="message"></li>
        </ul>
    </div>
</div>