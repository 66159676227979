import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-statut',
  templateUrl: './statut.component.html',
  styleUrls: ['./statut.component.scss']
})
export class StatutComponent implements OnInit {
@Input() agent: any;

  constructor() {
  }

  ngOnInit() {
  }

}
