import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OffreDetailComponent} from './offre-detail/offre-detail.component';
import {OffreTeaserComponent} from './offre-teaser/offre-teaser.component';
import {RouterModule} from '@angular/router';
import {offreRoutes} from './offre-routing.module';
import {SharedModule} from '../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {OffreService} from './offre.service';
import {OffreResolve} from './offre.resolve';
import {OffreLastComponent} from './offre-last/offre-last.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(offreRoutes, { relativeLinkResolution: 'legacy' }),
        SharedModule,
        NgbModule
    ],
    providers: [
        OffreService,
        OffreResolve
    ],
    declarations: [
        OffreDetailComponent,
        OffreTeaserComponent,
        OffreLastComponent],
    exports: [
        OffreTeaserComponent,
        OffreLastComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OffreModule {
}
