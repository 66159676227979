import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CsrfService } from '../../../../authentification/csrf.service';
import { CollectiviteService } from '../../../../collectivite/collectivite.service';
import { LoaderService } from '../../../../shared/loader/loader.service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'profil-collectivite-archivage',
  templateUrl: './archivage.component.html',
  styleUrls: ['./archivage.component.scss']
})
export class ArchivageComponent implements OnInit {

  @Input() collectivite: any;
  @Output() collectiviteChange = new EventEmitter<any>();

  @Input() archivageSuccess: boolean;
  @Output() archivageSuccessChange = new EventEmitter<boolean>();

  @Input() fieldsError: any;
  @Output() fieldsErrorChange = new EventEmitter<any>();

  @Output() refreshEvent = new EventEmitter<any>();

  showArchivage = false;
  public csrfToken = '';

  date: NgbDateStruct;

  constructor(
    private loader: LoaderService,
    private collectiviteService: CollectiviteService,
    private csrfService: CsrfService,
    private calendar: NgbCalendar
  ) {
    this.date = this.calendar.getToday();
  }

  ngOnInit() {
    this.updateCsrfToken();

    if (this.collectivite.fields_archivage.archivage_effectue) {
      this.showArchivage = true;
    }
    let dateArchivage = this.collectivite.fields_archivage.date_archivage;
    if (!(dateArchivage === null || dateArchivage === undefined)) {
      this.date.day = parseInt(dateArchivage.day);
      this.date.month = parseInt(dateArchivage.month);
      this.date.year = parseInt(dateArchivage.year);
    }
  }

  private updateCsrfToken() {
    this.csrfService.getToken()
      .subscribe(
        token => {
          this.csrfToken = token;
        });
  }

  public setCollectiviteArchivage(value: boolean) {
    this.showArchivage = value;
  }

  public setCollectiviteTransfert(event: any) {
    if (!(event.id === null || event.id === undefined)) {
      this.collectivite.fields_archivage.collectivite_transfert = event.id;
    }
  }

  public onSubmit(submittedForm: NgForm): void {
    this.loader.show();
    const data = submittedForm.value;
    data['id'] = this.collectivite.id;
    data['date_archivage'] = data.date_archivage.year + '-' + ('0' + data.date_archivage.month).slice(-2) + '-' + ('0' + data.date_archivage.day).slice(-2);
    data['collectivite_transfert'] = this.collectivite.fields_archivage.collectivite_transfert;
    data['transfert_referents'] = this.collectivite.fields_archivage.transfert_referents;
    data['archivage_demande'] = true;
    data['archivage_effectue'] = false;

    this.collectiviteService.update(data, this.csrfToken)
        .subscribe(
            () => {
                this.loader.hide();
                this.updateCsrfToken();
                this.archivageSuccessChange.emit(true);
                this.refresh();
                window.scrollTo(0, 0);
            },
            (value) => {
                this.loader.hide();
                this.updateCsrfToken();
                this.fieldsError = value.errors;
                this.archivageSuccessChange.emit(false);
                window.scrollTo(0, 0);
            }
        );
  }

  refresh() {
    this.refreshEvent.emit(true);
  }
}
