import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CollectiviteService} from './collectivite.service';
import {RouterModule} from '@angular/router';
import {collectiviteRoutes} from './collectivite-routing.module';
import {FicheCtComponent} from './fiche-ct/fiche-ct.component';
import {ListeCtComponent} from './liste-ct/liste-ct.component';
import {CollectiviteResolve} from './collectivite.resolve';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../shared/shared.module';
import {AddressPipe} from './address.pipe';
import {FicheCtLightComponent} from './fiche-ct-light/fiche-ct-light.component';
import {EmploiModule} from '../emploi/emploi.module';
import {FicheEmploiLightComponent} from '../emploi/fiche-emploi-light/fiche-emploi-light.component';
import {AffectationLightComponent} from '../emploi/affectation-light/affectation-light.component';
import {AuthentificationModule} from '../authentification/authentification.module';
import {FormsModule} from '@angular/forms';
import {HasRoleReferentCtDirective} from '../authentification/has-role-referent-ct.directive';
import {FicheCtEditComponent} from './fiche-ct-edit/fiche-ct-edit.component';
// import {SelectModule} from 'ng2-select';
import {OffreModule} from '../offre/offre.module';
import {DveModule} from "../dve/dve.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(
            collectiviteRoutes
        ),
        NgbModule,
        SharedModule,
        EmploiModule,
        AuthentificationModule,
        // SelectModule,
        OffreModule,
        DveModule
    ],
    providers: [
        CollectiviteService,
        CollectiviteResolve
    ],
    declarations: [
        FicheCtComponent,
        FicheCtLightComponent,
        AddressPipe,
        FicheCtEditComponent,
        ListeCtComponent
    ],
    exports: [
        FicheCtLightComponent,
        ListeCtComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class CollectiviteModule {
}
