import { environment } from '../../environments/environment';

export const getClientId = function() {
    return window['CLIENT_ID'] == null ? environment.clientId : window['CLIENT_ID'];
};

export const getSsoBaseUrl = function() {
    return window['SSO_BASE_URL'] == null ? environment.ssobaseurl : window['SSO_BASE_URL'];
};

export const getRegistrationUrl = function() {
    return window['REGISTRATION_URL'] == null ? environment.registrationUrl : window['REGISTRATION_URL'];
};

export const getLogoutUrl = function() {
    return window['LOGOUT_URL'] == null ? environment.logoutUrl : window['LOGOUT_URL'];
};

export const getAccountUrl = function() {
    return window['ACCOUNT_URL'] == null ? environment.accountUrl : window['ACCOUNT_URL'];
};

export const getIssuer = function() {
    return window['ISSUER'] == null ? environment.issuer : window['ISSUER'];
};
/* relitive url to avoid deploy errors */
export const getDrupalUri = function() {
    return '';
};

export const getJWKS = function() {
    return window['JWKS'];
};

export const getTokenDomain = function() {
    return window['TOKEN_DOMAIN'] == null ? environment.tokenDomain : window['TOKEN_DOMAIN'];
};

export const getReCaptcha = function() {
    return window['reCAPTCHAKey'] == null ? environment.reCAPTCHAKey : window['reCAPTCHAKey'];
};
