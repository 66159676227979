import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import {AuthRequiredGuard} from '../authentification/auth-required.guard';
import {ROLE_ADMIN, ROLE_AGENT, ROLE_CNFPT, ROLE_REFERENT_CT} from '../authentification/has-any-role';
import { AgentResolve } from '../agent/agent.resolve';
import { ProfilUtilisateurComponent } from './profil-utilisateur/profil-utilisateur.component';
import { CollectiviteResolve } from '../collectivite/collectivite.resolve';
import { ProfilCollectiviteComponent } from './profil-collectivite/profil-collectivite.component';

export const adminRoutes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthRequiredGuard],
    data: {
      title: 'Tableau de bord d\'admininistration',
      roles: [ROLE_CNFPT, ROLE_ADMIN],
    },

  },
  {
    path: 'dashboard/profil-utilisateur/:id',
    resolve: {
      agent: AgentResolve
    },
    component: ProfilUtilisateurComponent,
    canActivate: [AuthRequiredGuard],
    data: {
      title: 'Fiche détaillée d\'un utilisateur',
      roles: [ROLE_CNFPT, ROLE_ADMIN],
    },
    pathMatch: 'full'
  },
  {
    path: 'dashboard/profil-collectivite/:id',
    resolve: {
      collectivite: CollectiviteResolve
    },
    component: ProfilCollectiviteComponent,
    canActivate: [AuthRequiredGuard],
    data: {
      title: 'Fiche détaillée d\'une collectivité',
      roles: [ROLE_CNFPT, ROLE_ADMIN],
    },
    pathMatch: 'full'
  }
];
