import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {CollectiviteService} from '../collectivite.service';
import {LoaderService} from '../../shared/loader/loader.service';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EmploiErreurComponent} from '../../emploi/emploi-erreur/emploi-erreur.component';
import {EmploiFormComponent} from '../../emploi/emploi-form/emploi-form.component';
import {ExperienceFormComponent} from '../../emploi/experience-form/experience-form.component';
import {CsrfService} from '../../authentification/csrf.service';
import { DeleteDialogComponent } from '../../shared/delete-dialog/delete-dialog.component';
import { EmploiService } from '../../emploi/emploi.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { Location } from '@angular/common';

@Component({
    selector: 'app-fiche-ct-edit',
    templateUrl: './fiche-ct-edit.component.html',
    styleUrls: ['./fiche-ct-edit.component.scss']
})
export class FicheCtEditComponent implements OnInit {
    collectivite: any;
    success: boolean = undefined;
    isSubmitting = false;
    fieldsError = false;
    public csrfToken = '';
    files: any[] = [];
    errorsBlason: string[] = [];
    errorsOrganigramme: string[] = [];
    page = 1;
    itemsPerPage = 10;
    collectionSize = 10;

    fulltextQuery = '';
    // pour positionner l'onglet actif
    public activeIdString: string;

    showReturnButton = false;

    constructor(private route: ActivatedRoute,
                private collectiviteService: CollectiviteService,
                private loader: LoaderService,
                private modalService: NgbModal,
                private csrfService: CsrfService,
                private emploiService: EmploiService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.collectivite = this.route.snapshot.data['collectivite'];
        this.files['blason'] = null;
        this.files['organigramme'] = null;
        this.updateCsrfToken();
        this.refreshJobs();
        this.route.fragment.subscribe((fragment) => {
          this.activeIdString = fragment;
        });
        this.showReturnButton = !this.router.navigated;
    }

    /**
     * Refresh the CSRF Token
     */
    private updateCsrfToken() {
        this.csrfService.getToken()
            .subscribe(
                token => {
                    this.csrfToken = token;
                });
    }

    /**
     * Init the form submit
     */
    public initSubmit(): void {
        this.isSubmitting = true;
    }

    public onSubmit(submittedForm: NgForm): void {
        // this.loader.show();
        this.isSubmitting = false;
    }

    /**
     * Update the submitted Collectivite
     */
    public onSubmitCoordonnees(submittedForm: NgForm): void {
        this.loader.show();
        this.isSubmitting = true;
        const data = submittedForm.value;
        data['id'] = this.collectivite.id;

        this.collectiviteService.update(data, this.csrfToken)
            .subscribe(
                () => {
                    this.refresh();
                    this.loader.hide();
                    this.isSubmitting = false;
                    this.success = true;
                    this.updateCsrfToken();
                    window.scrollTo(0, 0);
                },
                (value) => {
                    this.loader.hide();
                    this.isSubmitting = false;
                    this.success = false;
                    this.updateCsrfToken();
                    this.fieldsError = value.errors;
                    window.scrollTo(0, 0);
                }
            );
    }

    /**
     * Get the collectivite data
     */
    private refresh() {
        const id = this.collectivite.id;

        // Get the Collectivite by given id
        this.collectiviteService.getWithEmplois(id).subscribe(
                (value) => {
                    this.collectivite = value;
                    this.loader.hide();
                },
                () => {
                    this.loader.hide();
                });
    }

    /**
     * Get the jobs of a collectivite
     */
    public refreshJobs(fromFulltext: boolean = false) {
        const id = this.collectivite.id;
        if (fromFulltext) {
          this.page = 1;
        }
        // Get the Collectivite by given id
        this.emploiService.getAllForCollectivite(id, this.page, this.itemsPerPage, this.fulltextQuery).subscribe(
          (value) => {
              this.collectivite.emplois = value.data;
              this.collectionSize = value.count;
          },
          () => {
            this.collectivite.emplois = [];
            this.page = 1;
            this.collectionSize = 10;
          });
    }

    /**
     * Display the emploi edition form
     */
    public openAddEmploiForm(): void {
        const modalRef = this.modalService.open(EmploiFormComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.emploi = null;
        modalRef.componentInstance.agent = null;
        modalRef.componentInstance.isEditedByReferent = true;
        modalRef.componentInstance.collectivite = this.collectivite;
        modalRef.result.then((result) => {
            if (result.success) {
                this.success = true;
                this.loader.show();
                this.refresh();
                window.scrollTo(0, 0);
            } else {
                this.success = false;
                window.scrollTo(0, 0);
            }
        }, (result) => {

        });
    }

    /**
     * Display the emploi edition form
     */
    public openEditEmploiForm(emploi: any): void {
        const modalRef = this.modalService.open(EmploiFormComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.emploi = emploi;
        modalRef.componentInstance.agent = null;
        modalRef.componentInstance.isEditedByReferent = true;
        modalRef.componentInstance.collectivite = this.collectivite;
        modalRef.result.then((result) => {
            if (result.success) {
                this.success = true;
                this.loader.show();
                this.refresh();
                window.scrollTo(0, 0);
            } else {
                this.success = false;
                window.scrollTo(0, 0);
            }
        }, (result) => {

        });
    }


    /**
     * Display the emploi edition form
     */
    public openEditExperienceForm(experience: any): void {
        const modalRef = this.modalService.open(ExperienceFormComponent, {windowClass: 'custom-modal-lg'});
        modalRef.componentInstance.experience = experience;
        modalRef.componentInstance.fromReferent = true;
        modalRef.result.then((result) => {
            if (result.success) {
                this.success = true;
                this.loader.show();
                this.refresh();
                window.scrollTo(0, 0);
            } else {
                this.success = false;
                window.scrollTo(0, 0);
            }
        }, (result) => {

        });
    }

    public openDisableDialog(id: number) {
        const modalRef = this.modalService.open(DeleteDialogComponent, {size: 'sm'});
        modalRef.componentInstance.action = "désactivation";
        modalRef.componentInstance.actionVerb = "désactiver";
        modalRef.componentInstance.element = "cet emploi";
        modalRef.result.then((result) => {
          this.loader.show();
          this.emploiService.changeEmploiValidity(id, false)
              .subscribe(
                  () => {
                    this.success = true;
                    this.loader.hide();
                    this.refreshJobs();
                  },
                  (value) => {
                    this.success = false;
                    this.loader.hide();
                    modalRef.componentInstance.fieldsError = value.errors;
                  }
              );
        });
    }

    public openEnableDialog(id: number) {
        const modalRef = this.modalService.open(ConfirmDialogComponent, {size: 'sm'});
        modalRef.componentInstance.title = "Confirmation de réactivation";
        modalRef.componentInstance.confirmText = "Réactiver";
        modalRef.componentInstance.messages = ["Êtes-vous sûr de vouloir réactiver cet emploi ?"];
        modalRef.result.then((result) => {
          this.loader.show();
          this.emploiService.changeEmploiValidity(id, true)
              .subscribe(
                  () => {
                    this.success = true;
                    this.loader.hide();
                    this.refreshJobs();
                  },
                  (value) => {
                    this.success = false;
                    this.loader.hide();
                    modalRef.componentInstance.fieldsError = value.errors;
                  }
              );
        });
    }

    public fileSelected($event: any, file: string) {
        if ($event.target && $event.target.files && $event.target.files.length > 0) {
            this.files[file] = $event.target.files[0];
        } else {
            this.files[file] = undefined;
        }
    }

    public uploadFile(file: string) {
        this.loader.show();
        this.isSubmitting = true;

        this.collectiviteService.uploadFile(this.collectivite.id, this.files[file], file)
            .subscribe(
                () => {
                    this.refresh();
                    this.loader.hide();
                    this.isSubmitting = false;
                    this.files[file] = null;
                    this.success = true;
                    if ('blason' === file) {
                        this.errorsBlason = [];
                    } else {
                        this.errorsOrganigramme = [];
                    }
                    window.scrollTo(0, 0);
                },
                (errors) => {
                    this.loader.hide();
                    this.isSubmitting = false;
                    this.success = false;
                    if ('blason' === file) {
                        this.errorsBlason = errors.error;
                    } else {
                        this.errorsOrganigramme = errors.error;
                    }
                    window.scrollTo(0, 0);
                }
            );
    }

    public removeFile(file: string) {
        this.loader.show();
        this.isSubmitting = true;

        this.collectiviteService.removeFile(this.collectivite.id, file)
            .subscribe(
                () => {
                    this.refresh();
                    this.loader.hide();
                    this.isSubmitting = false;
                    this.success = true;
                    window.scrollTo(0, 0);
                },
                () => {
                    this.loader.hide();
                    this.isSubmitting = false;
                    this.success = false;
                    window.scrollTo(0, 0);
                }
            );
    }
}

