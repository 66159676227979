<!-- begin: PAGE HEADER -->
<header class="page-title">
    <h1 class="text-uppercase bolded">
        Inscription d'un Référent
    </h1>
</header>
<!-- end: PAGE HEADER -->

<!-- begin: PAGE CONTENT -->
<div id="content" class="aed-registration-step2 aed-form-edition">
    <app-alert *ngIf="fieldError"
               [isAlertClosable]="false"
               [alertType]="'danger'"
               [alertMessage]="'La connexion avec le serveur est dégradée,le forumaire de mise à jour n\'est pas disponible.'"
    >
    </app-alert>

    <app-alert *ngIf="success === true"
               [isAlertClosable]="false"
               [alertType]="'success'"
               [alertMessage]="'Votre demande de droits a été créée avec succès.'"
    >
    </app-alert>

    <app-alert *ngIf="success === false"
               [isAlertClosable]="true"
               [alertType]="'danger'"
               [alertMessage]="'La mise à jour de votre profil utilisateur a échoué.'"
    >
    </app-alert>
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <app-alert *ngIf="notifySuccess === true"
                       [isAlertClosable]="true"
                       [alertType]="'success'"
                       [alertMessage]="'Votre message a été envoyé au CNFPT'"
            >
            </app-alert>

            <app-alert *ngIf="notifySuccess === false"
                       [isAlertClosable]="true"
                       [alertType]="'danger'"
                       [alertMessage]="'Impossible d\'envoyer votre message'"
                       [listError]="fieldsError"
            >
            </app-alert>
        </div>
    </div>

    <form class="profile-update-form aed-entity-form" #registrationReferentForm="ngForm">
        <div class="row">
            <div class="form-group cds-single  col-md-4 col-lg-4">
                <label for="field_categorie">
                    Quelle catégorie métier ?
                    <span class="required-field">(obligatoire)</span>
                </label>
                <angular2-multiselect [data]="metiers"
                                      [(ngModel)]="selectedCategorie"
                                      [ngModelOptions]="{standalone: true}"
                                      [settings]="settingsSingle"
                                      name="field_categorie"
                                      id="field_categorie"
                                      [disabled]="isConfirmStage"
                                      >
                                  </angular2-multiselect>

                <!--div *ngIf="fieldMetierReferentiel?.errors && fieldMetierReferentiel?.invalid && (fieldMetierReferentiel?.dirty || fieldMetierReferentiel?.touched)" class="form-error alert alert-danger">
                    <small *ngIf="fieldMetierReferentiel?.errors?.required">
                        Ce champ est requis.
                    </small>
                </div-->
            </div>

            <div class="form-group col-md-4 col-lg-4">
                <label for="field_metier">
                    Saisir un métier
                    <span class="required-field">(obligatoire)</span>
                </label>
                <input type="text" class="form-control" id="field_metier" name="field_metier"
                       [(ngModel)]="registration.field_metier" required [disabled]="isConfirmStage"
                       #field_metier="ngModel"
                >

                <div *ngIf="field_metier?.errors && field_metier?.invalid && (field_metier?.dirty || field_metier?.touched)" class="form-error alert alert-danger">
                    <small *ngIf="field_metier?.errors?.required">
                        Ce champ est requis.
                    </small>
                </div>
            </div>

            <div class="form-group col-md-4 col-lg-4">
                <label for="field_telephone">
                    Numéro de téléphone
                    <span class="required-field">(obligatoire)</span>
                </label>
                <input type="tel" class="form-control" id="field_telephone" name="field_telephone"
                       [(ngModel)]="registration.field_telephone" required [disabled]="isConfirmStage"
                       required phone #field_telephone="ngModel"
                >

                <div *ngIf="field_telephone?.errors && field_telephone?.invalid && (field_telephone?.dirty || field_telephone?.touched)" class="form-error alert alert-danger">
                    <small *ngIf="field_telephone?.errors?.required">
                        Ce champ est requis.
                    </small>
                    <small *ngIf="field_telephone?.errors?.phone">
                        Le numéro est invalide
                    </small>
                </div>
            </div>

            <div class="col-md-6 col-lg-6">
                <app-aed-typeahead (onChoose)="onPlaceSelection($event)"
                                   (keypress)="checkIfNumber($event)"
                                   [retriever]="'searchCollectiviteRefReferent'"
                                   [text]="'Dans quelle collectivité travaillez-vous ? Code postal (obligatoire)'"
                                   [placeholder]="'ex: 34800'"
                                   [showLabel]="true" [disabled]="isConfirmStage" [required]="true">
                </app-aed-typeahead>
                <div class="form-error alert alert-danger" *ngIf="!lastKeyIsDigit">
                    <small>Ce champ ne peut contenir que des chiffres.</small>
                </div>
                <p class="field-info">
                    Votre collectivité n’apparait pas dans cette liste ? Assurez-vous que vous avez saisi le bon code postal. En cas d’absence avérée, vous pouvez <a href="javascript:void(0);" class="clickable" (click)="notifyCnfpt('contact_cnfpt_missing_ct')">contacter le CNFPT</a> pour que la
                    collectivité soit rajoutée.
                </p>

            </div>

            <div class="form-group col-md-6 col-lg-6">
                <label for="field_email">
                    Courriel
                    <span class="required-field">(obligatoire)</span>
                </label>
                <input type="tel" class="form-control" id="field_email" name="field_email"
                       [(ngModel)]="registration.field_email" required [disabled]="isConfirmStage"
                       required email #field_email="ngModel"
                >

                <div *ngIf="field_email?.errors && field_email?.invalid && (field_email?.dirty || field_email?.touched)" class="form-error alert alert-danger">
                    <small *ngIf="field_email?.errors?.required">
                        Ce champ est requis.
                    </small>
                    <small *ngIf="field_email?.errors?.email">
                        Le courriel entré est invalide
                    </small>
                </div>
            </div>

            <div class="form-group col-md-12">
                <div *ngIf="isConfirmStage" class="alert alert-info"><p><strong>Vous allez procéder à une demande de
                    création d'un profil référent.</strong></p>
                    <p>Vous vous déclarez <strong>référent</strong> sur la collectivité <strong>{{registration.field_collectivite.label}}</strong>.</p>
                    <p>Confirmez-vous ces informations ?</p></div>

                <button type="button" (click)="toggleConfirm(true)" class="arrow-btn btn btn-primary text-uppercase"
                        [disabled]="registrationReferentForm.invalid || registration.field_collectivite?.id == null" *ngIf="!isConfirmStage">Valider le profil
                </button>
                <button type="submit" (click)="onSubmit()" class="arrow-btn btn btn-success text-uppercase"
                        [disabled]="registrationReferentForm.invalid || registration.field_collectivite?.id == null" *ngIf="isConfirmStage">Confirmer mon inscription
                </button>
                <button type="button" (click)="toggleConfirm(false)"
                        class="arrow-btn btn btn-primary text-uppercase"
                        *ngIf="isConfirmStage">Modifier
                </button>
                <p class="mar-10 text-warning" *ngIf="!isConfirmStage && (registrationReferentForm.invalid || registration.field_collectivite?.id == null)"><small>Vous devez remplir tous les champs obligatoires pour pouvoir valider votre profil.</small></p>
            </div>
        </div>
    </form>
</div>
<!-- end: PAGE CONTENT -->
