<app-breadcrumb></app-breadcrumb>

<div class="container" *ngIf="collectivite == null">
    <div class="row">
        <div class="col-md-12 col-lg-12 empty-block">
            <div class="inner">
                <p>
                    La collectivité n'est pas disponible.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="collectivite">
    <!-- begin: PAGE HEADER -->
    <header class="page-title">
      <div class="row">
        <h1 class="text-uppercase bolded col-md-8 col-lg-8">
            {{ collectivite.nomen_long }}
        </h1>
        <a class="public-profile col-md-3 col-lg-3 text-center" [routerLink]="['/collectivite/', collectivite.id]"
           target="_blank">
            <span class="bolded">Voir sa page publique</span>
        </a>
        <a class="clickable float-right col-md-1 col-lg-1" back-button>
          <span class="aed-icon aed-icon-back" aria-hidden="true"></span>
          <span class="pm-link-text plt-back">Retour</span>
        </a>
      </div>
    </header>
    <!-- end: PAGE HEADER -->

    <!-- begin: PAGE CONTENT -->
    <div id="content" class="ct-detail aed-form-edition">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <app-alert *ngIf="success === true"
                           [isAlertClosable]="true"
                           [alertType]="'success'"
                           [alertMessage]="'La collectivité a été mise à jour avec succès.'"
                >
                </app-alert>

                <app-alert *ngIf="success === false"
                           [isAlertClosable]="true"
                           [alertType]="'danger'"
                           [alertMessage]="'La mise à jour de votre collectivité a échoué'"
                           [listError]="fieldsError"
                >
                </app-alert>
            </div>
        </div>
        <!-- begin: COLLECTIVITE TABS -->
        <ng-container>
            <div class="aed-tabs">
                <ul ngbNav #nav="ngbNav" [(activeId)]="activeIdString" class="nav-pills">
                    <li [ngbNavItem]="'coordonnees'">
                        <a ngbNavLink>Coordonnées</a>
                        <ng-template ngbNavContent>
                            <form class="ct-update-form"
                                  (ngSubmit)="onSubmitCoordonnees(coordonneesUpdateForm)"
                                  #coordonneesUpdateForm="ngForm" novalidate>
                                <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                        <h2 class="bordered-title bolded">Coordonnées</h2>
                                    </div>

                                    <!-- begin: CTI / Blason -->
                                    <div class="col-md-12 col-lg-12">
                                        <h3 class="bordered-title bolded">Logo</h3>
                                    </div>
                                    <div class="form-group col-md-12 col-lg-12">
                                        <label for="blason">Blason</label>
                                        <div *ngIf="collectivite.blason_url" class="user-avatar">
                                            <img class="img-fluid" [src]="collectivite.blason_url" title="Votre blason"
                                                 alt="Votre blason"/>
                                        </div>
                                        <input type="file" class="form-control" id="blason" name="blason"
                                               (change)="fileSelected($event, 'blason')" accept="image/*">

                                        <div class="form-error alert alert-danger" *ngIf="errorsBlason?.length > 0">
                                            <small *ngFor="let error of errorsBlason">
                                                {{ error }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 col-lg-12">
                                        <button type="button"
                                                class="arrow-btn btn btn-primary text-uppercase"
                                                [disabled]="null === files || null === files['blason']"
                                                (click)="uploadFile('blason')">
                                            Transférer
                                        </button>
                                        <button type="button"
                                                class="arrow-btn btn btn-danger text-uppercase"
                                                (click)="removeFile('blason')" *ngIf="collectivite.blason_url">
                                            Retirer
                                        </button>
                                    </div>

                                    <div class="col-md-12 col-lg-12">
                                        <h3 class="bordered-title bolded">Libellé</h3>
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="l1_normalisee">
                                            Libellé
                                            <span class="required-field">(obligatoire)</span>
                                        </label>
                                        <input type="text" class="form-control" id="nomen_long" name="nomen_long"
                                               [(ngModel)]="collectivite.nomen_long" #nomen_long="ngModel" required>
                                        <div *ngIf="nomen_long?.errors && nomen_long?.invalid && (nomen_long?.dirty || nomen_long?.touched)" class="form-error alert alert-danger">
                                            <small *ngIf="nomen_long?.errors?.required">
                                                Le libellé est obligatoire
                                            </small>
                                        </div>
                                    </div>

                                    <!-- begin: CTI / ADDRESS -->
                                    <div class="col-md-12 col-lg-12">
                                        <h3 class="bordered-title bolded">Adresse</h3>
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="l1_normalisee">
                                            Ligne adressage 1
                                            <span class="required-field">(obligatoire)</span>
                                        </label>
                                        <input type="text" class="form-control" id="l1_normalisee" name="l1_normalisee"
                                               [(ngModel)]="collectivite.l1_normalisee" #l1_normalisee="ngModel" required>
                                        <div *ngIf="l1_normalisee?.errors && l1_normalisee?.invalid && (l1_normalisee?.dirty || l1_normalisee?.touched)" class="form-error alert alert-danger">
                                            <small *ngIf="l1_normalisee?.errors?.required">
                                                La première ligne d'adressage est obligatoire
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="l2_normalisee">
                                            Ligne adressage 2
                                        </label>
                                        <input type="text" class="form-control" id="l2_normalisee" name="l2_normalisee"
                                               [(ngModel)]="collectivite.l2_normalisee">
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="l3_normalisee">
                                            Ligne adressage 3
                                        </label>
                                        <input type="text" class="form-control" id="l3_normalisee" name="l3_normalisee"
                                               [(ngModel)]="collectivite.l3_normalisee">
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="l4_normalisee">
                                            Ligne adressage 4
                                        </label>
                                        <input type="text" class="form-control" id="l4_normalisee" name="l4_normalisee"
                                               [(ngModel)]="collectivite.l4_normalisee">
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="l5_normalisee">
                                            Ligne adressage 5
                                        </label>
                                        <input type="text" class="form-control" id="l5_normalisee" name="l5_normalisee"
                                               [(ngModel)]="collectivite.l5_normalisee">
                                    </div>

                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="l6_normalisee">
                                            Ligne adressage 6
                                            <span class="required-field">(obligatoire)</span>
                                        </label>
                                        <input type="text" class="form-control" id="l6_normalisee" name="l6_normalisee"
                                               [(ngModel)]="collectivite.l6_normalisee" required #l6_normalisee="ngModel">
                                        <div *ngIf="l6_normalisee?.errors && l6_normalisee?.invalid && (l6_normalisee?.dirty || l6_normalisee?.touched)" class="form-error alert alert-danger">
                                            <small *ngIf="l6_normalisee?.errors?.required">
                                                La 6ème ligne d'adressage est obligatoire
                                            </small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="libcom">
                                            Libellé de commune
                                        </label>
                                        <input type="text" class="form-control" id="libcom" name="libcom"
                                               [(ngModel)]="collectivite.libcom">
                                    </div>
                                    <div class="form-group col-md-6 col-lg-6">
                                        <label for="codpos">
                                            Code postal
                                        </label>
                                        <input type="text" class="form-control" id="codpos" name="codpos"
                                               [(ngModel)]="collectivite.codpos">
                                    </div>
                                    <!-- end: CTI / ADDRESS -->

                                    <!-- begin: CTI / ADDRESS -->
                                    <!-- end: CTI / ADDRESS -->

                                    <div class="col-md-12 clearfix">
                                        <div class="form-group-separator"></div>
                                    </div>

                                    <!-- begin: CTI / CONATCTS -->
                                    <div class="col-md-12 col-lg-12">
                                        <h3 class="bordered-title bolded">Contacts</h3>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-4">
                                        <label for="telephone1">Téléphone</label>
                                        <input type="text" class="form-control" id="telephone1" name="telephone1"
                                               [(ngModel)]="collectivite.telephone1" #telephone1="ngModel" phone>
                                        <div *ngIf="telephone1?.errors && telephone1?.invalid && (telephone1?.dirty || telephone1?.touched)" class="form-error alert alert-danger">
                                            <small *ngIf="telephone1?.errors?.phone">
                                                Le numéro n'est pas valide.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-4 col-lg-4">
                                        <label for="email1">Email</label>
                                        <input type="email" class="form-control" id="email1" name="email1"
                                               [(ngModel)]="collectivite.email1" #email1="ngModel" customEmail>
                                        <div *ngIf="email1?.errors && email1?.invalid && (email1?.dirty || email1?.touched)" class="form-error alert alert-danger">
                                            <small *ngIf="email1?.errors?.customEmail">
                                                Le courriel n'est pas valide.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-4 col-lg-4">
                                        <label for="web">Site web</label>
                                        <input type="text" class="form-control" id="web" name="web"
                                               [(ngModel)]="collectivite.web" #web="ngModel" url>
                                        <div *ngIf="web?.errors && web?.invalid && (web?.dirty || web?.touched)" class="form-error alert alert-danger">
                                            <small *ngIf="web?.errors?.url">
                                                L'adresse n'est pas valide
                                            </small>
                                        </div>
                                    </div>
                                    <!-- end: CTI / CONATCTS -->

                                    <!-- begin: CTI / RESEAUX -->
                                    <div class="col-md-12 col-lg-12">
                                        <h3 class="bordered-title bolded">Reseaux sociaux</h3>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-4">
                                        <label for="linkedin">LinkedIn</label>
                                        <input type="text" class="form-control" id="linkedin"
                                               name="linkedin"
                                               [(ngModel)]="collectivite.linkedin" #linkedin="ngModel"
                                               linkedin>
                                        <div *ngIf="linkedin?.invalid" class="form-error alert alert-danger">
                                            <small *ngIf="linkedin?.errors?.linkedin">
                                                L'adresse saisie n'est pas un profil Linkedin valide.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-4 col-lg-4">
                                        <label for="viadeo">Viadeo</label>
                                        <input type="text" class="form-control" id="viadeo"
                                               name="viadeo"
                                               [(ngModel)]="collectivite.viadeo" #viadeo="ngModel" viadeo>
                                        <div *ngIf="viadeo?.invalid" class="form-error alert alert-danger">
                                            <small *ngIf="viadeo?.errors?.viadeo">
                                                L'adresse saisie n'est pas un profil Viadeo valide.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-4 col-lg-4">
                                        <label for="facebook">Facebook</label>
                                        <input type="text" class="form-control" id="facebook"
                                               name="facebook"
                                               [(ngModel)]="collectivite.facebook" #facebook="ngModel"
                                               facebook>
                                        <div *ngIf="facebook?.invalid" class="form-error alert alert-danger">
                                            <small *ngIf="facebook?.errors?.facebook">
                                                L'adresse saisie n'est pas un profil Facebook valide.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-4 col-lg-4">
                                        <label for="twitter">Twitter</label>
                                        <input type="text" class="form-control" id="twitter"
                                               name="twitter"
                                               [(ngModel)]="collectivite.twitter" #twitter="ngModel" twitter>
                                        <div *ngIf="twitter?.invalid" class="form-error alert alert-danger">
                                            <small *ngIf="twitter?.errors?.twitter">
                                                L'adresse saisie n'est pas un profil Twitter valide.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-md-12 clearfix">
                                        <div class="form-group-separator"></div>
                                    </div>

                                    <div class="col-md-12 clearfix">
                                        <div class="form-group-separator"></div>
                                    </div>

                                    <!-- begin: CTI / ORGANI -->
                                    <div class="col-md-12 col-lg-12">
                                        <h3 class="bordered-title bolded">Organigramme</h3>
                                    </div>
                                    <div class="form-group col-md-12 col-lg-12">
                                        <label for="organigramme_url">Lien</label>
                                        <input type="text" class="form-control" id="organigramme_url" name="organigramme_url"
                                               [(ngModel)]="collectivite.organigramme_url">
                                    </div>
                                    <div class="form-group col-md-12 col-lg-12">
                                        <label for="blason">Fichier</label>
                                        <div *ngIf="collectivite.organigramme_file_url" class="user-cv">
                                            <a [href]="collectivite.organigramme_file_url" target="_blank">
                                                <span class="cv-text">Télecharger l'organigramme</span>
                                                <span class="aed-icon aed-icon-cv" aria-hidden="true"></span>
                                            </a>
                                        </div>
                                        <input type="file" class="form-control" id="organigramme" name="organigramme"
                                               (change)="fileSelected($event, 'organigramme')" accept="application/pdf">

                                        <div class="form-error alert alert-danger" *ngIf="errorsOrganigramme?.length > 0">
                                            <small *ngFor="let error of errorsOrganigramme">
                                                {{ error }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 col-lg-12">
                                        <button type="button"
                                                class="arrow-btn btn btn-primary text-uppercase"
                                                [disabled]="null === files || null === files['organigramme']"
                                                (click)="uploadFile('organigramme')">
                                            Transférer
                                        </button>
                                        <button type="button"
                                                class="arrow-btn btn btn-danger text-uppercase"
                                                (click)="removeFile('organigramme')" *ngIf="collectivite.organigramme_file_url">
                                            Retirer
                                        </button>
                                    </div>
                                    <!-- end: CTI / ORGANI -->

                                    <div class="col-md-12 clearfix">
                                        <div class="form-group-separator"></div>
                                    </div>

                                    <div class="form-group col-md-12 col-lg-12">
                                        <button type="submit"
                                                class="arrow-btn btn btn-primary text-uppercase"
                                                [disabled]="coordonneesUpdateForm.invalid">
                                            Mettre à jour les coordonnées
                                        </button>
                                    </div>

                                </div>
                            </form>
                        </ng-template>
                    </li>
                    <!-- end: COLLECTIVITE TAB / IDENTITY -->

                    <!-- begin: COLLECTIVITE TAB / EMPLOIS -->
                    <li [ngbNavItem]="'emplois'">
                        <a ngbNavLink>Emplois</a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-md-12 col-lg-12">
                                    <h2 class="bordered-title bolded">Emplois</h2>
                                </div>
                            </div>
                            <div>

                              <p class="search-description">Pour filtrer la liste des emplois, vous pouvez saisir une fonction, une cotation de poste ou le nom/prénom d'un agent.</p>

                              <form>
                                <div class="form-group form-inline float-left">
                                    <input class="form-control ml-2" type="text" placeholder="Saisir le texte recherché" name="fulltextSearch" [(ngModel)]="fulltextQuery"/>
                                    <button class="arrow-btn btn btn-third text-uppercase filter-button" (click)="refreshJobs(true)">Rechercher</button>
                                </div>
                              </form>
                              <div class="float-right">
                                  <button class="arrow-btn btn btn-primary text-uppercase filter-button"
                                  [routerLink]="['/collectivite', collectivite.id, 'emploi', 'new']">
                                  Ajouter un emploi
                                </button>
                              </div>

                            </div>
                            <div>

                              <table class="table table-striped">
                                <thead>
                                <tr class="table-header">
                                  <th scope="col">#</th>
                                  <th scope="col">Fonction</th>
                                  <th scope="col">Cotation de poste</th>
                                  <th scope="col">Affectation</th>
                                  <th scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let emploi of collectivite.emplois; index as i">
                                  <th class="{{ !emploi.is_valid ? 'italic-font' : '' }}" scope="row">{{ i + 1 }}</th>
                                  <td class="{{ !emploi.is_valid ? 'italic-font' : '' }}" >
                                    <ngb-highlight [result]="emploi.fonction"></ngb-highlight>
                                  </td>
                                  <td class="{{ !emploi.is_valid ? 'italic-font' : '' }}" >
                                    <ngb-highlight [result]="emploi.type_emploi?.label"></ngb-highlight>
                                  </td>
                                  <td class="{{ !emploi.is_valid ? 'italic-font' : '' }}" >
                                    <ng-container *ngIf="emploi.is_vacant; then isVacant;"></ng-container>
                                    <ng-container *ngIf="!emploi.is_vacant && emploi?.current_experience && emploi?.current_experience?.id; then isAssigned;"></ng-container>
                                    <ng-container *ngIf="!emploi.is_vacant && (!emploi?.current_experience || !emploi?.current_experience?.id); then notAssignedNorVacant;"></ng-container>

                                    <ng-template #isVacant>
                                      <ngb-highlight [result]="'Poste vacant'"></ngb-highlight>
                                    </ng-template>

                                    <ng-template #isAssigned>
                                      <ngb-highlight [result]="emploi.current_experience.user_id.label"></ngb-highlight>
                                    </ng-template>

                                    <ng-template #notAssignedNorVacant>
                                      <ngb-highlight [result]="'Sans information sur l\'affectation du poste'"></ngb-highlight>
                                    </ng-template>
                                  </td>
                                  <td>
                                    <span class="alv-label aed-icon aed-icon-edit clickable" aria-hidden="true" title="Editer l'emploi" [routerLink]="['/collectivite', collectivite.id, 'emploi', emploi.id]"></span>
                                    <ng-container *ngIf="emploi.is_valid; then isValid else isInvalid;"></ng-container>
                                    <ng-template #isValid>
                                      <span class="alv-label aed-icon aed-icon-eye-crossed clickable" title="Désactiver l'emploi" aria-hidden="true" (click)="openDisableDialog(emploi.id)"></span>
                                    </ng-template>
                                    <ng-template #isInvalid>
                                      <span class="alv-label aed-icon aed-icon-eye clickable" title="Réactiver l'emploi" aria-hidden="true" (click)="openEnableDialog(emploi.id)"></span>
                                    </ng-template>
                                  </td>
                                </tr>
                                </tbody>
                              </table>

                              <div class="d-flex justify-content-between p-2">
                                <ngb-pagination
                                  [collectionSize]="collectionSize" [(page)]="page" [pageSize]="itemsPerPage"
                                  [maxSize]="5" [boundaryLinks]="true" (pageChange)="refreshJobs()">
                                </ngb-pagination>

                                <select class="custom-select" style="width: auto" name="itemsPerPage" [(ngModel)]="itemsPerPage" (change)="refreshJobs()">
                                  <option [ngValue]="10">10 emplois par page</option>
                                  <option [ngValue]="20">20 emplois par page</option>
                                  <option [ngValue]="50">50 emplois par page</option>
                                </select>
                              </div>

                            </div>
                        </ng-template>
                      </li>
                    <!-- end: COLLECTIVITE TAB / EMPLOIS -->
                  </ul>

              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </ng-container>
        <!-- end: COLLECTIVITE TABS -->

    </div>
    <!-- end: PAGE CONTENT -->
</div>
