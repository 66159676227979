<app-breadcrumb></app-breadcrumb>

<div class="container">
    <!-- begin: PAGE HEADER -->
    <header class="page-title">
        <h1 class="text-uppercase bolded">
            Contactez-nous
        </h1>
    </header>
    <!-- end: PAGE HEADER -->

    <!-- begin: PAGE CONTENT -->
    <div id="content" class="ct-detail aed-form-edition">
        <app-alert *ngIf="fieldError"
                   [isAlertClosable]="false"
                   [alertType]="'danger'"
                   [alertMessage]="'Le formulaire n\'est pas valide. Veuillez corriger les erreurs et soumettre à nouveau.'"
        >
        </app-alert>

        <app-alert *ngIf="success === true"
                   [isAlertClosable]="false"
                   [alertType]="'success'"
                   [alertMessage]="'Votre message a été envoyé avec succès.'"
        >
        </app-alert>

        <app-alert *ngIf="success === false"
                   [isAlertClosable]="true"
                   [alertType]="'danger'"
                   [alertMessage]="'L\' envoi de  votre message a échoué.'"
        >
        </app-alert>

        <form class="aed-contact-form" #aedContactForm="ngForm" novalidate>
            <input type="hidden" id="csrf" name="csrf" [(ngModel)]="csrfToken" #csrf="ngModel">
            <div class="row">
                <div class="form-group col-md-4 col-lg-4">
                    <label for="name">
                        Nom
                        <span class="required-field">(obligatoire)</span>
                    </label>
                    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="contact.name" #name="ngModel" required>

                    <div *ngIf="name?.errors && name?.invalid && (name?.dirty || name?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="name?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>

                <div class="form-group col-md-4 col-lg-4">
                    <label for="mail">
                        Email
                        <span class="required-field">(obligatoire)</span>
                    </label>
                    <input type="email" class="form-control" id="mail" name="mail" [(ngModel)]="contact.mail" #mail="ngModel" required>

                    <div *ngIf="mail?.invalid && (mail?.dirty || mail?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="mail?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>

                <div class="form-group col-md-4 col-lg-4">
                    <label for="subject">
                        Sujet
                        <span class="required-field">(obligatoire)</span>
                    </label>
                    <input type="text" class="form-control" id="subject" name="subject" [(ngModel)]="contact.subject" #subject="ngModel" required>

                    <div *ngIf="subject?.invalid && (subject?.dirty || subject?.touched)" class="form-error alert alert-danger">
                        <small *ngIf="subject?.errors?.required">
                            Ce champ est requis.
                        </small>
                    </div>
                </div>

              <div class="form-group col-md-12 col-lg-12">
                <label for="message">
                  Message
                  <span class="required-field">(obligatoire)</span>
                </label>
                <textarea class="form-control" id="message" name="message" [(ngModel)]="contact.message" #message="ngModel" required>
                </textarea>

                <div *ngIf="message?.invalid && (message?.dirty || message?.touched)" class="form-error alert alert-danger">
                  <small *ngIf="message?.errors?.required">
                    Ce champ est requis.
                  </small>
                </div>
              </div>
              <div class="form-group col-md-12 col-lg-12">
              <re-captcha
                (resolved)="resolved($event)"
                required
                [(ngModel)]="contact.captcha"
                name="captcha"
                #captcha="ngModel"
                [siteKey]="reCaptchaKey"
              ></re-captcha>
              </div>
              <div class="form-group col-md-12 col-lg-12">
                <button type="submit"
                        class="arrow-btn btn btn-primary text-uppercase"
                        [disabled]="!aedContactForm.form.valid"
                        (click)="onSubmit(aedContactForm)">
                  Envoyer
                </button>
              </div>
            </div>
        </form>
    </div>
    <!-- end: PAGE CONTENT -->
</div>
