<!-- begin : HOME -->
<div id="aed-home" class="container-fluid">
    <!-- begin : HOME BANDEAU -->
    <app-bandeau></app-bandeau>
    <!-- end : HOME BANDEAU -->

    <div class="container">

      <div *ngIf="showRegistrationSuccess" class="fixed-top">
          <div class="container">
              <div class="alert alert-block alert-success {{alertRegisteredIsVisible === true ? 'invisible d-none' : ''}} " role="alert">
                  <a class="alert-close-btn" (click)="closeRegisteredAlert()" data-dismiss="alert" title="Fermer">
                      <span class="aed-icon aed-icon-close" aria-hidden="true"></span>
                      <span class="sr-only">Fermer</span>
                  </a>
                  <div class="inner">
                    <div *ngIf="collectiviteId; else isAgent">
                        <p>
                          Votre inscription est effectuée. Bienvenue sur l'Annuaire des cadres de la fonction publique territoriale.
                        </p>
                        <p>
                          Administrez les informations et les emplois de direction de votre collectivité :
                          <a class="alert-link" [routerLink]="['/collectivite', collectiviteId, 'edit']" fragment="emplois">continuer</a>
                        </p>
                    </div>
                    <ng-template #isAgent>
                        <p>
                          Votre inscription est effectuée. Bienvenue sur l’Annuaire des cadres de la fonction publique territoriale.
                        </p>
                        <p>
                          Accédez à votre espace pour compléter votre profil, votre parcours et vos postes de direction : <a class="alert-link" [routerLink]="['/profil']" fragment="tabCoordonnees">continuer</a>.
                        </p>
                    </ng-template>
                  </div>
              </div>
          </div>
      </div>

      <div *ngIf="showRegistrationDemandeSuccess" class="fixed-top">
          <div class="container">
              <div class="alert alert-block alert-success {{alertRegisteredIsVisible === true ? 'invisible d-none' : ''}} " role="alert">
                  <a class="alert-close-btn" (click)="closeRegisteredAlert()" data-dismiss="alert" title="Fermer">
                      <span class="aed-icon aed-icon-close" aria-hidden="true"></span>
                      <span class="sr-only">Fermer</span>
                  </a>
                  <div class="inner">
                      <p>
                        Votre inscription est effectuée. Bienvenue sur l’Annuaire des cadres de la fonction publique territoriale.
                      </p>
                      <p>
                        Une fois votre demande acceptée vous pourrez accèder à votre espace pour compléter votre profil, votre parcours et vos postes de direction.
                      </p>
                  </div>
              </div>
          </div>
      </div>

        <!-- begin : HOME TOP -->
        <div class="aed-page-top">
            <div class="apt-widgets">
                <div class="row">
                    <div class="apt-widget aw-first col-md-4 col-lg-4">
                        <div class="inner">
                            <a class="no-style" [routerLink]="'/annuaire'" fragment="fonction">
                                <span class="aed-icon aed-icon-family-tree" aria-hidden="true"></span>
                                <div class="apt-widget-infos">
                                    <app-loader-small *ngIf="jobCounter == undefined"></app-loader-small>
                                    <span class="apt-widget-number" *ngIf="jobCounter && jobCounter != undefined">{{ jobCounter }}</span>
                                    <span class="apt-widget-label lighter">Emploi<span *ngIf="jobCounter && jobCounter > 1">s</span> de direction</span>
                                    <span class="apt-widget-label bolded">Répertorié<span *ngIf="jobCounter && jobCounter > 1">s</span></span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="apt-widget aw-second col-md-4 col-lg-4">
                        <div class="inner">
                            <!--<a href="http://www.cnfpt.fr/listes-daptitude-a" target="_blank" class="apt-widget-tooltip" placement="bottom" ngbTooltip="Voir les listes d'aptitudes">-->
                                <!--<span class="sr-only">Voir les listes d'aptitudes</span>-->
                            <!--</a>-->
                            <a class="no-style" target="_blank" href="http://www.cnfpt.fr/evoluer/lemploi-fpt/l-emploi-des-cadres-de-direction">
                                <span class="aed-icon aed-icon-briefcase" aria-hidden="true"></span>
                                <div class="apt-widget-infos ">
                                    <app-loader-small *ngIf="offerCounter == undefined"></app-loader-small>
                                    <span class="apt-widget-number" *ngIf="offerCounter && offerCounter != undefined">{{ offerCounter }}</span>
                                    <span class="apt-widget-label lighter">Offre<span *ngIf="offerCounter && offerCounter > 1">s</span> d'emploi</span>
                                    <span class="apt-widget-label bolded">Publiée<span *ngIf="offerCounter && offerCounter > 1">s</span></span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="apt-widget aw-third col-md-4 col-lg-4">
                        <div class="inner">
                            <a class="no-style" [routerLink]="'/annuaire'" fragment="personne">
                                <span class="aed-icon aed-icon-user" aria-hidden="true"></span>
                                <div class="apt-widget-infos">
                                    <app-loader-small *ngIf="profileCounter == undefined"></app-loader-small>
                                    <span class="apt-widget-number" *ngIf="profileCounter && profileCounter != undefined">{{ profileCounter }}</span>
                                    <span class="apt-widget-label lighter">Pair<span *ngIf="profileCounter && profileCounter > 1">s</span> dans votre</span>
                                    <span class="apt-widget-label bolded">Réseau</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end : HOME TOP -->

        <!-- begin : HOME SEARCH FORM -->
        <form class="aed-highlighted-form">
            <div class="row">
                <div class="form-group col-md-3 col-lg-3 center-text">
                    <label>Qui ?</label>
                    <input class="form-control" placeholder="Rechercher une personne" #agent (keydown)="keyDownFunction($event, agent.value, emploi.value, place.value)">
                </div>
                <div class="form-group col-md-3 col-lg-3 center-text">
                    <label>Quoi ?</label>
                    <input class="form-control" placeholder="Rechercher un emploi" #emploi (keydown)="keyDownFunction($event, agent.value, emploi.value, place.value)">
                </div>
                <div class="form-group col-md-3 col-lg-3 center-text">
                    <label>Où ?</label>
                    <input class="form-control" placeholder="Rechercher un lieu" #place (keydown)="keyDownFunction($event, agent.value, emploi.value, place.value)">
                </div>
                <div class="form-group col-md-3 col-lg-3 mar-t-27">
                    <button type="button" class="btn arrow-btn btn-primary" (click)="triggerSearch(agent.value, emploi.value, place.value)">Rechercher</button>
                    <div class="advanced-search bolded">
                        <span class="aed-icon aed-icon-search"></span>
                        <a [routerLink]="['/annuaire']" fragment="clear">Recherche avancée</a>
                    </div>
                </div>
            </div>
        </form>
        <!-- end : HOME SEARCH FORM -->

        <app-map></app-map>

        <div class="aed-home-bloc  aed-dev-message">
            <div class="inner">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <h2 class="bordered-title">Contactez-nous</h2>
                        <p>
                            Écrivez-nous pour nous faire part des bugs à corriger et de vos suggestions pour une meilleure navigation.
                        </p>
                    </div>
                    <div class="col-md-12 col-lg-12">
                        <a class="btn arrow-btn btn-primary" [routerLink]="['/form/contact']">Contactez le CNFPT</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="aed-home-bloc aed-home-entries">
            <div class="row">

                <!-- begin : HOME LISTE APT -->
                <div class="col-md-3 col-lg-3 ahe-entry">
                    <a href="http://www.cnfpt.fr/evoluer/l-emploi-dans-la-fpt/l-emploi-des-cadres-de-direction" title="Dernières offres d’emploi - Voir toutes les offres" target="_blank">
                        <div class="ahe-entry-img">
                            <img class="img-fluid" src="assets/images/cnfpt002391_a4.jpg" alt="">
                        </div>
                        <h3>
                            <span class="bolded">Dernières offres d’emploi</span>
                            <span class="lighter">voir toutes les offres</span>
                        </h3>
                    </a>
                </div>
                <!-- end : HOME LISTE APT -->

                <!-- begin : HOME LAUREATS INET -->
                <div class="col-md-3 col-lg-3 ahe-entry">
                    <a href="https://secure-inet.cnfpt.fr/" title="CV des lauréats INET - Consulter les profils" target="_blank">
                        <div class="ahe-entry-img">
                            <img class="img-fluid" src="assets/images/ambi_eleve_conserv_009bib_b.jpg" alt="">
                        </div>
                        <h3>
                            <span class="bolded">CV des lauréats INET</span>
                            <span class="lighter">consulter les profils</span>
                        </h3>
                    </a>
                </div>
                <!-- end : HOME LAUREATS INET -->

                <!-- begin : HOME VACANCES -->
                <div class="col-md-3 col-lg-3 ahe-entry">
                    <a href="https://www.emploi-territorial.fr/page.php?controller=pubarretemv&voir=cdg&etab=106" title="Déclarations des vacances d'emploi A+" target="_blank">
                        <div class="ahe-entry-img">
                            <img class="img-fluid" src="assets/images/cnfpt003427ori.jpg" alt="">
                        </div>
                        <h3>
                            <span class="bolded">Déclarations vacances d'emploi A+</span>
                            <span class="lighter">consulter les arrêtés</span>
                        </h3>
                    </a>
                </div>
                <!-- end : HOME VACANCES -->

                <!-- begin : HOME LISTE APTITUDES A+ -->
                <div class="col-md-3 col-lg-3 ahe-entry">
                    <a href="http://www.cnfpt.fr/evoluer/lemploi-fpt/listes-daptitude-a/national" title="Listes d'aptitude A+" target="_blank">
                        <div class="ahe-entry-img">
                            <img class="img-fluid" src="assets/images/a-plus_gal.jpg" alt="">
                        </div>
                        <h3>
                            <span class="bolded">Listes d'aptitude A+</span>
                            <span class="lighter">consulter les listes</span>
                        </h3>
                    </a>
                </div>
                <!-- end : HOME LISTE APTITUDES A+ -->

            </div>
        </div>

        <!-- begin : HOME NEWS -->
        <div class="aed-home-news">
            <app-news-teaser></app-news-teaser>
        </div>
        <!-- end : HOME NEWS -->

        <!-- begin : HOME JOBS -->
        <div class="aed-home-jobs aed-jobs aed-home-bloc">
            <app-offre-last></app-offre-last>
        </div>
        <!-- end : HOME JOBS -->
    </div>
</div>
<!-- end : HOME -->
