import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  public activated: Subject<boolean> = new Subject<boolean>();

  constructor(
  ) {}

  show() {
    this.activated.next(true);
  }

  hide() {
    this.activated.next(false);
  }
}
