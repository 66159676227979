import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable()
export class LoaderSmallService {
    public activated: Subject<boolean> = new Subject<boolean>();

    constructor(
    ) {}

    show() {
        this.activated.next(true);
    }

    hide() {
        this.activated.next(false);
    }
}
