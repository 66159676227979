<span class="alv-label aed-icon aed-icon-clock" aria-hidden="true" *ngIf="startDate !== undefined"></span>
<ng-container *ngIf="startDate !== undefined">
    <ng-container *ngIf="end === undefined || end === null; else periode">
        <span class="alv-label"> Depuis </span>
        <span class="alv-value">{{ startDate | date:'y' }}</span>
    </ng-container>

    <ng-template #periode>
        <span class="alv-label" *ngIf="!isSameYear()"> De </span>
        <span class="alv-label" *ngIf="isSameYear()"> En </span>
        <span class="alv-value">{{ startDate | date:'y' }}</span>
        <ng-container *ngIf="!isSameYear()">
            <span class="alv-label"> à </span>
            <span class="alv-value">{{ endDate | date:'y' }}</span>
        </ng-container>
    </ng-template>
</ng-container>
